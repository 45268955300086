import { MaterialIcon } from '@conventioncatcorp/common-fe';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import {
  Button,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Modal,
  ModalBody,
  ModalHeader,
  UncontrolledDropdown,
} from 'reactstrap';
import { useTranslation } from '../../translations';
import { captureError } from '../../utils/errorHandling';
import { CashierContext, CashierContextProps, getBadgePrinter } from './cashiercontext';
import { BadgePrinterBase, PrinterInfoBase, PrinterManagerStatus } from './printers/PrinterBase';
import { ReceiptPrinterWeb } from './printers/ReceiptPrinter';
import { LabelPrinterManager } from './printers/ZebraPrinter';
import { Scanner, useScannerContext } from './scanner';

interface PrinterModalProps {
  readonly close: () => void;
  readonly settings: CashierContextProps;
}

export const PrinterModal: React.FC<PrinterModalProps> = ({ close, settings }) => {
  const { ts } = useTranslation();
  const { addScanner, scanners } = useScannerContext();
  const [availablePrinters, setAvailablePrinters] = useState<PrinterInfoBase[]>([]);
  const [activeReceiptPrinters, setActiveReceiptPrinters] = useState<ReceiptPrinterWeb[]>([]);
  const isWebUSBAvailable = navigator.usb !== undefined;
  const manualPrintMgrs = settings.printerManagers.filter(
    (m) =>
      m.status !== PrinterManagerStatus.Error && m.supportsManualConnect && m.startManualConnect,
  );

  const refreshAvailablePrinters = useCallback(async () => {
    const data = (
      await Promise.all(settings.printerManagers.map(async (m) => await m.getAvailablePrinters()))
    ).flat();

    setAvailablePrinters(data);
  }, [settings.printerManagers, setAvailablePrinters]);

  useEffect(() => {
    for (const m of settings.printerManagers) {
      if (m instanceof LabelPrinterManager) {
        m.addEventListener('connectedDevice', refreshAvailablePrinters);
        m.addEventListener('disconnectedDevice', refreshAvailablePrinters);
      }
    }

    return () => {
      for (const m of settings.printerManagers) {
        if (m instanceof LabelPrinterManager) {
          m.removeEventListener('connectedDevice', refreshAvailablePrinters);
          m.removeEventListener('disconnectedDevice', refreshAvailablePrinters);
        }
      }
    };
  }, [settings.printerManagers, refreshAvailablePrinters]);

  const refreshReceiptPrinters = useCallback(async () => {
    const data = settings.receiptPrinterManagers.flatMap((m) => m.getPrinters());
    setActiveReceiptPrinters(data);
  }, [settings.receiptPrinterManagers, setActiveReceiptPrinters]);

  useEffect(() => {
    for (const m of settings.receiptPrinterManagers) {
      m.addEventListener('connectedDevice', refreshReceiptPrinters);
      m.addEventListener('disconnectedDevice', refreshReceiptPrinters);
    }

    return () => {
      for (const m of settings.receiptPrinterManagers) {
        m.removeEventListener('connectedDevice', refreshReceiptPrinters);
        m.removeEventListener('disconnectedDevice', refreshReceiptPrinters);
      }
    };
  }, [settings.receiptPrinterManagers, refreshReceiptPrinters]);

  useEffect(() => {
    refreshAvailablePrinters().catch(captureError);
  }, [settings, setAvailablePrinters]);

  useEffect(() => {
    refreshReceiptPrinters().catch(captureError);
  }, [settings, setActiveReceiptPrinters]);

  const onClickAddScanner = useCallback(async () => {
    const d = await navigator.serial?.requestPort();
    if (d) {
      addScanner(d);
    }
  }, []);

  const { config } = settings;

  return (
    <Modal className="modal-large print-settings-modal hide-print" isOpen toggle={close}>
      <ModalHeader toggle={close}>Configure devices</ModalHeader>
      <ModalBody>
        <h4>Configured Badge Printers</h4>
        <div>
          {[config.primaryPrinter, config.secondaryPrinter].map((printerUid, index) => {
            // Don't show the printer if it's not set, or if it's the same as the primary printer
            if (!printerUid || (index === 1 && config.primaryPrinter === config.secondaryPrinter)) {
              return null;
            }

            const isPrimary = config.primaryPrinter === printerUid;
            const isSecondary =
              config.secondaryPrinter === printerUid || (isPrimary && !config.secondaryPrinter);

            const printer = getBadgePrinter(settings, printerUid);

            if (!printer) {
              return null;
            }

            return (
              <PrinterItem
                isPrimary={isPrimary}
                isSecondary={isSecondary}
                key={printerUid}
                printer={printer}
                printerInfo={printer}
              />
            );
          })}
        </div>
        <h4>
          <div>Available Badge Printers</div>
          {manualPrintMgrs.length > 0 && (
            <UncontrolledDropdown>
              <DropdownToggle caret>Add Manually</DropdownToggle>
              <DropdownMenu>
                {manualPrintMgrs.map((m) => (
                  <DropdownItem
                    key={m.type}
                    onClick={async () => {
                      await m.startManualConnect!();
                      await refreshAvailablePrinters();
                    }}
                  >
                    {m.name}
                  </DropdownItem>
                ))}
              </DropdownMenu>
            </UncontrolledDropdown>
          )}
        </h4>
        <div>
          {availablePrinters.length === 0 && (
            <div className="print-settings-emptystate">
              {ts('no_other_badge_printers_available')}
            </div>
          )}
          {availablePrinters.map((printer) => {
            const isPrimary = config.primaryPrinter === printer.uid;
            const isSecondary = config.secondaryPrinter === printer.uid;

            return (
              <PrinterItem
                isPrimary={isPrimary}
                isSecondary={isSecondary}
                key={printer.serial}
                printerInfo={printer}
              />
            );
          })}
        </div>
        <ScannerSection
          isWebUSBAvailable={isWebUSBAvailable}
          onClickAddScanner={onClickAddScanner}
          scanners={scanners}
        />
        <ReceiptPrinterSection
          activeReceiptPrinters={activeReceiptPrinters}
          isWebUSBAvailable={isWebUSBAvailable}
          refreshReceiptPrinters={refreshReceiptPrinters}
          settings={settings}
        />
      </ModalBody>
    </Modal>
  );
};

interface PrinterItemProps {
  readonly printer?: BadgePrinterBase;
  readonly isPrimary?: boolean;
  readonly isSecondary?: boolean;
  readonly printerInfo: BadgePrinterBase | PrinterInfoBase;
}

const PrinterItem: React.FC<PrinterItemProps> = ({
  isPrimary,
  isSecondary,
  printer,
  printerInfo,
}) => {
  const { updateCashierSettings, ...settings } = useContext(CashierContext);
  const { printerManagers } = settings;

  const printerManager = printerManagers.find((m) => m.type === printerInfo.type);

  // If there's no printerManager and no printer, then something is really wrong
  if (!printerManager && !printer) {
    return null;
  }

  const setPrimary = () => {
    if (isPrimary) {
      return;
    }

    if (printer) {
      updateCashierSettings!({ primaryPrinter: printer.uid });
    } else if (printerInfo.uid === settings.config.secondaryPrinter) {
      updateCashierSettings!({
        primaryPrinter: settings.config.secondaryPrinter,
        secondaryPrinter: undefined,
      });
    } else if (printerManager) {
      updateCashierSettings!({ primaryPrinter: printerInfo.uid });
    }
  };

  const setSecondary = () => {
    if (isSecondary) {
      return;
    }

    if (printer) {
      updateCashierSettings!({ secondaryPrinter: printer.uid });
    } else if (printerInfo.serial === settings.config.primaryPrinter) {
      updateCashierSettings!({
        secondaryPrinter: undefined,
      });
    } else if (printerManager) {
      updateCashierSettings!({
        secondaryPrinter: printerManager.usePrinter(printerInfo.serial, {})!.uid,
      });
    }
  };

  return (
    <div className="print-settings-printer">
      <div className="print-settings-printer-name">
        <div>
          <strong>{printerInfo.name}</strong>
        </div>
        <div>{printerInfo.type}</div>
      </div>
      <div>
        {printer && (
          <UncontrolledDropdown>
            <DropdownToggle color="link">
              <MaterialIcon name="settings" small />
            </DropdownToggle>
            <DropdownMenu>
              {printer.canConfigure && <DropdownItem>Configure Printer</DropdownItem>}
              <DropdownItem onClick={async () => await printer?.testPrint()}>
                Test Print
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
        )}
        <Button color="primary" disabled={isPrimary} onClick={setPrimary} outline={isPrimary}>
          {isPrimary ? 'Active' : 'Set'} as Primary
        </Button>
        <Button color="secondary" disabled={isSecondary} onClick={setSecondary} outline>
          {isSecondary ? 'Active' : 'Set'} as Secondary
        </Button>
      </div>
    </div>
  );
};

interface ScannerSectionProps {
  readonly isWebUSBAvailable: boolean;
  readonly onClickAddScanner: () => void;
  readonly scanners: Scanner[];
}

const ScannerSection: React.FC<ScannerSectionProps> = ({
  isWebUSBAvailable,
  scanners,
  onClickAddScanner,
}) => {
  const { ts } = useTranslation();
  return (
    <>
      <h4>
        <div>Scanners</div>
        {isWebUSBAvailable && (
          <UncontrolledDropdown>
            <DropdownToggle caret>Add Manually</DropdownToggle>
            <DropdownMenu>
              <DropdownItem key="serialscanner" onClick={onClickAddScanner}>
                {ts('scanner_with_web_serial')}
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
        )}
      </h4>
      <div>
        {scanners.length === 0 && (
          <div className="print-settings-emptystate">
            <p>
              {isWebUSBAvailable
                ? 'No serial scanners connected, try adding one manually.'
                : 'It seems like your browser does not support WebUSB. Please use a supported browser.'}
              <br />
              <small>
                <strong>{ts('usb_keyboard_scanners_are_always')}</strong> and should be ready to use
                without any additional setup.
              </small>
            </p>
          </div>
        )}
        {scanners.map((scanner) => {
          return <ScannerItem key={scanner.info.usbProductId} scanner={scanner} />;
        })}
      </div>
    </>
  );
};

const ScannerItem: React.FC<{ readonly scanner: Scanner }> = ({ scanner }) => {
  const { removeScanner } = useScannerContext();
  const onDelete = useCallback(() => {
    removeScanner(scanner.info);
  }, [scanner, removeScanner]);

  return (
    <div className="print-settings-printer">
      <div className="print-settings-printer-name">
        <div>
          <strong>{scanner.info.usbProductId}</strong>
        </div>
        <div>
          {scanner.info.usbVendorId}
          {scanner.port ? '(Connected)' : '(Disconnected)'}
        </div>
      </div>
      <div>
        <Button color="secondary" onClick={onDelete} outline>
          Remove
        </Button>
      </div>
    </div>
  );
};

interface ReceiptPrinterSectionProps {
  readonly activeReceiptPrinters: ReceiptPrinterWeb[];
  readonly settings: CashierContextProps;
  readonly refreshReceiptPrinters: () => Promise<void>;
  readonly isWebUSBAvailable: boolean;
}

const ReceiptPrinterSection: React.FC<ReceiptPrinterSectionProps> = ({
  activeReceiptPrinters,
  settings,
  refreshReceiptPrinters,
  isWebUSBAvailable,
}) => {
  return (
    <>
      <h4>
        <div>Receipt Printers</div>
        {settings.receiptPrinterManagers.length > 0 && isWebUSBAvailable && (
          <UncontrolledDropdown>
            <DropdownToggle caret>Add Manually</DropdownToggle>
            <DropdownMenu>
              {settings.receiptPrinterManagers.map((m) => (
                <DropdownItem
                  key={m.type}
                  onClick={async () => {
                    await m.startManualConnect!();
                    await refreshReceiptPrinters();
                  }}
                >
                  {m.name}
                </DropdownItem>
              ))}
            </DropdownMenu>
          </UncontrolledDropdown>
        )}
      </h4>
      <div>
        {activeReceiptPrinters.length === 0 && (
          <div className="print-settings-emptystate">
            {isWebUSBAvailable
              ? 'No receipt printers connected, try adding one manually.'
              : 'It seems like your browser does not support WebUSB. Please use a supported browser.'}
          </div>
        )}
        {activeReceiptPrinters.map((printer) => (
          <ReceiptPrinterItem key={printer.serial} printer={printer} />
        ))}
      </div>
    </>
  );
};

interface ReceiptPrinterItemProps {
  readonly printer?: ReceiptPrinterWeb;
}

const ReceiptPrinterItem: React.FC<ReceiptPrinterItemProps> = ({ printer }) => {
  if (printer === undefined) {
    return null;
  }

  return (
    <div className="print-settings-printer">
      <div className="print-settings-printer-name">
        <div>
          <strong>{printer.name}</strong>
        </div>
      </div>
      <div>
        <Button color="secondary" onClick={async () => await printer?.testPrint()}>
          Test Print
        </Button>
        <Button color="secondary" onClick={async () => await printer?.drawerKick()}>
          Kick Cash Drawer
        </Button>
      </div>
    </div>
  );
};
