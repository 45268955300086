import React, { FC, useCallback } from 'react';
import { Link } from 'react-router-dom';
import { LicenseWarnings } from '../../../shared/user/cashier';
import { PermissionBoundary } from '../../components';
import { useTranslation } from '../../translations';

interface RenderMessagesProps {
  readonly type: string;
  readonly title: string;
  readonly messages: string[];
  readonly licenseWarnings?: LicenseWarnings[];
  openUpdateFromScannerModal?(): void;
}

export const RenderMessages: FC<RenderMessagesProps> = ({
  licenseWarnings,
  type,
  title,
  messages,
  openUpdateFromScannerModal,
}) => {
  const { ts } = useTranslation();
  const openScannerModal = useCallback(
    (e: React.MouseEvent<HTMLAnchorElement>) => {
      e.preventDefault();
      openUpdateFromScannerModal?.();
    },
    [openUpdateFromScannerModal],
  );

  if (messages.length === 0 && (licenseWarnings?.length ?? 0) === 0) {
    return null;
  }

  return (
    <div className={`cashier-status-message cashier-status-message-${type}`}>
      <p className={`text-${type}`} style={{ marginBottom: 0 }}>
        {title}
      </p>
      <ul>
        {messages.map((message) => (
          // eslint-disable-next-line react/no-danger
          <li dangerouslySetInnerHTML={{ __html: message }} key={message} />
        ))}
        {licenseWarnings?.includes('dobMismatch') && <li>{ts('date_of_birth_does_not')}</li>}
        {licenseWarnings?.includes('nameMismatch') && (
          <li>
            {ts('name_does_not_match_scanned')}
            <PermissionBoundary inline requiredPermissions={['user:update']}>
              &#40;
              <Link id="openUpdateInfoFromScan" onClick={openScannerModal} to="">
                Update
              </Link>
              &#41;
            </PermissionBoundary>
          </li>
        )}
        {licenseWarnings?.includes('expired') && <li>{ts('scanned_id_has_an_expiration')}</li>}
      </ul>
    </div>
  );
};
