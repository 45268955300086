import { OptionDataValue } from '../options';
import { SettingTermKeys } from '../settings';

export const SettingTermKeysValues = [
  'childRegistrationError',
  'conventionTerms',
  'parentalConsentForm',
  'privacy',
  'terms',
  'vendor',
  'volunteerTerms',
];

export type FormNodeNameType =
  | 'attendanceFlags'
  | 'attendanceType'
  | 'badgeName'
  | 'badgePreview'
  | 'cart'
  | 'divider'
  | 'emergencyContacts'
  | 'linkTermsAgreement'
  | 'login'
  | 'markdown'
  | 'oauthLogin'
  | 'option'
  | 'otherOptions'
  | 'pii'
  | 'profilePicture'
  | 'randomizeButton'
  | 'subheader'
  | 'submit'
  | 'termsAgreement'
  | 'text';

export type FormNodeType =
  | DividerModel
  | FormAttendanceFlagsModel
  | FormBadgePreviewModel
  | FormEmergencyContactsModel
  | FormNodeAccountModel
  | FormNodeAttendanceTypeModel
  | FormNodeCartModel
  | FormNodeLinkTermsAgreementModel
  | FormNodeMarkdownModel
  | FormNodePIIModel
  | FormNodeSubheaderModel
  | FormNodeTermsAgreementModel
  | FormOAuthModel
  | FormOptionModel
  | FormOtherOptionsModel
  | FormProfilePictureModel
  | FormRandomizeButtonModel
  | FormTextModel
  | SubmitModel;

export interface FormLayout {
  finalPage: FinalPageModel;
  footer: string;
  header: string;
  pages: FormPageModel[];
  paymentOptions: FormPaymentOptions;
  stylesheet: string;
  disableBootstrap: boolean;
  timeout?: number;
  title: string;
}

interface FormPaymentOptions {
  showPaymentScreen?: boolean;
  markdown: string;
}

export interface FinalPageModel {
  id: 'final';
  name: string;
  sections: FormSectionModel[];
}

export interface FormPageModel {
  id: string;
  name: string;
  sections: FormSectionModel[];
}

export interface PaymentKioskPage {
  markdown: string;
}

export interface FormSectionModel {
  id: string;
  name: string;
  size?: 'large' | 'medium';
  nodes: FormNodeType[];
}

export interface FormNodeBase {
  id: string;
  displayName?: string;
  className?: string;
  type: FormNodeNameType;
}

export interface DividerModel extends FormNodeBase {
  type: 'divider';
}

export interface SubmitModel extends FormNodeBase {
  type: 'submit';
}

export interface FormOptionModel extends FormNodeBase {
  type: 'option';
  optionId: number | 'badgeName';
  markdown: string;
  defaultValue?: OptionDataValue;
}

export interface FormRandomizeButtonModel extends FormNodeBase {
  type: 'randomizeButton';
}

export interface FormEmergencyContactsModel extends FormNodeBase {
  type: 'emergencyContacts';
}

export interface FormOtherOptionsModel extends FormNodeBase {
  type: 'otherOptions';
}

export interface FormBadgePreviewModel extends FormNodeBase {
  type: 'badgePreview';
}

export interface FormProfilePictureModel extends FormNodeBase {
  type: 'profilePicture';
  title?: string;
  ignoreGravatar?: boolean;
}

export interface FormTextModel extends FormNodeBase {
  type: 'text';
  text: string;
}

export interface FormAttendanceFlagsModel extends FormNodeBase {
  type: 'attendanceFlags';
}

export interface FormNodeMarkdownModel extends FormNodeBase {
  type: 'markdown';
  markdown: string;
}

export type PIIType =
  | 'address'
  | 'badgeName'
  | 'dateOfBirth'
  | 'email'
  | 'phoneNumber'
  | 'realName';

export interface PIIElement {
  type: 'date' | 'email' | 'phoneNumber' | 'regionCountrySelector' | 'text';
  name:
    | 'addressCity'
    | 'addressLine1'
    | 'addressLine2'
    | 'addressStateCountry'
    | 'addressZipcode'
    | 'bornAt'
    | 'email'
    | 'firstName'
    | 'lastName'
    | 'phone'
    | 'preferredName'
    | 'registration[badgeName]';
  displayText?: string;
  optional?: true;
}

interface PIIMapElement {
  piiType: PIIType;
  elements: PIIElement[];
  pii?: boolean;
  name: string;
}

export const piiInputs: PIIMapElement[] = [
  {
    elements: [
      { type: 'text', name: 'firstName', displayText: 'First Name' },
      { type: 'text', name: 'lastName', displayText: 'Last Name' },
      { type: 'text', name: 'preferredName', displayText: 'Preferred Name', optional: true },
    ],
    pii: true,
    piiType: 'realName',
    name: 'Real Name',
  },
  {
    elements: [{ type: 'date', name: 'bornAt', displayText: 'Date of Birth' }],
    pii: true,
    piiType: 'dateOfBirth',
    name: 'Date of Birth',
  },
  {
    elements: [{ type: 'phoneNumber', name: 'phone' }],
    pii: true,
    piiType: 'phoneNumber',
    name: 'Phone',
  },
  {
    elements: [
      { type: 'text', name: 'addressLine1', displayText: 'Address Line 1' },
      { type: 'text', name: 'addressLine2', displayText: 'Address Line 2', optional: true },
      { type: 'text', name: 'addressCity', displayText: 'City' },
      { type: 'regionCountrySelector', name: 'addressStateCountry' },
      { type: 'text', name: 'addressZipcode', displayText: 'Zip / Postal Code' },
    ],
    pii: true,
    piiType: 'address',
    name: 'Address',
  },
  {
    elements: [{ type: 'email', name: 'email', displayText: 'Email Address' }],
    piiType: 'email',
    name: 'Email',
  },
  {
    elements: [{ type: 'text', name: 'registration[badgeName]', displayText: 'Badge Name' }],
    piiType: 'badgeName',
    name: 'Badge Name',
  },
];

export interface FormNodePIIModel extends FormNodeBase {
  type: 'pii';
  subType: PIIType;
  markdown?: string;
}

export interface FormNodeSubheaderModel extends FormNodeBase {
  type: 'subheader';
  markdown: string;
}

export interface FormNodeLinkTermsAgreementModel extends FormNodeBase {
  type: 'linkTermsAgreement';
  markdown: string;
  forceLinkClick?: boolean;
}

export interface FormNodeTermsAgreementModel extends FormNodeBase {
  type: 'termsAgreement';
  markdown: string;
  termsKey: SettingTermKeys;
  maxHeight?: number;
  forceScrollToBottom?: boolean;
}

export interface FormNodeCartModel extends FormNodeBase {
  type: 'cart';
}

export interface FormNodeAccountModel extends FormNodeBase {
  type: 'login';
}

export interface FormOAuthModel extends FormNodeBase {
  type: 'oauthLogin';
  markdown: string;
  target: string;
}

export interface FormNodeAttendanceTypeModel extends FormNodeBase {
  type: 'attendanceType';
}
