import * as Markdownit from 'markdown-it';
import markdownitAttr from 'markdown-it-attrs';
import markdownitBracketedSpans from 'markdown-it-bracketed-spans';
import markdownitSub from 'markdown-it-sub';
import markdownitSup from 'markdown-it-sup';
import React, { FC, useEffect, useMemo } from 'react';
import { useHistory } from 'react-router-dom';

let md: Markdownit | null = null;

interface Props {
  source?: string;
  span?: true;
}

export const RichMarkdownIt: FC<Props> = ({ source, span }) => {
  const history = useHistory();

  useEffect(() => {
    if (!window.reactHistory) {
      // Create reactHistory to be able to safely change pages without reloading... Jeez
      window.reactHistory = (path) => {
        history.push(path);
      };
    }
  });

  // Let's avoid re-rendering the markdown over, and over, and over again.
  const memoizedMarkdown = useMemo(() => {
    if (!source) {
      return null;
    }

    const ElClass = span ? 'span' : 'div';
    return (
      <ElClass
        className="react-markdown"
        dangerouslySetInnerHTML={{ __html: setupMarkdown().render(source) }}
      />
    );
  }, [source, span]);

  return memoizedMarkdown;
};

function setupMarkdown(): Markdownit {
  if (md) {
    return md;
  }

  md = new Markdownit();
  md.use(markdownitAttr, {
    allowedAttributes: ['id', 'class'],
    leftDelimiter: '{',
    rightDelimiter: '}',
  });

  md.use(markdownitBracketedSpans);
  md.use(markdownitSub);
  md.use(markdownitSup);

  const linkOpenDefault = md.renderer.rules.link_open;
  md.renderer.rules.link_open = (tokens, idx, options, env, self) => {
    const token = tokens[idx];
    const aIndex = token.attrIndex('href');

    const [, link] = token.attrs![aIndex];

    // If local link (starts with /, no URI prefix)
    if (link.startsWith('/')) {
      return `<a href='${link}' onClick='window.reactHistory("${link}"); return false;'>${token.content}</span>`;
    }

    token.attrSet('target', '_blank');

    return linkOpenDefault
      ? linkOpenDefault(tokens, idx, options, env, self)
      : self.renderToken(tokens, idx, options);
  };

  return md;
}
