import { JSONForm } from '@conventioncatcorp/common-fe/dist/components/json-form/JSONForm';
import React, { FC, useCallback, useState } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Button, Card, CardBody, CardTitle, Col, FormGroup, Input, Label, Row } from 'reactstrap';
import { passwordScore } from '../../../shared/user/password';
import {
  MaterialIcon,
  PageHeader,
  PasswordChangeInput,
  UserStateComponent,
} from '../../components';
import { useTranslation } from '../../translations';
import { useQuery } from '../../utils';

const Form: FC<{ readonly onSuccess: () => void }> = ({ onSuccess }) => {
  const qry = useQuery();
  const code = qry.get('code') ?? '';

  const { ts } = useTranslation();
  return (
    <>
      <p>{ts('to_complete_your_password_reset')}</p>
      <hr />
      <div className="text-center">
        <JSONForm id="resetForm" method="patch" onSuccess={onSuccess} path="/api/users/reset">
          <FormGroup row>
            <Label for="id" sm={4}>
              {ts('reset_code')}
            </Label>
            <Col sm={8}>
              <Input defaultValue={code} id="code" name="code" required />
            </Col>
          </FormGroup>
          <hr />
          <PasswordChangeInput isNew passwordScore={passwordScore} sm={8} />
          <FormGroup>
            <Button block color="primary" id="resetPassword" type="submit">
              {ts('reset_password')}
            </Button>
          </FormGroup>
          <FormGroup>
            <Link className="btn btn-outline-secondary btn-block" id="loginPage" to="/login">
              {ts('back_to_login')}
            </Link>
          </FormGroup>
        </JSONForm>
      </div>
    </>
  );
};

const Success: FC = () => {
  const { ts } = useTranslation();
  const redirect = useQuery().get('redirect');

  if (redirect) {
    toast.success(ts('your_password_has_been_reset'));
    return <Redirect to={redirect} />;
  }

  return (
    <div className="text-center" id="resetComplete">
      <MaterialIcon className="margin-bottom-10" large name="security" type="success" />
      <CardTitle>Success!</CardTitle>
      <p>{ts('your_password_has_been_successfully')}</p>
      <hr />
      <Link className="btn btn-link btn-block" to="/login">
        {ts('go_back_to_login')}
      </Link>
    </div>
  );
};

export const ResetComponent: FC = () => {
  const { ts } = useTranslation();
  const [success, setSuccess] = useState(false);
  const onSuccess = useCallback(() => {
    setSuccess(true);
  }, []);

  return (
    <UserStateComponent requireLoggedOut>
      <PageHeader>{ts('password_reset')}</PageHeader>
      <Row className="justify-content-center">
        <Col lg={6} xs={12}>
          <Card>
            <CardBody>{success ? <Success /> : <Form onSuccess={onSuccess} />}</CardBody>
          </Card>
        </Col>
      </Row>
    </UserStateComponent>
  );
};
