import React, { FC } from 'react';
import { Card, CardBody, CardHeader, Col, FormGroup, Input, Label } from 'reactstrap';
import { LazyMarkdown } from '../../components';
import { useTranslation } from '../../translations';

interface ConventionPolicyProps {
  readonly policy: string;
  readonly checked: boolean;
  readonly disabled: boolean;
  onChange(state: boolean): void;
}

export const ConventionPolicy: FC<ConventionPolicyProps> = ({
  policy,
  checked,
  disabled,
  onChange,
}) => {
  const { ts } = useTranslation();
  return (
    <Col className="margin-bottom-10" lg={12} xs={12}>
      <Card>
        <CardHeader>{ts('convention_policy')}</CardHeader>
        <CardBody>
          <LazyMarkdown source={policy} />
          <FormGroup>
            <div className="custom-control custom-checkbox margin-top-10">
              <Input
                checked={checked}
                className="custom-control-input"
                disabled={disabled}
                id="agreeConTerms"
                onChange={(e) => onChange(e.target.checked)}
                type="checkbox"
              />
              <Label className="custom-control-label" for="agreeConTerms">
                {ts('i_have_read_and_agree')}
              </Label>
            </div>
          </FormGroup>
        </CardBody>
      </Card>
    </Col>
  );
};
