import React, { FC, useCallback, useState } from 'react';
import { Card, CardBody, Col, Row } from 'reactstrap';
import {
  AgeCheck,
  AgeCheckResult,
  PageHeader,
  PolicyComponent,
  UserStateComponent,
} from '../../../components';
import { useTranslation } from '../../../translations';
import { useConvention } from '../../../utils';
import { makeLazyComponent } from '../../../utils/lazy';
import { RegisterComplete } from './complete';
import { RegisterForm } from './form';
import { StripeIdentityResult } from './stripeIdentity/model';

const LazyStripeIdentityRegistration = makeLazyComponent(
  async () =>
    (await import(/* webpackChunkName: "stripeIdentity" */ './stripeIdentity'))
      .StripeIdentityRegistration,
);

type RegistrationStage = 'ageCheck' | 'registerComplete' | 'registerForm' | 'termsCheck';

export const RegisterComponent: FC = () => {
  const { ts } = useTranslation();
  const [stage, setStage] = useState<RegistrationStage>('ageCheck');
  const [isChild, setIsChild] = useState<boolean>(false);

  const ageCheckOnSelect = useCallback((res: AgeCheckResult) => {
    if (res !== AgeCheckResult.Underage) {
      setStage('termsCheck');
      setIsChild(res === AgeCheckResult.Teen);
    }
  }, []);

  return (
    <UserStateComponent requireLoggedOut>
      <PageHeader className={['mt-3']}>{ts('account_registration')}</PageHeader>
      <Row className="justify-content-center">
        {stage === 'ageCheck' && (
          <Col lg={6} xs={12}>
            <AgeCheck onSelect={ageCheckOnSelect} />
          </Col>
        )}
        {stage === 'termsCheck' && (
          <Col lg={6} xs={12}>
            <Card>
              <CardBody className="text-center">
                <PolicyComponent
                  isChild={isChild}
                  onAccept={() => {
                    setStage('registerForm');
                  }}
                />
              </CardBody>
            </Card>
          </Col>
        )}
        {stage === 'registerForm' && (
          <RegisterContainer
            onComplete={() => {
              setStage('registerComplete');
            }}
          />
        )}
        {stage === 'registerComplete' && <RegisterComplete />}
      </Row>
    </UserStateComponent>
  );
};

const RegisterContainer: FC<{ onComplete(): void }> = ({ onComplete }) => {
  const [stripeIdentityResult, setStripeIdentityResult] = useState<StripeIdentityResult | null>(
    null,
  );

  const onStripeIdentityComplete = useCallback(
    (result: StripeIdentityResult) => {
      setStripeIdentityResult(result);
    },
    [setStripeIdentityResult],
  );

  const convention = useConvention();

  if (convention.identityVerification !== 'none' && !stripeIdentityResult?.identityId) {
    return <LazyStripeIdentityRegistration onComplete={onStripeIdentityComplete} />;
  }

  return (
    <RegisterForm
      forceValues={stripeIdentityResult?.user}
      identityId={stripeIdentityResult?.identityId}
      onComplete={onComplete}
    />
  );
};
