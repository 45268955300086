import React, { FC } from 'react';
import { UserStateComponent } from '../../../components';
import { useTranslation } from '../../../translations';
import { isLogicError, useQuery, useUser } from '../../../utils';
import { LoadingWrapper } from '../../../utils/LoadingWrapper';
import { LogicError } from '../../../utils/errorHandling';

export const VendorAssistantConfirmComponent: FC = () => {
  const { ts } = useTranslation();
  const user = useUser();
  const code = useQuery().get('code');

  return (
    <UserStateComponent>
      {code === null ? (
        <div id="noCodeFound">{ts('no_code_present_in_the')}</div>
      ) : (
        <LoadingWrapper<void, void>
          dataFetcher={async () => await api.confirmAssistant(user!.id, code)}
          errorDisplay={(err) =>
            isLogicError(err, LogicError.AssistanceRequestNotFound) && (
              <div id="assistantFail">
                <h3>{ts('invalid_confirmation_code')}</h3>
                <p>{ts('the_confirmation_code_provided_is')}</p>
                <p>{ts('if_you_are_sure_this')}</p>
              </div>
            )
          }
          inline
        >
          {() => (
            <div id="assistantSuccess">
              <h3>Confirmed!</h3>
              <p>{ts('thank_you_for_confirming_that')}</p>
              <p>{ts('you_can_now_close_this')}</p>
            </div>
          )}
        </LoadingWrapper>
      )}
    </UserStateComponent>
  );
};
