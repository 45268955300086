import React, { FC, useEffect, useState } from 'react';
import { FormGroup, Input, Label } from 'reactstrap';
import { AttendeeEmails } from '../../../../APIClient';
import { Loading } from '../../../../components';
import { useTranslation } from '../../../../translations';
import { setElementInArray, useFetcher } from '../../../../utils';
import { LoadingState } from '../../../../utils/LoadingState';

interface AttendeeSelectorProps {
  readonly selectionChanged: (userIds: number[]) => void;
}

export const AttendeeSelector: FC<AttendeeSelectorProps> = ({ selectionChanged }) => {
  const [filter, setFilter] = useState<AttendeeEmails>({
    productIds: [],
    regStatus: { paid: true, unpaid: false },
    withChildren: false,
  });

  const loadedUserIds = useFetcher(async () => {
    const { userIds } = await api.getAttendeeEmails(filter);
    return userIds;
  }, [filter]);

  useEffect(() => {
    if (loadedUserIds.state === LoadingState.Done) {
      selectionChanged(loadedUserIds.data!);
    } else {
      selectionChanged([]);
    }
  }, [loadedUserIds.state, loadedUserIds.data]);

  return <AttendeeProductSelector filter={filter} setFilter={setFilter} />;
};

interface AttendeeProductSelectorProps {
  readonly filter: AttendeeEmails;
  readonly setFilter: (filter: AttendeeEmails) => void;
}

const AttendeeProductSelector: FC<AttendeeProductSelectorProps> = ({ filter, setFilter }) => {
  const { ts } = useTranslation();
  const products = useFetcher(async () => {
    const categories = await api.getProductCategories();
    const productsCat = categories.find(({ isRegistration }) => isRegistration);

    if (!productsCat) {
      return [];
    }

    return await api.getFullProductsByCategory(productsCat.id);
  }, []);

  if (products.state !== LoadingState.Done) {
    return <Loading />;
  }

  return (
    <>
      <FormGroup>
        <Label for="area">{ts('products')}</Label>
        {products.data!.map((product) => (
          <div className="custom-control custom-checkbox margin-top-10" key={product.id}>
            <Input
              checked={filter.productIds.includes(product.id)}
              className="custom-control-input"
              id={`product_${product.id}`}
              onChange={(e) => {
                setFilter({
                  ...filter,
                  productIds: setElementInArray(filter.productIds, product.id, e.target.checked),
                });
              }}
              type="checkbox"
            />
            <Label className="custom-control-label" for={`product_${product.id}`}>
              {product.name}
            </Label>
          </div>
        ))}
      </FormGroup>
      <FormGroup>
        <Label for="area">{ts('registration_status')}</Label>
        <div className="custom-control custom-checkbox margin-top-10">
          <Input
            checked={filter.regStatus.paid}
            className="custom-control-input"
            id="reg_paid"
            onChange={(e) => {
              setFilter({
                ...filter,
                regStatus: {
                  ...filter.regStatus,
                  paid: e.target.checked,
                },
              });
            }}
            type="checkbox"
          />
          <Label className="custom-control-label" for="reg_paid">
            Paid
          </Label>
        </div>
        <div className="custom-control custom-checkbox margin-top-10">
          <Input
            checked={filter.regStatus.unpaid}
            className="custom-control-input"
            id="reg_unpaid"
            onChange={(e) => {
              setFilter({
                ...filter,
                regStatus: {
                  ...filter.regStatus,
                  unpaid: e.target.checked,
                },
              });
            }}
            type="checkbox"
          />
          <Label className="custom-control-label" for="reg_unpaid">
            Unpaid
          </Label>
        </div>
        <div className="custom-control custom-checkbox margin-top-10">
          <Input
            checked={filter.withChildren}
            className="custom-control-input"
            id="reg_parents"
            onChange={(e) => {
              setFilter({
                ...filter,
                withChildren: e.target.checked,
              });
            }}
            type="checkbox"
          />
          <Label className="custom-control-label" for="reg_parents">
            {ts('with_children')}
          </Label>
        </div>
      </FormGroup>
    </>
  );
};
