import { showReportDialog } from '@conventioncatcorp/common-fe/dist/errorHandling';
import React, { Component, ReactNode } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { Button, Col, Container, Row } from 'reactstrap';
import { useTranslation } from '../translations';
import { captureError } from '../utils/errorHandling';
import { MaterialIcon, PageHeader } from '.';

import './ErrorBoundary.scss';

interface Props {
  redirectText?: string;
  redirectTo?: string;
}

interface State {
  error?: Error;
  sentryErrorId?: string;
}

const ErrorBody: React.FC<{ readonly sentryErrorId?: string }> = ({ sentryErrorId }) => {
  const { ts } = useTranslation();

  return (
    <>
      <PageHeader>{ts('oops_well_this_is_awkward')}</PageHeader>
      <p>{ts('it_looks_like_there_was')}</p>
      <p>{ts('weve_logged_the_error_and')}</p>
      <hr />
      <p>
        {ts('in_the_meantime_heres_what')}
        <ul className="list-unstyled">
          <li>
            <MaterialIcon name="refresh" /> <u>Refresh the page</u> (sometimes that can help!)
          </li>
          <li>
            <MaterialIcon name="access_time" /> <u>Try again</u> in a little while. (Give it 10
            minutes or so.)
          </li>
          {sentryErrorId && (
            <li>
              <MaterialIcon name="bug_report" /> <u>Provide additional information</u> to help us
              fix the issue quicker.
            </li>
          )}
        </ul>
      </p>
    </>
  );
};

class ErrorBoundary extends Component<Props & RouteComponentProps, State> {
  public static getDerivedStateFromError(error: Error): State {
    return { error };
  }

  public constructor(props: Props & RouteComponentProps) {
    super(props);
    this.state = {};
  }

  public override componentDidCatch(err: Error): void {
    this.setState({
      sentryErrorId: captureError(err),
    });
  }

  public override render(): JSX.Element | ReactNode {
    const { error, sentryErrorId } = this.state;

    if (!error) {
      return this.props.children;
    }

    const { redirectText, redirectTo, history } = this.props;

    return (
      <Container className="mt-3 react-error">
        <Row className="justify-content-center">
          <Col className="margin-bottom-10" lg={5} xs={12}>
            <ErrorBody sentryErrorId={sentryErrorId} />
            <hr />
          </Col>
          <Col xs={12} />
          {sentryErrorId && (
            <Col className="mb-4" lg={2} xs={12}>
              <Button
                block
                color="primary"
                onClick={() => {
                  showReportDialog(sentryErrorId);
                }}
              >
                Provide Additional Info
              </Button>
            </Col>
          )}
          {history.location.pathname !== '/' && (
            <Col className="mb-4" lg={2} xs={12}>
              <Button
                block
                color="secondary"
                onClick={() => {
                  history.push(redirectTo ?? '/');
                }}
                outline
              >
                {redirectText ?? 'Back to the Homepage'}
              </Button>
            </Col>
          )}
          <Col xs={12} />
          <Col lg={5} xs={12}>
            <p className="text-secondary">
              <small>
                Uncaught Exception &mdash; {error.name}: {error.message}
              </small>
            </p>
          </Col>
        </Row>
      </Container>
    );
  }
}

const routedErrorBoundary = withRouter(ErrorBoundary);
export { routedErrorBoundary as ErrorBoundary };
