import React, { FC, useCallback, useState } from 'react';
import { Button, Card, CardBody, CardText, CardTitle, Col, Row } from 'reactstrap';
import { useTranslation } from '../translations';
import { useConfig, useConvention } from '../utils';
import { MaterialIcon, TermsLoader } from '.';

export enum AgeCheckResult {
  Adult,
  Teen,
  Underage,
}

interface AgeCheckProps {
  onSelect(res: AgeCheckResult): void;
}

function yearsAgo(date: Date, years: number): Date {
  const ret = new Date(date);
  ret.setFullYear(date.getFullYear() - years);
  return ret;
}

export const AgeCheck: FC<AgeCheckProps> = ({ onSelect }) => {
  const convention = useConvention();

  if (convention.identityVerification !== 'none') {
    return <AgeRestrictionBarrier onSelect={onSelect} />;
  }

  return <AllAgeCon onSelect={onSelect} />;
};

const AgeRestrictionBarrier: FC<AgeCheckProps> = ({ onSelect }) => {
  const { t, ts } = useTranslation();
  const [underage, setUnderage] = useState(false);
  const selectAdult = useCallback(() => {
    onSelect(AgeCheckResult.Adult);
  }, []);

  const selectUnderage = useCallback(() => {
    setUnderage(true);
    onSelect(AgeCheckResult.Underage);
  }, [onSelect]);

  const { startAt, minorAgeThreshold } = useConvention();
  const config = useConfig();
  const date = startAt.getUTCDate();
  const month = startAt.toLocaleDateString(ts('locale_code'), { month: 'long', timeZone: 'UTC' });
  const year = startAt.getUTCFullYear();

  if (underage) {
    return (
      <Card>
        <CardBody className="text-center">
          <MaterialIcon large name="error" type="danger" />
          <CardTitle>{t('age_check.we_are_sorry')}</CardTitle>
          <CardText>
            <p>{t('age_check.this_is_a_minimum_age_convention', String(minorAgeThreshold))}</p>
            <p>
              {t('age_check.if_become_over_minimum_age', String(minorAgeThreshold))}{' '}
              <strong>{config.contact.email.registration}</strong>.
            </p>
          </CardText>
          <Button
            block
            color="primary"
            onClick={() => {
              setUnderage(false);
            }}
            style={{ marginTop: '20px' }}
          >
            {t('go_back')}
          </Button>
        </CardBody>
      </Card>
    );
  }

  return (
    <Card>
      <CardBody className="text-center">
        <CardTitle>{t('age_check.age_check')}</CardTitle>
        <CardText>{t('age_check.confirm_minimum_age', String(minorAgeThreshold))}</CardText>
        <Row className="justify-content-center">
          <Col className="margin-bottom-10" lg={8} onClick={selectAdult} xs={12}>
            <Button block color="primary" id="age-option-adult">
              {t(
                'age_check.age_declaration_over',
                String(month),
                String(date),
                String(year - minorAgeThreshold),
                String(minorAgeThreshold),
              )}
            </Button>
          </Col>
          <Col className="margin-bottom-10" lg={8} onClick={selectUnderage} xs={12}>
            <Button block color="primary" id="age-option-teen">
              {t(
                'age_check.age_declaration_under',
                String(month),
                String(date),
                String(year - minorAgeThreshold),
                String(minorAgeThreshold - 1),
              )}
            </Button>
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
};

const AllAgeCon: FC<AgeCheckProps> = ({ onSelect }) => {
  const { t, ts } = useTranslation();
  const [underage, setUnderage] = useState(false);
  const selectAdult = useCallback(() => {
    onSelect(AgeCheckResult.Adult);
  }, []);

  const selectTeen = useCallback(() => {
    onSelect(AgeCheckResult.Teen);
  }, []);

  const selectUnderage = useCallback(() => {
    setUnderage(true);
    onSelect(AgeCheckResult.Underage);
  }, [onSelect]);

  const { startAt, timeZone, childAgeThreshold, minorAgeThreshold } = useConvention();

  const dateFormatter = new Intl.DateTimeFormat(ts('locale_code'), {
    day: 'numeric',
    month: 'long',
    timeZone,
    year: 'numeric',
  });

  if (underage) {
    return <UnderAgeWarning />;
  }

  let cutoffDate = yearsAgo(startAt, childAgeThreshold);
  if (cutoffDate > yearsAgo(new Date(), 13)) {
    cutoffDate = yearsAgo(new Date(), 13);
  }

  const cutoffDateStr = dateFormatter.format(cutoffDate);
  return (
    <Card>
      <CardBody className="text-center">
        <CardTitle>{t('age_check.age_check')}</CardTitle>
        <CardText>{t('age_check.confirm_minimum_age')}</CardText>
        <Row className="justify-content-center">
          <Col className="margin-bottom-10" lg={8} onClick={selectAdult} xs={12}>
            <Button block color="primary" id="age-option-adult">
              {t(
                'age_check.age_declaration__adult',
                dateFormatter.format(yearsAgo(startAt, minorAgeThreshold)),
                String(minorAgeThreshold),
              )}
            </Button>
          </Col>
          {childAgeThreshold < minorAgeThreshold && (
            <Col className="margin-bottom-10" lg={8} onClick={selectTeen} xs={12}>
              <Button block color="primary" id="age-option-teen">
                {t(
                  'age_check.age_declaration__minor',
                  dateFormatter.format(yearsAgo(startAt, minorAgeThreshold)),
                  String(childAgeThreshold),
                  String(minorAgeThreshold),
                )}
              </Button>
            </Col>
          )}
          <Col className="margin-bottom-10" lg={8} onClick={selectUnderage} xs={12}>
            <Button block color="primary" id="age-option-underage">
              {t('age_check.age_declaration__minor', cutoffDateStr, String(childAgeThreshold))}
            </Button>
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
};

const UnderAgeWarning: FC = () => {
  const { t } = useTranslation();
  return (
    <Card>
      <CardBody className="text-center">
        <MaterialIcon large name="error" type="danger" />
        <CardTitle>{t('age_check.we_are_sorry')}</CardTitle>
        <CardText>
          <TermsLoader name="childRegistrationError" />
        </CardText>
      </CardBody>
    </Card>
  );
};
