import React, { FC } from 'react';
import { Alert, FormGroup } from 'reactstrap';
import { RaffleWithUserDetails } from '../../shared/orders/raffle';
import { useTranslation } from '../translations';
import { LoadingWrapper } from '../utils/LoadingWrapper';
import { ActionButton } from './ActionButton';

interface Props {
  readonly raffle?: Pick<RaffleWithUserDetails, 'id' | 'roleOverride'>;
}

export const RaffleContainer: FC<Props> = ({ children, raffle }) => {
  // If there's no raffle, or user is a volunteer and volunteerOverride is enabled
  if (!raffle || raffle.roleOverride) {
    return <>{children}</>;
  }

  return (
    <LoadingWrapper dataFetcher={async () => await api.getRaffleById(raffle.id)}>
      {(data, refresh) => (
        <RaffleState raffle={data} refresh={refresh}>
          {children}
        </RaffleState>
      )}
    </LoadingWrapper>
  );
};

interface RaffleStateProps {
  readonly raffle: RaffleWithUserDetails;
  readonly refresh: () => void;
}

const RaffleState: FC<RaffleStateProps> = ({ children, raffle, refresh }) => {
  const { ts } = useTranslation();
  const ticketStatus = raffle.ticket?.status;
  const hasEntry = ticketStatus === 'waiting';

  if (ticketStatus === 'winner' || ticketStatus === 'redeemed') {
    return (
      <>
        <Alert className="raffle-status-winner" color="success">
          {ts('you_have_won_in_this')}
        </Alert>
        <br />
        {children}
      </>
    );
  }

  if (ticketStatus === 'rescinded') {
    return (
      <Alert className="raffle-status-rescinded" color="danger">
        {ts('your_ticket_has_been_rescinded')}
      </Alert>
    );
  }

  if (raffle.status !== 'open') {
    if (hasEntry) {
      return raffle.status === 'closed' ? (
        <Alert className="raffle-status-entered-pending-draw" color="info">
          <p>{ts('youre_in_the_raffle_for')}</p>
          <p>{ts('the_raffle_is_now_closed')}</p>
        </Alert>
      ) : (
        <Alert className="raffle-status-lost" color="secondary">
          <p>{ts('unfortunately_you_didnt_win_in')}</p>
        </Alert>
      );
    }

    return <Alert color="secondary">{ts('this_raffled_product_is_no')}</Alert>;
  }

  return (
    <FormGroup>
      {hasEntry ? (
        <Alert className="raffle-status-entered" color="primary">
          <p>{ts('youre_in_the_raffle_for')}</p>
          <p>{ts('once_the_drawing_takes_place')}</p>
        </Alert>
      ) : (
        <Alert className="raffle-status-not-entered" color="info">
          {ts('this_is_a_raffled_product')}
        </Alert>
      )}
      <ActionButton
        action={`/api/raffles/${raffle.id}/participate`}
        block
        className={`raffle-button-${hasEntry ? 'leave' : 'enter'}`}
        color={hasEntry ? 'danger' : 'primary'}
        id={`raffle${raffle.id}`}
        method={hasEntry ? 'delete' : 'post'}
        onSuccess={refresh}
        outline={hasEntry}
        payload={hasEntry ? undefined : { join: true }}
      >
        {hasEntry ? 'Leave' : 'Enter'} Raffle
      </ActionButton>
    </FormGroup>
  );
};
