import React, { ReactNode } from 'react';
import { MessageCard, PageHeader } from '../../components';
import { useTranslation } from '../../translations';
import { isLogicError } from '../../utils';
import { LogicError } from '../../utils/errorHandling';

const RegistrationClosed = () => {
  const { ts } = useTranslation();
  return (
    <MessageCard icon="remove_circle" id="registrationClosed" level="danger">
      {ts('registration_for_the_convention_is')}
    </MessageCard>
  );
};

export const registrationClosedHandler = (err: Error): ReactNode =>
  isLogicError(err, LogicError.RegistrationClosed) && (
    <>
      <PageHeader>Event Registration</PageHeader>
      <RegistrationClosed />
    </>
  );
