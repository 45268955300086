import { JSONForm } from '@conventioncatcorp/common-fe/dist/components/json-form/JSONForm';
import React, { FC, useMemo, useState } from 'react';
import { Badge, Button, Card, CardBody, CardHeader, FormGroup, Input, Label } from 'reactstrap';
import { ProductModel } from '../../../../../shared/orders';
import { ActionButton, ElementHeader, ElementSelector, MaterialIcon } from '../../../../components';
import { useTranslation } from '../../../../translations';
import { displayName } from '../../../../utils';
import { LocalSearchProvider } from '../utils';

interface AddonInfoProps {
  readonly product: ProductModel;
  readonly onUpdate: () => void;
}

const enum AddonInfoMode {
  View,
  Edit,
}

export const AddonInfo: FC<AddonInfoProps> = ({ product, onUpdate }) => {
  const { ts } = useTranslation();
  const [mode, setMode] = useState(AddonInfoMode.View);
  const [selectedId, setSelectedId] = useState<number>();
  const searchProvider = useMemo(
    () => new LocalSearchProvider(async () => await api.getProducts()),
    [],
  );

  if (mode === AddonInfoMode.View) {
    return (
      <Card className="margin-top-10" id="productAddons">
        <CardHeader>
          <div className="card-sub-header">{ts('addons')}</div>
          {!product.deletedAt && (
            <div className="float-right">
              <Button
                color="success"
                id="add"
                onClick={() => {
                  setMode(AddonInfoMode.Edit);
                }}
                title="Add"
              >
                <MaterialIcon name="add" />
              </Button>
            </div>
          )}
        </CardHeader>
        <CardBody>
          {product.addons.length === 0 && <i>There are no addons for this product.</i>}
          {product.addons.length > 0 &&
            product.addons.map((addon) => (
              <div id={`addon${addon.id}`} key={addon.id}>
                <ElementHeader
                  icon={{ iconName: 'card_giftcard' }}
                  rightContent={
                    <ActionButton
                      action={`/api/products/${product.id}/addons/${addon.id}`}
                      className="action-delete"
                      color="danger"
                      method="delete"
                      onSuccess={onUpdate}
                      outline
                      title="Delete"
                    >
                      <MaterialIcon name="delete" />
                    </ActionButton>
                  }
                  title={`${displayName(addon)} (ID: ${addon.id})`}
                >
                  {addon.isFree ? (
                    <Badge color="success" pill>
                      {ts('included_free')}
                    </Badge>
                  ) : (
                    <Badge color="secondary" pill>
                      {ts('not_included_extra_purchase')}
                    </Badge>
                  )}
                </ElementHeader>
              </div>
            ))}
        </CardBody>
      </Card>
    );
  }

  return (
    <Card className="margin-top-10">
      <CardHeader>
        <div className="card-sub-header">{ts('addons')}</div>
        {!product.deletedAt && (
          <div className="float-right">
            <Button
              color="danger"
              id="cancel"
              onClick={() => {
                setMode(AddonInfoMode.View);
              }}
              title={ts('cancel')}
            >
              <MaterialIcon name="cancel" />
            </Button>
          </div>
        )}
      </CardHeader>
      <CardBody>
        <JSONForm
          id="newAddonForm"
          method="post"
          onSuccess={() => {
            onUpdate();
            setMode(AddonInfoMode.View);
          }}
          path={`/api/products/${product.id}/addons`}
        >
          <FormGroup>
            <Label for="product">{ts('product_search')}</Label>
            <ElementSelector<ProductModel>
              id="addonProductSelector"
              maxItems={1}
              searchProvider={searchProvider}
              singleSelectionIdChanged={setSelectedId}
            />
            <Input id="addonId" name="addonId" type="hidden" value={selectedId} />
          </FormGroup>
          <FormGroup style={{ marginLeft: '7px' }}>
            <div className="custom-control custom-checkbox margin-top-10">
              <Input className="custom-control-input" id="isFree" name="isFree" type="checkbox" />
              <Label className="custom-control-label" for="isFree">
                {ts('addon_is_complimentary_and_should')}
              </Label>
            </div>
          </FormGroup>
          <Button block color="primary" disabled={!selectedId} id="submitNewAddon">
            {ts('create_addon')}
          </Button>
        </JSONForm>
      </CardBody>
    </Card>
  );
};
