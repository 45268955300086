import React, { FC, useState } from 'react';
import {
  Badge,
  Button,
  ButtonGroup,
  CardBody,
  CardFooter,
  CardHeader,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from 'reactstrap';
import { Order, OrderRefund } from '../../../../shared/orders';
import { DateTime } from '../../../components/Date';
import { getRefund, getRefundRequestReason } from '../../../models';
import { useTranslation } from '../../../translations';
import { classNames } from '../../../utils';
import { cToUsdStrPref } from '../../../utils/cToUsdStr';

enum RefundInfoTab {
  RequestInfo,
  RelatedPayments,
}

export const RefundInfo: FC<{
  readonly request: OrderRefund;
  onAction(type: 'approve' | 'reject'): void;
}> = ({ request, onAction }) => {
  const { ts } = useTranslation();
  const [infoTab, setInfoTab] = useState<RefundInfoTab>(1);

  const { order } = request;

  return (
    <>
      <CardHeader>
        <span>
          {ts('refund')} #{request.id} &mdash; Order #{order.id}
        </span>
      </CardHeader>
      <CardBody>
        <Nav className="margin-bottom-10" tabs>
          <NavItem>
            <NavLink
              className={classNames({ active: infoTab === RefundInfoTab.RequestInfo })}
              onClick={() => {
                setInfoTab(RefundInfoTab.RequestInfo);
              }}
            >
              Request Info
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classNames({ active: infoTab === RefundInfoTab.RelatedPayments })}
              onClick={() => {
                setInfoTab(RefundInfoTab.RelatedPayments);
              }}
            >
              Related Payments
            </NavLink>
          </NavItem>
        </Nav>
        <TabContent activeTab={infoTab}>
          <TabPane tabId={RefundInfoTab.RequestInfo}>
            <RequestInfo id={request.id} order={request.order} />
          </TabPane>
          <TabPane className="table" tabId={RefundInfoTab.RelatedPayments}>
            {order.payments.map((payment) => (
              <div className="table-item" key={payment.id}>
                <p>
                  Payment #{payment.id} &mdash; {payment.status === 'refunded' && '-'}
                  {cToUsdStrPref(payment.amount)}&nbsp;
                  {payment.status === 'success' ? (
                    <Badge color="success">{ts('success')}</Badge>
                  ) : (
                    <Badge color="secondary">{ts('refund')}</Badge>
                  )}
                </p>
                <p>Gateway: {payment.gateway}</p>
                <p>Reference: {payment.reference}</p>
              </div>
            ))}
          </TabPane>
        </TabContent>
      </CardBody>
      <CardFooter>
        <ButtonGroup>
          <Button
            onClick={() => window.open(`/housekeeping/attendees/user/${order.userId}`, '_blank')}
          >
            Profile
          </Button>
          {request.status === 'pending' && (
            <>
              <Button color="primary" id="approveRefund" onClick={() => onAction('approve')}>
                Approve
              </Button>
              <Button color="danger" id="rejectRefund" onClick={() => onAction('reject')}>
                Reject
              </Button>
            </>
          )}
        </ButtonGroup>
      </CardFooter>
    </>
  );
};

const RequestInfo: FC<{ readonly order: Order; readonly id: number }> = ({ order, id }) => {
  const refund = getRefund(order, id);

  const { ts } = useTranslation();
  return (
    <>
      <h6>{ts('requested_at')}</h6>
      <p>
        <DateTime value={refund.createdAt} />
      </p>

      <h6>{ts('reason_for_request')}</h6>
      <p>{getRefundRequestReason(refund.requestReason)}</p>

      <h6>{ts('additional_info')}</h6>
      <p>{refund.requestAddInfo ?? <i>{ts('no_additional_info_provided')}</i>}</p>
    </>
  );
};
