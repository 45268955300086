import { JSONForm } from '@conventioncatcorp/common-fe/dist/components/json-form/JSONForm';
import React, { ChangeEvent, FC, useCallback, useMemo, useState } from 'react';
import { toast } from 'react-toastify';
import {
  Alert,
  Button,
  Card,
  CardBody,
  Col,
  FormText,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from 'reactstrap';
import {
  ActionButton,
  AssetUpload,
  MaterialIcon,
  PageHeader,
  PermissionBoundary,
  UserStateComponent,
} from '../../components';
import { BadgeArt } from '../../models';
import { useTranslation } from '../../translations';
import { Fetcher, useConvention, useFetcher } from '../../utils';

export const BadgeArtComponent: FC = () => {
  const { ts } = useTranslation();
  const [modal, setModal] = useState<'delete' | 'limit' | null>(null);
  const [modalTarget, setModalTarget] = useState(0);

  const openModal = useCallback((name: 'delete' | 'limit', target: number) => {
    setModal(name);
    setModalTarget(target);
  }, []);

  const closeModal = useCallback((refresh: () => void) => {
    refresh();
    setModal(null);
    setModalTarget(0);
  }, []);

  const fetcher = useFetcher(async () => {
    return await api.getBadgeArt(true);
  });

  const artLookup = useMemo(() => {
    if (!fetcher.data) {
      return undefined;
    }

    return fetcher.data.find((t) => t.id === modalTarget);
  }, [fetcher.data, modalTarget]);

  if (!fetcher.complete) {
    return <Fetcher result={fetcher} />;
  }

  return (
    <UserStateComponent>
      <>
        {artLookup === undefined ? null : modal === 'delete' ? (
          <DeleteModal art={artLookup} close={() => closeModal(fetcher.refresh)} />
        ) : (
          <SetLimitModal art={artLookup} close={() => closeModal(fetcher.refresh)} />
        )}
        <PageHeader>{ts('badge_art_settings')}</PageHeader>
        <BadgeArtDisabledWarning />
        <PermissionBoundary requiredPermissions={['system:badgeart:update']}>
          <UploadCard refresh={fetcher.refresh} />
        </PermissionBoundary>
        <hr />
        <Row className="badgeArtContainer">
          <ListBadgeArt badgeArt={fetcher.data!} openModal={openModal} />
        </Row>
      </>
    </UserStateComponent>
  );
};

const UploadCard: FC<{ readonly refresh: () => void }> = ({ refresh }) => {
  return (
    <Row>
      <Col xs={12}>
        <Card>
          <CardBody>
            <AssetUpload
              category="badge-art"
              onComplete={refresh}
              onFileUploaded={async (assetId) => await api.createBadgeArt(assetId)}
            />
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
};

const ListBadgeArt: FC<{
  readonly badgeArt: BadgeArt[];
  readonly openModal: (name: 'delete' | 'limit', target: number) => void;
}> = ({ badgeArt, openModal }) => {
  const { ts } = useTranslation();
  return (
    <>
      {badgeArt.map((art) => (
        <Col id={`badgeArt${art.id}`} key={art.id} lg={6} md={12}>
          <Card>
            <CardBody>
              <div className="text-center">
                <img
                  alt={`Badge Art ${art.id}`}
                  src={`/api/badgeart/${art.id}/image`}
                  style={{ height: '200px' }}
                />
                <div>
                  Limit: {art.count} / {art.limit === null ? <>&infin;</> : art.limit}
                </div>
              </div>
              <Row>
                <Col md={6}>
                  <Button
                    block
                    className="action-limit"
                    color="primary"
                    onClick={() => openModal('limit', art.id)}
                    outline
                  >
                    {ts('configure_limit')}
                  </Button>
                </Col>
                <Col md={6}>
                  <Button
                    block
                    className="action-delete"
                    color="danger"
                    onClick={() => openModal('delete', art.id)}
                    outline
                  >
                    <MaterialIcon name="delete" />
                  </Button>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      ))}
    </>
  );
};

const BadgeArtDisabledWarning: FC = () => {
  const { ts } = useTranslation();
  const { enableBadgeArt } = useConvention();
  if (enableBadgeArt) {
    return null;
  }

  return (
    <Row>
      <Col lg={12} xs={12}>
        <Alert color="info">{ts('badge_art_selection_is_disabled')}</Alert>
      </Col>
    </Row>
  );
};

interface ListModal {
  readonly art: BadgeArt;
  close(refresh: boolean): void;
}

const DeleteModal: FC<ListModal> = ({ close, art }) => {
  const { ts } = useTranslation();
  const closeDelete = useCallback(() => {
    close(true);
    toast.success('Badge art has been deleted');
  }, [close]);

  const closeNoop = useCallback(() => {
    close(false);
  }, [close]);

  return (
    <Modal className="modal-large" id="deleteModal" isOpen>
      <ModalHeader>Delete {art.id}</ModalHeader>
      <ModalBody>
        <p>{ts('are_you_sure_you_want')}</p>
      </ModalBody>
      <ModalFooter>
        <ActionButton
          action={`/api/badgeart/${art.id}`}
          className="action-confirm"
          color="danger"
          method="delete"
          onSuccess={closeDelete}
        >
          {ts('delete')}
        </ActionButton>
        <Button color="secondary" id="cancel" onClick={closeNoop}>
          {ts('cancel')}
        </Button>
      </ModalFooter>
    </Modal>
  );
};

const SetLimitModal: FC<ListModal> = ({ close, art }) => {
  const { ts } = useTranslation();
  const [enabled, setEnabled] = useState(!!art.limit);
  const [limit, setLimit] = useState(art.limit ?? 100);

  const setEnabledI = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setEnabled(e.currentTarget.checked);
  }, []);

  const setLimitI = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setLimit(e.currentTarget.valueAsNumber);
  }, []);

  const closeLimit = useCallback(() => {
    close(true);
    toast.success(ts('badge_art_has_been_updated'));
  }, [close]);

  const closeNoop = useCallback(() => {
    close(false);
  }, [close]);

  return (
    <Modal className="modal-large" id="limitModal" isOpen>
      <ModalHeader>Set a limit for {art.id}</ModalHeader>
      <ModalBody>
        <div className="custom-control custom-checkbox margin-top-10">
          <Input
            checked={enabled}
            className="custom-control-input"
            id="enable"
            onChange={setEnabledI}
            type="checkbox"
          />
          <Label className="custom-control-label" for="enable">
            {ts('enable_limit')}
          </Label>
        </div>
        {enabled && (
          <div className="custom-control custom-checkbox margin-top-10">
            <Input
              id="limit"
              name="limit"
              onChange={setLimitI}
              placeholder="0"
              type="number"
              value={limit}
            />
            <FormText color="muted">{ts('the_total_number_of_badges')}</FormText>
          </div>
        )}
      </ModalBody>
      <ModalFooter>
        <JSONForm method="patch" onSuccess={closeLimit} path={`/api/badgeart/${art.id}`}>
          <Input name="limit" type="hidden" value={enabled ? limit : ''} />
          <Button className="action-confirm" color="primary" type="submit">
            {ts('update')}
          </Button>
        </JSONForm>
        <Button color="secondary" id="cancel" onClick={closeNoop}>
          {ts('cancel')}
        </Button>
      </ModalFooter>
    </Modal>
  );
};
