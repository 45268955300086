import React, { Component, FC } from 'react';
import { Card, CardBody, CardText, CardTitle, Col, Row } from 'reactstrap';
import { ConventionSetting } from '../../../shared/config';
import {
  AgeCheck as AgeCheckComponent,
  AgeCheckResult,
  Loading,
  MaterialIcon,
  PolicyComponent,
  WizardComponent,
  WizardItem,
  WizardStatus,
} from '../../components';
import { ActionType, store } from '../../services';
import { useTranslation } from '../../translations';
import { Fetcher, useConfig, useConvention, useFetcher } from '../../utils';
import { captureError } from '../../utils/errorHandling';
import { makeLazyComponent } from '../../utils/lazy';
import { randomString } from '../../utils/random';
import { StripeIdentityResult } from '../account/register/stripeIdentity/model';
import { KioskRegForm } from './BadgeInfo';
import { KioskChildBadges } from './KioskChildBadges';
import { KioskComplete } from './KioskComplete';
import { KioskPayment } from './KioskPayment';
import { KioskUserInfo } from './UserInfo';

const TimeoutPopup: FC<{ readonly timeoutCountdown: number }> = ({ timeoutCountdown }) => {
  const { ts } = useTranslation();
  return (
    <Loading hideLoader>
      <Card style={{ maxWidth: '600px', margin: '0 auto', color: 'black' }}>
        <CardBody>
          <MaterialIcon large name="timelapse" type="warning" />
          <CardTitle>{ts('are_you_still_there')}</CardTitle>
          <CardText>
            {ts('we_havent_detected_any_input')}
            <br />
            {ts('if_youre_still_there_just')}
          </CardText>
          <CardText>{ts('for_security_well_automatically_reset')}</CardText>
          <h3>
            {timeoutCountdown} second{timeoutCountdown !== 1 && 's'}
          </h3>
        </CardBody>
      </Card>
    </Loading>
  );
};

enum KioskStage {
  AgeCheck,
  IdentityVerification,
  Policy,
  UserInfo,
  ConInfo,
  ChildInfo,
  Payment,
  Complete,
}

interface KioskComponentState {
  attendanceTypeId?: number;
  isChild?: boolean;
  isStripe?: boolean;
  sessionId: string;
  stage: KioskStage;
  regId?: number;
  showTimeout?: boolean;
  maxIdleTime: number;
  timeoutCountdown: number;
  timeoutTimer: number;
  wizardItems: Record<string, WizardItem>;
  userId?: number;
  stripeIdentityResult?: StripeIdentityResult;
}

interface KioskComponentProps {
  readonly convention: ConventionSetting;
}

const LazyStripeIdentityRegistration = makeLazyComponent(
  async () =>
    (await import(/* webpackChunkName: "stripeIdentity" */ '../account/register/stripeIdentity'))
      .StripeIdentityRegistration,
);

class KioskComponentInner extends Component<KioskComponentProps, KioskComponentState> {
  public constructor(props: KioskComponentProps) {
    super(props);

    this.state = {
      maxIdleTime: 45,
      sessionId: randomString(),
      stage: KioskStage.AgeCheck,
      timeoutCountdown: 30,
      timeoutTimer: 0,
      wizardItems: {},
    };
  }

  public override async componentDidMount(): Promise<void> {
    document.addEventListener('mousemove', () => {
      this.resetTimeout();
    });

    document.addEventListener('keypress', () => {
      this.resetTimeout();
    });

    await this.updateKioskInfo();
  }

  public override render(): JSX.Element {
    const { showTimeout, wizardItems } = this.state;

    return (
      <>
        {showTimeout && this.showTimeout()}
        <Row
          className="justify-content-center container m-0"
          style={{
            paddingTop: '17.5px',
          }}
        >
          <Col className="margin-bottom-10" md={8} xs={12}>
            <WizardComponent items={Object.keys(wizardItems).map((k) => wizardItems[k])} />
          </Col>
          <Col md={8} xs={12}>
            {this.showKiosk()}
          </Col>
        </Row>
      </>
    );
  }

  private async updateKioskInfo(): Promise<void> {
    this.setState({
      wizardItems: {
        policies: {
          status: WizardStatus.InProgress,
          text: 'Policies',
        },
        userInfo: {
          status: WizardStatus.NotStarted,
          text: 'User Info',
        },

        badgeInfo: {
          status: WizardStatus.NotStarted,
          text: 'Badge Info',
        },
        payment: {
          status: WizardStatus.NotStarted,
          text: 'Payment',
        },
      },
    });
  }

  private async resetKiosk(): Promise<void> {
    const { timeoutTimer } = this.state;

    if (timeoutTimer !== 0) {
      clearTimeout(timeoutTimer);
    }

    this.setState((s) => ({ ...s, maxIdleTime: 45 }));

    try {
      await api.logout();

      store.dispatch({
        type: ActionType.Logout,
      });
    } catch {
      /* NO-OP */
    }

    await this.updateKioskInfo();

    this.setState({
      sessionId: randomString(32),
      showTimeout: false,
      stage: KioskStage.AgeCheck,
      timeoutCountdown: 30,
      timeoutTimer: 0,
      userId: undefined,
    });
  }

  private resetTimeout(): void {
    const { timeoutTimer, maxIdleTime } = this.state;
    let timeout = 0;

    if (timeoutTimer !== 0) {
      clearTimeout(timeoutTimer);
      if (this.props.convention.kioskTimeout) {
        timeout = setTimeout(() => {
          this.triggerTimeout();
        }, maxIdleTime * 1000);
      }
    }

    this.setState({
      showTimeout: false,
      timeoutCountdown: 30,
      timeoutTimer: timeout,
    });
  }

  private triggerTimeout(): void {
    window.scrollTo(0, 0);
    this.setState({
      showTimeout: true,
      timeoutTimer: setTimeout(async () => {
        this.timeoutCountdown().catch(captureError);
      }, 1000),
    });
  }

  private async timeoutCountdown(): Promise<void> {
    const { showTimeout, timeoutCountdown } = this.state;

    if (!showTimeout) {
      return;
    }

    if (timeoutCountdown === 0) {
      await this.resetKiosk();
      return;
    }

    this.setState({
      timeoutCountdown: timeoutCountdown - 1,
      timeoutTimer: setTimeout(async () => await this.timeoutCountdown(), 1000),
    });
  }

  private showTimeout(): JSX.Element {
    return <TimeoutPopup timeoutCountdown={this.state.timeoutCountdown} />;
  }

  private showKiosk(): JSX.Element {
    const { attendanceTypeId, stage, isStripe, regId, userId, wizardItems } = this.state;

    switch (stage) {
      case KioskStage.AgeCheck: {
        return this.ageCheck();
      }

      case KioskStage.IdentityVerification: {
        return (
          <Row className="justify-content-center">
            <LazyStripeIdentityRegistration
              onComplete={async (res) => {
                this.setState({
                  stage: KioskStage.Policy,
                  stripeIdentityResult: res,
                  maxIdleTime: 120,
                });

                this.resetTimeout();
              }}
            />
          </Row>
        );
      }

      case KioskStage.Policy: {
        return this.policy();
      }

      case KioskStage.UserInfo: {
        return (
          <KioskUserInfo
            onRegister={(user) => {
              store.dispatch({
                type: ActionType.LoginSuccess,
                user,
              });

              window.scrollTo(0, 0);

              this.setState({
                stage: KioskStage.ConInfo,
                userId: user.id,
                wizardItems: {
                  ...wizardItems,
                  badgeInfo: {
                    ...wizardItems.badgeInfo,
                    status: WizardStatus.InProgress,
                  },
                  userInfo: {
                    ...wizardItems.userInfo,
                    status: WizardStatus.Completed,
                  },
                },
              });
            }}
            stripeIdentityResult={this.state.stripeIdentityResult}
            updateStatus={(status) => {
              this.setState({
                wizardItems: {
                  ...wizardItems,
                  userInfo: {
                    ...wizardItems.userInfo,
                    status,
                  },
                },
              });
            }}
          />
        );
      }

      case KioskStage.ConInfo: {
        return (
          <KioskRegForm
            onRegister={(id, atId, hasChildren) => {
              window.scrollTo(0, 0);

              if (hasChildren) {
                this.setState({
                  attendanceTypeId: atId,
                  regId: id,
                  stage: KioskStage.ChildInfo,
                });

                return;
              }

              this.setState({
                attendanceTypeId: atId,
                stage: KioskStage.Payment,
                wizardItems: {
                  ...wizardItems,
                  badgeInfo: {
                    ...wizardItems.badgeInfo,
                    status: WizardStatus.Completed,
                  },
                  payment: {
                    ...wizardItems.payment,
                    status: WizardStatus.InProgress,
                  },
                },
              });
            }}
            userId={userId!}
          />
        );
      }

      case KioskStage.ChildInfo: {
        return (
          <KioskChildBadges
            onRegister={() => {
              window.scrollTo(0, 0);
              this.setState({
                stage: KioskStage.Payment,
                wizardItems: {
                  ...wizardItems,
                  badgeInfo: {
                    ...wizardItems.badgeInfo,
                    status: WizardStatus.Completed,
                  },
                  payment: {
                    ...wizardItems.payment,
                    status: WizardStatus.InProgress,
                  },
                },
              });
            }}
            registrationId={regId!}
          />
        );
      }

      case KioskStage.Payment: {
        return (
          <KioskPayment
            attendanceTypeId={attendanceTypeId!}
            onError={() => {
              this.setState({
                wizardItems: {
                  ...wizardItems,
                  payment: {
                    ...wizardItems.payment,
                    status: WizardStatus.Error,
                  },
                },
              });
            }}
            onProcessing={() => {
              this.setState({
                wizardItems: {
                  ...wizardItems,
                  payment: {
                    ...wizardItems.payment,
                    status: WizardStatus.Pending,
                  },
                },
              });
            }}
            onSuccess={(stripe) => {
              this.setState({
                isStripe: stripe,
                stage: KioskStage.Complete,
                wizardItems: {
                  ...wizardItems,
                  payment: {
                    ...wizardItems.payment,
                    status: WizardStatus.Completed,
                  },
                },
              });

              setTimeout(async () => await this.resetKiosk(), 10_000);
            }}
          />
        );
      }

      case KioskStage.Complete: {
        return <KioskComplete isStripe={isStripe} />;
      }
    }
  }

  private ageCheck(): JSX.Element {
    return (
      <Row className="justify-content-center">
        <AgeCheckComponent
          key={this.state.sessionId}
          onSelect={(res) => {
            this.ageCheckSelect(res);
          }}
        />
      </Row>
    );
  }

  private ageCheckSelect(res: AgeCheckResult): void {
    const { maxIdleTime } = this.state;
    const convention = this.props.convention;

    if (res === AgeCheckResult.Underage) {
      this.setState((state) => ({
        wizardItems: {
          ...state.wizardItems,
          policies: {
            ...state.wizardItems.policies,
            status: WizardStatus.Error,
          },
        },
      }));

      setTimeout(async () => await this.resetKiosk(), 10_000);
      return;
    }

    this.setState({
      isChild: res === AgeCheckResult.Teen,
      stage:
        convention.identityVerification === 'required'
          ? KioskStage.IdentityVerification
          : KioskStage.Policy,
      timeoutTimer: setTimeout(() => {
        this.triggerTimeout();
      }, maxIdleTime * 1000),
    });
  }

  private onPolicyAccept(): void {
    this.setState((state) => ({
      stage: KioskStage.UserInfo,
      wizardItems: {
        ...state.wizardItems,
        policies: {
          ...state.wizardItems.policies,
          status: WizardStatus.Completed,
        },
        userInfo: {
          ...state.wizardItems.userInfo,
          status: WizardStatus.InProgress,
        },
      },
    }));
  }

  private policy(): JSX.Element {
    return (
      <Card>
        <CardBody className="text-center">
          <PolicyComponent
            isChild={this.state.isChild}
            onAccept={() => {
              this.onPolicyAccept();
            }}
          />
        </CardBody>
      </Card>
    );
  }
}

const ErrorList: FC<{ readonly errors: string[] }> = ({ errors }) => {
  const { ts } = useTranslation();
  return (
    <Row
      className="justify-content-center container m-0"
      style={{
        paddingTop: '17.5px',
      }}
    >
      <Col className="text-center" md={8} xs={12}>
        <MaterialIcon large name="report_problem" type="warning" />
        <h3>{ts('kiosk_unavailable')}</h3>
        <hr />
        <p>{ts('the_following_errors_are_preventing')}</p>
        <ul>
          {errors.map((err) => (
            <li key={err}>{err}</li>
          ))}
        </ul>
        <p>{ts('please_contact_a_member_of')}</p>
      </Col>
    </Row>
  );
};

export const KioskComponent: FC = () => {
  const config = useConfig();
  const convention = useConvention();
  const errors: string[] = [];
  const fetcher = useFetcher(async () => {
    const category = (await api.getProductCategories()).find(
      ({ isRegistration }) => isRegistration,
    );

    return await api.getProductsByCategory(category!.id);
  }, []);

  if (!fetcher.complete) {
    return <Fetcher result={fetcher} />;
  }

  if (config.organization.maintenanceMode || convention.maintenanceMode) {
    errors.push('System is currently in maintenance mode.');
  }

  if (fetcher.data && fetcher.data.length === 0) {
    errors.push('No attendance types are available for registration.');
  }

  if (errors.length > 0) {
    return <ErrorList errors={errors} />;
  }

  return <KioskComponentInner convention={convention} />;
};
