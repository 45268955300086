import React, { FC, useState } from 'react';
import { FormGroup, Input, Label } from 'reactstrap';
import { DatePicker, DateT } from '../../..';
import { CurrentUser } from '../../../../../shared/user/base';
import { useTranslation } from '../../../../translations';

interface Props {
  readonly user: CurrentUser | null;
}

export const FormNodeDateOfBirth: FC<Props> = ({ user }) => {
  const { ts } = useTranslation();
  const [dateOfBirth, setDateOfBirth] = useState<string | null>(null);

  return (
    <div className="form-node form-phone-selector">
      <FormGroup>
        <Label>{ts('date_of_birth')}</Label>
        {user ? (
          <p>
            <strong>{user.bornAt ? <DateT value={user.bornAt} /> : <>Unknown</>}</strong>
          </p>
        ) : (
          <>
            <DatePicker defaultDate={null} onChange={setDateOfBirth} />
            <Input hidden id="bornAt" name="bornAt" value={dateOfBirth ?? undefined} />
          </>
        )}
      </FormGroup>
    </div>
  );
};
