import { MaterialIconText } from '@conventioncatcorp/common-fe';
import React, { FC } from 'react';
import { RaffleWithUserDetails } from '../../../shared/orders/raffle';
import { useTranslation } from '../../translations';

interface Props {
  readonly raffle: RaffleWithUserDetails;
}

export const RaffleRegState: FC<Props> = ({ raffle }) => {
  const { ts } = useTranslation();
  if (!raffle.ticket || raffle.ticket.status === 'deleted') {
    if (raffle.roleOverride) {
      return (
        <div className="mt-2 t-small text-success raffle-status-bypass">
          <MaterialIconText name="skip_next">
            {ts('youre_eligible_to_complete_your')}
          </MaterialIconText>
        </div>
      );
    }

    return (
      <div className="mt-2 t-small text-secondary raffle-status-not-entered">
        <MaterialIconText name="change_history">{raffle.notEnteredText}</MaterialIconText>
      </div>
    );
  }

  switch (raffle.ticket.status) {
    case 'waiting': {
      return waitingResult(raffle);
    }

    case 'rescinded': {
      return (
        <div className="mt-2 t-small text-danger raffle-status-rescinded">
          <MaterialIconText name="not_interested">{raffle.rescindedText}</MaterialIconText>
        </div>
      );
    }

    case 'winner':
    case 'redeemed': {
      return (
        <div className="mt-2 t-small text-success raffle-status-won">
          <MaterialIconText name="done">{raffle.winnerText}</MaterialIconText>
        </div>
      );
    }
  }
};

function waitingResult(raffle: RaffleWithUserDetails) {
  switch (raffle.status) {
    case 'open': {
      return (
        <div className="mt-2 t-small text-secondary raffle-status-entered">
          <MaterialIconText name="hourglass_empty">{raffle.waitingOpenText}</MaterialIconText>
        </div>
      );
    }

    case 'drawn': {
      return (
        <div className="mt-2 t-small text-secondary raffle-status-lost">
          <MaterialIconText name="thumb_down">{raffle.waitingDrawnText}</MaterialIconText>
        </div>
      );
    }

    case 'closed': {
      return (
        <div className="mt-2 t-small text-secondary raffle-status-entered-closed">
          <MaterialIconText name="schedule">{raffle.waitingClosedText}</MaterialIconText>
        </div>
      );
    }
  }
}
