import { SelectBlocks } from '@conventioncatcorp/common-fe/dist/components';
import React, { FC, useState } from 'react';
import { FormNodeAttendanceTypeModel } from '../../../../shared/kiosk';
import { ProductModel } from '../../../../shared/orders/product';
import { displayName } from '../../../utils';
import { getProductPrice } from '../../../utils/product';
import { LazyMarkdown } from '../../LazyMarkdown';

interface FormNodeAttendanceTypeProps extends FormNodeAttendanceTypeModel {
  readonly attendanceTypes: ProductModel[];
}

interface ViewerProps {
  readonly attendanceType: ProductModel;
}

const AttendanceTypeViewer: FC<ViewerProps> = ({ attendanceType }): JSX.Element => (
  <p>
    <h5>
      Perks of {displayName(attendanceType)}
      &nbsp;(${getProductPrice(attendanceType).price})
    </h5>
    <LazyMarkdown source={attendanceType.description} span />
  </p>
);

export const FormNodeAttendanceType: FC<FormNodeAttendanceTypeProps> = ({
  attendanceTypes,
  id,
}) => {
  const [attendanceTypeId, setAttendanceTypeId] = useState(attendanceTypes[0].id);
  const attendanceType = attendanceTypes.find(({ id: atId }) => atId === attendanceTypeId)!;

  if (attendanceTypes.length === 1) {
    return (
      <div className="form-node form-node-attendance-type" id={`node-${id}`}>
        <p>
          <i>There are no other attendance types to choose from.</i>
        </p>
        <input
          name="registration[attendanceTypeId]"
          required
          type="hidden"
          value={attendanceTypeId}
        />
        <AttendanceTypeViewer attendanceType={attendanceType} />
      </div>
    );
  }

  return (
    <div className="form-node form-node-attendance-type" id={`node-${id}`}>
      <SelectBlocks
        defaultSelectedId={[0]}
        items={attendanceTypes.map(({ name }) => ({ name, icon: '' }))}
        onChange={(idx) => {
          setAttendanceTypeId(attendanceTypes[idx[0]].id);
        }}
        textOnly
      />
      <input
        name="registration[attendanceTypeId]"
        required
        type="hidden"
        value={attendanceTypeId}
      />
      <hr />
      <AttendanceTypeViewer attendanceType={attendanceType} />
    </div>
  );
};
