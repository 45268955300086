import React, { FC } from 'react';
import { Input, PopoverBody, UncontrolledPopover } from 'reactstrap';
import { ProductModel } from '../../../../shared/orders';
import { MaterialIcon } from '../../../components';
import { useTranslation } from '../../../translations';

interface TableTypeArchiveWarningProps {
  readonly idx: number;
  readonly tableType: Pick<ProductModel, 'deletedAt'>;
}

export const TableTypeArchiveWarning: React.FC<TableTypeArchiveWarningProps> = ({
  idx,
  tableType,
}) => {
  const { ts } = useTranslation();
  if (!tableType.deletedAt) {
    return null;
  }

  return (
    <>
      &nbsp;
      <span id={`dealer${idx}TableTypeArchived`}>
        <MaterialIcon name="warning" type="warning" />
      </span>
      <UncontrolledPopover
        placement="bottom"
        target={`dealer${idx}TableTypeArchived`}
        trigger="hover"
      >
        <PopoverBody>
          <p>{ts('this_table_type_has_been')}</p>
          <p>{ts('a_new_currently_active_table')}</p>
        </PopoverBody>
      </UncontrolledPopover>
    </>
  );
};

interface EditEntryProps {
  readonly displayName: string;
  readonly name: string;
  readonly value?: string;
  readonly edit: boolean;
  renderEdit?(): JSX.Element;
}

export const EditEntry: FC<EditEntryProps> = ({ displayName, edit, renderEdit, value, name }) => {
  return (
    <tr>
      <th scope="row">{displayName}</th>
      <td id={`dealer-${name}`}>
        {edit && !renderEdit && <Input defaultValue={value ?? ''} id={name} name={name} />}
        {edit && renderEdit?.()}
        {!edit && <>{value}</>}
      </td>
    </tr>
  );
};
