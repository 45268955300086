import React, {
  Dispatch,
  FC,
  Fragment,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { toast } from 'react-toastify';
import {
  Badge,
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  FormGroup,
  Input,
  Label,
  Progress,
  Row,
} from 'reactstrap';
import { EmailTargets, SendEmailPayload } from '../../../../../shared/email';
import {
  MaterialIcon,
  PageHeader,
  PermissionBoundary,
  UserStateComponent,
} from '../../../../components';
import { UserListModal } from '../../../../components/UserListModal';
import { UserSelector } from '../../../../components/UserSelector';
import { useTranslation } from '../../../../translations';
import {
  Fetcher,
  mapObjectToArray,
  sum,
  useFetcher,
  useInputModel,
  useInputState,
  useObject,
} from '../../../../utils';
import { LoadingState } from '../../../../utils/LoadingState';
import { debounce } from '../../../../utils/debounce';
import { captureError } from '../../../../utils/errorHandling';
import { AttendeeSelector } from './attendee';
import { DealerSelector } from './dealer';
import { RaffleSelector } from './raffle';

interface MailQueueInfo {
  id: string;
  length: number;
  remaining?: number;
}

const customEntries = new Set(['custom', 'gatekeeping', 'vendor', 'attendees']);

export interface MassEmailState {
  payload: SendEmailPayload;
  estimate?: number;
  queueInfo?: MailQueueInfo[];
}

export const MassEmail: FC = () => {
  const { ts } = useTranslation();
  const [state, setState] = useState<MassEmailState>({
    payload: {
      body: '',
      fromAddress: '',
      recipients: 'none',
      subject: '',
    },
  });

  return (
    <UserStateComponent>
      <PageHeader>{ts('mass_email')}</PageHeader>
      <MassEmailInner setState={setState} state={state}>
        <EmailSelectRecipients setState={setState} state={state} />
      </MassEmailInner>
    </UserStateComponent>
  );
};

interface MassEmailInnerProps {
  readonly state: MassEmailState;
  readonly setState: Dispatch<SetStateAction<MassEmailState>>;
}

export const MassEmailInner: FC<MassEmailInnerProps> = ({ state, setState, children }) => {
  const { ts } = useTranslation();
  return (
    <Row className="justify-content-center">
      <Col lg={6} xs={12}>
        <Card className="markdown-container">
          <CardBody>
            <CardTitle>{ts('preview')}</CardTitle>
            <hr />
            <div>
              <BodyPreview input={state.payload.body} />
            </div>
          </CardBody>
        </Card>
      </Col>

      <Col lg={6} xs={12}>
        <Card>
          <CardBody id="massEmailForm">
            {state.queueInfo ? (
              <SendProgress
                onComplete={() => setState({ ...state, queueInfo: undefined })}
                queue={state.queueInfo}
              />
            ) : (
              <EmailEdit setState={setState} state={state}>
                {children}
              </EmailEdit>
            )}
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
};

const BodyPreview: FC<{ readonly input: string }> = ({ input }) => {
  const { ts } = useTranslation();
  const [text, setText] = useState('');
  const debounceBody = useMemo(
    () =>
      debounce((newBody: string) => {
        setText(newBody);
      }, 350),
    [],
  );

  const bodyFetch = useFetcher(async () => {
    if (text.length < 4) {
      return '';
    }

    const { preview } = await api.previewEmail(text);
    return preview;
  }, [text]);

  useEffect(() => debounceBody(input), [input, debounceBody]);

  if (bodyFetch.data === undefined || bodyFetch.state === LoadingState.Error) {
    return <Fetcher result={bodyFetch} />;
  }

  // Empty string
  if (!bodyFetch.data) {
    return <p>{ts('no_preview_to_display')}</p>;
  }

  return (
    <div
      // eslint-disable-next-line react/no-danger
      dangerouslySetInnerHTML={{ __html: bodyFetch.data }}
      style={{ fontFamily: 'Helvetica, Arial' }}
    />
  );
};

interface SendProgressProps {
  readonly queue: MailQueueInfo[];
  onComplete(): void;
}

const SendProgress: FC<SendProgressProps> = ({ onComplete, queue }) => {
  const { ts } = useTranslation();
  const estimate = queue.length > 0 ? sum(queue.map((t) => t.length)) : 0;
  const [percentComplete, setPercentComplete] = useState(0);
  const [attemptCount, setAttemptCount] = useState(0);
  const [queueInfo, setQueueInfo] = useState<MailQueueInfo[]>(() =>
    queue.map((q) => ({ ...q, remaining: q.length })),
  );

  const loadingFetch = useFetcher(async () => {
    const newQueueInfo: MailQueueInfo[] = [];
    let queueSize = 0;

    for (const item of queueInfo) {
      // eslint-disable-next-line no-await-in-loop
      const { remaining } = await api.checkEmailJobStatus(item.id);
      queueSize += remaining;

      newQueueInfo.push({ ...item, remaining });
    }

    setPercentComplete(((estimate - Math.min(estimate, queueSize)) / estimate) * 100);
    setQueueInfo(newQueueInfo);

    if (queueSize <= 0) {
      toast.success('Your email has been successfully sent!');
      onComplete();
    } else {
      setTimeout(() => {
        setAttemptCount(attemptCount + 1);
      }, 2000);
    }
  }, [attemptCount]);

  if (loadingFetch.error) {
    return <Fetcher result={loadingFetch} />;
  }

  return (
    <div className="text-center" id="emailSending">
      <MaterialIcon large name="send" />
      <h4>{ts('sending_in_progress')}</h4>
      <p>{ts('just_a_moment_were_sending')}</p>
      <p>{ts('it_is_safe_for_you_to_close')}</p>
      <Progress animated value={percentComplete} />
    </div>
  );
};

interface EmailEditProps {
  readonly state: MassEmailState;
  readonly setState: Dispatch<SetStateAction<MassEmailState>>;
}

const EmailEdit: FC<EmailEditProps> = ({ state, setState, children }) => {
  const { ts } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [payload, setPayload] = useObject(state, setState, 'payload');

  const setBody = useInputModel(setPayload, 'body');
  const setSubject = useInputModel(setPayload, 'subject');

  const onSubmit = useCallback(async () => {
    setLoading(true);
    try {
      const queueInfo = await api.sendMassEmail(payload);
      setState((old) => ({ ...old, queueInfo }));
    } catch (error) {
      captureError(error as Error);
    }

    setLoading(false);
  }, [payload]);

  return (
    <>
      {children}
      <FormGroup>
        <Label for="subject">{ts('subject')}</Label>
        <Input id="subject" name="subject" onChange={setSubject} value={payload.subject} />
      </FormGroup>
      <FormGroup>
        <Label for="body">{ts('body')}</Label>
        <Input
          id="body"
          name="body"
          onChange={setBody}
          style={{ minHeight: '200px' }}
          type="textarea"
          value={payload.body}
        />
      </FormGroup>
      <FormGroup>
        <Button
          block
          color="primary"
          disabled={loading || !state.estimate || !payload.fromAddress}
          id="sendEmailBtn"
          onClick={onSubmit}
          type="submit"
        >
          {ts('send_email')}
        </Button>
      </FormGroup>
    </>
  );
};

interface EmailSelectRecipientsProps {
  readonly state: MassEmailState;
  readonly setState: Dispatch<SetStateAction<MassEmailState>>;
}

const EmailSelectRecipients: FC<EmailSelectRecipientsProps> = ({ state, setState }) => {
  const { ts } = useTranslation();
  const [payload, setPayload] = useObject(state, setState, 'payload');
  const setAddress = useInputModel(setPayload, 'fromAddress');
  const [recipientType, setRecipientType] = useInputState('none');
  const setCustomUserIds = useCallback((customRecipients: number[]) => {
    setPayload((old) => ({ ...old, customRecipients }));
  }, []);

  const estimateFetcher = useFetcher(async () => {
    if (customEntries.has(recipientType) || recipientType === 'none') {
      return undefined;
    }

    const { count } = await api.estimateEmailRecipients(recipientType);
    return count;
  }, [recipientType]);

  const targetFetcher = useFetcher(async () => {
    const [targets, senders] = await Promise.all([api.getEmailTargets(), api.getEmailSenders()]);
    return { targets, senders: senders.senders };
  }, []);

  const isCustom = customEntries.has(recipientType);
  const estimate = isCustom ? payload.customRecipients?.length : estimateFetcher.data;

  useEffect(() => {
    setState((old) => ({
      ...old,
      estimate,
      payload: {
        ...old.payload,
        recipients: isCustom ? 'custom' : recipientType,
        customRecipients: isCustom ? payload.customRecipients : undefined,
      },
    }));
  }, [estimate, recipientType, isCustom]);

  if (!targetFetcher.complete) {
    return <Fetcher result={targetFetcher} />;
  }

  return (
    <>
      <FormGroup>
        <Label for="recipients">{ts('from_address')}</Label>
        <Input
          id="fromAddress"
          name="fromAddress"
          onChange={setAddress}
          type="select"
          value={payload.fromAddress}
        >
          <option disabled value="">
            {ts('pick_one')}
          </option>
          {targetFetcher.data!.senders.map(({ text }) => (
            <option key={text} value={text}>
              {text}
            </option>
          ))}
        </Input>
      </FormGroup>
      <FormGroup>
        <Label for="recipients">{ts('email_target')}</Label>
        <Input
          className="margin-bottom-10"
          id="recipients"
          name="recipients"
          onChange={setRecipientType}
          type="select"
          value={recipientType}
        >
          <option disabled value="none">
            {ts('select_an_email_target')}
          </option>
          <option value="custom">{ts('custom_individual_users')}</option>
          <PermissionBoundary requiredPermissions={['raffle:manage']}>
            <option value="gatekeeping">{ts('gatekeeping')}</option>
          </PermissionBoundary>
          <PermissionBoundary requiredPermissions={['vendor:read']}>
            <option value="vendor">{ts('vendor')}</option>
          </PermissionBoundary>
          <PermissionBoundary requiredPermissions={['registration:update', 'product:manage']}>
            <option value="attendees">{ts('attendees')}</option>
          </PermissionBoundary>
          <EmailTargetsComponent targets={targetFetcher.data!.targets} />
        </Input>
        <EmailEstimateText
          customRecipients={isCustom ? state.payload.customRecipients : []}
          estimate={estimate}
        />
      </FormGroup>
      {isCustom && (
        <EmailSelector recipientType={recipientType} selectionChanged={setCustomUserIds} />
      )}
    </>
  );
};

const EmailTargetsComponent: FC<{ readonly targets: EmailTargets }> = ({ targets }) => {
  return (
    <>
      {mapObjectToArray(targets, (val, key) => (
        <Fragment key={key}>
          <optgroup label={`Category: ${key}`} />
          {val.map(({ id, name }) => (
            <option key={id} value={`${key}.${id}`}>
              {name}
            </option>
          ))}
        </Fragment>
      ))}
    </>
  );
};

interface EmailEstimateTextProps {
  readonly estimate?: number;
  readonly customRecipients?: number[];
}

export const EmailEstimateText: FC<EmailEstimateTextProps> = ({ estimate, customRecipients }) => {
  const { ts } = useTranslation();
  const [modal, setModal] = React.useState(false);

  if (!estimate && estimate !== 0) {
    return null;
  }

  return (
    <p id="estimatedRecipients">
      {modal && customRecipients && (
        <UserListModal
          close={() => setModal(false)}
          title="Email recipients"
          userList={customRecipients}
        />
      )}
      {ts('this_email_will_be_sent_to')}{' '}
      {customRecipients ? (
        <a onClick={() => setModal(true)} style={{ cursor: 'pointer' }}>
          <CountBadge count={estimate} />
        </a>
      ) : (
        <CountBadge count={estimate} />
      )}{' '}
      {ts('recipients')}
    </p>
  );
};

const CountBadge: FC<{ readonly count: number }> = ({ count }) => {
  if (count === 0) {
    return <Badge color="secondary">0</Badge>;
  }

  if (count < 250) {
    return <Badge color="info">{count}</Badge>;
  }

  if (count < 500) {
    return <Badge color="success">{count}</Badge>;
  }

  if (count < 1000) {
    return <Badge color="warning">{count}</Badge>;
  }

  return <Badge color="danger">{count}</Badge>;
};

interface EmailSelectorProps {
  readonly recipientType: string;
  selectionChanged(ids: number[]): void;
}

const EmailSelector: FC<EmailSelectorProps> = ({ recipientType, selectionChanged }) => {
  if (recipientType === 'custom') {
    return (
      <FormGroup>
        <Label for="otherRecipients">Individual User(s)</Label>
        <UserSelector id="customRecipients" selectionIdsChanged={selectionChanged} />
      </FormGroup>
    );
  }

  if (recipientType === 'gatekeeping') {
    return <RaffleSelector selectionChanged={selectionChanged} />;
  }

  if (recipientType === 'vendor') {
    return <DealerSelector selectionChanged={selectionChanged} />;
  }

  if (recipientType === 'attendees') {
    return <AttendeeSelector selectionChanged={selectionChanged} />;
  }

  return null;
};
