import React, { FC, useCallback } from 'react';
import { Route, Switch, useHistory, useRouteMatch } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Card, CardBody, Col, Row } from 'reactstrap';
import * as Shared from '../../../../shared/options';
import {
  ActionButtonModal,
  LinkButton,
  MaterialIcon,
  PageHeader,
  UserStateComponent,
} from '../../../components';
import { validOptionTypes } from '../../../models';
import { useTranslation } from '../../../translations';
import { LoadingWrapper } from '../../../utils/LoadingWrapper';
import { captureError } from '../../../utils/errorHandling';
import { CreateOption } from './createOption';
import { OptionElement } from './optionElement';
import { ProductOptionBadges } from './utils';

interface OptionsContainerProps {
  readonly source: Shared.OptionSource;
}

export const OptionsContainer: FC<OptionsContainerProps> = ({ source }) => {
  const { ts } = useTranslation();
  const match = useRouteMatch();

  return (
    <UserStateComponent>
      <PageHeader>{ts('options')}</PageHeader>
      <Switch>
        <Route exact path={match.url} render={() => <ListOptions source={source} />} />
        <Route
          exact
          path={`${match.url}/new`}
          render={() => <CreateOptionFrame source={source} url={match.url} />}
        />
        <Route
          exact
          path={`${match.url}/:id`}
          render={(e) => (
            <OptionElement optionId={Number.parseInt(e.match.params.id, 10)} source={source} />
          )}
        />
      </Switch>
    </UserStateComponent>
  );
};

const ListOptions: FC<{ readonly source: Shared.OptionSource }> = ({ source }) => {
  const config = Shared.SourceConfig[source];
  const match = useRouteMatch();

  return (
    <LoadingWrapper<Shared.Option[], void>
      dataFetcher={async () => await api.getOptions(source)}
      inline
    >
      {(data, refresh) => (
        <>
          {config.allowCreate && (
            <>
              <div>
                <LinkButton color="primary" id="createOption" to={`${match.url}/new`}>
                  Create New Option
                </LinkButton>
              </div>
              <hr />
            </>
          )}
          <div>
            <Row className="justify-content-center">
              {data.map((option) => (
                <Col className="margin-bottom-10" key={option.id} lg={8} xs={12}>
                  <OptionListElement onChange={refresh} option={option} url={match.url} />
                </Col>
              ))}
            </Row>
          </div>
        </>
      )}
    </LoadingWrapper>
  );
};

const CreateOptionFrame: FC<{ readonly source: Shared.OptionSource; readonly url: string }> = ({
  source,
  url,
}) => {
  const { ts } = useTranslation();
  const history = useHistory();
  const onCreate = useCallback(
    (option: Shared.Option) => {
      history.push(`${url}/${option.id}`);
    },
    [history],
  );

  return (
    <>
      <div>
        <LinkButton color="secondary" id="cancelOption" to={url}>
          Back to {ts('options')}
        </LinkButton>
      </div>
      <hr />
      <div>
        <Row className="justify-content-center">
          <Col lg={8} xs={12}>
            <CreateOption onCreate={onCreate} source={source} />
          </Col>
        </Row>
      </div>
    </>
  );
};

interface OptionListElementProps {
  readonly option: Shared.Option;
  readonly url: string;
  onChange(): void;
}

const OptionListElement: FC<OptionListElementProps> = ({ url, option, onChange }) => {
  const key = `option${option.id}`;
  const optionType = validOptionTypes[option.type];

  const deleteOption = useCallback(async () => {
    try {
      await api.deleteOption(option.source, option.id);
      toast.success('Option has been deleted!');
      onChange();
    } catch (error) {
      captureError(error as Error);
    }
  }, [option.id]);

  if (optionType.hidden) {
    return null;
  }

  return (
    <Card id={key}>
      <CardBody>
        <div
          style={{
            minWidth: '200px',
            position: 'absolute',
            right: '15px',
            textAlign: 'right',
            top: '15px',
          }}
        />
        <h4 style={{ marginRight: '30px' }}>
          {option.name} <small>({optionType.name})</small>
        </h4>
        <div className="float-right">
          <LinkButton color="primary" id={`${key}Edit`} to={`${url}/${option.id}`}>
            <MaterialIcon name="edit" />
          </LinkButton>{' '}
          <ActionButtonModal
            actionContent="Delete"
            buttonContent={<MaterialIcon name="delete" />}
            color="danger"
            id={`${key}Delete`}
            onComplete={deleteOption}
            title="Delete Option"
          >
            Are you sure you want to delete {option.name}? Data for this option from users will be
            erased.
          </ActionButtonModal>
        </div>
        <ProductOptionBadges option={option} />
      </CardBody>
    </Card>
  );
};
