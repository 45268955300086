import {
  JSONForm,
  PreSubmitData,
} from '@conventioncatcorp/common-fe/dist/components/json-form/JSONForm';
import React, { FC, useCallback, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  PopoverBody,
  Row,
  UncontrolledPopover,
} from 'reactstrap';
import { OptionDataPayload } from '../../../../shared/options';
import { ExtendedUser, PendingReviewInfo } from '../../../../shared/user/extended';
import { VolunteerDepartmentModel } from '../../../../shared/volunteer/model';
import {
  ActionButtonModal,
  DateT,
  LinkButton,
  MaterialIcon,
  PermissionBoundary,
} from '../../../components';
import { useTranslation } from '../../../translations';
import { TableKVPair, useBoolState } from '../../../utils';
import { LoadingWrapper } from '../../../utils/LoadingWrapper';
import { DealerEditForm } from '../dealers/dealerEdit';
import { ProfileEditModal } from './profileEditModal';
import { RegistrationEditModal } from './registrationEditModal';
import { ResetSecondFactorButton } from './resetSecondFactorButton';
import { DealerApplicationStatus, RegistrationAttendanceType } from './utils';

interface UserProfileTabProps {
  readonly userData: ExtendedUser;
  refresh(): void;
}

interface ResetCodeResponse {
  resetCode: string;
}

export const UserProfileTab: FC<UserProfileTabProps> = ({ userData, refresh }) => {
  return (
    <Row>
      <Col className="margin-bottom-10" lg={6} xs={12}>
        <UserProfileCard refresh={refresh} user={userData} />
      </Col>
      <Col lg={6} xs={12}>
        <UserRegistrationNew refresh={refresh} user={userData} />
        <PermissionBoundary inline requiredPermissions={['registration:cashier']}>
          <UserActions refresh={refresh} user={userData} />
        </PermissionBoundary>
      </Col>
    </Row>
  );
};

const UserActions: FC<{ readonly user: ExtendedUser; refresh(): void }> = ({ user, refresh }) => {
  const [atConResetCode, setAtConResetCode] = useState<string | undefined>();

  const { ts } = useTranslation();
  return (
    <Card id="userActions">
      <CardHeader>{ts('actions')}</CardHeader>
      <CardBody>
        <JSONForm<ResetCodeResponse>
          method="patch"
          onSuccess={(data) => {
            setAtConResetCode(data.resetCode);
          }}
          path={`/api/users/${user.id}/reset/atcon`}
        >
          <Button
            block
            className={atConResetCode && 'complete'}
            color="primary"
            id="atConResetPassword"
            type="submit"
          >
            {atConResetCode ? `Reset code: ${atConResetCode}` : 'At-Con Password Reset'}
          </Button>
          <PermissionBoundary requiredPermissions={['registration:cashier']}>
            <LinkButton block to={`/housekeeping/cashier/${user.id}`}>
              {ts('open_cashier_interface')}
            </LinkButton>
          </PermissionBoundary>
        </JSONForm>
        <br />
        {user.hasSecondFactor && <ResetSecondFactorButton refresh={refresh} userId={user.id} />}
      </CardBody>
    </Card>
  );
};

interface UserRegistrationNewProps {
  readonly user: ExtendedUser;
  refresh(): void;
}

const UserRegistrationNew: FC<UserRegistrationNewProps> = ({ user, refresh }) => {
  return (
    <Card className="margin-bottom-10">
      <CardHeader>Information</CardHeader>
      <CardBody>
        {user.pendingReview ? (
          <>
            <AccountReviewRow refresh={refresh} review={user.matchingBan} userId={user.id} />
            <hr />
          </>
        ) : null}

        <UserRegistrationInformationRow refresh={refresh} user={user} />
        <PermissionBoundary inline requiredPermissions={['vendor:read']}>
          <hr />
          <UserDealerInfoRow user={user} />
        </PermissionBoundary>
        <PermissionBoundary inline requiredPermissions={['volunteer:read']}>
          <hr />
          <UserVolunteerInfoRow user={user} />
        </PermissionBoundary>
      </CardBody>
    </Card>
  );
};

interface AccountReviewRowProps {
  readonly userId: number;
  readonly review?: PendingReviewInfo;
  refresh(): void;
}

const AccountReviewRow: FC<AccountReviewRowProps> = ({ userId, review, refresh }) => {
  const completeAccountReview = useCallback(async () => {
    await api.completeReview(userId);
    refresh();
  }, [userId]);

  const { ts } = useTranslation();
  return (
    <div>
      <Row>
        <Col xs={9}>
          <h5>{ts('possible_duplicate_account_with_a')}</h5>
          {review?.firstName && (
            <div id="matchingFirstName">
              <b>{ts('matching_first_name')}</b>: {review?.firstName}
            </div>
          )}
          {review?.lastName && (
            <div>
              <b>{ts('matching_last_name')}</b>: {review?.lastName}
            </div>
          )}
          {review?.text && <div>{review?.text}</div>}
          {!review && <div>{ts('no_matching_ban')}</div>}
        </Col>
        <Col xs={3}>
          <PermissionBoundary inline requiredPermissions={['user:note:delete']}>
            <ActionButtonModal
              actionContent="Complete review"
              buttonContent={<MaterialIcon name="check" />}
              className="float-right"
              color="success"
              id="completeReview"
              onComplete={completeAccountReview}
              title="Complete account review?"
            >
              <p>{ts('if_this_account_is_a')}</p>
              <p>{ts('are_you_sure_you_want')}</p>
            </ActionButtonModal>
          </PermissionBoundary>
        </Col>
      </Row>
    </div>
  );
};

interface UserVolunteerInfoRowProps {
  readonly user: ExtendedUser;
}

const UserVolunteerInfoRow: FC<UserVolunteerInfoRowProps> = ({ user }) => {
  const { volunteer } = user;

  const { ts } = useTranslation();
  return (
    <div>
      <Row>
        <Col xs={9}>
          <h5>Volunteer</h5>
          {!volunteer && <div>{ts('no_application')}</div>}
          {volunteer && <VolunteerStatus departments={volunteer.departments} />}
        </Col>
        <Col xs={3}>
          <PermissionBoundary inline requiredPermissions={['volunteer:update']}>
            <LinkButton
              className="float-right"
              color="primary"
              id="updateVolunteer"
              outline
              to={`/housekeeping/attendees/user/${user.id}/volunteer`}
            >
              <MaterialIcon name="edit" small />
            </LinkButton>
          </PermissionBoundary>
        </Col>
      </Row>
    </div>
  );
};

const VolunteerStatus: FC<{ readonly departments: VolunteerDepartmentModel[] }> = ({
  departments,
}) => {
  const assignment = departments.filter((t) => t.states.includes('assignment'));

  if (assignment.length > 0) {
    return <div>Assigned to: {assignment.map((t) => t.name).join(', ')}</div>;
  }

  const request = departments.filter((t) => t.states.includes('request'));

  if (request.length > 0) {
    return <div>Requested: {assignment.map((t) => t.name).join(', ')}</div>;
  }

  return <div>Application pending...</div>;
};

interface UserDealerInfoRowProps {
  readonly user: ExtendedUser;
}

const UserDealerInfoRow: FC<UserDealerInfoRowProps> = ({ user }) => {
  const { dealer, dealerAssistance } = user;
  const [createModal, openModelCreate, closeModelCreate] = useBoolState(false);

  const { ts } = useTranslation();
  return (
    <div>
      <Row>
        <Col xs={9}>
          <h5>{ts('dealer')}</h5>
          {!dealer && <div id="noDealerApplication">{ts('no_application')}</div>}
          {dealer && (
            <div>
              <div className="dealerName">{dealer.name}</div>
              {dealer.applications.map((app) => {
                return (
                  <div key={app.area.id}>
                    <b>Area:</b> {app.area.name} -{' '}
                    <span className="dealerAppStatus">
                      <DealerApplicationStatus grant={!!app.paidOrderItemId} status={app.status} />
                    </span>
                  </div>
                );
              })}
            </div>
          )}
          {dealerAssistance.length > 0 && (
            <div>
              {ts('dealer')} Assistance:{' '}
              {dealerAssistance.map((t, id) => {
                return (
                  <span className="dealerAssistant" key={t.dealerId}>
                    <Link to={`/housekeeping/dealers/list/${t.dealerId}`}>{t.name}</Link>
                    {id < dealerAssistance.length - 1 ? ', ' : ''}
                  </span>
                );
              })}
            </div>
          )}
        </Col>
        <Col xs={3}>
          <PermissionBoundary inline requiredPermissions={['vendor:update']}>
            {dealer && (
              <LinkButton
                className="float-right"
                color="primary"
                id="updateDealer"
                outline
                to={`/housekeeping/dealers/list/${dealer.id}`}
              >
                <MaterialIcon name="edit" small />
              </LinkButton>
            )}
            {!dealer && (
              <Button
                className="float-right"
                color="primary"
                id="createDealer"
                onClick={openModelCreate}
                outline
              >
                <MaterialIcon name="add" small />
              </Button>
            )}
            {createModal && <DealerCreateModal close={closeModelCreate} userId={user.id} />}
          </PermissionBoundary>
        </Col>
      </Row>
    </div>
  );
};

interface DealerCreateModalProps {
  readonly userId: number;
  close(): void;
}

const DealerCreateModal: FC<DealerCreateModalProps> = ({ userId, close }) => {
  const history = useHistory();
  const [options, setOptions] = useState<OptionDataPayload>({});

  const { ts } = useTranslation();
  const redirectUser = useCallback(
    (result: { id: number }) => {
      toast.success(`${ts('dealer')} created`);
      history.push(`/housekeeping/dealers/list/${result.id}`);
    },
    [userId, history],
  );

  const preSubmit = useCallback(
    (reqOpts: PreSubmitData<{ options: OptionDataPayload }>) => {
      reqOpts.inputs!.options = options;
    },
    [options],
  );

  return (
    <Modal className="modal-large" id="createDealerModal" isOpen toggle={close}>
      <ModalHeader toggle={close}>Create dealer application</ModalHeader>
      <LoadingWrapper dataFetcher={async () => await api.getDealerForm()}>
        {(form) => (
          <JSONForm<{ id: number }, { options: OptionDataPayload }>
            method="post"
            onSuccess={redirectUser}
            path={`/api/users/${userId}/dealer`}
            preSubmit={preSubmit}
          >
            <ModalBody>
              <DealerEditForm edit form={form} options={options} setOptions={setOptions} />
            </ModalBody>
            <ModalFooter>
              <Button color="primary" id="submitCreateDealer" type="submit">
                {ts('create')}
              </Button>{' '}
              <Button color="secondary" onClick={close} outline>
                {ts('cancel')}
              </Button>
            </ModalFooter>
          </JSONForm>
        )}
      </LoadingWrapper>
    </Modal>
  );
};

interface UserRegistrationInformationRowProps {
  readonly user: ExtendedUser;
  refresh(): void;
}

const UserRegistrationInformationRow: FC<UserRegistrationInformationRowProps> = ({
  user,
  refresh,
}) => {
  const { registration } = user;
  const [edit, enableEdit, disableEdit] = useBoolState(false);

  const { ts } = useTranslation();
  return (
    <div>
      {edit && (
        <RegistrationEditModal
          bannedWords={[]}
          close={disableEdit}
          complete={() => {
            disableEdit();
            refresh();
          }}
          registration={registration ?? undefined}
          userId={user.id}
        />
      )}
      <Row>
        <Col xs={9}>
          <h5>Registration</h5>
          {!registration && <div id="notRegistered">{ts('not_registered')}</div>}
          {registration && (
            <div id="registrationInfo">
              Status: <RegistrationAttendanceType registration={registration} />
            </div>
          )}
        </Col>
        <Col xs={3}>
          <PermissionBoundary inline requiredPermissions={['registration:update', 'product:read']}>
            <Button
              className="float-right"
              color={registration ? 'primary' : 'success'}
              id="createReg"
              onClick={enableEdit}
              outline
            >
              <MaterialIcon name="edit" small />
            </Button>
          </PermissionBoundary>
        </Col>
      </Row>
    </div>
  );
};

interface UserProfileCardProps {
  readonly user: ExtendedUser;
  refresh(): void;
}

const UserProfileCard: FC<UserProfileCardProps> = ({ user, refresh }) => {
  const [edit, enableEdit, disableEdit] = useBoolState(false);

  return (
    <Card id={`userProfile${user.id}`}>
      {edit && (
        <ProfileEditModal
          close={disableEdit}
          complete={() => {
            refresh();
            disableEdit();
          }}
          showAddress
          user={user}
        />
      )}
      <CardHeader>
        <PermissionBoundary inline requiredPermissions={['user:update']}>
          <ModalEditButton id="updateProfile" start={enableEdit} />
        </PermissionBoundary>
        User Profile
        <IdentityStatus user={user} />
      </CardHeader>
      <CardBody>
        <table className="table">
          <tbody>
            <TableKVPair displayName="First Name" name="firstName" value={user.firstName} />
            <TableKVPair displayName="Last Name" name="lastName" value={user.lastName} />
            <TableKVPair
              displayName="Preferred Name"
              name="preferredName"
              value={user.preferredName}
            />
            <TableKVPair displayName="User Name" name="username" value={user.username} />
            <TableKVPair
              displayName="Born At"
              name="bornAt"
              value={user.bornAt && <DateT options={{ timeZone: 'UTC' }} value={user.bornAt} />}
            />
            <TableKVPair
              displayName="Email"
              name="email"
              value={user.email && <EmailStatus user={user} />}
            />
            <TableKVPair displayName="Phone" name="phone" value={user.phone} />
            <TableKVPair
              displayName="Address Line 1"
              name="addressLine1"
              value={user.address?.line1}
            />
            <TableKVPair
              displayName="Address Line 2"
              name="addressLine2"
              value={user.address?.line2}
            />
            <TableKVPair displayName="City" name="addressCity" value={user.address?.city} />
            <TableKVPair displayName="State" name="addressState" value={user.address?.state} />
            <TableKVPair
              displayName="Zipcode"
              name="addressZipcode"
              value={user.address?.zipcode}
            />
            <TableKVPair
              displayName="Country"
              name="addressCountry"
              value={user.address?.country}
            />
          </tbody>
        </table>
      </CardBody>
    </Card>
  );
};

const EmailStatus: FC<{ readonly user: ExtendedUser }> = ({ user }) => {
  if (user.pendingEmailChange) {
    return (
      <>
        {user.email}
        <span className="inline-icon" id="pendingEmailChange">
          <MaterialIcon name="warning" type="warning" />
        </span>
        <UncontrolledPopover placement="bottom" target="pendingEmailChange" trigger="hover">
          <PopoverBody>
            <p>
              This user has a pending email change request. They need to click the link on their new
              email to confirm the email change.
            </p>
          </PopoverBody>
        </UncontrolledPopover>
      </>
    );
  }

  if (!user.verified || !user.emailVerified) {
    return (
      <>
        {user.email}
        <span className="inline-icon" id="emailNotVerified">
          <MaterialIcon name="report" type="danger" />
        </span>
        <UncontrolledPopover placement="bottom" target="emailNotVerified" trigger="hover">
          <PopoverBody>
            <p>
              This user has not yet confirmed their e-mail address. They may request for a password
              reset to verify their email.
            </p>
          </PopoverBody>
        </UncontrolledPopover>
      </>
    );
  }

  return <>{user.email}</>;
};

const IdentityStatus: FC<{ readonly user: ExtendedUser }> = ({ user }) => {
  if (user.identity?.firstName !== user.firstName) {
    return null;
  }

  return (
    <>
      <span className="inline-icon" id="stripeVerified">
        <MaterialIcon name="verified_user" small type="primary" />
      </span>
      <UncontrolledPopover placement="bottom" target="stripeVerified" trigger="hover">
        <PopoverBody>
          <p>User's identity has been verified with Stripe Identity.</p>
        </PopoverBody>
      </UncontrolledPopover>
    </>
  );
};

const ModalEditButton: FC<{ readonly id: string; start(): void }> = ({ id, start }) => {
  return (
    <Button
      className="float-right"
      color="primary"
      id={id}
      onClick={start}
      style={{ padding: '0px 5px' }}
    >
      <MaterialIcon name="edit" small />
    </Button>
  );
};
