import React, { FC } from 'react';
import {
  Button,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from 'reactstrap';
import { getDate } from '../../../components/Date';
import { useTranslation } from '../../../translations';

export const TimePickerModal: FC<{ readonly callback: (time?: number) => void }> = ({
  callback,
}) => {
  const { ts } = useTranslation();
  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const date = e.currentTarget.elements.namedItem('date')!;
    const { value } = date as HTMLInputElement;

    callback(new Date(value).getTime());
  };

  return (
    <Modal isOpen>
      <Form onSubmit={onSubmit}>
        <ModalHeader>Pick a time to browse from</ModalHeader>
        <ModalBody>
          <FormGroup row>
            <Label for="type" md={4} sm={12}>
              Date
            </Label>
            <Col md={8} sm={12}>
              <Input defaultValue={getDate(new Date())} id="auditSetDate" name="date" type="date" />
            </Col>
          </FormGroup>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" id="auditUpdateDate" type="submit">
            Update
          </Button>
          <Button
            color="secondary"
            onClick={() => {
              callback();
            }}
          >
            {ts('cancel')}
          </Button>
        </ModalFooter>
      </Form>
    </Modal>
  );
};
