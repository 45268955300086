import React, { FC, FormEvent, useCallback, useLayoutEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { Col, Input, Label, Row } from 'reactstrap';
import { Department, VolunteerDepartmentModel } from '../../../../shared/volunteer';
import { MaterialIconText } from '../../../components/MaterialIconText';
import { useTranslation } from '../../../translations';
import { classNames } from '../../../utils';

const departmentTypes = ['Experience', 'Interest', 'Avoid'] as const;

type DepartmentTypes = (typeof departmentTypes)[number];

export const VolunteerDepartments: FC<{
  readonly departments: Department[];
  readonly volunteerDepartments?: VolunteerDepartmentModel[];
}> = ({ departments, volunteerDepartments }) => {
  const [state, setState] = useState<Record<string, number[]>>({});

  useLayoutEffect(() => {
    const initialState: Record<string, number[]> = {};
    for (const depType of departmentTypes) {
      initialState[depType] = (volunteerDepartments ?? [])
        .filter((volDep) => (volDep.states as string[]).includes(depType.toLowerCase()))
        .map((volDep) => volDep.id);
    }

    setState({ ...initialState });
  }, []);

  const manaullySetState = useCallback(
    (newRecord: Record<string, number[]>) => {
      setState((old) => ({ ...old, ...newRecord }));
    },
    [setState],
  );

  const changeSelection = useCallback(
    (type: DepartmentTypes, event: FormEvent<HTMLInputElement>) => {
      const value = Number.parseInt(event.currentTarget.value, 10);

      if (event.currentTarget.checked) {
        if (type === 'Interest' && state.Interest.length >= 5) {
          toast.error('You can only select up to 5 interests');
          return;
        }

        if (type === 'Avoid') {
          manaullySetState({
            Avoid: [...state.Avoid, value],
            Experience: state.Experience.filter((n) => n !== value),
            Interest: state.Interest.filter((n) => n !== value),
          });
        } else {
          manaullySetState({
            Avoid: state.Avoid.filter((n) => n !== value),
            [type]: [...state[type], value],
          });
        }
      } else {
        manaullySetState({
          [type]: state[type].filter((n) => n !== value),
        });
      }
    },
    [state, setState],
  );

  return (
    <Row className="department-select">
      <Col className="margin-bottom-10 department-select-header" lg={6} xs={12}>
        <strong>Department Name</strong>
      </Col>
      {departmentTypes.map((type) => (
        <Col
          className="margin-bottom-10 text-center department-select-header"
          key={type}
          lg={2}
          xs={4}
        >
          <strong>{type}</strong>
        </Col>
      ))}
      {departments.map(({ name, id, publiclyVisible }) => (
        <Col className="department-select-item" id={`department-${id}`} key={id} xs={12}>
          <Row>
            <Col className="margin-bottom-10" lg={6} xs={12}>
              {name}
              {!publiclyVisible && <HiddenDepartment />}
            </Col>
            {Object.keys(state).length > 0 &&
              departmentTypes.map((type) => {
                const formId = `department${type}${id}`;
                return (
                  <Col className="margin-bottom-10 text-center" key={type} lg={2} xs={4}>
                    <div
                      className={classNames(
                        {
                          negative: type === 'Avoid',
                        },
                        'custom-control',
                        'custom-checkbox',
                      )}
                    >
                      <Input
                        checked={state[type].includes(id)}
                        className="custom-control-input"
                        id={formId}
                        name={`department${type}[]`}
                        onChange={(event) => {
                          changeSelection(type, event);
                        }}
                        type="checkbox"
                        value={id}
                      />
                      <Label className="custom-control-label" for={formId} />
                    </div>
                  </Col>
                );
              })}
            <Col className="margin-bottom-10 show-mobile" xs={12} />
          </Row>
        </Col>
      ))}
    </Row>
  );
};

export const HiddenDepartment: FC = () => {
  const { ts } = useTranslation();
  return (
    <div className="small text-muted">
      <MaterialIconText name="visibility" small>
        This is a <strong>{ts('hidden')}</strong> department.
      </MaterialIconText>
    </div>
  );
};
