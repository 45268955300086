import React, { FC } from 'react';
import { NavLink, Route, RouteComponentProps, Switch } from 'react-router-dom';
import { Nav } from 'reactstrap';
import { PageHeader, PermissionBoundary, UserRoute, UserStateComponent } from '../../../components';
import { ContactSettings } from './Contact';
import { ConventionSettings } from './Convention';
import { ConventionListSettings } from './ConventionList';
import { FlagSettings } from './FlagsSettings';
import { KioskSettings } from './KioskSettings';
import { TermsSettings } from './Terms';
import { BadgeDesignerSettings } from './badgedesigner/BadgeDesignerSettings';
import { TemplateSettings } from './emailtemplates/TemplateSettings';
import { ExportBlock, ExportSettings, MenuLinkSettings, PlatformInfoSettings } from './';

export const SettingsPage: FC<RouteComponentProps> = ({ match: { path, url } }) => {
  return (
    <UserStateComponent>
      <PageHeader>System Settings</PageHeader>
      <Nav tabs>
        <NavLink activeClassName="active" className="nav-link" exact to={url}>
          Convention
        </NavLink>
        <NavLink activeClassName="active" className="nav-link" to={`${url}/terms`}>
          Terms
        </NavLink>
        <NavLink activeClassName="active" className="nav-link" to={`${url}/badge`}>
          Badge Designer
        </NavLink>
        <PermissionBoundary inline requiredPermissions={['registration:update']}>
          <NavLink activeClassName="active" className="nav-link" to={`${url}/flags`}>
            Reg Flags
          </NavLink>
        </PermissionBoundary>
        <NavLink activeClassName="active" className="nav-link" to={`${url}/links`}>
          Links
        </NavLink>
        <NavLink activeClassName="active" className="nav-link" to={`${url}/contact`}>
          Contact
        </NavLink>
        <PermissionBoundary inline requiredPermissions={['system:export']}>
          <NavLink activeClassName="active" className="nav-link" to={`${url}/export`}>
            Export
          </NavLink>
        </PermissionBoundary>
        <NavLink activeClassName="active" className="nav-link" to={`${url}/platform`}>
          Platform Info
        </NavLink>
        <PermissionBoundary inline requiredPermissions={['system:settings:update']}>
          <NavLink activeClassName="active" className="nav-link" to={`${url}/conventions`}>
            Conventions
          </NavLink>
        </PermissionBoundary>
        <PermissionBoundary inline requiredPermissions={['email:templates']}>
          <NavLink activeClassName="active" className="nav-link" to={`${url}/templates`}>
            Email Templates
          </NavLink>
        </PermissionBoundary>
      </Nav>
      <div style={{ marginTop: '20px' }}>
        <Switch>
          <Route component={ConventionSettings} exact path={path} />
          <Route component={TermsSettings} exact path={`${path}/terms`} />
          <Route component={MenuLinkSettings} exact path={`${path}/links`} />
          <Route component={KioskSettings} exact path={`${path}/kiosk`} />
          <Route component={ContactSettings} exact path={`${path}/contact`} />
          <Route component={ExportSettings} exact path={`${path}/export`} />
          <UserRoute
            component={FlagSettings}
            exact
            path={`${path}/flags`}
            requirePermissions={['registration:update']}
          />
          <Route component={ExportBlock} exact path={`${path}/export/block`} />
          <UserRoute
            component={BadgeDesignerSettings}
            path={`${path}/badge`}
            requirePermissions={['system:badgedesign:read']}
          />
          <Route component={PlatformInfoSettings} exact path={`${path}/platform`} />
          <UserRoute
            component={ConventionListSettings}
            exact
            path={`${path}/conventions`}
            requirePermissions={['system:settings:update']}
          />
          <UserRoute
            component={TemplateSettings}
            path={`${path}/templates`}
            requirePermissions={['email:templates']}
          />
        </Switch>
      </div>
    </UserStateComponent>
  );
};
