import React, { FC, useState } from 'react';
import { FormGroup, Label } from 'reactstrap';
import { PhoneInput } from '../../..';
import { CurrentUser } from '../../../../../shared/user/base';
import { useTranslation } from '../../../../translations';

interface Props {
  readonly user: CurrentUser | null;
}

export const FormNodePIIPhone: FC<Props> = ({ user }) => {
  const { ts } = useTranslation();
  const [phone, setPhone] = useState<string | null>(null);

  return (
    <div className="form-node form-phone-selector">
      <FormGroup>
        <Label>{ts('phone_number')}</Label>
        {user ? (
          <p>
            <strong>{user.phone}</strong>
          </p>
        ) : (
          <>
            <input name="phone" type="hidden" value={phone ?? ''} />
            <PhoneInput name="phone" onChange={setPhone} value={phone} />
          </>
        )}
      </FormGroup>
    </div>
  );
};
