import React, { FC } from 'react';
import { Card, CardBody, CardText, Col, Row } from 'reactstrap';
import { MaterialIcon } from '../../../components';
import { useTranslation } from '../../../translations';

export const DealersClosed: FC<{ readonly noAreas: boolean }> = ({ noAreas }) => {
  const { ts } = useTranslation();
  const reason = noAreas
    ? ts('no_dealer_areas_are_currently')
    : ts('we_are_not_currently_accepting');

  return (
    <Row className="justify-content-center" id="dealerClosed">
      <Col lg={6} xs={12}>
        <Card className="danger">
          <CardBody className="text-center">
            <MaterialIcon large name="remove_circle" type="danger" />
            <CardText tag="div">
              <p>{reason}</p>
              <p>{ts('please_check_back_later')}</p>
            </CardText>
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
};
