import React, { FC } from 'react';
import { Col, Row } from 'reactstrap';
import { MaterialIcon } from '../../components';
import { useTranslation } from '../../translations';

interface KioskCompleteProps {
  readonly isStripe?: boolean;
}

export const KioskComplete: FC<KioskCompleteProps> = ({ isStripe }) => {
  const { ts } = useTranslation();
  return (
    <Row
      className="justify-content-center container m-0"
      style={{
        paddingTop: '17.5px',
      }}
    >
      <Col className="text-center" md={8} xs={12}>
        <>
          <MaterialIcon
            large
            name={isStripe ? 'verified_user' : 'payment'}
            type={isStripe ? 'success' : 'warning'}
          />
          <h3>{ts('please_proceed_to_the_cashier')}</h3>
          <hr />
          <p>{ts('your_registration_is_now_complete')}</p>
          <p>{ts('please_ensure_that_you_have')}</p>
          {!isStripe && <p>{ts('as_you_have_not_yet')}</p>}
          <hr />
          <h4>{ts('this_kiosk_will_reset_shortly')}</h4>
        </>
      </Col>
    </Row>
  );
};
