import React, { FC, useCallback } from 'react';
import { Button, Card, CardBody, Col, Row } from 'reactstrap';
import { useTranslation } from '../../translations';
import { useConvention, useUser } from '../../utils';
import { LoadingWrapper } from '../../utils/LoadingWrapper';
import { Cart } from '../cart';

interface KioskPaymentsProps {
  readonly attendanceTypeId: number;
  onProcessing(): void;
  onSuccess(isStripe: boolean): void;
  onError(): void;
}

export const KioskPayment: FC<KioskPaymentsProps> = ({
  attendanceTypeId,
  onSuccess,
  onProcessing,
  onError,
}) => {
  const { ts } = useTranslation();
  const convention = useConvention();
  const user = useUser();

  const dataFetcher = useCallback(
    async (attendanceTypeId2: number): Promise<boolean> => {
      if (!user) {
        return false;
      }

      const reg = await api.getUserActiveRegistration(user.id);
      if (reg.paidOrderItem?.productId === attendanceTypeId2) {
        onSuccess(true);
        return true;
      }

      /**
       * If kiosk payments are disabled, return false. This is the same as clicking "Pay by Cashier".
       */
      if (!convention.kioskPaymentsEnabled) {
        onSuccess(false);
        return true;
      }

      return false;
    },
    [onSuccess, convention, user?.id],
  );

  return (
    <LoadingWrapper dataFetcher={dataFetcher} inline passback={attendanceTypeId}>
      {(success) =>
        success ? null : (
          <>
            <Card className="margin-bottom-10">
              <CardBody className="text-center">
                <Row>
                  <Col lg={8} xs={12}>
                    <p style={{ marginBottom: 0 }}>{ts('if_you_want_to_pay')}</p>
                  </Col>
                  <Col lg={4} xs={12}>
                    <Button
                      block
                      color="primary"
                      id="kioskAtDeskPayment"
                      onClick={() => {
                        onSuccess(false);
                      }}
                    >
                      Pay at Cashier
                    </Button>
                    <Button
                      block
                      color="primary"
                      id="kioskPayNow"
                      onClick={() => {
                        const element = document.getElementById('paymentCard');
                        if (element) {
                          element.scrollIntoView();
                        }
                      }}
                    >
                      Pay now
                    </Button>
                  </Col>
                </Row>
              </CardBody>
            </Card>
            <hr />
            <Cart
              onError={onError}
              onProcessing={onProcessing}
              onSuccess={() => {
                onSuccess(true);
              }}
            />
          </>
        )
      }
    </LoadingWrapper>
  );
};
