import React, { Dispatch, FC, SetStateAction, useState } from 'react';
import { toast } from 'react-toastify';
import { Button, ButtonGroup, Input, Label } from 'reactstrap';
import {
  DealerApplication,
  DealerFullModel,
  dealerStatusList,
  UpdateDealerApplicationPayload,
} from '../../../../shared/dealer';
import { LogicError } from '../../../../shared/error';
import { ProductModel, ProductVisibility } from '../../../../shared/orders';
import { ActionButtonModal, MaterialIcon } from '../../../components';
import { OptionSimpleDisplay } from '../../../components/option/OptionSimpleDisplay';
import { useTranslation } from '../../../translations';
import {
  displayName,
  handleUnknownOptionError,
  isLogicError,
  toggleElementInArray,
  useForm,
  useInputModel,
} from '../../../utils';
import { captureError } from '../../../utils/errorHandling';
import { getProductPrice } from '../../../utils/product';
import {
  ApplicationOptionListRender,
  cleanupApplicationOptions,
} from '../../apply/dealer/SpaceApplicationForm';
import { DealerApplicationStatus } from '../attendee/utils';
import { TableTypeArchiveWarning } from '.';

interface DealerApplicationFormProps {
  readonly edit: boolean;
  readonly dealer: DealerFullModel;
  readonly application?: DealerApplication;
  readonly area: { id: number; categoryId: number; name: string };
  readonly tableTypes: ProductModel[];
  onSuccess(): void;
  cancel(): void;
}

interface DealerApplicationViewerProps {
  readonly application?: DealerApplication;
  readonly area: { id: number; categoryId: number; name: string };
  readonly tableTypes: ProductModel[];
}

const DealerApplicationViewer: FC<DealerApplicationViewerProps> = ({
  area,
  application,
  tableTypes,
}) => {
  const table = tableTypes.find((t) => t.id === application?.tableType.id);

  const { ts } = useTranslation();
  return (
    <>
      <h3>{area.name} application</h3>
      <table className="table">
        <tbody>
          <tr>
            <th scope="row">Table Number</th>
            <td id="dealer-specialRequests">{application?.tableNumber}</td>
          </tr>
          <tr>
            <th scope="row">Special Requests</th>
            <td id="dealer-tableNumber">{application?.specialRequests}</td>
          </tr>
          {application && (
            <>
              <tr>
                <th scope="row">{ts('status')}</th>
                <td id="dealerStatus">
                  <DealerApplicationStatus
                    grant={!!application.paidOrderItemId}
                    status={application.status}
                  />
                </td>
              </tr>
              <tr>
                <th scope="row">Table</th>
                <td>
                  <div id="tableDescription">
                    {displayName(application.tableType)}
                    <TableTypeArchiveWarning
                      idx={application.id}
                      tableType={application.tableType}
                    />
                  </div>
                </td>
              </tr>
              <tr>
                <th scope="row">Requests</th>
                <td>
                  {application.dealerRequestTypes.map((request) => (
                    <div id={`request${request.id}`} key={request.id}>
                      {displayName(request)}
                    </div>
                  ))}
                </td>
              </tr>
            </>
          )}
          {table &&
            Object.entries(application?.options ?? {}).map(([key, value]) => {
              let option = table.options.find((o) => o.id.toString() === key);

              if (!option) {
                option = table.addons
                  .flatMap((a) => a.options)
                  .find((o) => o.id.toString() === key);
              }

              return (
                <tr key={key}>
                  <th scope="row">{option?.name ?? key}</th>
                  <td>
                    {option ? <OptionSimpleDisplay option={option} value={value} /> : <p>{key}</p>}
                  </td>
                </tr>
              );
            })}
        </tbody>
      </table>
    </>
  );
};

export const DealerApplicationForm: FC<DealerApplicationFormProps> = ({
  edit,
  dealer,
  application: inputTpplication,
  area,
  tableTypes,
  onSuccess,
  cancel,
}) => {
  if (!edit) {
    return (
      <DealerApplicationViewer application={inputTpplication} area={area} tableTypes={tableTypes} />
    );
  }

  return (
    <DealerApplicationFormEdit
      application={inputTpplication}
      area={area}
      cancel={cancel}
      dealer={dealer}
      onSuccess={onSuccess}
      tableTypes={tableTypes}
    />
  );
};

interface DealerApplicationFormEditProps {
  readonly application?: DealerApplication;
  readonly dealer: DealerFullModel;
  readonly area: { id: number; categoryId: number; name: string };
  readonly tableTypes: ProductModel[];
  onSuccess(): void;
  cancel(): void;
}

const DealerApplicationFormEdit: FC<DealerApplicationFormEditProps> = ({
  application: inputApplication,
  dealer,
  area,
  tableTypes,
  onSuccess,
  cancel,
}) => {
  const { ts } = useTranslation();
  const [application, setApplication] = useState<UpdateDealerApplicationPayload>(() => ({
    requests: inputApplication?.dealerRequestTypes.map((t) => t.id) ?? [],
    specialRequests: inputApplication?.specialRequests ?? '',
    tableTypeId: inputApplication?.tableType.id ?? tableTypes[0]?.id,
    status: inputApplication?.status ?? 'pending',
    tableNumber: inputApplication?.tableNumber ?? '',
    options: inputApplication?.options ?? {},
  }));

  const setSpecialRequests = useInputModel(setApplication, 'specialRequests');
  const setTableNumber = useInputModel(setApplication, 'tableNumber');

  const form = useForm(async () => {
    const table = tableTypes.find((t) => t.id === application.tableTypeId)!;
    try {
      await api.updateDealerApplication(dealer.id, area.id, {
        ...application,
        options: cleanupApplicationOptions(application.options, table),
        requests: application.requests.filter((t) => table.addons.some((a) => a.id === t)),
      });
    } catch (error) {
      if (isLogicError(error, LogicError.UnknownProductOption)) {
        handleUnknownOptionError(error, table.options);
      } else {
        captureError(error as Error);
      }

      return;
    }

    onSuccess();
  }, [dealer.id, area, application]);

  return (
    <form onSubmit={form.onSubmit}>
      <span style={{ float: 'right' }}>
        <ButtonGroup className="margin-bottom-10">
          <Button color="primary" disabled={form.saving} id="submitApplication" type="submit">
            {ts('save')}
          </Button>
          <Button disabled={form.saving} onClick={cancel} outline>
            {ts('cancel')}
          </Button>
        </ButtonGroup>
      </span>
      <h3>{area.name} application</h3>
      <table className="table">
        <tbody>
          <tr>
            <th scope="row">Special Requests</th>
            <td id="dealer-specialRequests">
              <Input
                id="specialRequests"
                max={1000}
                name="specialRequests"
                onChange={setSpecialRequests}
                type="textarea"
                value={application.specialRequests}
              />
            </td>
          </tr>
          <tr>
            <th scope="row">Table Number</th>
            <td id="dealer-tableNumber">
              <Input
                id="tableNumber"
                name="tableNumber"
                onChange={setTableNumber}
                value={application.tableNumber ?? ''}
              />
            </td>
          </tr>
          <tr>
            <th scope="row">{ts('status')}</th>
            <td id="dealerStatus">
              <ApplicationStatusEdit
                application={application}
                areaId={area.id}
                complete={onSuccess}
                dealerId={dealer.id}
                isPaid={!!inputApplication?.paidOrderItemId}
                setApplication={setApplication}
              />
            </td>
          </tr>
          <tr>
            <th scope="row">Table</th>
            <td>
              <ApplicationTableTypes
                application={application}
                disabled={!!inputApplication?.paidOrderItemId}
                setTableTypeIdOverride={(t) => setApplication({ ...application, tableTypeId: t })}
                tableTypes={tableTypes}
              />
            </td>
          </tr>
          <tr>
            <th scope="row">Requests</th>
            <td>
              <ApplicationRequests
                application={application}
                disabled={!!inputApplication?.paidOrderItemId}
                setApplication={setApplication}
                tableTypes={tableTypes}
              />
            </td>
          </tr>
        </tbody>
      </table>
      <ApplicationOptionListRender
        application={application}
        disabled={!!inputApplication?.paidOrderItemId}
        product={tableTypes.find(({ id }) => id === application.tableTypeId)!}
        setApplication={setApplication}
      />
    </form>
  );
};

const ApplicationStatusEdit: FC<{
  readonly dealerId: number;
  readonly application: UpdateDealerApplicationPayload;
  readonly isPaid: boolean;
  readonly areaId: number;
  readonly setApplication: Dispatch<SetStateAction<UpdateDealerApplicationPayload>>;
  complete(): void;
}> = ({ areaId, dealerId, application, isPaid, complete, setApplication }) => {
  const { ts } = useTranslation();
  return (
    <div style={{ display: 'flex' }}>
      <Input
        defaultValue={application.status}
        disabled={isPaid}
        id={`application${areaId}status`}
        name="status"
        onChange={(ev) => {
          setApplication((prev) => ({
            ...prev,
            status: ev.target.value as DealerApplication['status'],
          }));
        }}
        type="select"
      >
        {dealerStatusList.map((t) => (
          <option key={t.key} value={t.key}>
            {t.name}
          </option>
        ))}
      </Input>
      {isPaid && (
        <ActionButtonModal
          actionContent="Delete"
          buttonContent={<MaterialIcon name="delete" />}
          className="indent"
          color="danger"
          id="deleteAppPayment"
          onComplete={async () => {
            await api.deleteActiveDealerApplicationPayment(dealerId, areaId);
            toast.success(ts('payment_deleted'));
            complete();
          }}
          title="Delete Application Payment?"
        >
          Are you sure you want to delete the payment related to this application?
        </ActionButtonModal>
      )}
    </div>
  );
};

interface ApplicationTableTypesProps {
  readonly application: UpdateDealerApplicationPayload;
  readonly tableTypes: ProductModel[];
  readonly setTableTypeIdOverride: (input: number) => void;
  readonly disabled: boolean;
}

const ApplicationTableTypes: FC<ApplicationTableTypesProps> = ({
  application,
  tableTypes,
  setTableTypeIdOverride,
  disabled,
}) => {
  return (
    <Input
      disabled={disabled}
      id="tableTypeId"
      name="tableTypeId"
      onChange={(ev) => {
        setTableTypeIdOverride(Number.parseInt(ev.currentTarget.value, 10));
      }}
      type="select"
      value={application.tableTypeId.toString()}
    >
      {tableTypes.map((tableType) => (
        <option key={tableType.id} value={tableType.id}>
          {tableType.visibility === ProductVisibility.UNLISTED && '(Unlisted) '}
          {tableType.visibility === ProductVisibility.HIDDEN && '(Hidden) '}
          {displayName(tableType)} ({getProductPrice(tableType).price})
        </option>
      ))}
    </Input>
  );
};

interface ApplicationRequestsProps {
  readonly tableTypes: ProductModel[];
  readonly disabled: boolean;
  readonly application: UpdateDealerApplicationPayload;
  readonly setApplication: Dispatch<SetStateAction<UpdateDealerApplicationPayload>>;
}

const ApplicationRequests: FC<ApplicationRequestsProps> = ({
  tableTypes,
  application,
  disabled,
  setApplication,
}) => {
  const currentTableType = tableTypes.find(({ id }) => id === application.tableTypeId);

  return (
    <>
      {currentTableType?.addons.map((addon) => {
        const key = `requests${addon.id}`;
        return (
          <div className="custom-control custom-checkbox margin-top-10" key={addon.id}>
            <Input
              className="custom-control-input"
              defaultChecked={application.requests.includes(addon.id)}
              disabled={disabled}
              id={key}
              onChange={() => {
                setApplication((prev) => ({
                  ...prev,
                  requests: toggleElementInArray(prev.requests, addon.id),
                }));
              }}
              type="checkbox"
            />
            <Label className="custom-control-label" for={key}>
              {displayName(addon)}
            </Label>
          </div>
        );
      })}
    </>
  );
};
