import React, { FC } from 'react';
import { Badge, Table } from 'reactstrap';
import { MaterialIcon } from '../../../components';
import { useTranslation } from '../../../translations';
import { DateRange, Fetcher, useFetcher } from '../../../utils';

export const ConventionListSettings: FC = () => {
  const { ts } = useTranslation();
  const result = useFetcher(async () => {
    return await api.getConventionList();
  }, []);

  if (!result.complete) {
    return <Fetcher result={result} />;
  }

  return (
    <div>
      <Table>
        <thead>
          <tr>
            <th style={{ width: '100px' }}>{ts('enabled')}</th>
            <th>{ts('long_name')}</th>
            <th>{ts('short_name')}</th>
            <th>{ts('dates')}</th>
            <th>{ts('domain')}</th>
          </tr>
        </thead>
        <tbody>
          {result.data!.map((convention) => (
            <tr id={`convention-${convention.id}`} key={convention.id}>
              <td style={{ width: '100px', textAlign: 'center' }}>
                <MaterialIcon name={convention.enabled ? 'done' : 'close'} />
              </td>
              <td>{convention.longName}</td>
              <td>{convention.shortName}</td>
              <td>
                <DateRange
                  end={convention.endAt}
                  start={convention.startAt}
                  timeZone={convention.timeZone}
                />
              </td>
              <td>
                {convention.domain}{' '}
                {convention.cloudflareStatus && (
                  <Badge
                    color={convention.cloudflareStatus.status === 'active' ? 'success' : 'warning'}
                    pill
                    title={convention.cloudflareStatus.validation_errors
                      ?.map((t) => t.message)
                      .join('\n')}
                  >
                    {convention.cloudflareStatus?.status}
                  </Badge>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );
};
