import React, { FC } from 'react';
import { CardBrand, PaymentGateway } from '../../shared/orders/model';

export const cardBrands: Record<string, string> = {
  amex: 'American Express',
  diners: 'Diners Club',
  discover: 'Discover',
  giftcard: 'Gift Card',
  jcb: 'JCB',
  mastercard: 'MasterCard',
  unionpay: 'Union Pay',
  unknown: 'Unknown Card',
  visa: 'Visa',
};

export const PaymentInfo: FC<{
  readonly gateway: PaymentGateway;
  readonly cardBrand: CardBrand | null;
  readonly cardLast4: string;
}> = ({ cardBrand, cardLast4, gateway }) => {
  // const { t, ts } = useTranslation();
  switch (gateway) {
    case 'card': {
      return <>Card (Manual)</>;
    }

    case 'cash': {
      return <>Cash</>;
    }

    case 'paypal': {
      return <>PayPal</>;
    }

    case 'external': {
      if (cardBrand && cardLast4) {
        return (
          <>
            {cardBrands[cardBrand]} <small>(**** {cardLast4})</small>
          </>
        );
      }

      return <>Credit Card</>;
    }

    case 'voucher': {
      return <>Voucher</>;
    }

    case 'writeoff': {
      return <>Manual Payment</>;
    }

    default: {
      return <>Unknown</>;
    }
  }
};
