import React, { FC } from 'react';
import { PageHeader, SearchComponent, UserStateComponent } from '../../../components';

export const UserSearch: FC = () => {
  return (
    <UserStateComponent>
      <PageHeader>User Search</PageHeader>
      <SearchComponent />
    </UserStateComponent>
  );
};
