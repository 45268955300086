import React, { Dispatch, FC, SetStateAction, useCallback, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import {
  Button,
  Col,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from 'reactstrap';
import { LogicError } from '../../../../shared/error';
import { OptionDataPayload } from '../../../../shared/options';
import { ProductModel, ProductOptionInput } from '../../../../shared/orders';
import { RaffleTicketStatus, raffleTicketStatus } from '../../../../shared/orders/raffle';
import {
  RegistrationFlagModel,
  RegistrationOptionInput,
  RegistrationUpsert,
} from '../../../../shared/registration/model';
import { RegistrationTicket, UserRegistration } from '../../../../shared/user/base';
import { ActionButtonModal, AttendanceFlags } from '../../../components';
import { ActionModal } from '../../../components/ActionModal';
import { UserSelector } from '../../../components/UserSelector';
import { RegistrationOptionRenderer } from '../../../modules/registration/ProductOptionViewerList';
import { useTranslation } from '../../../translations';
import {
  fthrow,
  isAccessError,
  isLogicError,
  useForm,
  useInputModel,
  useObject,
} from '../../../utils';
import { LoadingWrapper } from '../../../utils/LoadingWrapper';
import { captureError } from '../../../utils/errorHandling';
import { cleanupRegOptions } from '../../../utils/options';

async function getProducts(): Promise<{
  products: ProductModel[];
  flags: RegistrationFlagModel[];
  options: RegistrationOptionInput[];
}> {
  const category = (await api.getProductCategories()).find(({ isRegistration }) => isRegistration);

  const [products, form] = await Promise.all([
    category
      ? api
          .getFullProductsByCategory(category.id)
          .catch((error: Error) => (isAccessError(error) ? [] : fthrow(error)))
      : [],
    api.getRegistrationForm(),
  ]);

  return { products, flags: form.flags, options: form.options };
}

interface RegistrationCardProps {
  readonly userId: number;
  readonly registration?: UserRegistration;
  readonly bannedWords: string[];
  complete(): void;
  close(): void;
}

export const RegistrationEditModal: FC<RegistrationCardProps> = (props) => {
  return (
    <Modal className="modal-large" id="registrationCard" isOpen toggle={props.close}>
      <ModalHeader>Edit Registration</ModalHeader>
      <LoadingWrapper dataFetcher={getProducts} inline>
        {({ products, flags, options }) => {
          if (products.length === 0) {
            return <RegistrationNoProducts close={props.close} />;
          }

          return (
            <RegistrationCardInner
              {...props}
              attendanceTypes={products}
              flags={flags}
              options={options}
            />
          );
        }}
      </LoadingWrapper>
    </Modal>
  );
};

const RegistrationNoProducts: FC<{ close(): void }> = ({ close }) => {
  const { ts } = useTranslation();
  return (
    <>
      <ModalBody>{ts('no_registration_products_available')}</ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={close}>
          {ts('cancel')}
        </Button>
      </ModalFooter>
    </>
  );
};

interface RegistrationCardInnerProps extends RegistrationCardProps {
  readonly attendanceTypes: ProductModel[];
  readonly flags: RegistrationFlagModel[];
  readonly options: RegistrationOptionInput[];
}

const RegistrationCardInner: FC<RegistrationCardInnerProps> = ({
  userId,
  registration,
  attendanceTypes,
  bannedWords,
  flags,
  options,
  complete,
  close,
}) => {
  const { ts } = useTranslation();
  const [reg, setReg] = useState<RegistrationUpsert>(() => ({
    attendanceTypeId:
      attendanceTypes.find(({ id }) => id === registration?.attendanceType.id)?.id ??
      attendanceTypes[0].id,
    badgeName: registration?.badgeName ?? '',
    options: registration?.options ?? {},
    flags: registration?.flags ?? [],
    raffle: registration?.raffleTicket,
  }));

  const [enabledFlags, setEnabledFlags] = useObject(reg, setReg, 'flags');
  const [raffle, setRaffle] = useObject(reg, setReg, 'raffle');
  const [showForceModal, setShowForceModal] = useState(false);
  const attendanceType = attendanceTypes.find(({ id }) => id === reg.attendanceTypeId)!;
  const setBadgeName = useInputModel(setReg, 'badgeName');

  useEffect(() => {
    if (attendanceType.raffle && !reg.raffle) {
      setRaffle({ status: 'waiting', points: 0 });
    } else if (!attendanceType.raffle && reg.raffle) {
      setRaffle(undefined);
    }
  }, [attendanceType, reg]);

  const form = useForm(async () => {
    try {
      if (registration) {
        await api.updateRegistration(
          registration.id,
          cleanupRegOptions([...attendanceType.options, ...options], reg),
        );
      } else {
        await api.createRegistration(
          userId,
          cleanupRegOptions([...attendanceType.options, ...options], reg),
        );
      }
    } catch (error) {
      if (isLogicError(error, LogicError.ProductNotAvailable)) {
        setShowForceModal(true);
        return;
      }

      throw error;
    }

    complete();
  }, [registration, reg, userId, options, registration, complete]);

  return (
    <form onSubmit={form.onSubmit}>
      {showForceModal && (
        <ForceModal
          close={() => setShowForceModal(false)}
          complete={complete}
          reg={cleanupRegOptions([...attendanceType.options, ...options], reg)}
          registration={registration}
          userId={userId}
        />
      )}
      <ModalBody>
        <Row>
          <Col lg={6} md={12}>
            <FormGroup>
              <Label for="badgeName">{ts('badge_name')}</Label>
              <Input
                invalid={bannedWords.includes('badgeName')}
                maxLength={32}
                name="badgeName"
                onChange={setBadgeName}
                value={reg.badgeName}
              />
              {bannedWords.includes('badgeName') && (
                <FormFeedback>{ts('badge_name_contains_a_banned')}</FormFeedback>
              )}
            </FormGroup>
          </Col>
          <Col lg={6} md={12}>
            <FormGroup>
              <Label for="attendanceType">{ts('attendance_type')}</Label>
              <Input
                name="attendanceTypeId"
                onChange={(e) =>
                  setReg({ ...reg, attendanceTypeId: Number.parseInt(e.target.value, 10) })
                }
                type="select"
                value={attendanceType.id}
              >
                {attendanceTypes.map(({ id, name }) => (
                  <option key={id} value={id}>
                    {name}
                  </option>
                ))}
              </Input>
            </FormGroup>
          </Col>
          {flags.length > 0 && (
            <Col lg={12}>
              <FormGroup>
                <AttendanceFlags
                  enabledFlags={enabledFlags}
                  flags={flags}
                  onUpdate={setEnabledFlags}
                />
              </FormGroup>
            </Col>
          )}
          {options.length > 0 && (
            <Col lg={12}>
              <FormProductOptions
                options={options.filter((t) => t.fromRegistration)}
                payload={reg.options}
                setRegistration={setReg}
                title="Registration Options"
              />
            </Col>
          )}
          {attendanceType && attendanceType.options.length > 0 && (
            <Col lg={12}>
              <FormProductOptions
                options={attendanceType.options}
                payload={reg.options}
                setRegistration={setReg}
                title="Product Options"
              />
            </Col>
          )}
          {raffle && (
            <Col lg={12}>
              <UpdateRaffleComponent setTicket={setRaffle} ticket={raffle} />
            </Col>
          )}
        </Row>
      </ModalBody>
      <ModalFooter>
        {registration?.paidOrderItem && (
          <div style={{ flexGrow: 1 }}>
            <ActionButtonModal
              actionContent={ts('delete')}
              buttonContent={<>Remove payment</>}
              color="danger"
              id="deleteRegPayment"
              onComplete={async () => {
                await api.deleteRegistrationPayment(userId);
                toast.success(ts('payment_deleted'));
                complete();
              }}
              title="Delete Registration Payment?"
            >
              Are you sure you want to delete the payment related to this registration?
            </ActionButtonModal>
            <TransferButton complete={complete} userId={userId} />
          </div>
        )}

        {registration && !registration.paidOrderItem && (
          <div style={{ flexGrow: 1 }}>
            <ActionButtonModal
              actionContent={ts('delete')}
              buttonContent={<>{ts('delete')}</>}
              color="danger"
              id="deleteReg"
              onComplete={async () => {
                await api.deleteRegistration(registration.id);
                toast.success('Registration deleted!');
                complete();
              }}
              title="Delete Registration?"
            >
              Are you sure you want to delete this registration?
            </ActionButtonModal>
          </div>
        )}

        <Button color="primary" disabled={form.saving} id="submitUpdateReg" type="submit">
          {registration ? 'Update' : 'Create'} Registration
        </Button>
        <Button color="secondary" onClick={close}>
          {ts('cancel')}
        </Button>
      </ModalFooter>
    </form>
  );
};

interface TransferButtonProps {
  readonly userId: number;
  complete(): void;
}

const TransferButton: FC<TransferButtonProps> = ({ userId, complete }) => {
  const [ids, setIds] = useState<number[]>([]);

  return (
    <ActionButtonModal
      actionContent="Transfer"
      buttonContent={<>Transfer</>}
      color="secondary"
      id="transferPayment"
      onComplete={async () => {
        await api.transferRegistration(userId, ids[0]);
        toast.success('Transfer completed!');
        complete();
      }}
      title="Transfer Registration Payment?"
    >
      Select user to transfer this registration to:
      <UserSelector id="transferSelect" maxItems={1} selectionIdsChanged={setIds} />
    </ActionButtonModal>
  );
};

interface FormProductOptionProps {
  readonly title: string;
  readonly options: ProductOptionInput[];
  readonly payload: OptionDataPayload;
  readonly setRegistration: Dispatch<SetStateAction<RegistrationUpsert>>;
}

const FormProductOptions: FC<FormProductOptionProps> = ({
  title,
  options,
  payload,
  setRegistration,
}) => {
  return (
    <>
      <hr />
      <FormGroup row>
        <Label md={4} sm={12}>
          {title}
        </Label>
        <Col md={8} sm={12}>
          {options.map((o) => (
            <Col key={o.id} xs={12}>
              <RegistrationOptionRenderer
                option={o}
                setRegistration={setRegistration}
                values={payload}
              />
            </Col>
          ))}
        </Col>
      </FormGroup>
    </>
  );
};

interface UpdateRaffleComponentProps {
  readonly ticket: RegistrationTicket;
  readonly setTicket: Dispatch<SetStateAction<RegistrationTicket | undefined>>;
}

const UpdateRaffleComponent: FC<UpdateRaffleComponentProps> = ({ ticket, setTicket }) => {
  const { ts } = useTranslation();
  return (
    <>
      <hr />
      <FormGroup row>
        <Label md={4} sm={12}>
          Silo Options
        </Label>
        <Col lg={8} md={12}>
          <FormGroup>
            <Label for="raffle[status]">{ts('status')}</Label>
            <Input
              id="raffleStatus"
              name="raffle[status]"
              onChange={(e) =>
                setTicket({ ...ticket, status: e.target.value as RaffleTicketStatus })
              }
              type="select"
              value={ticket?.status ?? 'waiting'}
            >
              {raffleTicketStatus.map((name) => (
                <option key={name} value={name}>
                  {name}
                </option>
              ))}
            </Input>
          </FormGroup>
          <FormGroup>
            <Label for="raffle[points]">Points</Label>
            <Input
              maxLength={32}
              name="raffle[points]"
              onChange={(e) => setTicket({ ...ticket, points: e.target.valueAsNumber })}
              type="number"
              value={ticket?.points ?? 0}
            />
          </FormGroup>
        </Col>
      </FormGroup>
    </>
  );
};

interface ForceModalProps {
  readonly userId: number;
  readonly registration?: UserRegistration;
  readonly reg: RegistrationUpsert;
  close(): void;
  complete(): void;
}

const ForceModal: FC<ForceModalProps> = ({ userId, registration, reg, close, complete }) => {
  const onComplete = useCallback(async () => {
    try {
      if (registration) {
        await api.updateRegistration(registration.id, { ...reg, force: true });
      } else {
        await api.createRegistration(userId, { ...reg, force: true });
      }

      complete();
    } catch (error) {
      captureError(error as Error);
    }
  }, []);

  const { ts } = useTranslation();
  return (
    <ActionModal
      actionContent={<>Force</>}
      close={close}
      color="danger"
      id="forceModal"
      isOpen
      onComplete={onComplete}
      title="Force Registration"
    >
      <p>{ts('the_product_you_are_trying')}</p>
      <p>{ts('this_will_override_any_product')}</p>
    </ActionModal>
  );
};
