import React, { FC, useCallback, useState } from 'react';
import { toast } from 'react-toastify';
import { Alert, Button, Col, FormText, Input, Label, Row } from 'reactstrap';
import { Policy } from '../../../../shared/policies';
import { useTranslation } from '../../../translations';
import { Fetcher, useConvention, useFetcher, useInputState } from '../../../utils';
import { captureError } from '../../../utils/errorHandling';

export const TermsSettings: FC = () => {
  const { identityVerification } = useConvention();
  const fetcher = useFetcher(async () => {
    return await api.getPolicies();
  }, []);

  if (!fetcher.complete) {
    return <Fetcher result={fetcher} />;
  }

  const { refresh, data } = fetcher;

  const policies = data ?? [];

  return (
    <div>
      <TermsElement
        description="Policy explaining how the organization / convention will use the user's data. Displayed when creating a new user account and on the Privacy Policy page."
        displayName="Privacy terms"
        name="privacy"
        policies={policies}
        refresh={refresh}
      />
      <hr />
      <TermsElement
        description="The organization / convention's terms and conditions or code of conduct. Displayed when creating a new user account and on the Terms & Conditions page."
        displayName="Convention Policy / Terms & Conditions"
        name="terms"
        policies={policies}
        refresh={refresh}
      />
      <hr />
      <TermsElement
        description="Error message shown to users when they are below the child age threshold, or below 13 years of age, whichever is less."
        displayName="Child Registration Error"
        name="childRegistrationError"
        policies={policies}
        refresh={refresh}
      />
      <hr />
      <TermsElement
        description="Warning message shown to users when they are below the minor age threshold."
        displayName="Minor Registration Warning"
        name="underageWarning"
        policies={policies}
        refresh={refresh}
      />
      <hr />
      <TermsElement
        description="Terms shown to parents when registering child badges under their account."
        displayName="Child Badge Terms"
        name="childBadgeTerms"
        policies={policies}
        refresh={refresh}
      />
      <hr />
      <TermsElement
        allowOrgDelete
        description="Displayed on the event registration page when the user is registering for their badge."
        displayName="Registration Terms"
        name="conventionTerms"
        policies={policies}
        refresh={refresh}
      />
      <hr />
      <TermsElement
        allowOrgDelete
        description="Text to be displayed on the login page, below the convention name and logo."
        displayName="Login Page Announcement"
        name="loginAnnouncement"
        policies={policies}
        refresh={refresh}
      />
      {identityVerification !== 'none' && (
        <>
          <hr />
          <TermsElement
            description="Warning message to display when the user wants to opt-out of Stripe Identity verification."
            displayName="Opt Out from Stripe Identity"
            name="optOutIdentity"
            policies={policies}
            refresh={refresh}
          />
        </>
      )}
    </div>
  );
};

interface TermsElementProps {
  readonly allowOrgDelete?: boolean;
  readonly name: string;
  readonly displayName: string;
  readonly description: string;
  readonly policies: Policy[];
  refresh(): void;
}

export const TermsElement: FC<TermsElementProps> = ({
  allowOrgDelete,
  description,
  displayName,
  name,
  policies,
  refresh,
}) => {
  const { ts } = useTranslation();
  const [editAnyway, setEditAnyway] = useState(false);
  const matchingPolicies = policies.filter((p) => p.key === name);
  const hasConSpecificPolicy = matchingPolicies.some((p) => !!p.conventionId);

  const createPolicy = useCallback(
    async (orgWide: boolean) => {
      await api.upsertPolicy({
        key: name,
        value: 'TBC',
        orgWide,
      });

      refresh();
    },
    [name, refresh],
  );

  return (
    <div className="left-align margin-top-10 mb-2">
      <Label for={name}>
        {displayName}
        <FormText color="muted">{description}</FormText>
      </Label>
      <Row>
        {matchingPolicies.map((policy) => {
          const orgWide = !policy.conventionId;
          const key = orgWide ? `${name}-${policy.conventionId}` : name;

          const allowEditingPolicy = editAnyway || !orgWide || !hasConSpecificPolicy;

          return (
            <Col key={key} md={6} xs={12}>
              <h6>{orgWide ? ts('organization_wide_policy') : ts('convention_specific_policy')}</h6>
              {allowEditingPolicy && (
                <TermsTextBox
                  allowOrgDelete={allowOrgDelete}
                  displayName={displayName}
                  name={name}
                  orgWide={orgWide}
                  policy={policy}
                  refresh={refresh}
                />
              )}
              {!allowEditingPolicy && (
                <Alert color="warning">
                  <p>{ts('this_policy_is_overridden_by')}</p>
                  <p>{ts('changes_made_to_the_organizationwide')}</p>
                  <Button
                    className="mt-2"
                    color="danger"
                    onClick={() => setEditAnyway(true)}
                    outline
                    size="sm"
                  >
                    {ts('i_understand_but_i_want')}
                  </Button>
                </Alert>
              )}
            </Col>
          );
        })}
        {matchingPolicies.length < 2 && (
          <Col md={6} xs={12}>
            <h6>Create Policies</h6>
            {matchingPolicies.length === 0 && (
              <Button
                block
                className="mb-2"
                color="secondary"
                onClick={async () => await createPolicy(true)}
              >
                {ts('create_organizationwide_policy')}
              </Button>
            )}
            <Button
              block
              className="mb-2"
              color="secondary"
              onClick={async () => await createPolicy(false)}
            >
              {ts('create_conventionspecific_policy')}
            </Button>
          </Col>
        )}
      </Row>
    </div>
  );
};

interface TermsTextBoxProps {
  readonly displayName: string;
  readonly name: string;
  readonly policy: Policy;
  readonly orgWide?: boolean;
  readonly allowOrgDelete?: boolean;
  refresh(): void;
}

const TermsTextBox: FC<TermsTextBoxProps> = ({
  allowOrgDelete,
  displayName,
  policy,
  name,
  orgWide,
  refresh,
}) => {
  const [value, setValue] = useInputState(policy?.value ?? '');
  const [saving, setSaving] = useState(false);

  const onSave = useCallback(async () => {
    try {
      setSaving(true);

      await api.upsertPolicy({
        key: name,
        value,
        orgWide: orgWide ?? false,
      });

      toast.success(
        `${
          orgWide ? 'Organization-wide' : 'Convention-specific'
        } policy "${displayName}" saved successfully!`,
      );

      refresh();
    } catch (error) {
      captureError(error as Error);
    }

    setSaving(false);
  }, [value, orgWide]);

  const onDelete = useCallback(async () => {
    try {
      setSaving(true);

      await api.deletePolicy({
        key: policy.key,
        orgWide: orgWide ?? false,
      });

      toast.success(
        `${
          orgWide ? 'Organization-wide' : 'Convention-specific'
        } policy "${displayName}" deleted successfully!`,
      );

      refresh();
    } catch (error) {
      captureError(error as Error);
    }

    setSaving(false);
  }, [policy.id]);

  return (
    <div>
      <Input
        disabled={saving}
        id={name}
        onChange={setValue}
        rows={15}
        type="textarea"
        value={value}
      />
      <Row>
        <Col md={orgWide && !allowOrgDelete ? 12 : 6} xs={12}>
          <Button block className="mt-2" color="primary" disabled={saving} onClick={onSave}>
            Save
          </Button>
        </Col>
        {(allowOrgDelete || !orgWide) && (
          <Col md={6} xs={12}>
            <Button
              block
              className="mt-2"
              color="danger"
              disabled={saving}
              onClick={onDelete}
              outline
            >
              Delete
            </Button>
          </Col>
        )}
      </Row>
    </div>
  );
};
