import { JSONForm } from '@conventioncatcorp/common-fe/dist/components/json-form/JSONForm';
import React, { FC, useState } from 'react';
import { Button, Card, CardBody, CardHeader, Col, Input, Row } from 'reactstrap';
import { ProductModel } from '../../../../../shared/orders';
import { MaterialIcon } from '../../../../components';
import { useTranslation } from '../../../../translations';
import { cToUsdStr, cToUsdStrPref } from '../../../../utils/cToUsdStr';

interface PriceInfoProps {
  readonly product: ProductModel;
  readonly onUpdate: () => void;
}

const enum PriceInfoMode {
  View,
  Edit,
}

export const PriceInfo: FC<PriceInfoProps> = ({ product, onUpdate }) => {
  const { ts } = useTranslation();
  const [mode, setMode] = useState(PriceInfoMode.View);

  if (mode === PriceInfoMode.View) {
    return (
      <Card className="margin-bottom-10">
        <CardHeader>Prices</CardHeader>
        <CardBody>
          <Row className="text-center">
            <Col xs={12}>
              <strong>Current Price</strong>
              <h2 style={{ marginTop: '5px' }}>{cToUsdStrPref(product.price)}</h2>
            </Col>
            {!product.deletedAt && (
              <Col xs={12}>
                <Button
                  block
                  color="primary"
                  id="changePrice"
                  onClick={() => {
                    setMode(PriceInfoMode.Edit);
                  }}
                  style={{ marginTop: '10px' }}
                >
                  Change Price
                </Button>
              </Col>
            )}
          </Row>
        </CardBody>
      </Card>
    );
  }

  return (
    <Card>
      <CardHeader>Prices</CardHeader>
      <CardBody>
        <JSONForm
          method="patch"
          onSuccess={() => {
            onUpdate();
            setMode(PriceInfoMode.View);
          }}
          path={`/api/products/${product.id}/price`}
        >
          <Row className="text-center">
            <Col xs={5}>
              <strong>New Price</strong>
              <h2 style={{ marginTop: '5px' }}>
                <Row>
                  <Col xs={2}>$</Col>
                  <Col xs={10}>
                    <Input
                      defaultValue={cToUsdStr(product.price)}
                      id="price"
                      min={0}
                      name="price"
                      step={0.01}
                      style={{ fontSize: '1.25rem', marginTop: '-3px' }}
                      type="number"
                    />
                  </Col>
                </Row>
              </h2>
            </Col>
            <Col xs={2}>
              <MaterialIcon large name="arrow_back_ios" />
            </Col>
            <Col xs={5}>
              <strong>Current Price</strong>
              <h2 style={{ marginTop: '5px' }}>{cToUsdStrPref(product.price)}</h2>
            </Col>
            <Col xs={6}>
              <Button block color="primary" id="saveNewPrice" outline style={{ marginTop: '10px' }}>
                Save New Price
              </Button>
            </Col>
            <Col xs={6}>
              <Button
                block
                color="danger"
                onClick={() => {
                  setMode(PriceInfoMode.View);
                }}
                outline
                style={{ marginTop: '10px' }}
              >
                {ts('cancel')}
              </Button>
            </Col>
          </Row>
        </JSONForm>
      </CardBody>
    </Card>
  );
};
