import { JSONForm } from '@conventioncatcorp/common-fe/dist/components/json-form/JSONForm';
import React, { FC, useCallback, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Button, Col, Container, FormGroup, FormText, Input, Label } from 'reactstrap';
import {
  PageHeader,
  PhoneInput,
  RegionSelector,
  UniqueAttributeInput,
  UserStateComponent,
} from '../../../components';
import { CountrySelector } from '../../../components/CountrySelector';
import { useTranslation } from '../../../translations';

export const ManualCreateComponent: FC = () => {
  const { ts } = useTranslation();
  const history = useHistory();
  const [country, setCountry] = useState('');
  const [region, setRegion] = useState('');
  const [phone, setPhone] = useState('');

  const onSuccess = useCallback((data: { id: number }) => {
    toast.success('Account created.');
    history.push(`/housekeeping/attendees/user/${data.id}`);
  }, []);

  return (
    <UserStateComponent>
      <PageHeader>{ts('manual_account_creation')}</PageHeader>
      <Container className="justify-content-center" id="createManualUser">
        <JSONForm<{ id: number }> method="post" onSuccess={onSuccess} path="/api/users/manual">
          <FormGroup row>
            <Label for="reason" sm={4}>
              {ts('reason_for_manual_creation')}
            </Label>
            <Col sm={8}>
              <Input id="reason" name="reason" type="textarea" />
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label for="username" sm={4}>
              Username
            </Label>
            <Col sm={8}>
              <UniqueAttributeInput type="username" />
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label for="email" sm={4}>
              {ts('email_address')}
            </Label>
            <Col sm={8}>
              <UniqueAttributeInput type="email" />
            </Col>
          </FormGroup>
          <hr />
          <FormGroup row>
            <Label for="firstName" sm={4}>
              {ts('first_name')}
            </Label>
            <Col sm={8}>
              <Input
                autoComplete="given-name"
                id="firstName"
                name="firstName"
                placeholder={`${ts('example')}: John`}
              />
              <FormText color="muted">
                This must be your legal first name, as it appears on your government issued ID.
              </FormText>
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label for="lastName" sm={4}>
              {ts('last_name')}
            </Label>
            <Col sm={8}>
              <Input
                autoComplete="family-name"
                id="lastName"
                name="lastName"
                placeholder={`${ts('example')}: Doe`}
              />
              <FormText color="muted">
                This must be your legal last name, as it appears on your government issued ID.
              </FormText>
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label for="preferredName" sm={4}>
              {ts('preferred_name')}
            </Label>
            <Col sm={8}>
              <Input
                autoComplete="username"
                id="preferredName"
                name="preferredName"
                placeholder={`${ts('example')}: Johnny`}
              />
              <FormText color="muted">{ts('if_you_have_a_name')}</FormText>
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label for="bornAt" sm={4}>
              {ts('date_of_birth')}
            </Label>
            <Col sm={8}>
              <Input autoComplete="bday" id="bornAt" name="bornAt" type="date" />
            </Col>
          </FormGroup>
          <hr />
          <FormGroup row>
            <Label for="addressLine1" sm={4}>
              {ts('address_line_1')}
            </Label>
            <Col sm={8}>
              <Input
                autoComplete="address-line1"
                id="addressLine1"
                name="addressLine1"
                placeholder={`${ts('example')}: 123 Main St`}
              />
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label for="addressLine2" sm={4}>
              {ts('address_line_2_optional')}
            </Label>
            <Col sm={8}>
              <Input
                autoComplete="address-line2"
                id="addressLine2"
                name="addressLine2"
                placeholder={`${ts('example')}: Apt 123`}
              />
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label for="addressCity" sm={4}>
              City
            </Label>
            <Col sm={8}>
              <Input
                autoComplete="address-level2"
                id="addressCity"
                name="addressCity"
                placeholder={`${ts('example')}: Reno`}
              />
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label for="addressZipcode" sm={4}>
              {ts('zip_postal_code')}
            </Label>
            <Col sm={8}>
              <Input
                autoComplete="postal-code"
                id="addressZipcode"
                name="addressZipcode"
                placeholder={`${ts('example')}: 89501`}
              />
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label for="addressCountry" sm={4}>
              Country
            </Label>
            <Col sm={8}>
              <CountrySelector
                autoComplete="country-name"
                classes="form-control"
                id="addressCountry"
                name="addressCountry"
                onChange={(data) => setCountry(data)}
                value={country}
                valueType="short"
              />
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label for="addressState" sm={4}>
              {ts('state_province')}
            </Label>
            <Col sm={8}>
              <RegionSelector
                autoComplete="address-level1"
                classes="form-control"
                country={country}
                countryValueType="short"
                data-coerce={false}
                disableWhenEmpty
                id="addressState"
                name="addressState"
                onChange={(data) => setRegion(data)}
                value={region}
                valueType="short"
              />
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label for="phone" sm={4}>
              {ts('phone_number')}
            </Label>
            <Col sm={8}>
              <input name="phone" type="hidden" value={phone} />
              <PhoneInput onChange={(data) => setPhone(data!)} value={phone} />
            </Col>
          </FormGroup>
          <FormGroup id="sendVerificationEmailGroup" row>
            <Col sm={4} />
            <Col sm={8}>
              <div className="custom-control custom-checkbox margin-top-10 left-align">
                <Input
                  className="custom-control-input"
                  id="sendVerificationEmail"
                  name="sendVerificationEmail"
                  type="checkbox"
                />
                <Label className="custom-control-label" for="sendVerificationEmail">
                  Send the user an account confirmation email.
                  <FormText color="muted">
                    The user will not receive additional emails until their password is reset.
                  </FormText>
                </Label>
              </div>
            </Col>
          </FormGroup>
          <FormGroup>
            <Button block color="primary" id="submitForm" type="submit">
              Create user
            </Button>
          </FormGroup>
        </JSONForm>
      </Container>
    </UserStateComponent>
  );
};
