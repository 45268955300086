import React, { FC, useCallback } from 'react';
import { Link } from 'react-router-dom';
import { Badge } from 'reactstrap';
import { DealerForm, DealerFullModel, DealerOptionInput } from '../../../../shared/dealer';
import { OptionDataPayload, OptionDataValue } from '../../../../shared/options';
import { ProductOptionRenderer } from '../../../components';
import { OptionSimpleDisplay } from '../../../components/option/OptionSimpleDisplay';
import { useTranslation } from '../../../translations';
import { renderName } from '../../../utils';
import { EditEntry } from '.';

interface DealerEditFormProps {
  readonly dealer?: DealerFullModel;
  readonly edit: boolean;
  readonly form: DealerForm;
  readonly options: OptionDataPayload;
  readonly setOptions: React.Dispatch<React.SetStateAction<OptionDataPayload>>;
}

export const DealerEditForm: FC<DealerEditFormProps> = ({
  dealer,
  edit,
  form,
  options,
  setOptions,
}) => {
  const { ts } = useTranslation();
  return (
    <table className="table">
      <tbody>
        {dealer && (
          <tr>
            <th scope="row">User</th>
            <td id="userName">
              <Link to={`/housekeeping/attendees/user/${dealer.user.id}`}>
                {renderName(dealer.user)}
              </Link>{' '}
              {dealer.user.hasRestrictionNotice ? (
                <Badge className="has-restriction-notice" color="danger" key="hasRestrictionNotice">
                  Restriction
                </Badge>
              ) : undefined}
            </td>
          </tr>
        )}
        <EditEntry displayName="Business Name" edit={edit} name="name" value={dealer?.name} />
        <EditEntry displayName="Business Email" edit={edit} name="email" value={dealer?.email} />
        <OptionList
          edit={edit}
          form={form.options.filter((t) => t.sortOrder <= 0)}
          options={options}
          setOptions={setOptions}
        />
        <EditEntry
          displayName="Address Line 1"
          edit={edit}
          name="addressLine1"
          value={dealer?.addressLine1}
        />
        <EditEntry
          displayName={ts('address_line_2_optional')}
          edit={edit}
          name="addressLine2"
          value={dealer?.addressLine2 ?? ''}
        />
        <EditEntry displayName="City" edit={edit} name="addressCity" value={dealer?.addressCity} />
        <EditEntry
          displayName="State / Province"
          edit={edit}
          name="addressState"
          value={dealer?.addressState}
        />
        <EditEntry
          displayName="Zip / Postal Code"
          edit={edit}
          name="addressZipcode"
          value={dealer?.addressZipcode}
        />
        <EditEntry
          displayName="Country"
          edit={edit}
          name="addressCountry"
          value={dealer?.addressCountry}
        />
        <OptionList
          edit={edit}
          form={form.options.filter((t) => t.sortOrder > 0)}
          options={options}
          setOptions={setOptions}
        />
      </tbody>
    </table>
  );
};

interface OptionListProps {
  readonly edit: boolean;
  readonly form: DealerOptionInput[];
  readonly options: OptionDataPayload;
  readonly setOptions: React.Dispatch<React.SetStateAction<OptionDataPayload>>;
}

const OptionList: FC<OptionListProps> = ({ edit, form, options, setOptions }) => {
  const onOptionChange = useCallback((id: number, value: OptionDataValue) => {
    setOptions((old) => ({
      ...old,
      [id.toString()]: value,
    }));
  }, []);

  return (
    <>
      {form.map((option) => {
        if (option.internalName === 'name' || option.internalName === 'email') {
          return null;
        }

        const value = options[option.id];

        if (!value && !edit) {
          return null;
        }

        return (
          <tr key={option.id}>
            <th scope="row">{option.name}</th>
            <td id={`dealer-${option.id}`}>
              {edit ? (
                <ProductOptionRenderer
                  defaultValue={value}
                  onChange={onOptionChange}
                  option={option}
                  required={option.required}
                />
              ) : (
                <OptionSimpleDisplay option={option} value={value} />
              )}
            </td>
          </tr>
        );
      })}
    </>
  );
};
