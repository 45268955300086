import React, { FC } from 'react';
import { Button } from 'reactstrap';
import { captureError } from '../../../utils/errorHandling';
import { FormNodeMarkdown } from './FormNodeMarkdown';

export type KioskException = 'underReview' | 'unverified';

interface Props {
  readonly exception: KioskException;
  tryLogin(): Promise<void>;
}

export const FormExceptionHandler: FC<Props> = (props) => {
  switch (props.exception) {
    case 'unverified': {
      return <FormUnverifiedException {...props} />;
    }

    case 'underReview': {
      return <FormUnderReviewException />;
    }
  }
};

const FormUnderReviewException: FC = () => {
  const message =
    // eslint-disable-next-line max-len
    "Your registration was created, but your account has been flagged for manual review by the registration team.\n\nWe'll notify you by email once this process has been completed.\n\nWe apologize for any inconvenience caused.";

  return (
    <section className="section" id="section-verification-pending">
      <h2>Registration Under Review</h2>
      <div className="section-nodes">
        <div className="section-node">
          <FormNodeMarkdown id="1" markdown={message} type="markdown" />
        </div>
      </div>
    </section>
  );
};

const FormUnverifiedException: FC<{ tryLogin(): Promise<void> }> = ({ tryLogin }) => {
  const doRetry = async () => {
    try {
      await tryLogin();
    } catch (error) {
      captureError(error as Error);
    }
  };

  const message =
    // eslint-disable-next-line max-len
    'Please check your email to confirm your account and complete registration.\n\nBe sure to check your **spam / junk folder**!\n\nOnce you\'ve verified your email, return here and click "Continue".';

  return (
    <section className="section" id="section-verification-pending">
      <h2>Email Verification Required</h2>
      <div className="section-nodes">
        <div className="section-node">
          <FormNodeMarkdown id="1" markdown={message} type="markdown" />
        </div>
        <div className="section-node">
          <Button color="primary" id="retry-login" onClick={doRetry} outline>
            Continue
          </Button>
        </div>
      </div>
    </section>
  );
};
