import {
  JSONForm,
  PreSubmitData,
} from '@conventioncatcorp/common-fe/dist/components/json-form/JSONForm';
import React, { FC, useCallback, useState } from 'react';
import {
  Button,
  Col,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from 'reactstrap';
import { Order } from '../../../../../shared/orders/model';
import { RefundPaymentTable, refundUpdatePreSubmit } from '../../../../components';
import { RefundGrantList, RefundGrantTable } from '../../../../components/RefundGrantTable';
import { useTranslation } from '../../../../translations';

interface OrderRefundModalProps {
  readonly order: Order;
  onSuccess(): void;
  onCancel(): void;
}

interface RefundPostData {
  [key: string]: unknown;
  paymentData: Record<string, number>;
  reason: string;
  removeGrants: RefundGrantList[];
}

export const OrderRefundModal: FC<OrderRefundModalProps> = ({ order, onCancel, onSuccess }) => {
  const { ts } = useTranslation();
  const [refundGrantList, setRefundGrantList] = useState<RefundGrantList[]>([]);

  const preSubmit = useCallback(
    (form: PreSubmitData<RefundPostData>) => {
      refundUpdatePreSubmit(form);
      form.inputs!.removeGrants = refundGrantList;
    },
    [refundGrantList],
  );

  return (
    <Modal className="modal-large" id="refundModal" isOpen>
      <JSONForm<{}, RefundPostData>
        method="post"
        onSuccess={onSuccess}
        path={`/api/orders/${order.id}/refund`}
        preSubmit={preSubmit}
      >
        <ModalHeader>Manual Refund #{order.id}</ModalHeader>
        <ModalBody>
          <p>{ts('use_the_table_below_to')}</p>
          <p>{ts('your_reason_for_approving_this')}</p>
          <RefundPaymentTable payments={order.payments || []} />
          <RefundGrantTable list={refundGrantList} order={order} setList={setRefundGrantList} />
          <FormGroup row>
            <Label for="reason" lg={5} sm={12}>
              {ts('reason_for_approval')}
              <span className="text-danger">*</span>
            </Label>
            <Col lg={7} sm={12}>
              <Input id="reason" name="reason" required type="textarea" />
            </Col>
          </FormGroup>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={onCancel} type="button">
            {ts('cancel')}
          </Button>{' '}
          <Button color="primary" id="issueRefund" type="submit">
            {ts('issue_refund')}
          </Button>
        </ModalFooter>
      </JSONForm>
    </Modal>
  );
};
