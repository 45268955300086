import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import { Card, CardBody, CardTitle, Col, Row } from 'reactstrap';
import { MaterialIcon, PageHeader, UserStateComponent } from '../../components';
import { useTranslation } from '../../translations';
import { Fetcher, isResourceError, useFetcher } from '../../utils';

export const ChangeEmailComponent: FC<{
  readonly match: {
    params: {
      code?: string;
    };
  };
}> = ({ match }) => {
  const { ts } = useTranslation();
  return (
    <UserStateComponent>
      <PageHeader>{ts('account_change_email')}</PageHeader>
      <Row className="justify-content-center">
        <Col lg={6} xs={12}>
          <Card>
            <CardBody className="text-center">
              <ConfirmInner code={match.params.code} />
              <hr />
              <Link className="btn btn-link btn-block" to="/">
                {ts('go_back_to_homepage')}
              </Link>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </UserStateComponent>
  );
};

const ConfirmInner: FC<{ readonly code?: string }> = ({ code }) => {
  const fetcher = useFetcher(async () => {
    return await api.confirmChangeEmail(code ?? '');
  }, [code]);

  const { ts } = useTranslation();
  if (fetcher.error && isResourceError(fetcher.error, 'User')) {
    return (
      <div id="confirmError">
        <MaterialIcon className="margin-bottom-10" large name="warning" type="warning" />
        <CardTitle>{ts('error_during_activation')}</CardTitle>
        <p>{ts('sorry_but_the_code_you')}</p>
        <p>{ts('try_copying_the_activation_link')}</p>
      </div>
    );
  }

  if (fetcher.error) {
    return <Fetcher result={fetcher} />;
  }

  if (!fetcher.complete) {
    return (
      <div id="confirmLoading">
        <MaterialIcon className="margin-bottom-10" large name="security" type="muted" />
        <CardTitle>{ts('just_a_moment')}</CardTitle>
        <p>{ts('we_are_checking_the_email')}</p>
      </div>
    );
  }

  return (
    <div id="confirmUpdated">
      <MaterialIcon className="margin-bottom-10" large name="verified_user" type="success" />
      <CardTitle>{ts('email_updated')}</CardTitle>
      <p>{ts('your_email_address_has_been')}</p>
    </div>
  );
};
