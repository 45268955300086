import { Method } from '@conventioncatcorp/common-fe/dist/APIClient';
import React, { FC, useState } from 'react';
import { Button, ButtonProps } from 'reactstrap';
import { useTranslation } from '../translations';
import { omit } from '../utils';
import { captureError } from '../utils/errorHandling';
import { MaterialIcon } from './MaterialIcon';

interface ActionButtonProps<T = unknown> {
  readonly action: string;
  readonly method: Method;
  readonly payload?: object;
  onSuccess(data: T): void;
  onFailure?(err: Error): void;
}

export const ActionButton: FC<ActionButtonProps & ButtonProps> = (props) => {
  const { t } = useTranslation();
  const { action, method, payload, onSuccess, onFailure } = props;
  const [requestPending, setRequestPending] = useState(false);

  const submitForm = async () => {
    if (requestPending) {
      return;
    }

    setRequestPending(true);

    try {
      const result = await api.request({
        method,
        payload,
        url: action,
      });

      setRequestPending(false);

      onSuccess(result);
    } catch (error) {
      setRequestPending(false);
      if (onFailure) {
        onFailure(error as Error);
      } else {
        captureError(error as Error);
      }
    }
  };

  return (
    <Button
      {...omit(props, 'action', 'method', 'payload', 'onSuccess', 'onFailure')}
      disabled={props.disabled ?? requestPending}
      onClick={submitForm}
    >
      {!requestPending && (props.children || 'Submit')}
      {requestPending && (
        <>
          <MaterialIcon name="sync" /> {t('action_button.updating')}
        </>
      )}
    </Button>
  );
};
