import React, { FC } from 'react';
import { Switch } from 'react-router-dom';
import { Header, Sidenav, SidenavItem, UserRoute } from '../../components';
import { Dealers } from '../../containers/housekeeping/dealers';
import { CategoryView, ProductViewer } from '../../containers/housekeeping/products';
import { useTranslation } from '../../translations';
import { DealerDashboard } from './dashboard';
import { DealerAreas } from './dealerAreas';
import { DealerMassEmail } from './dealerMassEmail';
import { DealerProducts } from './dealerProducts';
import { DealerSettingsPage } from './dealerSettings';

export const DealerContainerComponent: FC = () => {
  const { ts } = useTranslation();
  return (
    <>
      <Sidenav>
        <SidenavItem
          icon="keyboard_backspace"
          id="navHomepage"
          link="/"
          text={ts('back_to_homepage')}
        />
        <SidenavItem
          icon="supervised_user_circle"
          id="navHousekeeping"
          link="/housekeeping"
          requiredPermissions="system:login"
          text={ts('back_to_housekeeping')}
        />

        <SidenavItem header requireLoggedIn text="Dealers" />
        <SidenavItem
          icon="dashboard"
          id="navDealerDashboard"
          link="/housekeeping/dealers/dashboard"
          requiredPermissions="vendor:read"
          text={ts('dashboard')}
        />
        <SidenavItem
          icon="people"
          id="navDealers"
          link="/housekeeping/dealers/list"
          requiredPermissions="vendor:read"
          text={ts('applications')}
        />
        <SidenavItem
          icon="people"
          id="navDealerAreas"
          link="/housekeeping/dealers/areas"
          requiredPermissions="vendor:update"
          text="Areas"
        />
        <SidenavItem
          icon="list"
          id="navDealerProducts"
          link="/housekeeping/dealers/products"
          requiredPermissions="vendor:read"
          text={ts('products')}
        />
        <SidenavItem
          icon="email"
          id="navMassEmail"
          link="/housekeeping/dealers/massemail"
          requiredPermissions="massemail"
          text={ts('mass_email')}
        />

        <SidenavItem header requireLoggedIn requiredPermissions="vendor:update" text="Settings" />
        <SidenavItem
          icon="settings"
          id="navSettings"
          link="/housekeeping/dealers/settings"
          requiredPermissions="vendor:update"
          text={ts('dealer_settings')}
        />
      </Sidenav>
      <div className="page-wrap">
        <Header />
        <Switch>
          <UserRoute
            component={DealerDashboard}
            path="/housekeeping/dealers/dashboard"
            requirePermissions={['vendor:read']}
          />
          <UserRoute
            component={Dealers}
            path="/housekeeping/dealers/list"
            requirePermissions={['vendor:read']}
          />
          <UserRoute
            component={DealerAreas}
            path="/housekeeping/dealers/areas"
            requirePermissions={['vendor:update']}
          />
          <UserRoute
            component={DealerProducts}
            exact
            path="/housekeeping/dealers/products"
            requirePermissions={['vendor:read']}
          />
          <UserRoute
            component={CategoryView}
            exact
            path="/housekeeping/dealers/products/category/:id"
            requirePermissions={['vendor:read']}
          />
          <UserRoute
            component={ProductViewer}
            exact
            path="/housekeeping/dealers/products/:id"
            requirePermissions={['vendor:read']}
          />
          <UserRoute
            component={DealerSettingsPage}
            path="/housekeeping/dealers/settings"
            requirePermissions={['vendor:update']}
          />
          <UserRoute component={DealerMassEmail} exact path="/housekeeping/dealers/massemail" />
        </Switch>
      </div>
    </>
  );
};
