import {
  classNames,
  JSONForm,
  MaterialIconText,
  PreSubmitData,
} from '@conventioncatcorp/common-fe';
import React, { FC, useCallback, useEffect, useState } from 'react';
import { Redirect, RouteComponentProps, useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
  Alert,
  Badge,
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Col,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Nav,
  NavItem,
  Row,
  TabContent,
  TabPane,
} from 'reactstrap';
import { Raffle, RaffleUpsert, ValidCriterions } from '../../../../../shared/orders/raffle';
import { ActionButton, InlineHelp, PageHeader, UserStateComponent } from '../../../../components';
import { useTranslation } from '../../../../translations';
import { LoadingWrapper } from '../../../../utils/LoadingWrapper';
import { RaffleTextEdit } from './RaffleTextEdit';
import { ProductSelector } from './productSelector';
import { RaffleEntrants, raffleEntrantsRef } from './raffleEntrants';
import { RoleSelector } from './roleSelector';
import { RunRaffleComponent } from './runRaffle';
import { WeightEditor } from './weightEditor';

import './style.scss';

export const RaffleEditorWrapper: FC<RouteComponentProps<{ id?: string }>> = (props) => {
  if (!props.match.params.id) {
    return <RaffleEditor />;
  }

  const id = Number.parseInt(props.match.params.id, 10);
  return (
    <LoadingWrapper<Raffle, void>
      dataFetcher={async () => await api.getFullRaffleById(id)}
      errorDisplay={() => <Redirect to="/housekeeping/gatekeeping" />}
    >
      {(data, refresh) => <RaffleEditor raffle={data} refresh={refresh} />}
    </LoadingWrapper>
  );
};

interface Props {
  readonly raffle?: Raffle;
  refresh?(): void;
}

const RaffleEditor: FC<Props> = ({ raffle, refresh }) => {
  const history = useHistory();
  const [raffleTree, setRaffleTree] = useState<ValidCriterions>();
  const [roleIds, setRoleIds] = useState<number[]>(raffle?.rolesOverride ?? []);
  const [productIds, setProductIds] = useState<number[]>(
    raffle?.products.map(({ id }) => id) ?? [],
  );

  const [raffleStatus, setRaffleStatus] = useState<'closed' | 'drawn' | 'open'>(
    raffle?.status ?? 'open',
  );

  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
  const [activeTab, setActiveTab] = useState('2');
  const [deleteModal, setDeleteModal] = useState(false);

  const refreshRaffle = useCallback(() => {
    refresh!();
    if (raffleEntrantsRef.current) {
      raffleEntrantsRef.current();
    }
  }, [raffleEntrantsRef]);

  const preSubmit = useCallback(
    (opts: PreSubmitData<RaffleUpsert>) => {
      opts.inputs!.criterion = raffleTree!;
      opts.inputs!.productIds = productIds;
      opts.inputs!.rolesOverride = roleIds;
    },
    [raffleTree, productIds, roleIds],
  );

  const onSuccess = useCallback(
    (data?: { id?: number }) => {
      toast.success(`Silo successfully ${isEdit ? 'updated' : 'created'}!`);
      setHasUnsavedChanges(false);
      if (data?.id) {
        history.push(`/housekeeping/gatekeeping/${data.id}`);
      } else {
        refreshRaffle();
      }
    },
    [raffle],
  );

  const onTreeUpdate = useCallback((newRaffleTree: ValidCriterions) => {
    setRaffleTree(newRaffleTree);
    setHasUnsavedChanges(true);
  }, []);

  const isEdit = !!raffle;

  useEffect(() => {
    if (!raffle) {
      return;
    }

    setRaffleTree(raffle.criterion);
  }, [raffle]);

  const { ts } = useTranslation();
  return (
    <UserStateComponent>
      {deleteModal && (
        <DeleteRaffleModal close={() => setDeleteModal(false)} raffleId={raffle!.id} />
      )}
      <PageHeader>{isEdit ? `Editing Silo: ${raffle.name}` : 'Creating Silo'}</PageHeader>

      <Row>
        <Col lg={4} xs={12}>
          <JSONForm<{ id?: number }, {}>
            method={isEdit ? 'patch' : 'post'}
            onSuccess={onSuccess}
            path={isEdit ? `/api/raffles/${raffle.id}` : '/api/raffles'}
            preSubmit={preSubmit}
          >
            <Card className="mb-3">
              <CardHeader>Silo Information {raffleStatusBadge(raffleStatus)}</CardHeader>
              <CardBody>
                <Row>
                  <Col lg={10} xs={12}>
                    <FormGroup>
                      <Label for="name">{ts('name')}</Label>
                      <Input defaultValue={raffle?.name} id="name" name="name" required />
                    </FormGroup>
                  </Col>
                  <Col lg={10} xs={12}>
                    <div className="custom-control custom-checkbox margin-top-10">
                      <Input
                        className="custom-control-input"
                        defaultChecked={raffleStatus === 'open'}
                        id="openRaffle"
                        onChange={(e) => {
                          setRaffleStatus(e.currentTarget.checked ? 'open' : 'closed');
                        }}
                        type="checkbox"
                      />
                      <Label className="custom-control-label" for="openRaffle">
                        Allow new entries to the silo?
                      </Label>
                      <input name="status" type="hidden" value={raffleStatus} />
                    </div>
                  </Col>
                  <Col lg={10} xs={12}>
                    <div className="custom-control custom-checkbox margin-top-10">
                      <Input
                        className="custom-control-input"
                        defaultChecked={raffle?.overflowEntries ?? false}
                        id="overflowEntries"
                        name="overflowEntries"
                        type="checkbox"
                      />
                      <Label className="custom-control-label" for="overflowEntries">
                        Overflow stock silo
                        <InlineHelp>
                          When the product is out of stock, add users to the silo for later
                          selection.
                        </InlineHelp>
                      </Label>
                    </div>
                  </Col>
                </Row>
              </CardBody>
              <CardFooter>
                <Row>
                  <Col lg={4} xs={12}>
                    <Button block color="primary" id="submitForm">
                      {isEdit ? 'Update' : 'Create'} Silo
                    </Button>
                  </Col>
                  {isEdit && (
                    <Col lg={4} xs={12}>
                      <Button
                        block
                        color="danger"
                        id="openDeleteRaffle"
                        onClick={() => setDeleteModal(true)}
                      >
                        Delete Silo
                      </Button>
                    </Col>
                  )}
                </Row>
              </CardFooter>
            </Card>
            <Card className="mb-3">
              <ProductSelector defaultProducts={raffle?.products} onChange={setProductIds} />
            </Card>
            <Card className="mb-3">
              <RoleSelector selectedIds={roleIds} setSelectedIds={setRoleIds} />
            </Card>
          </JSONForm>
        </Col>
        <Col lg={7} xs={12}>
          {hasUnsavedChanges && (
            <Alert className="mb-3 raffle-weight-unsaved" color="warning">
              <MaterialIconText name="save">
                You currently have unsaved silo weight changes.
              </MaterialIconText>
            </Alert>
          )}
          {isEdit && (
            <Nav tabs>
              <NavItem id="raffleTabEntrants" onClick={() => setActiveTab('2')}>
                <div className={classNames({ active: activeTab === '2' }, 'nav-link')}>
                  Entrants
                </div>
              </NavItem>
              <NavItem id="raffleTabText" onClick={() => setActiveTab('3')}>
                <div className={classNames({ active: activeTab === '3' }, 'nav-link')}>Wording</div>
              </NavItem>
              <NavItem id="raffleTabRun" onClick={() => setActiveTab('4')}>
                <div className={classNames({ active: activeTab === '4' }, 'nav-link')}>Run</div>
              </NavItem>
              <NavItem id="raffleTabWeight" onClick={() => setActiveTab('1')}>
                <div className={classNames({ active: activeTab === '1' }, 'nav-link')}>
                  Weight Configuration
                </div>
              </NavItem>
            </Nav>
          )}
          <TabContent activeTab={activeTab}>
            <TabPane tabId="1">
              <WeightEditor onUpdate={onTreeUpdate} raffleTree={raffleTree} />
            </TabPane>
            {isEdit && (
              <TabPane tabId="2">
                <RaffleEntrants raffle={raffle} />
              </TabPane>
            )}
            {isEdit && (
              <TabPane tabId="3">
                <RaffleTextEdit raffle={raffle} />
              </TabPane>
            )}
            {isEdit && (
              <TabPane tabId="4">
                <RunRaffleComponent raffle={raffle} />
              </TabPane>
            )}
          </TabContent>
        </Col>
      </Row>
    </UserStateComponent>
  );
};

function raffleStatusBadge(status: 'closed' | 'drawn' | 'open'): JSX.Element {
  let statusText = 'Open to Entries';
  let statusColor = 'success';

  if (status === 'closed') {
    statusText = 'Closed to Entries';
    statusColor = 'secondary';
  } else if (status === 'drawn') {
    statusText = 'Recently Drawn';
    statusColor = 'info';
  }

  return <Badge color={statusColor}>{statusText}</Badge>;
}

const DeleteRaffleModal: FC<{ readonly raffleId: number; close(): void }> = ({
  raffleId,
  close,
}) => {
  const { ts } = useTranslation();
  const history = useHistory();
  return (
    <Modal isOpen>
      <ModalHeader>Are you sure?</ModalHeader>
      <ModalBody>
        <p>{ts('are_you_sure_you_want')}</p>
        <p>All ticket data will be lost.</p>
      </ModalBody>
      <ModalFooter>
        <ActionButton
          action={`/api/raffles/${raffleId}`}
          color="danger"
          id="deleteRaffle"
          method="delete"
          onSuccess={() => {
            close();
            toast.success('Successfully deleted silo.');
            history.push('/housekeeping/gatekeeping');
          }}
        >
          Delete Silo
        </ActionButton>
        <Button color="secondary" onClick={() => close()}>
          {ts('cancel')}
        </Button>
      </ModalFooter>
    </Modal>
  );
};
