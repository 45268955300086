import React, { Dispatch, FC, SetStateAction } from 'react';
import { Col, FormGroup, Input, Label, Row } from 'reactstrap';
import { OptionInternal, SourceConfig } from '../../shared/options';
import { validOptionTypes } from '../models';
import { useTranslation } from '../translations';
import { mapObjectToArray, useInputCheckboxModel, useInputModel } from '../utils';
import { CheckboxLabel } from './CheckboxLabel';
import { OptionEditorMinMax, OptionEditorSelect } from './optionEditorComponents';
import { InlineHelp } from '.';

interface OptionEditorProps {
  readonly option: OptionInternal;
  readonly setOption: Dispatch<SetStateAction<OptionInternal>>;
  setIsEditing(state: boolean): void;
}

export const OptionEditor: FC<OptionEditorProps> = ({
  option,
  setOption,
  setIsEditing,
}: OptionEditorProps) => {
  const { ts } = useTranslation();
  const setType = useInputModel(setOption, 'type');
  const setName = useInputModel(setOption, 'name');
  const setDisplayOnCashier = useInputCheckboxModel(setOption, 'displayOnCashier');
  const config = SourceConfig[option.source];

  return (
    <Row>
      <Col lg={6} xs={12}>
        <FormGroup>
          <Label for="name">{ts('name')}</Label>
          <Input
            id="name"
            onChange={setName}
            placeholder="Option {ts('name')}"
            required
            value={option.name}
          />
        </FormGroup>
      </Col>
      <Col lg={6} xs={12}>
        <FormGroup>
          <Label for="type">
            {ts('type')}
            <InlineHelp>
              <Label>Option Type Information</Label>
              <ul className="flushLeft">
                {mapObjectToArray(validOptionTypes, ({ name, description, currency }, key) => {
                  if (!config.allowPrice && currency) {
                    return null;
                  }

                  return (
                    <li key={key}>
                      <strong>{name}</strong>: {description}
                    </li>
                  );
                })}
              </ul>
            </InlineHelp>
          </Label>
          <Input
            // Disables the option to change the type of the option if editing
            disabled={option.id !== 0}
            id="type"
            onChange={setType}
            type="select"
            value={option.type}
          >
            {mapObjectToArray(validOptionTypes, ({ name, currency, hidden }, key) => {
              if (!config.allowPrice && currency) {
                return null;
              }

              if (hidden) {
                return null;
              }

              return (
                <option key={key} value={key}>
                  {name}
                </option>
              );
            })}
          </Input>
        </FormGroup>
      </Col>
      {option.source === 'product' && (
        <Col lg={6} xs={12}>
          <FormGroup>
            <CheckboxLabel
              checked={option.displayOnCashier}
              id="displayOnCashier"
              onChange={setDisplayOnCashier}
            >
              Display on cashier interface
            </CheckboxLabel>
          </FormGroup>
        </Col>
      )}
      <Col xs={12} />
      {option.type === 'select' || option.type === 'multi' ? (
        <OptionEditorSelect
          isMulti={option.type === 'multi'}
          option={option}
          setIsEditing={setIsEditing}
          setOption={setOption}
        />
      ) : (
        <OptionEditorMinMax
          label={option.type === 'text' ? 'Length' : 'Value'}
          option={option}
          setOption={setOption}
        />
      )}
      <Col xs={12}>
        <hr />
      </Col>
    </Row>
  );
};
