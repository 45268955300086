import React, { FC } from 'react';
import { Label } from 'reactstrap';
import { useTranslation } from '../translations';
import { InlineHelp } from '.';

export const PlatformFeeHelp: FC = () => {
  const { ts } = useTranslation();
  return (
    <InlineHelp>
      <Label>{ts('platform_fee_information')}</Label>
      <p>{ts('by_default_our_platform_fees')}</p>
      <Label>{ts('example')}</Label>
      <ul>
        <li>{ts('if_you_absorb_the_platform')}</li>
        <li>{ts('if_you_choose_to_pass')}</li>
      </ul>
    </InlineHelp>
  );
};
