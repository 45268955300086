import { JSONForm } from '@conventioncatcorp/common-fe/dist/components/json-form/JSONForm';
import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, FormGroup, Input, Label, Modal, ModalBody } from 'reactstrap';
import { v4 } from 'uuid';
import { NewPasswordInput, UniqueAttributeInput } from '../..';
import { FormNodeAccountModel } from '../../../../shared/kiosk';
import { CurrentUser } from '../../../../shared/user/base';
import { passwordScore } from '../../../../shared/user/password';
import { SecondFactorPrompt } from '../../../containers/account/secondFactor/SecondFactorPrompt';
import { ActionType, GlobalState } from '../../../services';
import { useTranslation } from '../../../translations';
import { isAuthError, omit, useBoolState, useOrganization } from '../../../utils';
import { AuthErrorCodes, captureError } from '../../../utils/errorHandling';
import { Loading } from '../../Loading';

interface RegisterData {
  [key: string]: string;
  username: string;
  password: string;
  confirmPassword: string;
}

export interface FormNodeAccountProps extends FormNodeAccountModel {
  setFormIgnore(active: boolean): void;
}

export const FormNodeAccount: FC<FormNodeAccountProps> = ({ setFormIgnore }) => {
  const { ts } = useTranslation();
  const [loadingDone, setLoadingDone] = useState(false);
  const [user, setUser] = useState<CurrentUser | null>(null);
  const [regData, setRegData] = useState<Partial<RegisterData> | null>(null);
  const [showLogin, enableLoginMode, disableLoginMode] = useBoolState(false);
  const [showRegister, enableRegisterMode, disableRegisterMode] = useBoolState(false);
  const globalUser = useSelector((state: GlobalState) => state.user);
  const organization = useOrganization();

  const dispatch = useDispatch();

  const checkLogin = useCallback(async () => {
    if (globalUser) {
      setUser(globalUser);
    } else {
      try {
        const u = await api.getActiveUser();
        dispatch({ type: ActionType.LoginSuccess, user: u });
        disableLoginMode();
        setUser(u);
      } catch {
        /* noop */
      }
    }

    setLoadingDone(true);
  }, []);

  const createAccount = useCallback((data: RegisterData) => {
    setRegData(omit(data, 'confirmPassword'));
    disableRegisterMode();
  }, []);

  useEffect(() => {
    checkLogin().catch(captureError);
  }, []);

  useEffect(() => {
    setFormIgnore(showLogin);
  }, [showLogin]);

  if (!loadingDone) {
    return (
      <div className="account-container">
        <Loading inline />
      </div>
    );
  }

  if (regData) {
    // Temp user created via the register function, will create on completion
    return (
      <div className="account-container">
        <div className="login-container">
          <h4>You've chosen to create an account!</h4>
          <p>
            Your username will be <strong>{regData.username}</strong>.
          </p>
          {Object.keys(regData).map((key) => (
            <input key={key} name={key} type="hidden" value={regData[key]} />
          ))}
        </div>
        <div className="register-container">
          <Button
            onClick={() => {
              setRegData(null);
            }}
            outline
          >
            {ts('cancel')}
          </Button>
          <p>
            Changed your mind? Click <strong>{ts('cancel')}</strong> to cancel creating an account.
          </p>
        </div>
      </div>
    );
  }

  if (user) {
    const doLogout = async () => {
      try {
        await api.logout();
      } catch {
        /* noop */
      }

      setUser(null);
    };

    return (
      <div className="account-container">
        <div className="login-container">
          <h4>
            {ts('welcome_back')}, {user.preferredName ?? user.firstName ?? user.username}
          </h4>
        </div>
        <div className="register-container">
          <Button onClick={doLogout} outline>
            Change Account
          </Button>
          <p>
            Not the right account? Click <strong>Change Account</strong> to log out.
          </p>
        </div>
      </div>
    );
  }

  return (
    <>
      {showLogin && <FormNodeLogin onCancel={disableLoginMode} onSuccess={checkLogin} />}
      {showRegister && (
        <FormNodeRegister onCancel={disableRegisterMode} onSuccess={createAccount} />
      )}
      <div className="account-container">
        <div className="login-container">
          <Button color="primary" onClick={enableLoginMode}>
            {ts('login')}
          </Button>
          <p>If you have an account on {organization.name}, you can use that to sign in.</p>
        </div>
        <div className="register-container">
          <Button onClick={enableRegisterMode} outline>
            {ts('register')}
          </Button>
          <p>
            Or, if you don't have an account but would like to save your data for next time, you can
            register!
          </p>
        </div>
      </div>
      <input name="username" type="hidden" value={v4()} />
      <input name="password" type="hidden" value={v4()} />
    </>
  );
};

interface ModalProps {
  onCancel(): void;
  onSuccess(): void;
}

const FormNodeLogin: FC<ModalProps> = ({ onCancel, onSuccess }) => {
  const { ts } = useTranslation();
  const [showSecondFactor, setShowSecondFactor] = useState(false);
  const submitRef = useRef<HTMLButtonElement>(null);

  const onFailure = useCallback((err: Error) => {
    if (!isAuthError(err, AuthErrorCodes.SecondFactorMissing)) {
      return false;
    }

    setShowSecondFactor(true);
    return true;
  }, []);

  const closeSecondFactor = useCallback(() => {
    setShowSecondFactor(false);
  }, []);

  const submitSecondFactor = useCallback(() => {
    submitRef.current!.click();
  }, []);

  return (
    <Modal className="login-modal" isOpen>
      <ModalBody>
        <div className="text-center">
          <h4>{ts('login')}</h4>
        </div>
        <hr />
        <JSONForm method="post" onFailure={onFailure} onSuccess={onSuccess} path="/api/login">
          {showSecondFactor && (
            <SecondFactorPrompt onCancel={closeSecondFactor} onSubmit={submitSecondFactor} />
          )}
          <FormGroup>
            <Label>Email / {ts('username')}</Label>
            <Input name="usernameOrMail" type="text" />
          </FormGroup>
          <FormGroup>
            <Label>{ts('password')}</Label>
            <Input name="password" type="password" />
          </FormGroup>
          <FormGroup>
            <Button block color="primary" innerRef={submitRef}>
              {ts('login')}
            </Button>
          </FormGroup>
          <FormGroup>
            <Button block color="secondary" onClick={onCancel} outline type="button">
              {ts('cancel')}
            </Button>
          </FormGroup>
        </JSONForm>
      </ModalBody>
    </Modal>
  );
};

interface RegisterModalProps {
  onCancel(): void;
  onSuccess(data: RegisterData): void;
}

const FormNodeRegister: FC<RegisterModalProps> = ({ onCancel, onSuccess }) => {
  const { ts } = useTranslation();
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const isFormOK =
    username.length > 0 &&
    password.length > 6 &&
    confirmPassword.length > 6 &&
    password === confirmPassword;

  const onSubmitClicked = useCallback(() => {
    onSuccess({ username, password, confirmPassword });
  }, [username, password, confirmPassword]);

  return (
    <Modal className="register-modal" isOpen>
      <ModalBody>
        <div className="text-center">
          <h4>{ts('register')}</h4>
        </div>
        <hr />
        <p>Your account will not be created until you have finished the registration flow.</p>
        <hr />
        <div className="form-content">
          <FormGroup>
            <Label>{ts('username')}</Label>
            <UniqueAttributeInput onChange={setUsername} type="username" />
          </FormGroup>
          <FormGroup>
            <Label>{ts('password')}</Label>
            <NewPasswordInput minLength={6} minScore={passwordScore} onChange={setPassword} />
          </FormGroup>
          <FormGroup>
            <Label>Confirm {ts('password')}</Label>
            <Input
              autoComplete="new-password"
              id="confirmPassword"
              onChange={(e) => {
                setConfirmPassword(e.currentTarget.value);
              }}
              type="password"
            />
          </FormGroup>
          <FormGroup>
            <Button
              block
              color="primary"
              disabled={!isFormOK}
              onClick={onSubmitClicked}
              type="button"
            >
              {ts('register')}
            </Button>
          </FormGroup>
          <FormGroup>
            <Button block color="secondary" onClick={onCancel} outline type="button">
              {ts('cancel')}
            </Button>
          </FormGroup>
        </div>
      </ModalBody>
    </Modal>
  );
};
