import React, { FC, useState } from 'react';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { useTranslation } from '../../../translations';

interface Reset2FAProps {
  readonly userId: number;
  refresh(): void;
}

export const ResetSecondFactorButton: FC<Reset2FAProps> = ({ userId, refresh }) => {
  const { ts } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  async function removeSecondFactor(): Promise<void> {
    setIsLoading(true);
    const factors = await api.getSecondFactors(userId);

    for (const factor of factors) {
      // eslint-disable-next-line no-await-in-loop
      await api.deleteSecondFactor(factor.id);
    }

    setIsLoading(false);
    setIsOpen(false);
    refresh();
  }

  return (
    <>
      <Modal className="modal-large" id="removeSecondFactorModal" isOpen={isOpen}>
        <ModalHeader>Remove Second Factor</ModalHeader>
        <ModalBody>
          Are you sure you want to remove second factor authentication from the user?
        </ModalBody>
        <ModalFooter>
          <Button
            color="primary"
            disabled={isLoading}
            id="removeSecondFactor"
            onClick={removeSecondFactor}
          >
            Remove
          </Button>
          <Button
            color="secondary"
            onClick={() => {
              setIsOpen(false);
            }}
          >
            {ts('cancel')}
          </Button>
        </ModalFooter>
      </Modal>
      <Button
        block
        id="promptRemoveSecondFactor"
        onClick={() => {
          setIsOpen(true);
        }}
        type="submit"
      >
        Reset 2FA
      </Button>
    </>
  );
};
