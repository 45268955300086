import React, { createRef, FC, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
  Badge,
  Button,
  Card,
  CardBody,
  Col,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from 'reactstrap';
import {
  Raffle,
  RaffleTicket,
  raffleTicketStatus,
  RaffleTicketStatus,
} from '../../../../../shared/orders/raffle';
import { DataViewItem, DateT, DropdownItemProps, PaginatedDataView } from '../../../../components';
import { useTranslation } from '../../../../translations';
import { Fn, renderName, useInputState, useNumericInputState } from '../../../../utils';

interface Props {
  readonly raffle: Raffle;
}

export const raffleEntrantsRef = createRef<(() => void) | null>();

export const RaffleEntrants: FC<Props> = ({ raffle }) => {
  const [pointsTicket, setPointsTicket] = useState<RaffleTicket | undefined>();
  const [search, setSearch] = useInputState('');
  const [ticketStatus, setTicketStatus] = useInputState('');

  function openSetPointsModal(ticket: RaffleTicket): void {
    setPointsTicket(ticket);
  }

  useEffect(() => {
    raffleEntrantsRef.current?.();
  }, [search, ticketStatus]);

  return (
    <Card id="raffleEntrants">
      {pointsTicket && raffleEntrantsRef.current && (
        <SetPointsModal
          close={() => setPointsTicket(undefined)}
          refresh={raffleEntrantsRef.current}
          ticket={pointsTicket}
        />
      )}
      <CardBody>
        <Row>
          <Col md={8}>
            <Input onChange={setSearch} placeholder="Search..." type="text" value={search} />
          </Col>
          <Col md={4}>
            <Input id="ticketStatus" onChange={setTicketStatus} type="select" value={ticketStatus}>
              <option value="">All status</option>
              {raffleTicketStatus.map((c) => (
                <option key={c} value={c}>
                  {c}
                </option>
              ))}
            </Input>
          </Col>
        </Row>

        <PaginatedDataView<RaffleTicket>
          dataFetcher={async (page) =>
            await api.getRaffleTicketsById(raffle.id, page, search, ticketStatus)
          }
          pageSize={15}
          refreshRef={raffleEntrantsRef}
          renderer={(ticket, refresh) => (
            <DataViewItem
              content={<RaffleTicketRow ticket={ticket} />}
              dropdownItems={getDropdownOptions(ticket, refresh, openSetPointsModal)}
              id={`ticket${ticket.id}`}
              subtitle={`ID: ${ticket.userId}`}
              title={
                <Link to={`/housekeeping/attendees/user/${ticket.user.id}`}>
                  {' '}
                  {renderName(ticket.user)}
                </Link>
              }
            />
          )}
        />
      </CardBody>
    </Card>
  );
};

function getDropdownOptions(
  ticket: RaffleTicket,
  refresh: Fn,
  openSetPointsModal: (ticket: RaffleTicket) => void,
): DropdownItemProps[] {
  const out: DropdownItemProps[] = [
    {
      className: 'ticket-set-points',
      onClick: () => openSetPointsModal(ticket),
      text: 'Set ticket points',
    },
  ];

  const ticketReset = {
    className: 'ticket-reset',
    onClick: async () => {
      await api.setRaffleTicket(ticket.raffleId, ticket.userId, 'waiting', ticket.points);
      toast.success(`Reset Ticket #${ticket.id} to Entered / Waiting.`);
      refresh();
    },
    text: 'Reset Ticket',
  };

  switch (ticket.status) {
    case 'waiting': {
      out.push(
        {
          className: 'ticket-rescind',
          isDangerous: true,
          onClick: async () => {
            await api.setRaffleTicket(ticket.raffleId, ticket.userId, 'rescinded', ticket.points);
            toast.success(`Rescinded Ticket #${ticket.id} for ${renderName(ticket.user)}.`);
            refresh();
          },
          text: 'Revoke / Void Ticket',
        },
        {
          className: 'ticket-set-winner',
          isDangerous: true,
          onClick: async () => {
            await api.setRaffleTicket(ticket.raffleId, ticket.userId, 'winner', ticket.points);
            toast.success(`Marked Ticket #${ticket.id} as a winner.`);
            refresh();
          },
          text: 'Set as Winner',
        },
      );

      break;
    }

    case 'winner': {
      out.push(ticketReset, {
        className: 'ticket-rescind',
        isDangerous: true,
        onClick: async () => {
          await api.setRaffleTicket(ticket.raffleId, ticket.userId, 'rescinded', ticket.points);
          toast.success(`Rescinded Ticket #${ticket.id} for ${renderName(ticket.user)}.`);
          refresh();
        },
        text: 'Revoke / Void Ticket',
      });

      break;
    }

    case 'redeemed': {
      out.push(ticketReset, {
        className: 'ticket-reset-win',
        isDangerous: true,
        onClick: async () => {
          await api.setRaffleTicket(ticket.raffleId, ticket.userId, 'winner', ticket.points);
          toast.success(`Reset Ticket #${ticket.id} to Winner.`);
          refresh();
        },
        text: 'Reset Ticket to Winner',
      });

      break;
    }

    case 'rescinded': {
      out.push(ticketReset);
      break;
    }

    case 'deleted': {
      out.push(ticketReset);
      break;
    }
  }

  return out;
}

const RaffleTicketRow: FC<{ readonly ticket: RaffleTicket }> = ({ ticket }) => {
  const { ts } = useTranslation();
  return (
    <div className={`badge-list compact ticket-${ticket.status}`}>
      <RaffleTicketBadge status={ticket.status} />
      {ticket.points > 0 && <Badge color="info">Points: {ticket.points}</Badge>}
      <Badge>
        {ts('created_at')}
        <DateT value={ticket.createdAt} />{' '}
      </Badge>
    </div>
  );
};

const RaffleTicketBadge: FC<{ readonly status: RaffleTicketStatus }> = ({ status }) => {
  switch (status) {
    case 'waiting': {
      return <Badge color="info">Entered / Waiting</Badge>;
    }

    case 'winner': {
      return <Badge color="success">Winner</Badge>;
    }

    case 'redeemed': {
      return <Badge color="warning">Redeemed</Badge>;
    }

    case 'rescinded': {
      return <Badge color="danger">Rescinded</Badge>;
    }

    case 'deleted': {
      return <Badge color="danger">Deleted</Badge>;
    }

    default: {
      // 9888 is Warning icon
      return <Badge color="info">&#9888; Unkown</Badge>;
    }
  }
};

interface SetPointsModalProps {
  readonly ticket: RaffleTicket;
  readonly refresh: Fn;
  readonly close: Fn;
}

const SetPointsModal: FC<SetPointsModalProps> = ({ ticket, refresh, close }) => {
  const [points, setPoints] = useNumericInputState(ticket.points, true);

  const { ts } = useTranslation();
  async function update(): Promise<void> {
    await api.setRaffleTicket(ticket.raffleId, ticket.userId, ticket.status, points);
    toast.success(`Updated Ticket #${ticket.id} to ${points} points.`);
    refresh();
    close();
  }

  return (
    <Modal id="updatePointsModal" isOpen toggle={close}>
      <ModalHeader toggle={close}>Update ticket points</ModalHeader>
      <ModalBody>
        <p>Set amount of points:</p>
        <FormGroup>
          <Label for="points">Points:</Label>
          <Input id="points" name="points" onChange={setPoints} value={points} />
        </FormGroup>
      </ModalBody>
      <ModalFooter>
        <Button color="primary" id="updatePoints" onClick={update}>
          {ts('update')}
        </Button>{' '}
        <Button color="secondary" onClick={close}>
          {ts('cancel')}
        </Button>
      </ModalFooter>
    </Modal>
  );
};
