import React, { FC } from 'react';
import { Badge, FormGroup, Input, Label } from 'reactstrap';
import { UniqueAttributeInput } from '../..';
import { FormNodePIIModel, PIIElement, piiInputs } from '../../../../shared/kiosk';
import { CurrentUser } from '../../../../shared/user/base';
import { useConvention, useUser } from '../../../utils';
import { RichMarkdownIt } from '../../RichMarkdownIt';
import { FormNodeDateOfBirth } from './pii/FormNodeDateOfBirth';
import { FormNodePIIPhone } from './pii/FormNodePIIPhone';
import { FormNodePIIRegionAddress } from './pii/FormNodePIIRegionAddress';

const formElement = (
  element: PIIElement,
  user: CurrentUser | null,
  isPII: boolean,
  markdown?: string,
): JSX.Element => {
  switch (element.type) {
    case 'regionCountrySelector': {
      return <FormNodePIIRegionAddress key={element.name} user={user} />;
    }

    case 'phoneNumber': {
      return <FormNodePIIPhone key={element.name} user={user} />;
    }

    case 'date': {
      return <FormNodeDateOfBirth key={element.name} user={user} />;
    }

    // TODO: This does not reset if the user logsin and then presses "Change account"
    case 'email': {
      return (
        <div className={`form-node form-pii-${element.name}`} key={element.name}>
          <FormGroup>
            <Label>
              <RichMarkdownIt source={markdown ?? element.displayText} span />
              {element.optional && (
                <>
                  {' '}
                  <Badge color="secondary">Optional</Badge>
                </>
              )}
            </Label>
            {user ? (
              <p>
                <strong>{user.email}</strong>
              </p>
            ) : (
              <UniqueAttributeInput type="email" />
            )}
          </FormGroup>
        </div>
      );
    }

    default: {
      return (
        <div className={`form-node form-pii-${element.name}`} key={element.name}>
          <FormGroup>
            <Label>
              <RichMarkdownIt source={markdown ?? element.displayText} span />
              {element.optional && (
                <>
                  {' '}
                  <Badge color="secondary">Optional</Badge>
                </>
              )}
            </Label>
            {user &&
            element.name !== 'addressStateCountry' &&
            element.name !== 'registration[badgeName]' &&
            (user[element.name] || isPII) ? (
              <p>
                <strong>{(user[element.name] ?? 'N/A') as string}</strong>
              </p>
            ) : (
              <Input name={element.name} required={!element.optional} type={element.type} />
            )}
          </FormGroup>
        </div>
      );
    }
  }
};

export const FormNodePII: FC<FormNodePIIModel> = ({ subType, markdown }) => {
  const conv = useConvention();
  const user = useUser();
  const { elements, pii } = piiInputs.find(({ piiType }) => piiType === subType)!;

  if (!conv.registrationRequirePii && pii) {
    return null;
  }

  return <>{elements.map((element) => formElement(element, user, !!pii, markdown))}</>;
};
