import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import { Row } from 'reactstrap';
import { AttendeeStats, IdentityStats } from '../../../shared/stats';
import { InfoCard, PageHeader, PermissionBoundary, UserStateComponent } from '../../components';
import { UserListModal } from '../../components/UserListModal';
import { useTranslation } from '../../translations';
import { isAccessError, useConvention } from '../../utils';
import { LoadingWrapper } from '../../utils/LoadingWrapper';

const AttendeeStatsComponent: FC<{ readonly stats: AttendeeStats }> = ({ stats }) => {
  const { ts } = useTranslation();
  const [modal, setModal] = React.useState<'pendingReview' | null>(null);

  const paidStats = stats.paid.map(({ name, count }) => (
    <InfoCard
      className="margin-bottom-10"
      heading={`${name} (Paid)`}
      icon="people_outline"
      id={`${name}PaidStats`}
      key={name}
      largeText
      status="info"
      text={['', count.toString()]}
    />
  ));

  return (
    <>
      {modal && (
        <UserListModal
          close={() => setModal(null)}
          title="Pending accouts review"
          userList={stats.pendingReviewUserIds}
        />
      )}
      <InfoCard
        className="margin-bottom-10"
        heading={ts('total_registrations')}
        icon="people"
        id="attendeeStats"
        largeText
        status="info"
        text={['', stats.total.toString()]}
      />
      {stats.printed > 0 && (
        <InfoCard
          className="margin-bottom-10"
          heading="Badges printed"
          icon="print"
          id="badgesPrinted"
          largeText
          status="info"
          text={['', stats.printed.toString()]}
        />
      )}
      <PermissionBoundary inline requiredPermissions={['user:note:delete']}>
        {stats.pendingReviewCount > 0 && (
          <InfoCard
            className="margin-bottom-10"
            heading={
              <a onClick={() => setModal('pendingReview')} style={{ cursor: 'pointer' }}>
                Pending Account Review
              </a>
            }
            icon="key"
            id="pendingReview"
            largeText
            status="info"
            text={['', stats.pendingReviewCount.toString()]}
          />
        )}
      </PermissionBoundary>
      <PermissionBoundary inline requiredPermissions={['refund:create']}>
        {stats.pendingRefundRequests > 0 && (
          <InfoCard
            className="margin-bottom-10"
            heading={<Link to="/housekeeping/attendees/refund">Pending Refund Requests</Link>}
            icon="payments"
            id="pendingRefundRequests"
            largeText
            status="info"
            text={['', stats.pendingRefundRequests.toString()]}
          />
        )}
      </PermissionBoundary>
      {...paidStats}
    </>
  );
};

const IdentityStatsComponent: FC<{ readonly stats: IdentityStats }> = ({ stats }) => {
  const identityStats = stats.status.map(({ name, count }) => (
    <InfoCard
      className="margin-bottom-10"
      heading={`Identity: ${name}`}
      icon="people_outline"
      id={`${name}IdentityStats`}
      key={name}
      largeText
      status="info"
      text={['', count.toString()]}
    />
  ));

  return (
    <>
      <InfoCard
        className="margin-bottom-10"
        heading="Identity Verifications Left"
        icon="people"
        id="identityLeft"
        largeText
        status="info"
        text={['', stats.left.toString()]}
      />
      {...identityStats}
    </>
  );
};

export const HousekeepingHome: FC = () => {
  const { ts } = useTranslation();
  const convention = useConvention();
  return (
    <UserStateComponent>
      <PageHeader>{ts('dashboard')}</PageHeader>
      <Row className="justify-content-center" id="dashboard">
        <LoadingWrapper<AttendeeStats, number>
          dataFetcher={async () => await api.getAttendeeStats()}
          errorDisplay={(err) =>
            isAccessError(err) && (
              <InfoCard
                heading="No Stats Available"
                icon="show_chart"
                status="warning"
                text={["You don't have access to view any statistics information."]}
              />
            )
          }
          inline
        >
          {(data) => <AttendeeStatsComponent stats={data} />}
        </LoadingWrapper>
        {convention.identityVerification !== 'none' && (
          <>
            <hr />
            <LoadingWrapper<IdentityStats, number>
              dataFetcher={async () => await api.getIdentityStats()}
              inline
            >
              {(data) => <IdentityStatsComponent stats={data} />}
            </LoadingWrapper>
          </>
        )}
      </Row>
      <hr />
    </UserStateComponent>
  );
};
