import React, { FC, useContext } from 'react';
import { Link } from 'react-router-dom';
import { Alert, Card, CardBody, Col, Row } from 'reactstrap';
import { SearchComponent, UserStateComponent } from '../../components';
import { useTranslation } from '../../translations';
import { CashierContext } from './cashiercontext';

// eslint-disable-next-line import/no-unused-modules -- misidentified as unused
export const CashierHome: FC = () => {
  const { ts } = useTranslation();
  const {
    config: { primaryPrinter, secondaryPrinter },
  } = useContext(CashierContext);

  return (
    <UserStateComponent>
      <Row>
        {!primaryPrinter && !secondaryPrinter && (
          <Col xs={12}>
            <Alert color="warning">
              <strong>Warning:</strong> No printers are configured for this registration station.
            </Alert>
          </Col>
        )}
        <Col xs={8}>
          <SearchComponent
            hideHelp
            redirectOnSingle
            redirectUrl="/housekeeping/cashier"
            showBornAt
          />
        </Col>
        <Col xs={4}>
          <Card>
            <CardBody>
              <Link className="btn btn-outline-primary btn-block" to="/housekeeping">
                {ts('exit_registration_station')}
              </Link>
              <hr />
              <h4>{ts('search_help')}</h4>
              <p>
                {ts('you_can_search_for_an')}
                <strong>scanning the barcode on the back of their ID</strong>, or by using one of
                the following:
              </p>
              <ul>
                <li>{ts('badge_id')}</li>
                <li>{ts('username')}</li>
                <li>{ts('first_name_last_name_and')}</li>
                <li>{ts('phone_number')}</li>
                <li>{ts('email_address')}</li>
              </ul>
              <p>
                When "<strong>Enable auto navigate</strong>" is selected, the system will redirect
                to the attendee's profile page when only one result is found for the search.
              </p>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </UserStateComponent>
  );
};
