import { JSONForm } from '@conventioncatcorp/common-fe/dist/components/json-form/JSONForm';
import React, { FC } from 'react';
import {
  Button,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from 'reactstrap';
import * as OrderSummary from '../../../../../shared/orders/model';
import { useTranslation } from '../../../../translations';

interface CreateVoucherModalProps {
  readonly order: OrderSummary.Order;
  onSuccess(): void;
  onCancel(): void;
}

export const OrderCreateVoucherModal: FC<CreateVoucherModalProps> = ({
  order,
  onCancel,
  onSuccess,
}) => {
  const { ts } = useTranslation();
  return (
    <Modal className="modal-large" id="createVoucherModal" isOpen>
      <JSONForm
        method="post"
        onSuccess={onSuccess}
        path={`/api/orders/${order.id}/vouchers/create`}
      >
        <ModalHeader>Create voucher #{order.id}</ModalHeader>
        <ModalBody>
          <p>{ts('create_a_onetime_use_voucher')}</p>
          <FormGroup>
            <Label for="displayName">
              {ts('display_name')}
              <small>(Optional)</small>
            </Label>
            <Input id="displayName" name="displayName" />
          </FormGroup>
          <FormGroup>
            <Label for="name">{ts('amount')}</Label>
            <div className="input-group">
              <div className="input-group-prepend">
                <div className="input-group-text">$</div>
              </div>
              <Input id="amount" min={0.01} name="amount" required step={0.01} type="number" />
            </div>
          </FormGroup>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={onCancel} type="button">
            {ts('cancel')}
          </Button>{' '}
          <Button color="primary" id="createVoucherOrder" type="submit">
            {ts('create')}
          </Button>
        </ModalFooter>
      </JSONForm>
    </Modal>
  );
};

interface DeleteVoucherModalProps {
  readonly order: OrderSummary.Order;
  readonly voucherId: number;
  onSuccess(): void;
  onCancel(): void;
}

export const DeteteOrderVoucherModal: FC<DeleteVoucherModalProps> = ({
  order,
  voucherId,
  onCancel,
  onSuccess,
}) => {
  const { ts } = useTranslation();
  const voucher = order.vouchers.find((t) => t.id === voucherId);

  if (!voucher) {
    return null;
  }

  return (
    <Modal className="modal-large" id="deleteVoucherModal" isOpen>
      <JSONForm
        method="delete"
        onSuccess={onSuccess}
        path={`/api/orders/${order.id}/vouchers/${voucher.id!}`}
      >
        <ModalHeader>Delete voucher for #{order.id}</ModalHeader>
        <ModalBody>
          <p>{ts('are_you_sure_you_want')}</p>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={onCancel} type="button">
            {ts('cancel')}
          </Button>{' '}
          <Button color="danger" id="deleteVoucherOrder" type="submit">
            {ts('delete')}
          </Button>
        </ModalFooter>
      </JSONForm>
    </Modal>
  );
};
