import React, { FC, useEffect, useRef } from 'react';
import { FormText, Input, Popover, PopoverBody, PopoverHeader, Progress } from 'reactstrap';
import zxcvbn from 'zxcvbn';
import { useTranslation } from '../translations';
import { useBoolState, useFetcher, useInputState } from '../utils';

interface PasswordStrengthProps {
  readonly minLength: number;
  readonly minScore: number;
  onChange?(value: string): void;
}

const weakPasswordStr = 'You must choose a stronger password!';

const scoreMap = [
  {
    color: 'danger',
    text: 'Terrible! Password is too insecure.',
  },
  {
    color: 'danger',
    text: 'Bad, you can do better.',
  },
  {
    color: 'warning',
    text: 'Fair enough...',
  },
  {
    color: 'info',
    text: 'Good!',
  },
  {
    color: 'success',
    text: 'Great!',
  },
];

function getHelpText(value: string, minLength: number, help_text: string, checker?: typeof zxcvbn) {
  if (value.length < minLength) {
    return {
      color: 'muted',
      score: 0,
      text: help_text,
    };
  }

  if (!checker) {
    return {
      color: 'muted',
      score: -1,
      text: '',
    };
  }

  const { score, feedback } = checker(value);
  const scoreResult = scoreMap[score];

  return {
    color: scoreResult.color,
    text: scoreResult.text,
    feedback,
    score,
  };
}

export const NewPasswordInput: FC<PasswordStrengthProps> = ({ onChange, minLength, minScore }) => {
  const { ts } = useTranslation();
  const [focus, enableFocus, disableFocus] = useBoolState(false);
  const [value, setValue] = useInputState('');
  const passwordInput = useRef<HTMLInputElement>(null);
  const fetcher = useFetcher(async () => {
    return { lib: (await import(/* webpackChunkName: "zxcvbn" */ 'zxcvbn')).default };
  }, []);

  const { color, text, feedback, score } = getHelpText(
    value,
    minLength,
    ts('password_must_be_at_least'),
    fetcher.data?.lib,
  );

  useEffect(() => {
    if (onChange) {
      onChange(value);
    }
  }, [onChange, value]);

  useEffect(() => {
    const { current } = passwordInput;
    if (!current) {
      return;
    }

    if (value.length === 0) {
      current.setCustomValidity('');
      return;
    }

    const goodScore = score === -1 || score >= minScore;
    current.setCustomValidity(value.length > minLength && goodScore ? '' : weakPasswordStr);
  }, [value, score, passwordInput.current, minLength, fetcher.data]);

  return (
    <>
      <Input
        autoComplete="new-password"
        id="password"
        innerRef={passwordInput}
        name="password"
        onBlur={disableFocus}
        onChange={setValue}
        onFocus={enableFocus}
        type="password"
        value={value}
      />
      <div className="margin-top-10" id="feedback-container">
        <Progress color={color === 'muted' ? 'danger' : color} value={Math.floor(25 * score)} />
        <FormText color={color}>{text}</FormText>
      </div>
      <Popover
        isOpen={
          focus &&
          value !== '' &&
          (!!feedback?.warning || !!feedback?.suggestions.length) &&
          score < 3
        }
        placement="bottom"
        target="feedback-container"
      >
        <PopoverHeader>Password help</PopoverHeader>
        <PopoverBody>
          {feedback?.warning ?? 'Too short'}
          <ul>{feedback?.suggestions.map((s) => <li key={s}>{s}</li>)}</ul>
        </PopoverBody>
      </Popover>
    </>
  );
};
