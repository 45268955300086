import React, { FC, useCallback, useState } from 'react';
import { FormGroup, Input, Label } from 'reactstrap';
import { PageHeader, UserStateComponent } from '../../components';
import { MassEmailState } from '../../containers/housekeeping';
import { DealerSelector } from '../../containers/housekeeping/attendee/massemail/dealer';
import {
  EmailEstimateText,
  MassEmailInner,
} from '../../containers/housekeeping/attendee/massemail/form';
import { useTranslation } from '../../translations';
import { Fetcher, useFetcher } from '../../utils';

export const DealerMassEmail: FC = () => {
  const { ts } = useTranslation();
  const [state, setState] = useState<MassEmailState>({
    payload: {
      body: '',
      fromAddress: '',
      recipients: 'custom',
      subject: '',
      customRecipients: [],
    },
  });

  const targetFetcher = useFetcher(async () => {
    const [targets, senders] = await Promise.all([api.getEmailTargets(), api.getEmailSenders()]);
    return { targets, senders: senders.senders };
  }, []);

  const setCustomUserIds = useCallback((customRecipients: number[]) => {
    setState((old) => ({
      payload: { ...old.payload, customRecipients },
      estimate: customRecipients.length,
    }));
  }, []);

  const setAddress = useCallback((fromAddress: string) => {
    setState((old) => ({ payload: { ...old.payload, fromAddress } }));
  }, []);

  if (!targetFetcher.complete) {
    return <Fetcher result={targetFetcher} />;
  }

  return (
    <UserStateComponent>
      <PageHeader>{ts('mass_email')}</PageHeader>

      <MassEmailInner setState={setState} state={state}>
        <>
          <EmailEstimateText
            customRecipients={state.payload.customRecipients}
            estimate={state.estimate ?? 0}
          />
          <FormGroup>
            <Label for="recipients">{ts('from_address')}</Label>
            <Input
              id="fromAddress"
              name="fromAddress"
              onChange={(e) => setAddress(e.currentTarget.value)}
              type="select"
              value={state.payload.fromAddress}
            >
              <option disabled value="">
                (Pick one)
              </option>
              {targetFetcher.data!.senders.map(({ text }) => (
                <option key={text} value={text}>
                  {text}
                </option>
              ))}
            </Input>
          </FormGroup>
          <DealerSelector selectionChanged={setCustomUserIds} />
        </>
      </MassEmailInner>
    </UserStateComponent>
  );
};
