import React, { FC, useCallback, useState } from 'react';
import { NavLink, Route, RouteComponentProps, Switch } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Button, Nav } from 'reactstrap';
import { DealerConfig } from '../../../shared/dealer';
import { PageHeader, UserStateComponent } from '../../components';
import {
  ConfigBooleanSetting,
  ConfigNumberBoxSetting,
  ConfigTextBoxSetting,
} from '../../containers/housekeeping/settings/AbstractLiveSettings';
import { TermsElement } from '../../containers/housekeeping/settings/Terms';
import { useTranslation } from '../../translations';
import { Fetcher, useFetcher } from '../../utils';
import { captureError } from '../../utils/errorHandling';

export const DealerSettingsPage: FC<RouteComponentProps> = ({ match: { path, url } }) => {
  const { ts } = useTranslation();
  return (
    <UserStateComponent>
      <PageHeader>{ts('dealer_settings')}</PageHeader>
      <Nav tabs>
        <NavLink activeClassName="active" className="nav-link" exact to={url}>
          {ts('general')}
        </NavLink>
        <NavLink activeClassName="active" className="nav-link" to={`${url}/terms`}>
          {ts('terms')}
        </NavLink>
      </Nav>
      <div style={{ marginTop: '20px' }}>
        <Switch>
          <Route component={DealerSettingsGeneral} exact path={path} />
          <Route component={DealerTermsSettings} exact path={`${path}/terms`} />
        </Switch>
      </div>
    </UserStateComponent>
  );
};

const DealerTermsSettings: FC = () => {
  const { ts } = useTranslation();
  const fetcher = useFetcher(async () => {
    return await api.getPolicies();
  }, []);

  if (!fetcher.complete) {
    return <Fetcher result={fetcher} />;
  }

  return (
    <div>
      <TermsElement
        description={ts('policy_to_be_displayed_to')}
        displayName={ts('vendor_policy')}
        name="vendor"
        policies={fetcher.data ?? []}
        refresh={fetcher.refresh}
      />
    </div>
  );
};

const DealerSettingsGeneral: FC = () => {
  const fetcher = useFetcher(async () => {
    return await api.getDealerConfig();
  });

  if (!fetcher.complete) {
    return <Fetcher result={fetcher} />;
  }

  return <DealerSettingsKitchenSink initialConfig={fetcher.data!} />;
};

const DealerSettingsKitchenSink: FC<{ readonly initialConfig: DealerConfig }> = ({
  initialConfig,
}) => {
  const [config, setConfig] = useState(initialConfig);
  const [saving, setSaving] = useState(false);

  const { ts } = useTranslation();
  const save = useCallback(async () => {
    setSaving(true);
    try {
      await api.updateDealerConfig(config);
      toast.success(ts('dealer_settings_have_been_updated'));
    } catch (error) {
      captureError(error as Error);
    }

    setSaving(false);
  }, [config]);

  return (
    <>
      <h3>Dealers</h3>

      <ConfigBooleanSetting
        description={ts('enables_disables_the_dealers')}
        displayName={ts('enable_dealers_module')}
        name="enableDealers"
        saving={saving}
        update={(val) => {
          setConfig((old) => {
            return { ...old, enableDealers: val! };
          });
        }}
        value={config.enableDealers}
      />

      <ConfigNumberBoxSetting
        description={ts('the_number_of_dealer_assistants')}
        displayName={ts('dealer_assistant_count_limit')}
        name="dealerAssistantLimit"
        saving={saving}
        update={(val) => {
          setConfig((old) => {
            return { ...old, dealerAssistantLimit: val! };
          });
        }}
        value={config.dealerAssistantLimit}
      />

      <ConfigTextBoxSetting
        description={ts('contact_for_dealer_applications_this')}
        displayName={ts('dealers_email')}
        name="dealers"
        placeholder="dealers"
        saving={saving}
        update={(val) => {
          setConfig((old) => {
            return { ...old, dealerEmail: val! };
          });
        }}
        value={config.dealerEmail ?? ''}
      />

      <Button
        className="float-right"
        color="primary"
        disabled={saving}
        id="saveSettings"
        onClick={save}
      >
        Save
      </Button>
    </>
  );
};
