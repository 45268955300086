import { JSONForm } from '@conventioncatcorp/common-fe/dist/components/json-form/JSONForm';
import React, { FC } from 'react';
import {
  Button,
  Col,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from 'reactstrap';
import { useTranslation } from '../../../../translations';

interface WebhookModalProps {
  readonly types: string[];
  readonly open: boolean;
  readonly onClose: (cancelled: boolean) => void;
}

export const WebhookModal: FC<WebhookModalProps> = ({ open, types, onClose }) => {
  const { ts } = useTranslation();
  return (
    <Modal isOpen={open}>
      <JSONForm
        id="webhook-form"
        method="post"
        onSuccess={() => {
          onClose(false);
        }}
        path="/api/webhooks"
      >
        <ModalHeader>Create Webhook</ModalHeader>
        <ModalBody>
          <FormGroup row>
            <Label for="name" md={4} sm={12}>
              Name
            </Label>
            <Col md={8} sm={12}>
              <Input name="name" />
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label for="url" md={4} sm={12}>
              Url
            </Label>
            <Col md={8} sm={12}>
              <Input name="url" type="url" />
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label for="url" md={4} sm={12}>
              Events
            </Label>
            <Col md={8} sm={12}>
              <Input multiple name="events" type="select">
                {types.map((t) => (
                  <option key={t} value={t}>
                    {t}
                  </option>
                ))}
              </Input>
            </Col>
          </FormGroup>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" type="submit">
            Create
          </Button>
          <Button
            color="secondary"
            onClick={() => {
              onClose(true);
            }}
          >
            {ts('cancel')}
          </Button>
        </ModalFooter>
      </JSONForm>
    </Modal>
  );
};
