import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from '../translations';
import { classNames, useBoolState } from '../utils';
import { MaterialIcon } from './MaterialIcon';

import './NetworkStatus.scss';

const useNetworkStatus = () => {
  const [online, setOnline, setOffline] = useBoolState(navigator.onLine);
  useEffect(() => {
    window.addEventListener('online', setOnline);
    window.addEventListener('offline', setOffline);
    return () => {
      window.removeEventListener('online', setOnline);
      window.removeEventListener('offline', setOffline);
    };
  }, []);

  return online;
};

export const NetworkStatus: FC = () => {
  const { ts } = useTranslation();
  const [isFirstRun, setIsFirstRun] = useState(true);
  const [showOnlineMsg, setShowOnlineMsg] = useState(false);
  const online = useNetworkStatus();

  useEffect(() => {
    if (online) {
      setShowOnlineMsg(true);
    }
  }, [online]);

  useEffect(() => {
    if (!showOnlineMsg) {
      return;
    }

    if (isFirstRun) {
      setIsFirstRun(false);
      setShowOnlineMsg(false);
      return;
    }

    setTimeout(() => setShowOnlineMsg(false), 3000);
  }, [isFirstRun, showOnlineMsg]);

  if (online) {
    return null;
  }

  return (
    <>
      <div
        className={classNames(
          { 'connection-toast-show': !online },
          'connection-toast connection-offline',
        )}
      >
        <div className="connection-icon">
          <MaterialIcon name="wifi_off" type="danger" />
        </div>
        <div className="connection-text">
          <div className="connection-title">{ts('it_seems_like_youre_offline')}</div>
          <div>{ts('please_check_your_network_connectivity')}</div>
          <div>{ts('this_message_will_disappear_once')}</div>
        </div>
      </div>
      <div
        className={classNames(
          { 'connection-toast-show': showOnlineMsg },
          'connection-toast connection-online',
        )}
      >
        <div className="connection-icon">
          <MaterialIcon name="wifi" type="success" />
        </div>
        <div className="connection-text">
          <div className="connection-title">{ts('youre_back_online')}</div>
        </div>
      </div>
    </>
  );
};
