import { classNames, serializeFormJSON } from '@conventioncatcorp/common-fe';
import React, { FC, useCallback, useState } from 'react';
import { FormPageModel } from '../../../../shared/kiosk';
import { Order } from '../../../../shared/orders/model';
import { ProductModel, ProductOptionInput } from '../../../../shared/orders/product';
import { FormInputs } from '../LayoutRenderer';
import { FormSection } from './FormSection';

interface FormPageProps extends FormPageModel {
  readonly attendanceTypes: ProductModel[];
  readonly productOptions: ProductOptionInput[];
  readonly order?: Order;
  readonly lastPage: boolean;
  readonly updateInputs: (data: FormInputs) => void;
  readonly updateOrder: () => Promise<void>;
}

export const FormPage: FC<FormPageProps> = ({
  attendanceTypes,
  name,
  sections,
  productOptions,
  order,
  lastPage,
  updateInputs,
  updateOrder,
}) => {
  const [ignoreForm, setIgnoreForm] = useState(false);
  const pageName = name.replaceAll(/[^\dA-Za-z-]/g, '-');

  const onSubmit = useCallback(
    (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      if (ignoreForm) {
        return;
      }

      const form = e.currentTarget as HTMLFormElement;

      const pageData = serializeFormJSON(form) as FormInputs;
      updateInputs(pageData);
    },
    [updateInputs, ignoreForm],
  );

  return (
    <div
      className={classNames({ 'form-page-last': lastPage }, `form-page-container page-${pageName}`)}
    >
      <form id="pageForm" onSubmit={onSubmit}>
        {sections.map((section) => (
          <FormSection
            key={section.name}
            {...section}
            attendanceTypes={attendanceTypes}
            lastPage={lastPage}
            order={order}
            productOptions={productOptions}
            setFormIgnore={setIgnoreForm}
            updateOrder={updateOrder}
          />
        ))}
      </form>
    </div>
  );
};
