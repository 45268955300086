import React, { FC } from 'react';
import { toast } from 'react-toastify';
import { Card, CardBody, CardHeader } from 'reactstrap';
import { ProductModel } from '../../../../../shared/orders';
import { ActionButtonModal } from '../../../../components';
import { useTranslation } from '../../../../translations';

interface PriceInfoProps {
  readonly product: ProductModel;
  readonly onUpdate: () => void;
}

export const ProductExtraActions: FC<PriceInfoProps> = ({ product, onUpdate }) => {
  const { ts } = useTranslation();
  return (
    <Card>
      <CardHeader>{ts('actions')}</CardHeader>
      <CardBody>
        <ActionButtonModal
          actionContent={ts('clear_unpaid_orders')}
          block
          buttonContent={<>{ts('clear_unpaid_orders')}</>}
          color="secondary"
          id="clearUnpaidOrders"
          onComplete={async () => {
            await api.clearProductFromCarts(product.id);
            toast.success(ts('order_items_removed'));
            onUpdate();
          }}
          title={ts('clear_unpaid_orders')}
        >
          Are you you want to remove this product from all carts in which the payment has not yet
          been completed?
        </ActionButtonModal>
      </CardBody>
    </Card>
  );
};
