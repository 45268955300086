import { Breadcrumb, InlineHelp, MaterialIconText } from '@conventioncatcorp/common-fe';
import React, { FC, useCallback, useState } from 'react';
import { Button, Col, FormGroup, Input, Label, ModalBody, ModalFooter, Row } from 'reactstrap';
import { AddressDistanceCriteronOptions } from '../../../../../shared/orders/raffle';
import { UserLocation } from '../../../../models';
import { useTranslation } from '../../../../translations';
import { useCheckboxState } from '../../../../utils';
import { RaffleConfiguratorProps } from '../utils';
import { AddressSearch } from '.';

export const AddressDistance: FC<RaffleConfiguratorProps<AddressDistanceCriteronOptions>> = ({
  defaultOptions,
  displayName,
  onBack,
  onCancel,
  onConfigure,
}) => {
  const { ts } = useTranslation();
  const [address, setAddress] = useState<UserLocation | undefined>(defaultOptions?.origin);
  const [preferFar, setPreferFar] = useCheckboxState(defaultOptions?.preferFar ?? true);
  const onFinish = useCallback(() => {
    onConfigure({
      name: 'addressDistance',
      options: { origin: address, preferFar },
    });
  }, [onConfigure, address, preferFar]);

  const onAddressChange = useCallback((addr: UserLocation | undefined) => {
    setAddress(addr);
  }, []);

  return (
    <>
      <ModalBody>
        <Row>
          <Col xs={12}>
            <Breadcrumb
              items={[
                { text: `Display Name: ${displayName ?? 'None'}` },
                { text: 'Type: Address Distance' },
              ]}
            />
            <MaterialIconText name="keyboard_backspace" onClick={onBack}>
              {ts('go_back')}
            </MaterialIconText>
            <hr />
          </Col>
          <Col xs={12}>
            <div>
              The venue address will be searched via the Google Places API to determine the latitude
              and longitude coordinates for distance calculations. For ease of calculations, we use
              the city of the event and not the specific street address.
            </div>
          </Col>
          <Col xs={12}>
            <hr />
          </Col>
          <AddressSearch defaultLocation={address} onChange={onAddressChange} />
          <Col xs={12}>
            <hr />
          </Col>
          <Col xs={12}>
            <FormGroup>
              <div className="custom-control custom-checkbox margin-top-10">
                <Input
                  className="custom-control-input"
                  defaultChecked
                  id="eventStore"
                  name="eventStore"
                  onChange={setPreferFar}
                  type="checkbox"
                />
                <Label className="custom-control-label" for="eventStore">
                  Prefer Attendees further away from Event
                  <InlineHelp>
                    <p>
                      Checking this box will weight attendees higher the further away from the event
                      venue they are located.
                    </p>
                    <p>When unchecked, it will weight attendees lower if they are further away.</p>
                  </InlineHelp>
                </Label>
              </div>
            </FormGroup>
          </Col>
        </Row>
      </ModalBody>
      <ModalFooter>
        <Button
          className="editor-finish-btn"
          color="primary"
          disabled={!address}
          onClick={onFinish}
        >
          Finish
        </Button>
        <Button className="editor-cancel-btn" color="secondary" onClick={onCancel} outline>
          {ts('cancel')}
        </Button>
      </ModalFooter>
    </>
  );
};
