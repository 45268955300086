import React, { FC, useCallback, useState } from 'react';
import { Button, Card, CardBody, CardHeader } from 'reactstrap';
import { ProductModel } from '../../../../../shared/orders';
import { ActionButtonModal, LazyMarkdown, MaterialIcon } from '../../../../components';
import { useTranslation } from '../../../../translations';
import { TableKVPair } from '../../../../utils';
import { ProductForm } from '../productForm';
import { getPlatformFeeBadge } from '../utils';

interface ProductInfoProps {
  readonly product: ProductModel;
  readonly onUpdate: () => void;
}

const enum ProductInfoMode {
  View,
  Edit,
}

export const ProductInfo: FC<ProductInfoProps> = ({ product, onUpdate }) => {
  const { ts } = useTranslation();
  const [mode, setMode] = useState(ProductInfoMode.View);

  const deleteProduct = useCallback(async () => {
    await api.deleteProductById(product.id);
    onUpdate();
  }, [product.id, onUpdate]);

  if (mode === ProductInfoMode.View) {
    return (
      <Card id="productInfo">
        <CardHeader>
          <div className="card-sub-header">Product Information</div>
          <div className="float-right">
            {!product.deletedAt && (
              <>
                <Button
                  className="indent"
                  color="primary"
                  id="edit"
                  onClick={() => {
                    setMode(ProductInfoMode.Edit);
                  }}
                  title="Edit"
                >
                  <MaterialIcon name="edit" />
                </Button>
                <ActionButtonModal
                  actionContent="Delete"
                  buttonContent={<MaterialIcon name="delete" />}
                  className="indent"
                  color="danger"
                  id="delete"
                  onComplete={deleteProduct}
                  title="Delete product?"
                >
                  Are you sure you want to delete {product.name}?
                </ActionButtonModal>
              </>
            )}
          </div>
        </CardHeader>
        <CardBody>
          <table className="table">
            <tbody>
              <TableKVPair displayName="Name" name="name" value={product.name} />
              <TableKVPair
                displayName="Display Name"
                name="displayName"
                value={product.displayName}
              />
              <TableKVPair
                displayName="Description"
                name="description"
                value={<LazyMarkdown source={product.description ?? '*No Description Provided*'} />}
              />
              <TableKVPair
                displayName="Minimum Purchase Age"
                name="minAge"
                value={product.minAge?.toString() ?? 'None Set'}
              />
              <TableKVPair
                displayName="Maximum Purchase Age"
                name="maxAge"
                value={product.maxAge?.toString() ?? 'None Set'}
              />
              <TableKVPair displayName="Category" name="category" value={product.category.name} />
              <TableKVPair
                displayName="Platform Fee"
                name="platformFee"
                value={getPlatformFeeBadge(product)}
              />
            </tbody>
          </table>
        </CardBody>
      </Card>
    );
  }

  return (
    <Card>
      <CardHeader>
        <div className="card-sub-header">Product Information</div>
        <div className="float-right">
          <Button
            color="danger"
            id="cancel"
            onClick={() => {
              setMode(ProductInfoMode.View);
            }}
            title={ts('cancel')}
          >
            <MaterialIcon name="cancel" />
          </Button>
        </div>
      </CardHeader>
      <CardBody>
        <ProductForm
          action={`/api/products/${product.id}`}
          category={product.category}
          method="patch"
          onSuccess={() => {
            onUpdate();
            setMode(ProductInfoMode.View);
          }}
          product={product}
        />
      </CardBody>
    </Card>
  );
};
