import React, { FC, useEffect } from 'react';
import { FormNodeCartModel } from '../../../../../shared/kiosk';
import { Order } from '../../../../../shared/orders/model';
import { PaymentGatewayWrapper } from '../../../PaymentGateway';
import { FormAlert } from '../FormAlert';
import { FormOrderInfo } from './FormOrderInfo';

import './FormCart.scss';

interface Props extends FormNodeCartModel {
  readonly order: Order;
  setFormIgnore(active: boolean): void;
  updateOrder(): Promise<void>;
}

export const FormCart: FC<Props> = ({ order, setFormIgnore, updateOrder }) => {
  useEffect(() => {
    setFormIgnore(true);
  }, []);

  return (
    <div className="form-cart">
      <div className="form-cart-orderinfo">
        <FormOrderInfo order={order} />
        <hr />
        <FormAlert
          markdown={
            '#### Important\n\nWhen making payments, please **do not** use the refresh or back buttons on your browser.'
          }
          type="warning"
        />
        <FormAlert markdown={transactionSecurityText} type="info" />
      </div>
      <div className="form-cart-payment">
        <PaymentGatewayWrapper
          onSuccess={updateOrder}
          onSuccessTimeout={1000}
          total={order.breakdown.total - order.breakdown.paid}
        />
      </div>
    </div>
  );
};

const transactionSecurityText = `#### Transaction Security\n\n
Our payment processor implements **Verified by Visa** and **MasterCard SecureCode** security measures to protect against unauthorized use of your cards.\n\n
If your financial institution participates in either of these programs, you may see a dialog asking you to verify your identity to complete the transaction. \
This dialog will vary depending on your financial institution.\n\n
If you have any concerns or issues, **please contact your financial institution** using the customer service number on the back of your card.
`;
