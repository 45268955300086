/* eslint-disable import/no-unused-modules */
import React, { FC, useCallback } from 'react';
import { FormGroup, FormText, Input, Label, Table } from 'reactstrap';
import { Order, OrderItem } from '../../shared/orders/model';
import { useTranslation } from '../translations';
import { displayName } from '../utils';

export interface RefundGrantList {
  orderItemId: number;
  quantity: number;
}

interface RefundGrantTableProps {
  readonly order: Order;
  readonly list: RefundGrantList[];
  setList(list: RefundGrantList[]): void;
}

export const RefundGrantTable: FC<RefundGrantTableProps> = ({ order, list, setList }) => {
  const { ts } = useTranslation();
  // parentOrderItemId - free addons can not be refunded
  const renderOrderItems = order.orderItems
    .filter(({ referenceId, parentOrderItemId }) => referenceId && !parentOrderItemId)
    .map((orderItem) => {
      return (
        <RefundOrderItemTableItem
          key={orderItem.id}
          list={list}
          orderItem={orderItem}
          setList={setList}
        />
      );
    });

  if (renderOrderItems.length === 0) {
    return null;
  }

  return (
    <div>
      <p>{ts('remove_grants_given_to_the')}</p>
      <Table striped>
        <thead>
          <tr>
            <th>{ts('product_name')}</th>
            <th>{ts('quantity')}</th>
            <th>{ts('quantity')} to Remove</th>
          </tr>
        </thead>
        <tbody>{renderOrderItems}</tbody>
      </Table>
      <FormGroup style={{ marginLeft: '7px' }}>
        <div className="custom-control custom-checkbox margin-top-10">
          <Input
            className="custom-control-input"
            defaultChecked
            id="stockReturn"
            name="stockReturn"
            type="checkbox"
          />
          <Label className="custom-control-label" for="stockReturn">
            {ts('return_limited_quantity_items_to')}
          </Label>
        </div>
        <FormText color="muted">
          If you don't want to re-add these items to their respective available stocks, uncheck this
          box. If there are only unlimited quantity items, you can ignore this.
        </FormText>
      </FormGroup>
    </div>
  );
};

interface RefundOrderItemTableItemProps {
  readonly orderItem: OrderItem;
  readonly list: RefundGrantList[];
  setList(list: RefundGrantList[]): void;
}

const RefundOrderItemTableItem: FC<RefundOrderItemTableItemProps> = ({
  orderItem,
  list,
  setList,
}) => {
  const onChange = useCallback(
    (a: React.ChangeEvent<HTMLInputElement>) => {
      const quantity = a.currentTarget.valueAsNumber;
      const item: RefundGrantList = list.find((t) => t.orderItemId === orderItem.id) ?? {
        orderItemId: orderItem.id,
        quantity,
      };

      item.quantity = quantity;

      setList([...list.filter((t) => t.orderItemId !== orderItem.id), item]);
    },
    [orderItem, list, setList],
  );

  return (
    <tr>
      <td>{displayName(orderItem.product)}</td>
      <td>{orderItem.quantity}</td>
      <td>
        <div className="input-group">
          <Input
            id={`removeGrant${orderItem.id}`}
            max={orderItem.grant?.quantity ?? 1}
            min="0"
            onChange={onChange}
            step="1"
            type="number"
          />
        </div>
      </td>
    </tr>
  );
};
