import React, { FC } from 'react';
import { Route, Switch } from 'react-router-dom';
import { Header, Sidenav, SidenavItem, SupportAgentBanner, UserRoute } from '../../components';
import { ErrorBoundary } from '../../components/ErrorBoundary';
import { useTranslation } from '../../translations';
import { checkPermission, useUser } from '../../utils';
import { makeLazyComponent } from '../../utils/lazy';
import { NotFoundError } from '../error/notfound';
import { FeeManagement, PendingRefunds } from './attendee';
import { ManualCreateComponent } from './attendee/createUser';
import { MassEmail } from './attendee/massemail/form';
import { OrderInfo } from './attendee/orders/orderInfo';
import { UserNotescomponent } from './attendee/usernotes/UserNotesComponent';
import { AuditPage } from './audit/log';
import { BadgeArtComponent } from './badgeArt';
import { DevelopersFrame } from './developers';
import { DevicesPage } from './devices';
import { OptionsContainer } from './options';
import {
  CategoryView,
  ProductCategoriesContainer,
  ProductViewer,
  VoucherComponent,
} from './products';
import { RaffleEditorWrapper } from './raffles/editor/raffleEditor';
import { RaffleList } from './raffles/list';
import { RolesPage } from './roles';
import { SettingsPage } from './settings';
import { SurchargesContainer } from './surcharges';
import { SurchargeItem } from './surcharges/surchargeItem';
import { HousekeepingHome, UserProfile, UserSearch } from '.';

const StatisticsComponent = makeLazyComponent(
  async () =>
    (await import(/* webpackChunkName: "statistics" */ '../../modules/stats/statistics'))
      .Statistics,
);

const OrderListComponent = makeLazyComponent(
  async () =>
    (await import(/* webpackChunkName: "orderList" */ './attendee/orders/orderList')).OrderList,
);

// eslint-disable-next-line max-lines-per-function
export const HousekeepingComponent: FC = () => {
  const { ts } = useTranslation();
  return (
    <ErrorBoundary redirectText={ts('back_to_housekeeping')} redirectTo="/housekeeping">
      <Sidenav>
        <SidenavItem
          icon="keyboard_backspace"
          id="navHomepage"
          link="/"
          text={ts('back_to_homepage')}
        />

        <SidenavItem
          header
          requireLoggedIn
          requiredPermissions="system:login"
          text="Housekeeping"
        />
        <SidenavItem
          icon="dashboard"
          id="navDashboard"
          link="/housekeeping"
          requiredPermissions="system:login"
          text={ts('dashboard')}
        />
        <SidenavItem
          icon="shopping_cart"
          id="navCashier"
          link="/housekeeping/cashier"
          requiredPermissions="registration:cashier"
          text={ts('cashier_ui')}
        />
        <SidenavItem
          icon="devices"
          id="navDevices"
          link="/housekeeping/devices"
          requiredPermissions="system:login"
          text="Devices"
        />

        <SidenavItem
          header
          requireLoggedIn
          requiredPermissions="system:login"
          text={ts('attendee_management')}
        />
        <SidenavItem
          icon="search"
          id="navAttendeeSearch"
          link="/housekeeping/attendees/search"
          requiredPermissions="system:login"
          text={ts('user_search')}
        />
        <SidenavItem
          icon="description"
          id="navUserNotes"
          link="/housekeeping/attendees/notes"
          requiredPermissions="user:note:read"
          text={ts('user_notes')}
        />
        <SidenavItem
          icon="local_atm"
          id="navFees"
          link="/housekeeping/attendees/fees"
          requiredPermissions="fee:read"
          text={ts('fee_management')}
        />
        <SidenavItem
          icon="undo"
          id="navRefundRequests"
          link="/housekeeping/attendees/refund"
          requiredPermissions="refund:create"
          text={ts('refund_requests')}
        />
        <SidenavItem
          icon="email"
          id="navMassEmail"
          link="/housekeeping/attendees/massemail"
          requiredPermissions="massemail"
          text={ts('mass_email')}
        />
        <SidenavItem
          icon="show_chart"
          id="statistics"
          link="/housekeeping/attendees/statistics"
          requiredPermissions="registration:stats"
          text={ts('attendee_statistics')}
        />

        <SidenavItem
          header
          matchAny
          requireLoggedIn
          requiredPermissions={['product:read', 'comp:create', 'order:view', 'raffle:manage']}
          text={ts('product_management')}
        />
        <SidenavItem
          icon="list"
          id="navProducts"
          link="/housekeeping/products"
          requiredPermissions="product:read"
          text={ts('products')}
        />
        <SidenavItem
          icon="widgets"
          id="navOptions"
          link="/housekeeping/options"
          requiredPermissions="product:manage"
          text={ts('options')}
        />
        <SidenavItem
          icon="local_atm"
          id="navProductOptions"
          link="/housekeeping/surcharges"
          requiredPermissions="product:manage"
          text={ts('surcharges')}
        />
        <SidenavItem
          icon="card_giftcard"
          id="navVouchers"
          link="/housekeeping/vouchers"
          requiredPermissions="comp:create"
          text={ts('vouchers')}
        />
        <SidenavItem
          icon="receipt"
          id="navOrders"
          link="/housekeeping/orders"
          requiredPermissions="order:view"
          text={ts('orders')}
        />
        <SidenavItem
          icon="lock"
          id="navGatekeeping"
          link="/housekeeping/gatekeeping"
          requiredPermissions="raffle:manage"
          text={ts('gatekeeping')}
        />

        <VolunteerManagment />

        <SidenavItem
          header
          requireLoggedIn
          requiredPermissions="vendor:read"
          text={ts('vendor_management')}
        />
        <SidenavItem
          icon="add_shopping_cart"
          id="navDealers"
          link="/housekeeping/dealers/dashboard"
          requiredPermissions="vendor:read"
          text={ts('dealers')}
        />
        <SidenavItem
          header
          requireLoggedIn
          requiredPermissions="system:badgeart:update"
          text={ts('system')}
        />
        <SidenavItem
          icon="add"
          id="userCreate"
          link="/housekeeping/user/create"
          requiredPermissions="user:create"
          text={ts('user_creation')}
        />
        <SidenavItem
          icon="assignment"
          id="auditLogs"
          link="/housekeeping/auditlog"
          requiredPermissions="audit:read"
          text={ts('audit_logs')}
        />
        <SidenavItem
          icon="link"
          id="developers"
          link="/housekeeping/developers"
          matchAny
          requiredPermissions={['webhook:manage', 'oauth:manage']}
          text={ts('developers')}
        />
        <SidenavItem
          icon="settings"
          id="navSettings"
          link="/housekeeping/settings"
          requiredPermissions="system:settings:update"
          text={ts('settings')}
        />
        <SidenavItem
          icon="lock_open"
          id="navRolePermissions"
          link="/housekeeping/roles"
          requiredPermissions="system:permission:read"
          text={ts('role_permissions')}
        />
        <SidenavItem
          icon="art_track"
          id="navbadgeArt"
          link="/housekeeping/badgeArt"
          requiredPermissions={['asset:create', 'system:badgeart:update']}
          text={ts('badge_art')}
        />
      </Sidenav>
      <div className="page-wrap">
        <Header />
        <SupportAgentBanner />
        <Switch>
          <UserRoute
            component={HousekeepingHome}
            exact
            path="/housekeeping"
            requirePermissions={['system:login']}
          />
          <UserRoute
            component={MassEmail}
            exact
            path="/housekeeping/attendees/massemail"
            requirePermissions={['massemail']}
          />
          <UserRoute
            component={UserSearch}
            exact
            path="/housekeeping/attendees/search"
            requirePermissions={['system:login']}
          />
          <UserRoute
            component={UserNotescomponent}
            exact
            path="/housekeeping/attendees/notes"
            requirePermissions={['ban:read']}
          />
          <UserRoute
            component={FeeManagement}
            exact
            path="/housekeeping/attendees/fees"
            requirePermissions={['system:login']}
          />
          <UserRoute
            component={PendingRefunds}
            exact
            path="/housekeeping/attendees/refund"
            requirePermissions={['refund:create']}
          />
          <UserRoute
            component={StatisticsComponent}
            path="/housekeeping/attendees/statistics"
            requirePermissions={['registration:stats']}
          />
          <UserRoute
            component={RaffleList}
            exact
            path="/housekeeping/gatekeeping"
            requirePermissions={['raffle:manage']}
          />
          <UserRoute
            component={RaffleEditorWrapper}
            exact
            path="/housekeeping/gatekeeping/new"
            requirePermissions={['system:login']}
          />
          <UserRoute
            component={RaffleEditorWrapper}
            exact
            path="/housekeeping/gatekeeping/:id"
            requirePermissions={['system:login']}
          />
          <UserRoute
            component={VoucherComponent}
            path="/housekeeping/vouchers"
            requirePermissions={['comp:create']}
          />
          <UserRoute
            component={OrderListComponent}
            exact
            path="/housekeeping/orders"
            requirePermissions={['order:view']}
          />
          <UserRoute
            component={OrderInfo}
            exact
            path="/housekeeping/orders/:id"
            requirePermissions={['order:view']}
          />
          <UserRoute
            path="/housekeeping/options"
            render={() => <OptionsContainer source="product" />}
            requirePermissions={['system:login']}
          />
          <UserRoute
            component={ProductCategoriesContainer}
            exact
            path="/housekeeping/products"
            requirePermissions={['system:login']}
          />
          <UserRoute
            component={CategoryView}
            exact
            path="/housekeeping/products/category/:id"
            requirePermissions={['system:login']}
          />
          <UserRoute
            component={ProductViewer}
            path="/housekeeping/products/:id"
            requirePermissions={['system:login']}
          />
          <UserRoute
            component={SurchargesContainer}
            exact
            path="/housekeeping/surcharges"
            requirePermissions={['system:login']}
          />
          <UserRoute
            component={SurchargeItem}
            exact
            path="/housekeeping/surcharges/:id"
            requirePermissions={['system:login']}
          />
          <UserRoute
            component={UserProfile}
            path="/housekeeping/attendees/user/:id"
            requirePermissions={['system:login']}
          />
          <UserRoute
            component={BadgeArtComponent}
            exact
            path="/housekeeping/badgeArt"
            requirePermissions={['system:login']}
          />
          <UserRoute
            component={ManualCreateComponent}
            exact
            path="/housekeeping/user/create"
            requirePermissions={['system:login']}
          />
          <UserRoute
            component={RolesPage}
            path="/housekeeping/roles"
            requirePermissions={['system:permission:read']}
          />
          <UserRoute
            component={DevicesPage}
            path="/housekeeping/devices"
            requirePermissions={['system:login']}
          />
          <UserRoute
            component={SettingsPage}
            path="/housekeeping/settings"
            requirePermissions={['system:settings:update']}
          />
          <UserRoute
            component={DevelopersFrame}
            matchAny
            path="/housekeeping/developers"
            requirePermissions={['webhook:manage', 'oauth:manage']}
          />
          <UserRoute
            component={AuditPage}
            path="/housekeeping/auditlog"
            requirePermissions={['audit:read']}
          />
          <Route component={NotFoundError} />
        </Switch>
      </div>
    </ErrorBoundary>
  );
};

const VolunteerManagment: FC = () => {
  const { ts } = useTranslation();
  const user = useUser()!;
  const volunteerPermission = checkPermission(user, 'volunteer:read');

  if (!volunteerPermission && !user.isDepartmentLead) {
    return null;
  }

  return (
    <>
      <SidenavItem
        header
        requireLoggedIn
        requiredPermissions="volunteer:read"
        text={ts('volunteer_management')}
      />
      <SidenavItem
        icon="people"
        id="navVolunteerApplications"
        link={volunteerPermission ? '/housekeeping/volunteers' : '/housekeeping/volunteers/lead'}
        text={ts('volunteer_management')}
      />
    </>
  );
};
