import React, { FC } from 'react';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { useTranslation } from '../../../translations';
import { useConfig, useConvention } from '../../../utils';

interface FelonyDialogProps {
  onClose(accepted: boolean): void;
}

export const FelonyDialog: FC<FelonyDialogProps> = ({ onClose }) => {
  const { ts } = useTranslation();
  const { shortName } = useConvention();
  useConfig();

  return (
    <Modal id="felonyRequirementExceptionModal" isOpen toggle={() => onClose(false)}>
      <ModalHeader>{shortName} Felony Requirement Exception Required</ModalHeader>
      <ModalBody>
        <p>{ts('longname_requires_that_all_volunteers')}</p>
        <p>{ts('if_you_have_been_convicted')}</p>
        <p>
          If you <strong>have not</strong> been convicted of a felony, please click{' '}
          <strong>{ts('cancel')}</strong> and check the certification under Volunteer Code of
          Conduct at the top of the page.
        </p>
        <hr />
        <p>
          {ts('if_you_have_already_requested')}
          <strong>{ts('submit_anyway')}</strong>".
        </p>
      </ModalBody>
      <ModalFooter>
        <Button color="danger" id="submitAnyway" onClick={() => onClose(true)}>
          {ts('submit_anyway')}
        </Button>{' '}
        <Button color="secondary" id="cancel" onClick={() => onClose(false)} outline>
          {ts('cancel')}
        </Button>
      </ModalFooter>
    </Modal>
  );
};
