import React, { FC, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { Button, Col, Row } from 'reactstrap';
import { ProductItem } from '..';
import { ProductModel } from '../../../../../shared/orders';
import { MaterialIcon } from '../../../../components';
import { useTranslation } from '../../../../translations';
import { CategorySurcharges } from './categorySurcharges';

interface Props {
  readonly categoryId: number;
  readonly products: ProductModel[];
  onToggle(): void;
}

export const CategoryInfo: FC<Props> = ({ categoryId, products, onToggle }) => {
  const { ts } = useTranslation();
  const history = useHistory();

  const [showingDeleted, setShowingDeleted] = React.useState<Boolean>(false);

  const productsShown = useMemo(() => {
    if (showingDeleted) {
      return [...products.filter((p) => !p.deletedAt), ...products.filter((p) => !!p.deletedAt)];
    }

    return products.filter((p) => !p.deletedAt);
  }, [products, showingDeleted]);

  const toggleDelete = () => {
    setShowingDeleted(!showingDeleted);
  };

  return (
    <>
      <Row>
        <Col>
          <Button color="primary" id="createProduct" onClick={onToggle}>
            {ts('create_new_product')}
          </Button>
          &nbsp;
          <Button
            color="secondary"
            onClick={() => {
              history.push(history.location.pathname.replace(`/category/${categoryId}`, ''));
            }}
            outline
          >
            Back to Categories
          </Button>
        </Col>
      </Row>
      <hr />
      <div>
        <Row id="productList">
          <Col lg={4} xs={12}>
            <CategorySurcharges categoryId={categoryId} />
          </Col>
          {productsShown.length === 0 && (
            <Col className="text-center" lg={6} xs={12}>
              <MaterialIcon large name="shopping_cart" />
              <h4>{ts('no_products_here')}</h4>
              <p>{ts('theres_no_products_in_this')}</p>
            </Col>
          )}
          <Col lg={6} xs={12}>
            {productsShown.map((product) => (
              <ProductItem key={product.id} product={product} />
            ))}
            {products.some((t) => t.deletedAt) && (
              <Button color="secondary" onClick={toggleDelete}>
                {showingDeleted ? 'Hide Deleted Products' : 'Show Deleted Products'}
              </Button>
            )}
          </Col>
        </Row>
      </div>
    </>
  );
};
