import React, { Dispatch, FC, useCallback, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
  Alert,
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  FormGroup,
  FormText,
  Input,
  Label,
  Row,
} from 'reactstrap';
import {
  DealerArea,
  DealerForm,
  DealerFullModel,
  DealerModel,
  DealerOptionInput,
} from '../../../../shared/dealer';
import { OptionDataValue } from '../../../../shared/options';
import {
  CountrySelector,
  MasonryRow,
  ProductOptionViewer,
  RegionSelector,
} from '../../../components';
import { convertDealerToUpsert } from '../../../models/dealer';
import { useTranslation } from '../../../translations';
import { ObjectInputForm, useCheckboxState, useConfig, useUser } from '../../../utils';
import { captureError } from '../../../utils/errorHandling';

interface DealerFormProps {
  readonly dealer?: DealerFullModel;
  readonly form: DealerForm;
  readonly prevDealer?: DealerFullModel;
  readonly callback: () => void;
}

// eslint-disable-next-line max-lines-per-function
export const DealerFormComponent: FC<DealerFormProps> = ({
  dealer: currentDealer,
  prevDealer,
  form,
  callback,
}) => {
  const { ts } = useTranslation();
  const user = useUser()!;
  const config = useConfig();
  const history = useHistory();
  const isApproved = !!currentDealer?.applications.some((t) => t.status === 'approved');
  const options = [...form.options].sort((a, b) => a.sortOrder - b.sortOrder);
  const [saving, setSaving] = useState(false);

  const [dealer, setDealer] = useState<DealerModel>(() => {
    if (currentDealer) {
      return convertDealerToUpsert(currentDealer);
    }

    if (prevDealer) {
      return convertDealerToUpsert(prevDealer);
    }

    return {
      name: '',
      email: '',
      addressCountry: user.addressCountry,
      addressState: user.addressState,
      addressLine1: user.addressLine1,
      addressLine2: user.addressLine2 ?? null,
      addressCity: user.addressCity,
      addressZipcode: user.addressZipcode,
      options: {},
    };
  });

  const [taxPolicy, setTaxPolicy] = useCheckboxState(!!currentDealer);
  const submitForm = useCallback(async () => {
    setSaving(true);

    if (dealer.addressLine2 === '') {
      setDealer((old) => ({
        ...old,
        addressLine2: null,
      }));
    }

    try {
      await api.updateDealer(user.id, dealer);
      setSaving(false);
      toast.success(ts('your_vendor_information_has_been'));
      callback();

      if (config.dealersAreas.length > 1) {
        history.push('/vendor');
      } else {
        history.push(`/vendor/space/${config.dealersAreas[0].id}`);
      }
    } catch (error) {
      setSaving(false);
      captureError(error as Error);
    }
  }, [dealer, callback, config, user]);

  return (
    <Row className="justify-content-center" id="dealerForm">
      <FormAlerts
        areas={config.dealersAreas}
        dealer={currentDealer}
        dealerAssistantLimit={form.dealerAssistantLimit}
        prevDealer={prevDealer}
      />
      <BusinessInfo
        dealer={dealer}
        isApproved={isApproved}
        options={options}
        setDealer={setDealer}
      />
      <ProductInfo
        dealer={dealer}
        isApproved={isApproved}
        options={options}
        setDealer={setDealer}
      />
      <Col className="margin-bottom-10" lg={8} xs={12}>
        <Card>
          <CardBody>
            <CardTitle>{ts('vendor_policy')}</CardTitle>
            <div className="custom-control custom-checkbox margin-top-10">
              <Input
                checked={taxPolicy}
                className="custom-control-input"
                disabled={!!currentDealer}
                id="taxPolicy"
                onChange={setTaxPolicy}
                type="checkbox"
              />
              <Label className="custom-control-label" for="taxPolicy">
                {ts('i_understand_and_agree_to')}
                <Link target="_blank" to="/policy/vendor">
                  {ts('vendor_policy')}
                </Link>
                .
              </Label>
            </div>
          </CardBody>
        </Card>
      </Col>
      <Col className="margin-top-10" lg={6} xs={12}>
        <Button
          block
          color="primary"
          disabled={saving}
          id="continueApplication"
          onClick={submitForm}
        >
          {saving ? <>Saving...</> : <>Continue</>}
        </Button>
      </Col>
    </Row>
  );
};

interface BusinessInfoProps {
  readonly dealer: DealerModel;
  readonly setDealer: React.Dispatch<React.SetStateAction<DealerModel>>;
  readonly options: DealerOptionInput[];
  readonly isApproved: boolean;
}

const BusinessInfo: FC<BusinessInfoProps> = ({ dealer, setDealer, options, isApproved }) => {
  const { ts } = useTranslation();
  return (
    <Col className="margin-bottom-10" lg={8} xs={12}>
      <Card>
        <CardBody>
          <CardTitle>{ts('business_info')}</CardTitle>
          <Row>
            <MasonryRow>
              <OtherDealerOptions
                dealer={dealer}
                isApproved={isApproved}
                options={options.filter((t) => t.sortOrder < 0)}
                setDealer={setDealer}
              />
            </MasonryRow>
            <Col xs={12}>
              <hr />
            </Col>
            <Col md={6} xs={12}>
              <FormGroup>
                <Label for="addressLine1">Street / House Name</Label>
                <ObjectInputForm name="addressLine1" object={dealer} setObject={setDealer} />
              </FormGroup>
            </Col>
            <Col md={6} xs={12}>
              <FormGroup>
                <Label for="addressLine2">{ts('address_line_2_optional')}</Label>
                <Input id="addressLine2" value={dealer.addressLine2 ?? undefined} />
                <FormText color="muted">
                  <small>{ts('optional')}</small>
                </FormText>
              </FormGroup>
            </Col>
            <Col md={6} xs={12}>
              <FormGroup>
                <Label for="addressCity">{ts('city')}</Label>
                <ObjectInputForm name="addressCity" object={dealer} setObject={setDealer} />
              </FormGroup>
            </Col>
            <Col md={6} xs={12}>
              <FormGroup>
                <Label for="addressZipcode">{ts('zip_postal_code')}</Label>
                <ObjectInputForm name="addressZipcode" object={dealer} setObject={setDealer} />
              </FormGroup>
            </Col>
            <Col lg={6} xs={12}>
              <FormGroup>
                <Label for="addressCity">{ts('country')}</Label>
                <CountrySelector
                  autoComplete="country-name"
                  classes="form-control"
                  id="addressCountry"
                  name="addressCountry"
                  onChange={(addressCountry) => setDealer({ ...dealer, addressCountry })}
                  value={dealer.addressCountry}
                  valueType="short"
                />
              </FormGroup>
            </Col>
            <Col lg={6} xs={12}>
              <FormGroup>
                <Label for="addressState">{ts('state_province')}</Label>
                <RegionSelector
                  autoComplete="address-level1"
                  classes="form-control"
                  country={dealer.addressCountry}
                  countryValueType="short"
                  data-coerce={false}
                  id="addressState"
                  name="addressState"
                  onChange={(addressState) => setDealer({ ...dealer, addressState })}
                  value={dealer.addressState}
                  valueType="short"
                />
              </FormGroup>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </Col>
  );
};

interface ProductInfoProps {
  readonly dealer: DealerModel;
  readonly setDealer: React.Dispatch<React.SetStateAction<DealerModel>>;
  readonly options: DealerOptionInput[];
  readonly isApproved: boolean;
}

const ProductInfo: FC<ProductInfoProps> = ({ dealer, setDealer, options, isApproved }) => {
  const { ts } = useTranslation();
  return (
    <Col className="margin-bottom-10" lg={8} xs={12}>
      <Card>
        <CardBody>
          <CardTitle>{ts('product_info')}</CardTitle>
          <Row>
            <MasonryRow>
              <OtherDealerOptions
                dealer={dealer}
                isApproved={isApproved}
                options={options.filter((t) => t.sortOrder >= 0)}
                setDealer={setDealer}
              />
            </MasonryRow>
          </Row>
        </CardBody>
      </Card>
    </Col>
  );
};

interface FormAlertsProps {
  readonly dealer?: DealerFullModel;
  readonly prevDealer?: DealerFullModel;
  readonly areas: DealerArea[];
  readonly dealerAssistantLimit: number;
}

const FormAlerts: FC<FormAlertsProps> = ({ areas, dealer, prevDealer, dealerAssistantLimit }) => {
  const { ts } = useTranslation();
  const alerts: JSX.Element[] = [];

  if (!dealer || dealer.applications.length === 0) {
    alerts.push(
      <FormAlertItem alertType="info" id="dealerNotSubmitted" key="dealerNotSubmitted">
        You haven't yet submitted an application this year.
      </FormAlertItem>,
    );
  }

  if (!dealer && prevDealer) {
    alerts.push(
      <FormAlertItem alertType="info" id="dealerAutofilled" key="dealerAutofilled">
        We've automatically filled in some of the details from a previous application.
      </FormAlertItem>,
    );
  }

  if (dealer) {
    for (const application of dealer.applications) {
      const area = areas.find((t) => t.id === application.areaId)!;

      switch (application.status) {
        case 'pending': {
          alerts.push(
            <FormAlertItem alertType="info" id="dealerAppPending" key="dealerAppPending">
              <CardTitle>
                Your application for <AreaLink area={area} /> has not been approved yet.
              </CardTitle>
              Please be patient, your application will be reviewed by the Dealer's Den team who will
              contact you in due course.
            </FormAlertItem>,
          );

          break;
        }

        case 'waitlisted': {
          alerts.push(
            <FormAlertItem alertType="info" id="dealerAppWaitlisted" key="dealerAppWaitlisted">
              <CardTitle>
                Your application for <AreaLink area={area} /> is waitlisted.
              </CardTitle>
              Your application will be reviewed by the Dealer's Den team, but there is not enough
              space for everyone.
            </FormAlertItem>,
          );

          break;
        }

        case 'rejected': {
          alerts.push(
            <FormAlertItem alertType="info" id="dealerAppRejected" key="dealerAppRejected">
              <CardTitle>
                Your application for <AreaLink area={area} /> was rejected.
              </CardTitle>
              Unfortunately your application has been rejected by the event at this time.
            </FormAlertItem>,
          );

          break;
        }

        case 'approved': {
          if (application.paidOrderItemId) {
            alerts.push(
              <FormAlertItem alertType="success" id="dealerAppPaid" key="dealerAppPaid">
                <CardTitle>{ts('your_application_has_been_approved')}</CardTitle>
                {application.tableNumber ? (
                  <>
                    Your table assignment for <AreaLink area={area} /> is{' '}
                    <strong>{application.tableNumber}</strong>. This position may be subject to
                    change before the convention.
                  </>
                ) : (
                  <>
                    The <AreaLink area={area} /> team have not yet assigned you a table. This will
                    be confirmed closer to the convention.
                  </>
                )}
                {dealerAssistantLimit > 0 && (
                  <div>
                    <Link to="/vendor/assistants">{ts('click_here')}</Link>
                    {ts('to_manage_assistants')}
                  </div>
                )}
              </FormAlertItem>,
            );
          } else {
            alerts.push(
              <FormAlertItem
                alertType="warning"
                id="dealerAppOutstanding"
                key="dealerAppOutstanding"
              >
                <CardTitle>{ts('your_areaname_application_has_been')}</CardTitle>
                You have been conditionally approved and are now required to make payment in order
                to secure your table. You must register for the convention before you will able to
                pay.
                <br />
                <br />
                <Button block color="primary" outline tag={Link} to="/pay">
                  {ts('pay_for_table')}
                </Button>
              </FormAlertItem>,
            );
          }
        }
      }
    }
  }

  if (areas.every((t) => t.full)) {
    alerts.push(
      <FormAlertItem alertType="danger" id="dealerFull" key="dealerFull">
        <CardTitle>{ts('all_dealer_areas_are_currently')}</CardTitle>
        You may continue to submit an application for consideration if a table opens up before the
        convention.
      </FormAlertItem>,
    );
  }

  return <>{alerts}</>;
};

const FormAlertItem: FC<{ readonly id: string; readonly alertType: string }> = ({
  id,
  alertType,
  children,
}) => {
  return (
    <Col id={id} lg={8} xs={12}>
      <Alert color={alertType}>{children}</Alert>
    </Col>
  );
};

const AreaLink: FC<{ readonly area: DealerArea }> = ({ area }) => {
  return <Link to={`/vendor/space/${area.id}`}>{area.name}</Link>;
};

interface OtherDealerOptionsProps {
  readonly isApproved: boolean;
  readonly dealer: DealerModel;
  readonly setDealer: Dispatch<(prevState: DealerModel) => DealerModel>;
  readonly options: DealerOptionInput[];
}

const OtherDealerOptions: FC<OtherDealerOptionsProps> = ({
  isApproved,
  options,
  setDealer,
  dealer,
}) => {
  const setOptionValue = useCallback(
    (id: number, value: OptionDataValue) => {
      if (options.some((t) => t.id === id && t.internalName === 'name')) {
        setDealer((old) => ({ ...old, name: value as string }));
      }

      if (options.some((t) => t.id === id && t.internalName === 'email')) {
        setDealer((old) => ({ ...old, email: value as string }));
      }

      setDealer((old) => ({
        ...old,
        options: {
          ...old.options,
          [id]: value,
        },
      }));
    },
    [setDealer],
  );

  return (
    <>
      {options.map((option) => {
        let value = dealer?.options[option.id];

        if (option.internalName === 'name') {
          value = dealer.name;
        }

        if (option.internalName === 'email') {
          value = dealer.email;
        }

        return (
          <Col
            className="mb-1"
            id={option.internalName ? `dealer_${option.internalName}` : undefined}
            key={option.id}
            lg={6}
            xs={12}
          >
            <ProductOptionViewer
              defaultValue={value}
              disabled={option.applicationOnly && isApproved}
              onChange={setOptionValue}
              option={option}
            />
          </Col>
        );
      })}
    </>
  );
};
