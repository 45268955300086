import React, { FC, ReactNode, useCallback, useState } from 'react';
import { Button, Card, CardBody, CardText, CardTitle, Col, Row } from 'reactstrap';
import { useTranslation } from '../translations';
import { MaterialIcon, UserStateComponent } from '.';

export const PIIAccessWarning: FC<{
  readonly ep: string;
  readonly children: ReactNode;
  setData(data: unknown): void;
}> = ({ ep, children, setData }) => {
  const { ts } = useTranslation();
  const [renderChildren, setRenderChildren] = useState(false);

  const requestPII = useCallback(async () => {
    setData(await api.request({ url: ep }));
    setRenderChildren(true);
  }, [setData, setRenderChildren]);

  if (renderChildren) {
    return <>{children}</>;
  }

  return (
    <UserStateComponent>
      <Row className="justify-content-center">
        <Col className="margin-bottom-10" xs={12}>
          <Card className="warning">
            <CardBody className="text-center">
              <MaterialIcon large name="warning" type="warning" />
              <CardTitle>PII Access Warning</CardTitle>
              <CardText>{ts('you_are_attempting_to_access')}</CardText>
              <CardText>{ts('you_are_required_to_confirm')}</CardText>
              <CardText>{ts('this_access_will_be_logged')}</CardText>
            </CardBody>
          </Card>
        </Col>
        <Col lg={6} xs={12}>
          <Button
            block
            color="danger"
            id="accessEmergencyContactsBtn"
            onClick={async () => await requestPII()}
          >
            Continue
          </Button>
        </Col>
      </Row>
    </UserStateComponent>
  );
};
