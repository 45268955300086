import React, { FC } from 'react';
import { Badge } from 'reactstrap';
import { useTranslation } from '../translations';
import { useConvention } from '../utils';
import { DateTime } from './Date';
import { InlineHelp } from '.';

interface Props {
  readonly minAge?: number;
  readonly maxAge?: number;
  readonly title?: string;
  readonly audience?: string;
  readonly idPrefix: string;
}

export const AgeRestrictedProductBadge: FC<Props> = ({
  audience,
  title,
  minAge,
  maxAge,
  idPrefix,
}) => {
  const { t, ts } = useTranslation();
  audience ??= ts('age_restricted_product_badge.audience_you');
  title ??= ts('age_restricted_product_badge.product_arp');
  const { startAt, timeZone } = useConvention();
  return (
    <Badge className="with-inline-icon" color="danger" id={`${idPrefix}AgeRestricted`} pill>
      {title}&nbsp;
      <InlineHelp>
        {minAge &&
          !maxAge &&
          t(
            'age_restricted_product_badge.audience_must_be_at_least_to_purchase',
            audience,
            minAge,
            <DateTime timeZone={timeZone} value={startAt} />,
          )}
        {!minAge &&
          maxAge &&
          t(
            'age_restricted_product_badge.audience_must_be_no_older_to_purchase',
            audience,
            maxAge,
            <DateTime timeZone={timeZone} value={startAt} />,
          )}
        {minAge &&
          maxAge &&
          t(
            'age_restricted_product_badge.audience_must_be_at_least_no_older_to_purchase',
            audience,
            minAge,
            maxAge,
            <DateTime timeZone={timeZone} value={startAt} />,
          )}
      </InlineHelp>
    </Badge>
  );
};
