import React, { FC, useEffect } from 'react';
import {
  Col,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Label,
  Row,
} from 'reactstrap';
import { SurchargeAmountType, SurchargeType } from '../../../../shared/orders/model';
import { InlineHelp } from '../../../components';
import { useTranslation } from '../../../translations';
import { useInputState } from '../../../utils';

interface Props {
  readonly wide?: boolean;
}

export const SurchargeForm: FC<Props> = ({ wide }) => {
  const { ts } = useTranslation();
  const [type, setType] = useInputState<SurchargeType>('surcharge');
  const [amountType, setAmountTypeEv, setAmountType] = useInputState<SurchargeAmountType>('fixed');

  const isFixed = amountType === 'fixed';
  const isTax = type === 'tax';

  useEffect(() => {
    if (isTax) {
      setAmountType('percent');
    }
  }, [type]);

  return (
    <Row>
      <Col lg={wide && 6} xs={12}>
        <FormGroup>
          <Label for="name">{ts('name')}</Label>
          <Input id="name" name="name" placeholder={ts('surcharge')} required />
        </FormGroup>
      </Col>
      <Col lg={wide && 6} xs={12}>
        <FormGroup>
          <Label for="name">Display {ts('name')}</Label>
          <Input id="displayName" name="displayName" placeholder="Display {ts('name')}" required />
        </FormGroup>
      </Col>
      <Col lg={wide && 6} xs={12}>
        <FormGroup>
          <Label for="type">
            {ts('type')}
            <InlineHelp>
              <Label>{ts('surcharge_type_information')}</Label>
              <ul className="flushLeft">
                <li>
                  <strong>{ts('surcharge')}</strong>: An arbitrary fixed amount or percent-based
                  charge.
                </li>
                <li>
                  <strong>{ts('tax_rate')}</strong>: An involuntary percent-based charge required by
                  a government entity.
                </li>
              </ul>
            </InlineHelp>
          </Label>
          <Input id="type" name="type" onChange={setType} required type="select">
            <option value="surcharge">{ts('surcharge')}</option>
            <option value="tax">{ts('tax_rate')}</option>
          </Input>
        </FormGroup>
      </Col>
      <Col lg={wide && 6} xs={12}>
        <FormGroup>
          <Label for="amountType">{ts('amount_type')}</Label>
          <Input
            defaultValue={isTax || !isFixed ? 'percent' : 'fixed'}
            id="amountType"
            name="amountType"
            onChange={setAmountTypeEv}
            required
            type="select"
          >
            <option disabled={isTax} value="fixed">
              Fixed {ts('amount')}
            </option>
            <option value="percent">Percentage</option>
          </Input>
        </FormGroup>
      </Col>
      <Col lg={wide && 6} xs={12}>
        <FormGroup>
          <Label for="amount">{ts('amount')}</Label>
          <InputGroup>
            {isFixed && (
              <InputGroupAddon addonType="prepend">
                <InputGroupText>$</InputGroupText>
              </InputGroupAddon>
            )}
            <Input
              id="amount"
              max={isFixed ? undefined : '100'}
              min="0.01"
              name="amount"
              required
              step="0.01"
              type="number"
            />
            {!isFixed && (
              <InputGroupAddon addonType="append">
                <InputGroupText>%</InputGroupText>
              </InputGroupAddon>
            )}
          </InputGroup>
        </FormGroup>
      </Col>
    </Row>
  );
};
