import React, { FC, useEffect, useState } from 'react';
import { FormGroup, Input, Label } from 'reactstrap';
import {
  DealerArea,
  DealerFullModel,
  dealerStatusList,
  DealerStatusTypes,
} from '../../../../../shared/dealer';
import { ProductAddonModel, ProductModel, ProductVisibility } from '../../../../../shared/orders';
import { useTranslation } from '../../../../translations';
import {
  capitalize,
  displayName,
  distinct,
  Fetcher,
  setElementInArray,
  useFetcher,
} from '../../../../utils';
import { LoadingState } from '../../../../utils/LoadingState';
import { LoadingWrapper } from '../../../../utils/LoadingWrapper';

type ApplicationType = DealerStatusTypes | 'paid' | 'unpaid';
const applicationType: ApplicationType[] = [
  ...dealerStatusList.map((t) => t.key),
  'paid',
  'unpaid',
];

interface DealerSelectorProps {
  readonly selectionChanged: (userIds: number[]) => void;
}

interface Filters {
  tableTypes: number[] | null;
  areaIds: number[];
  addonIds: number[] | null;
  status: ApplicationType[];
}

function filterDealers(dealers: DealerFullModel[], filter: Filters): DealerFullModel[] {
  const result: DealerFullModel[] = [];

  for (const dealer of dealers) {
    let shouldAdd = false;

    for (const application of dealer.applications) {
      if (!filter.areaIds.includes(application.areaId)) {
        continue;
      }

      if (filter.tableTypes && !filter.tableTypes.includes(application.tableType.id)) {
        continue;
      }

      if (filter.addonIds) {
        const hasAddon = application.dealerRequestTypes.some((v) =>
          filter.addonIds!.includes(v.id),
        );

        if (!hasAddon) {
          continue;
        }
      }

      if (!filter.status.includes(application.status)) {
        continue;
      }

      const paid = !!application.paidOrderItemId;

      if (filter.status.includes('paid') && paid) {
        shouldAdd = true;
      } else if (filter.status.includes('unpaid') && !paid) {
        shouldAdd = true;
      }
    }

    if (shouldAdd) {
      result.push(dealer);
    }
  }

  return result;
}

export const DealerSelector: FC<DealerSelectorProps> = ({ selectionChanged }) => {
  const { ts } = useTranslation();
  const [areaIds, setAreaIds] = useState<number[]>([]);
  const [tableTypes, setTableTypes] = useState<number[] | null>(null);
  const [addonIds, setAddonIds] = useState<number[] | null>(null);
  const [status, setStatus] = useState<ApplicationType[]>(['approved', 'paid', 'unpaid']);

  const dealerRequest = useFetcher(async () => {
    if (areaIds.length === 0) {
      return [];
    }

    return await api.getAllDealers(areaIds);
  }, [areaIds]);

  useEffect(() => {
    const results = filterDealers(dealerRequest?.data ?? [], {
      areaIds,
      tableTypes,
      addonIds,
      status,
    });

    selectionChanged(distinct(results.map((t) => t.user.id)));
  }, [dealerRequest?.data, areaIds, tableTypes, addonIds, status]);

  if (dealerRequest.state === LoadingState.Error) {
    return <Fetcher result={dealerRequest} />;
  }

  return (
    <LoadingWrapper dataFetcher={async () => await api.getDealerAreas()}>
      {(areas) => (
        <div>
          <FormGroup>
            <Label for="area" id="dealerAreaSelection">
              Areas
            </Label>
            {areas.map((area) => (
              <div className="custom-control custom-checkbox margin-top-10" key={area.id}>
                <Input
                  checked={areaIds.includes(area.id)}
                  className="custom-control-input"
                  disabled={!dealerRequest?.complete}
                  id={`area_${area.id}`}
                  onChange={(e) => {
                    setAreaIds(setElementInArray(areaIds, area.id, e.target.checked));
                  }}
                  type="checkbox"
                />
                <Label className="custom-control-label" for={`area_${area.id}`}>
                  {area.name}
                </Label>
              </div>
            ))}
          </FormGroup>
          <FormGroup>
            <Label for="products">Table type</Label>
            <div className="custom-control custom-checkbox margin-top-10">
              <Input
                checked={tableTypes !== null}
                className="custom-control-input"
                disabled={!dealerRequest?.complete}
                id="filterTableType"
                onChange={() => {
                  setTableTypes(tableTypes === null ? [] : null);
                }}
                type="checkbox"
              />
              <Label className="custom-control-label" for="filterTableType">
                Filter by table type
              </Label>
            </div>
            {tableTypes !== null && (
              <TableTypeFilter
                addonIds={addonIds}
                areas={areas}
                dealers={dealerRequest?.data ?? []}
                setAddonIds={setAddonIds}
                setTableTypes={setTableTypes}
                tableTypes={tableTypes}
              />
            )}
          </FormGroup>
          <FormGroup>
            <Label for="dealerStatus">{ts('status')}</Label>
            {applicationType.map((type) => {
              return (
                <div className="custom-control custom-checkbox margin-top-10" key={type}>
                  <Input
                    checked={status.includes(type)}
                    className="custom-control-input"
                    disabled={!dealerRequest?.complete}
                    id={`status_${type}`}
                    onChange={(e) => {
                      setStatus(setElementInArray(status, type, e.target.checked));
                    }}
                    type="checkbox"
                  />
                  <Label className="custom-control-label" for={`status_${type}`}>
                    {capitalize(type)}
                  </Label>
                </div>
              );
            })}
          </FormGroup>
        </div>
      )}
    </LoadingWrapper>
  );
};

interface TableTypeFilterProps {
  readonly areas: DealerArea[];
  readonly dealers: DealerFullModel[];
  readonly tableTypes: number[];
  readonly setTableTypes: (values: number[]) => void;
  readonly addonIds: number[] | null;
  readonly setAddonIds: (values: number[] | null) => void;
}

const TableTypeFilter: FC<TableTypeFilterProps> = (props) => {
  const { areas, dealers, tableTypes, setTableTypes, addonIds, setAddonIds } = props;
  const categoryIds = distinct(areas.map((t) => t.categoryId));

  const { ts } = useTranslation();
  const categories = useFetcher(async () => {
    const products: ProductModel[] = [];
    const addons: ProductAddonModel[] = [];

    await Promise.all(
      categoryIds.map(async (categoryId) => {
        const categoryProducts = await api.getFullProductsByCategory(categoryId);

        for (const product of categoryProducts) {
          if (
            !products.some((t) => t.id === product.id) &&
            product.visibility !== ProductVisibility.HIDDEN
          ) {
            products.push(product);
          }

          for (const addon of product.addons) {
            if (!addons.some((t) => t.id === addon.id)) {
              addons.push(addon);
            }
          }
        }
      }),
    );

    return { products, addons };
  }, categoryIds);

  return (
    <Fetcher inline result={categories}>
      <>
        <div style={{ paddingLeft: '10px' }}>
          {categories.data?.products.map((product) => {
            const count = dealers.filter((t) =>
              t.applications.some((u) => u.tableType.id === product.id),
            ).length;

            if (count === 0) {
              return null;
            }

            return (
              <div className="custom-control custom-checkbox margin-top-10" key={product.id}>
                <Input
                  checked={tableTypes.includes(product.id)}
                  className="custom-control-input"
                  id={`product_${product.id}`}
                  onChange={(e) => {
                    setTableTypes(setElementInArray(tableTypes, product.id, e.target.checked));
                  }}
                  type="checkbox"
                />
                <Label className="custom-control-label" for={`product_${product.id}`}>
                  {displayName(product)} ({count})
                </Label>
              </div>
            );
          })}
        </div>
        <div className="custom-control custom-checkbox margin-top-10">
          <Input
            checked={addonIds !== null}
            className="custom-control-input"
            id="filterAddon"
            onChange={() => {
              setAddonIds(addonIds === null ? [] : null);
            }}
            type="checkbox"
          />
          <Label className="custom-control-label" for="filterAddon">
            {ts('filter_by_addons')}
          </Label>
        </div>
        <div style={{ paddingLeft: '10px' }}>
          {addonIds !== null &&
            categories.data?.addons.map((addon) => {
              const count = dealers.filter((dealer) => {
                return dealer.applications.some((app) =>
                  app.dealerRequestTypes.some((v) => v.id === addon.id),
                );
              }).length;

              if (count === 0) {
                return null;
              }

              return (
                <div className="custom-control custom-checkbox margin-top-10" key={addon.id}>
                  <Input
                    checked={addonIds.includes(addon.id)}
                    className="custom-control-input"
                    id={`addon_${addon.id}`}
                    onChange={(e) => {
                      setAddonIds(setElementInArray(addonIds, addon.id, e.target.checked));
                    }}
                    type="checkbox"
                  />
                  <Label className="custom-control-label" for={`addon_${addon.id}`}>
                    {displayName(addon)} ({count})
                  </Label>
                </div>
              );
            })}
        </div>
      </>
    </Fetcher>
  );
};
