import React, { FC } from 'react';
import { FormNodeSubheaderModel } from '../../../../shared/kiosk';
import { LazyMarkdown } from '../../LazyMarkdown';

export const FormNodeSubheader: FC<FormNodeSubheaderModel> = ({ id, markdown }) => {
  return (
    <div className="form-node form-node-subheader" id={`node-${id}`}>
      <h4>
        <LazyMarkdown source={markdown} />
      </h4>
    </div>
  );
};
