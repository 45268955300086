import React, { ChangeEvent, FC, useCallback, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { Button, FormGroup, Input, Label, Modal, ModalBody, ModalFooter } from 'reactstrap';
import { SecondFactorType } from '../../../../shared/user/password';
import { MaterialIcon } from '../../../components';
import { useTranslation } from '../../../translations';
import { useConstantSetters } from '../../../utils';

interface SecondFactorState {
  onCancel(): void;
  onSubmit(): void;
}

export const SecondFactorPrompt: FC<SecondFactorState> = ({ onCancel, onSubmit }) => {
  const { ts } = useTranslation();
  const [type, setType] = useState(SecondFactorType.Otp);
  const [secondFactorCode, setSecondFactorCode] = useState('');
  const secondFactor = useRef<HTMLInputElement>(null);

  const isOtp = type === SecondFactorType.Otp;
  const length = isOtp ? 6 : 10;

  const onChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setSecondFactorCode(e.currentTarget.value);
  }, []);

  const focusSecondFactor = useCallback(() => {
    secondFactor.current?.focus();
  }, [secondFactor.current]);

  const toggle2FAType = useConstantSetters(
    setType,
    isOtp ? SecondFactorType.Backup : SecondFactorType.Otp,
  );

  const onSubmitForm = useCallback((e: React.UIEvent) => {
    e.preventDefault();
    onSubmit();
  }, []);

  const onKeyUp = useCallback((e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.keyCode === 13) {
      onSubmit();
    }
  }, []);

  return (
    <>
      <Input defaultValue={type} id="secondFactorType" name="secondFactor[type]" type="hidden" />
      <Input name="secondFactor[code]" required type="hidden" value={secondFactorCode} />
      <Modal isOpen onOpened={focusSecondFactor}>
        <div>
          <ModalBody className="twofa-modal">
            <div className="text-center">
              <MaterialIcon large name="security" type="primary" />
              <h4>Two-Factor Authentication</h4>
            </div>
            <hr />
            <p className="text-secondary text-center">
              {isOtp
                ? 'Enter the 6-digit code generated by your two-factor authentication app '
                : 'Enter one of your alphanumeric backup codes '}
              to verify it's really you.
            </p>
            <FormGroup className="text-center">
              <Label for="secondFactor[code]">{ts('second_factor_code')}</Label>
              <Input
                autoFocus
                id="secondFactor"
                innerRef={secondFactor}
                maxLength={length}
                minLength={length}
                onChange={onChange}
                onKeyUp={onKeyUp}
                placeholder={isOtp ? '123456' : 'ADSKJBKASF'}
                required
              />
            </FormGroup>
          </ModalBody>
          <ModalFooter>
            <Link
              className="btn btn-link btn-block"
              id="secondFactorChangeType"
              onClick={toggle2FAType}
              to="#"
            >
              {isOtp ? 'Use Backup Code Instead' : `Use ${ts('second_factor_code')}`}
            </Link>
            <Button color="primary" id="login2FA" onClick={onSubmitForm}>
              Login
            </Button>
            <Button color="secondary" onClick={onCancel} outline>
              {ts('cancel')}
            </Button>
          </ModalFooter>
        </div>
      </Modal>
    </>
  );
};
