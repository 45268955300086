import React, { FC } from 'react';
import { PageHeader, TermsLoader, UserStateComponent } from '../../components';
import { useTranslation } from '../../translations';

export const TermsComponent: FC = () => {
  const { ts } = useTranslation();
  return (
    <UserStateComponent>
      <div id="conTerms">
        <PageHeader>{ts('convention_terms_and_conditions')}</PageHeader>
        <TermsLoader name="terms" />
      </div>
    </UserStateComponent>
  );
};
