import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import { Navbar } from 'reactstrap';
import { useTranslation } from '../translations';
import { useConfig } from '../utils';

export const Footer: FC = () => {
  const { ts, language, setLanguage, availableLanguages } = useTranslation();
  const config = useConfig();

  const handleLanguageChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setLanguage(event.target.value);
  };

  return (
    <Navbar className="footer" color="light" light>
      <div>
        <a href="https://concat.app" rel="noopener noreferrer" target="_blank">
          <img src="https://cdn.concat.app/static/powered_by_concat.svg" />
        </a>
        <p>
          Version: <Link to="/changelog">{VERSION}</Link>
        </p>
      </div>
      <select id="language-select" onChange={handleLanguageChange} value={language}>
        {availableLanguages.map(({ key, label }) => (
          <option key={key} value={key}>
            {label}
          </option>
        ))}
      </select>
      <div style={{ marginLeft: 'auto' }}>
        {' '}
        {/* Align to the right */}
        {config && (
          <p>
            <Link to="/policy/terms">{ts('terms_and_conditions')}</Link>
            &nbsp;|&nbsp;
            <Link to="/policy/privacy">{ts('privacy_policy')}</Link>
          </p>
        )}
      </div>
    </Navbar>
  );
};
