import {
  JSONForm,
  PreSubmitData,
} from '@conventioncatcorp/common-fe/dist/components/json-form/JSONForm';
import React, { FC, useCallback, useState } from 'react';
import {
  Button,
  Col,
  FormGroup,
  FormText,
  Input,
  Label,
  PopoverBody,
  Row,
  UncontrolledPopover,
} from 'reactstrap';
import { ProductCategoryModel, ProductModel } from '../../../../shared/orders';
import { InlineHelp, MaterialIcon, PlatformFeeHelp, RadioButtonGroup } from '../../../components';
import { NullableNumberInput } from '../../../components/NullableNumberInput';
import { ProductSelection } from '../../../components/ProductSelection';
import { RoleSelection } from '../../../components/RoleSelection';
import {
  migrateTimezone,
  NullableTimeZoneInputField,
  TimeZoneInputField,
  trimTime,
} from '../../../components/TimeZoneInputField';
import { useTranslation } from '../../../translations';
import { useConvention } from '../../../utils';
import { checkForPlatformFee } from './utils';

interface ProductFormProps {
  readonly method: 'patch' | 'post';
  readonly action: string;
  readonly product?: ProductModel;
  readonly category: ProductCategoryModel;
  readonly onSuccess: (props: { id?: number }) => void;
}

interface ProductFormForm {
  stockMaximumQuantity: number | null;
  price: number | null;
  roleRestriction: number[] | null;
  grantRestriction: number[] | null;
}

// eslint-disable-next-line max-lines-per-function
export const ProductForm: FC<ProductFormProps> = ({
  action,
  method,
  category,
  product,
  onSuccess,
}) => {
  const { ts } = useTranslation();
  const { timeZone } = useConvention();
  const [maxStock, setMaxStock] = useState<number | null>(
    product ? product.stockMaximumQuantity! : null,
  );

  const [selectedRoleIds, setSelectedRoleIds] = useState<number[]>(product?.roleRestriction ?? []);
  const [selectedGrantIds, setSelectedGrantIds] = useState<number[]>(
    (product?.requiredGrants ?? []).map((grant) => grant.productId),
  );

  const [availableTo, setAvailableTo] = useState(() => product?.availableTo ?? null);
  const [availableFrom, setAvailableFrom] = useState<Date>(
    () => product?.availableFrom ?? migrateTimezone(trimTime(new Date()), timeZone),
  );

  const [expiresOn, setExpiresOn] = useState(() => product?.grantExpiresOn ?? null);

  const toggleMaxStock = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const { checked } = event.target;
      setMaxStock(checked ? product?.stockMaximumQuantity ?? 1 : null);
    },
    [setMaxStock, product],
  );

  const preSubmit = useCallback(
    ({ inputs }: PreSubmitData<ProductFormForm>) => {
      inputs!.roleRestriction = selectedRoleIds.length === 0 ? null : selectedRoleIds;
      inputs!.grantRestriction = selectedGrantIds;
      inputs!.stockMaximumQuantity = maxStock;
      if (inputs?.price) {
        inputs.price = Math.floor(inputs.price * 100);
      }
    },
    [maxStock, selectedRoleIds, selectedGrantIds],
  );

  return (
    <JSONForm<{ id?: number }, ProductFormForm>
      method={method}
      onSuccess={onSuccess}
      path={action}
      preSubmit={preSubmit}
    >
      <Row>
        <Col lg={6} xs={12}>
          <FormGroup>
            <Label for="name">{ts('name')}</Label>
            <Input
              defaultValue={product?.name}
              id="name"
              name="name"
              placeholder="Product {ts('name')}"
              required
            />
          </FormGroup>
        </Col>
        <Col lg={6} xs={12}>
          <FormGroup>
            <Label for="displayName">
              Display {ts('name')} <small>{ts('optional')}</small>
            </Label>
            <Input defaultValue={product?.displayName ?? ''} id="displayName" name="displayName" />
          </FormGroup>
        </Col>
      </Row>
      <FormGroup>
        <Label for="description">{ts('description')}</Label>
        <Input
          defaultValue={product?.description}
          id="description"
          name="description"
          type="textarea"
        />
      </FormGroup>
      <Row>
        {!product && (
          <>
            <Col lg={6} xs={12}>
              <FormGroup>
                <Label for="price">{ts('base_price_')}</Label>
                <Input id="price" min="0" name="price" required step="0.01" type="number" />
              </FormGroup>
            </Col>
            <Col lg={6} xs={12} />
          </>
        )}
        <Col lg={6} xs={12}>
          <FormGroup>
            <Label for="stockUnlimited">{ts('stock_control')}</Label>
            <div className="custom-control custom-checkbox margin-top-5">
              <Input
                className="custom-control-input"
                defaultChecked={maxStock !== null}
                id="stockUnlimited"
                onChange={toggleMaxStock}
                type="checkbox"
              />
              <Label className="custom-control-label" for="stockUnlimited">
                {ts('limit_available_stock')}
              </Label>
            </div>
            <FormText className="margin-top-10" color="muted">
              Limiting the stock will allow a finite number of this product to be sold.
            </FormText>
          </FormGroup>
        </Col>
        <Col lg={6} xs={12}>
          {maxStock !== null && (
            <FormGroup>
              <Label for="stockMaximumQuantity">Max Stock</Label>
              <Input
                defaultValue={maxStock.toString()}
                id="stockMaximumQuantity"
                min="1"
                onChange={(event) => {
                  setMaxStock(Number(event.target.value));
                }}
                required
                step="1"
                type="number"
              />
              <FormText color="muted">
                Maximum stock can only be decreased by the remaining stock available.
              </FormText>
            </FormGroup>
          )}
        </Col>
        <Col lg={6} xs={12}>
          <FormGroup>
            <Label for="availableFrom">{ts('available_from')}</Label>
            <Input name="availableFrom" type="hidden" value={availableFrom.toISOString()} />
            <TimeZoneInputField
              defaultValue={availableFrom}
              id="availableFrom"
              onChange={setAvailableFrom}
              timeZone={timeZone}
            />
          </FormGroup>
        </Col>
        <Col lg={6} xs={12}>
          <FormGroup>
            <Label for="availableTo">Available Until</Label>
            <Input
              name="availableTo"
              type="hidden"
              value={availableTo ? availableTo.toISOString() : null!}
            />
            <NullableTimeZoneInputField
              defaultValue={availableTo}
              id="availableTo"
              min={availableFrom}
              onChange={setAvailableTo}
              timeZone={timeZone}
            />
          </FormGroup>
        </Col>
        {category.isRegistration && (
          <Col lg={6} xs={12}>
            <FormGroup>
              <Label for="grantExpiresOn">Expires On</Label>{' '}
              {expiresOn && expiresOn.toISOString() !== availableTo?.toISOString() && (
                <>
                  <span className="inline-icon text-danger" id="expiresWarning">
                    <MaterialIcon name="warning" small />
                  </span>
                  <UncontrolledPopover placement="bottom" target="expiresWarning" trigger="hover">
                    <PopoverBody>
                      "Expires On" does not match "Available Until". Double check that this is
                      intentional.
                    </PopoverBody>
                  </UncontrolledPopover>
                </>
              )}
              <Input
                name="grantExpiresOn"
                type="hidden"
                value={expiresOn ? expiresOn.toISOString() : null!}
              />
              <NullableTimeZoneInputField
                defaultValue={expiresOn}
                id="grantExpiresOn"
                onChange={setExpiresOn}
                timeZone={timeZone}
              />
            </FormGroup>
          </Col>
        )}
        <Col lg={6} xs={12}>
          <FormGroup>
            <Label for="minAge">
              Minimum Purchase Age <small>{ts('optional')}</small>
            </Label>
            <Input
              defaultValue={product?.minAge}
              id="minAge"
              max={product?.maxAge ?? 150}
              min={13}
              name="minAge"
              type="number"
            />
          </FormGroup>
        </Col>
        <Col lg={6} xs={12}>
          <FormGroup>
            <Label for="maxAge">
              Maximum Purchase Age <small>{ts('optional')}</small>
            </Label>
            <Input
              defaultValue={product?.maxAge}
              id="maxAge"
              max={150}
              min={product?.minAge ?? 13}
              name="maxAge"
              type="number"
            />
          </FormGroup>
        </Col>
        <Col lg={6} xs={12}>
          <FormGroup>
            <Label for="roleRestriction">{ts('restrict_product_to_user_roles')}</Label>
            <RoleSelection
              permissionError="You do not have sufficient permission to limit products by role."
              selectedIds={selectedRoleIds}
              setSelectedIds={setSelectedRoleIds}
            />
          </FormGroup>
        </Col>
        {!category.isVendor && (
          <Col lg={6} xs={12}>
            <FormGroup>
              <Label for="grantRestriction">
                {ts('restrict_product_to_grants')}
                <InlineHelp>{ts('the_user_will_not_be')}</InlineHelp>
              </Label>
              <ProductSelection
                excludedIds={product ? [product.id] : []}
                permissionError="You do not have sufficient permission to limit products by grant."
                selectedIds={selectedGrantIds}
                setSelectedIds={setSelectedGrantIds}
              />
            </FormGroup>
          </Col>
        )}

        {!category.isRegistration && !category.isVendor && (
          <Col lg={6} xs={12}>
            <FormGroup>
              <NullableNumberInput
                defaultValue={product?.maxGrants ?? null}
                id="maxGrants"
                name="maxGrants"
                step={1}
              >
                Maximum purchases per user
              </NullableNumberInput>
            </FormGroup>
          </Col>
        )}
        <Col lg={6} xs={12}>
          <FormGroup>
            <Label for="visibility">{ts('product_visibility')}</Label>
            <RadioButtonGroup
              defaultSelected={product?.visibility}
              id="visibility"
              name="visibility"
              options={[
                { label: 'Public', value: 'public', icon: 'public' },
                { label: 'Unlisted', value: 'unlisted', icon: 'link' },
                { label: 'Hidden', value: 'hidden', icon: 'lock' },
              ]}
            />
          </FormGroup>
        </Col>
        <Col lg={6} xs={12}>
          <FormGroup>
            <div className="custom-control custom-checkbox margin-top-10">
              <Input
                className="custom-control-input"
                defaultChecked={product && checkForPlatformFee(product)}
                id="passPlatformFee"
                name="passPlatformFee"
                type="checkbox"
              />
              <Label className="custom-control-label" for="passPlatformFee">
                {ts('pass_platform_fee_to_customer')}
                <PlatformFeeHelp />
              </Label>
            </div>
          </FormGroup>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col lg={8} xs={12}>
          <Button block color="primary" id="submitNewProduct">
            {product ? 'Update' : 'Create'} Product
          </Button>
        </Col>
      </Row>
    </JSONForm>
  );
};
