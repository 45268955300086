import { JSONForm } from '@conventioncatcorp/common-fe/dist/components/json-form/JSONForm';
import React, { FC, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Button, Card, CardBody, Col, FormGroup, Input, Label, Row } from 'reactstrap';
import { InlineHelp } from '../../../components';
import { useTranslation } from '../../../translations';

export enum ProductType {
  Digital,
  Physical,
  Registration,
  Vendor,
}

const productTypes: ProductTypeInfo[] = [
  {
    canUseEventStore: true,
    description: 'Digital Products are redeemed for online only items or rewards.',
    key: ProductType.Digital,
    name: 'Digital',
  },
  {
    canUseEventStore: true,
    description: 'Physical Products are tangible items received by the user by post or in person.',
    key: ProductType.Physical,
    name: 'Physical',
  },
  {
    canUseEventStore: false,
    description:
      'Vendor / Dealer Products are table types and additional requests which are used in the Vendor application process.',
    key: ProductType.Vendor,
    name: 'Vendor / Dealer',
  },
];

export const CreateCategory: FC<{
  readonly forcedType?: ProductType;
}> = ({ forcedType }) => {
  const { ts } = useTranslation();
  const [productType, setProductType] = useState(forcedType ?? ProductType.Digital);
  const activeProductType = productTypes.find(({ key }) => key === productType)!;
  const history = useHistory();

  return (
    <Col lg={8} xs={12}>
      <Card>
        <CardBody>
          <h4>{ts('new_category')}</h4>
          <hr />
          <JSONForm<{ id: number }>
            id="createCategoryForm"
            method="post"
            onSuccess={(data) => {
              toast.success(ts('new_category_has_been_created'));
              history.push(`${history.location.pathname}/category/${data.id}`);
            }}
            path="/api/products/categories"
          >
            <Row className="justify-content-center">
              <Col lg={6} xs={12}>
                <FormGroup>
                  <Label for="name">{ts('name')}</Label>
                  <Input id="name" name="name" placeholder="Category {ts('name')}" required />
                </FormGroup>
              </Col>
              <Col lg={6} xs={12} />
              <Col lg={6} xs={12}>
                <FormGroup>
                  <Label for="productType">
                    {ts('type_of_products')}
                    <InlineHelp>
                      <Label>{ts('product_type_information')}</Label>
                      <ul className="flushLeft">
                        {productTypes.map((pt) => (
                          <li key={pt.key}>{pt.description}</li>
                        ))}
                      </ul>
                    </InlineHelp>
                  </Label>
                  <Input
                    defaultValue={productType}
                    disabled={!!forcedType}
                    id="productType"
                    name="productType"
                    onChange={({ currentTarget: { value } }) => {
                      setProductType(productTypes.find(({ key }) => key.toString() === value)!.key);
                    }}
                    type="select"
                  >
                    {productTypes.map((pt) => (
                      <option key={pt.key} value={pt.key}>
                        {pt.name}
                      </option>
                    ))}
                  </Input>
                </FormGroup>
              </Col>
              <Col lg={6} xs={12}>
                <Label>{ts('product_type_description')}</Label>
                <p>{activeProductType.description}</p>
              </Col>
              {activeProductType.canUseEventStore && (
                <Col className="margin-bottom-10" lg={6} xs={12}>
                  <div className="custom-control custom-checkbox margin-top-10">
                    <Input
                      className="custom-control-input"
                      id="eventStore"
                      name="eventStore"
                      type="checkbox"
                    />
                    <Label className="custom-control-label" for="eventStore">
                      {ts('list_this_category_in_the')}
                      <InlineHelp>
                        <p>{ts('checking_this_box_will_cause')}</p>
                      </InlineHelp>
                    </Label>
                  </div>
                </Col>
              )}
              <Col lg={6} xs={12} />
              <Col lg={8} xs={12}>
                <Button block color="primary" id="submitNewCategory">
                  {ts('create_category')}
                </Button>
              </Col>
            </Row>
          </JSONForm>
        </CardBody>
      </Card>
    </Col>
  );
};

interface ProductTypeInfo {
  key: ProductType;
  name: string;
  description: string;
  canUseEventStore: boolean;
}
