import { JSONForm } from '@conventioncatcorp/common-fe/dist/components/json-form/JSONForm';
import React, { FC, useMemo, useState } from 'react';
import { Button, Card, CardBody, CardHeader, FormGroup, Label } from 'reactstrap';
import { ProductModel } from '../../../../../shared/orders';
import { ActionButton, ElementHeader, ElementSelector, MaterialIcon } from '../../../../components';
import { Surcharge } from '../../../../models';
import { useTranslation } from '../../../../translations';
import { SurchargeForm } from '../../surcharges/SurchargeForm';
import { surchargeRateText, surchargeTitle } from '../../surcharges/utils';
import { LocalSearchProvider } from '../utils';

interface Props {
  readonly product: ProductModel;
  readonly onUpdate: () => void;
}

const enum Mode {
  View,
  Search,
  Create,
}

export const SurchargeInfo: FC<Props> = ({ product, onUpdate }) => {
  const { ts } = useTranslation();
  const [mode, setMode] = useState<Mode>(Mode.View);
  const [selectedSurcharges, setSelectedSurcharges] = useState<Surcharge[]>([]);
  const memoizedSearchProvider = useMemo(
    () =>
      new LocalSearchProvider(async () => {
        return (await api.getSurcharges()).filter(({ deletedAt }) => !deletedAt);
      }),
    [],
  );

  if (mode === Mode.Search) {
    return (
      <Card className="margin-top-10">
        <CardHeader>
          <div className="float-left" style={{ marginTop: '7px' }}>
            Surcharges
          </div>
          {!product.deletedAt && (
            <div className="float-right">
              <Button
                color="danger"
                id="cancel"
                onClick={() => {
                  setMode(Mode.View);
                }}
                title={ts('cancel')}
              >
                <MaterialIcon name="cancel" />
              </Button>
            </div>
          )}
        </CardHeader>
        <CardBody>
          <JSONForm<undefined, { surchargeIds: number[] }>
            id="assignSurchargeForm"
            method="post"
            onSuccess={() => {
              onUpdate();
              setMode(Mode.View);
            }}
            path={`/api/products/${product.id}/surcharges`}
            preSubmit={(r) => (r.inputs!.surchargeIds = selectedSurcharges.map(({ id }) => id))}
          >
            <FormGroup>
              <Label for="surcharges">{ts('surcharge_search')}</Label>
              <ElementSelector<Surcharge>
                defaultSelected={selectedSurcharges}
                id="productSurchargeSelector"
                onCreateClicked={() => {
                  setMode(Mode.Create);
                }}
                searchProvider={memoizedSearchProvider}
                selectionChanged={setSelectedSurcharges}
              />
            </FormGroup>
            <Button block color="primary" id="assignSurcharges">
              {ts('assign_surcharges')}
            </Button>
          </JSONForm>
        </CardBody>
      </Card>
    );
  }

  if (mode === Mode.Create) {
    return (
      <Card className="margin-top-10">
        <CardHeader>
          <div className="float-left" style={{ marginTop: '7px' }}>
            Surcharges
          </div>
          {!product.deletedAt && (
            <div className="float-right">
              <Button
                color="danger"
                id="cancel"
                onClick={() => {
                  setMode(Mode.Search);
                }}
                title={ts('cancel')}
              >
                <MaterialIcon name="cancel" />
              </Button>
            </div>
          )}
        </CardHeader>
        <CardBody>
          <JSONForm<{ surcharge: Surcharge }, { amount: number }>
            id="createSurchargeForm"
            method="post"
            onSuccess={({ surcharge }) => {
              setSelectedSurcharges(selectedSurcharges.concat(surcharge));
              setMode(Mode.Search);
            }}
            path="/api/surcharges"
            preSubmit={(r) => (r.inputs!.amount *= 100)}
          >
            <SurchargeForm wide />
            <Button color="primary" id="createSurcharge">
              {ts('create_surcharge')}
            </Button>
          </JSONForm>
        </CardBody>
      </Card>
    );
  }

  const surcharges = (product.surcharges ?? []).filter(({ deletedAt }) => !deletedAt);
  return (
    <Card className="margin-top-10">
      <CardHeader>
        <div className="float-left" style={{ marginTop: '7px' }}>
          Surcharges
        </div>
        {!product.deletedAt && (
          <div className="float-right">
            <Button
              color="success"
              id="edit"
              onClick={() => {
                setMode(Mode.Search);
              }}
              title="Add"
            >
              <MaterialIcon name="add" />
            </Button>
          </div>
        )}
      </CardHeader>
      <CardBody>
        {surcharges.length === 0 && <i>There are currently no surcharges.</i>}
        {surcharges.map((surcharge) => (
          <ElementHeader
            icon={{ iconName: 'local_atm' }}
            key={surcharge.id}
            rightContent={
              <ActionButton
                action={`/api/products/${product.id}/surcharges/${surcharge.id}`}
                className="action-delete"
                color="danger"
                method="delete"
                onSuccess={onUpdate}
                outline
                title="Delete"
              >
                <MaterialIcon name="delete" />
              </ActionButton>
            }
            title={surcharge.name}
          >
            {surchargeTitle(surcharge)} of {surchargeRateText(surcharge)}
          </ElementHeader>
        ))}
      </CardBody>
    </Card>
  );
};
