import { JSONForm } from '@conventioncatcorp/common-fe/dist/components/json-form/JSONForm';
import React, { FC, useCallback, useMemo, useState } from 'react';
import { unstable_batchedUpdates } from 'react-dom';
import { Button, Card, CardBody, CardHeader, FormGroup, Input, Label } from 'reactstrap';
import { Option } from '../../../../../shared/options';
import { ProductModel } from '../../../../../shared/orders';
import { ActionButton, ElementHeader, ElementSelector, MaterialIcon } from '../../../../components';
import { useTranslation } from '../../../../translations';
import { useTernaryState } from '../../../../utils';
import { CreateOption } from '../../options/createOption';
import { ProductOptionBadges } from '../../options/utils';
import { LocalSearchProvider } from '../utils';

interface ProductOptionProps {
  readonly product: ProductModel;
  readonly onUpdate: () => void;
}

const enum Mode {
  View,
  Edit,
  Create,
}

export const ProductOption: FC<ProductOptionProps> = ({ product, onUpdate }) => {
  const { ts } = useTranslation();
  const [mode, setViewMode, setEditMode, setCreateMode] = useTernaryState(
    Mode.View,
    Mode.View,
    Mode.Edit,
    Mode.Create,
  );

  const [selectedOptions, setSelectedOptions] = useState<Option[]>([]);

  const searchProvider = useMemo(
    () => new LocalSearchProvider(async () => await api.getOptions('product')),
    [],
  );

  const resetViewMode = useCallback(() => {
    unstable_batchedUpdates(() => {
      setViewMode();
      setSelectedOptions([]);
    });
  }, [setViewMode]);

  let header: JSX.Element | null = null;
  let body: JSX.Element | JSX.Element[] | null = null;
  switch (mode) {
    case Mode.View: {
      if (!product.deletedAt && product.canAddMoreOptions) {
        header = (
          <div className="float-right">
            <Button color="success" id="create" onClick={setEditMode} title="Add">
              <MaterialIcon name="add" />
            </Button>
          </div>
        );
      }

      const hasProducts = !product.options || product.options.length === 0;
      body = hasProducts ? (
        <i>{ts('there_are_currently_no_product')}</i>
      ) : (
        product.options.map((u) => (
          <div id={`option${u.id}`} key={u.id}>
            <ElementHeader
              icon={{ iconName: 'widgets' }}
              rightContent={
                <ActionButton
                  action={`/api/products/${product.id}/options/${u.id}`}
                  className="action-delete"
                  color="danger"
                  method="delete"
                  onSuccess={onUpdate}
                  outline
                  title="Delete"
                >
                  <MaterialIcon name="delete" />
                </ActionButton>
              }
              title={u.name}
            >
              <ProductOptionBadges option={u} required={u.required} />
            </ElementHeader>
          </div>
        ))
      );

      break;
    }

    case Mode.Create: {
      header = (
        <div className="float-right">
          <Button color="danger" id="cancel" onClick={setEditMode} title={ts('cancel')}>
            <MaterialIcon name="cancel" />
          </Button>
        </div>
      );

      body = (
        <CreateOption
          onCreate={(opt) => {
            unstable_batchedUpdates(() => {
              setSelectedOptions(selectedOptions.concat(opt));
              setEditMode();
            });
          }}
          source="product"
        />
      );

      break;
    }

    case Mode.Edit: {
      header = (
        <div className="float-right">
          <Button color="danger" id="cancel" onClick={resetViewMode} title={ts('cancel')}>
            <MaterialIcon name="cancel" />
          </Button>
        </div>
      );

      body = (
        <JSONForm<{}, { optionIds: number[] }>
          method="post"
          onSuccess={() => {
            resetViewMode();
            onUpdate();
          }}
          path={`/api/products/${product.id}/options`}
          preSubmit={(r) => (r.inputs!.optionIds = selectedOptions.map(({ id }) => id))}
        >
          <FormGroup>
            <Label for="option">{ts('option_search')}</Label>
            <ElementSelector<Option>
              defaultSelected={selectedOptions}
              id="productOptionSelector"
              onCreateClicked={setCreateMode}
              searchProvider={searchProvider}
              selectionChanged={setSelectedOptions}
            />
          </FormGroup>
          <div
            className="custom-control custom-checkbox margin-top-10"
            style={{ marginLeft: '7px', marginBottom: '12px' }}
          >
            <Input className="custom-control-input" id="required" name="required" type="checkbox" />
            <Label className="custom-control-label" for="required">
              {ts('mark_selected_options_as_required')}
            </Label>
          </div>
          <Button block color="primary" id="assignOptions">
            {ts('assign_options')}
          </Button>
        </JSONForm>
      );
    }
  }

  return (
    <Card className="margin-top-10" id="productOptions">
      <CardHeader>
        <div className="card-sub-header">{ts('options')}</div>
        {header}
      </CardHeader>
      <CardBody>{body}</CardBody>
    </Card>
  );
};
