import React, { FC, useState } from 'react';
import { FormNodeType, FormSectionModel } from '../../../../shared/kiosk';
import { Order } from '../../../../shared/orders/model';
import { ProductModel, ProductOptionInput } from '../../../../shared/orders/product';
import { FormNodeAccount, FormNodeAccountProps } from './FormNodeAccount';
import { FormNodeAttendanceType } from './FormNodeAttendanceType';
import { FormNodeSubmit } from './FormNodeBase';
import { FormNodeLinkTermsAgreement } from './FormNodeLinkTermsAgreement';
import { FormNodeMarkdown } from './FormNodeMarkdown';
import { FormNodePII } from './FormNodePII';
import { FormNodeProductOption } from './FormNodeProductOption';
import { FormNodeSubheader } from './FormNodeSubheader';
import { FormNodeTermsAgreement } from './FormNodeTermsAgreement';
import { FormOAuthLogin } from './FormOAuthLogin';
import { FormCart } from './cart/FormNodeCart';

interface FormSectionProps extends FormSectionModel {
  readonly productOptions: ProductOptionInput[];
  readonly attendanceTypes: ProductModel[];
  readonly lastPage: boolean;
  readonly order?: Order;
  setFormIgnore(active: boolean): void;
  updateOrder(): Promise<void>;
}

interface RenderProps {
  readonly attendanceTypes: ProductModel[];
  readonly node: FormNodeType;
  readonly productOptions: ProductOptionInput[];
  readonly lastPage: boolean;
  readonly order?: Order;
  setFormIgnore(active: boolean): void;
  updateOrder(): Promise<void>;
}

const RenderFormSection: FC<RenderProps> = ({
  attendanceTypes,
  node,
  productOptions,
  lastPage,
  order,
  setFormIgnore,
  updateOrder,
}) => {
  const [policyChecked, setPolicyChecked] = useState(false);

  switch (node.type) {
    case 'attendanceType': {
      const attendanceTypeNode = node;
      return <FormNodeAttendanceType {...attendanceTypeNode} attendanceTypes={attendanceTypes} />;
    }

    case 'cart': {
      const cartNode = node;
      return (
        <FormCart
          {...cartNode}
          order={order!}
          setFormIgnore={setFormIgnore}
          updateOrder={updateOrder}
        />
      );
    }

    case 'login': {
      const loginNode = node as FormNodeAccountProps;
      return <FormNodeAccount {...loginNode} setFormIgnore={setFormIgnore} />;
    }

    case 'option': {
      const poNode = node;
      return <FormNodeProductOption {...poNode} productOptions={productOptions} />;
    }

    case 'markdown': {
      const markdownNode = node;
      return <FormNodeMarkdown {...markdownNode} />;
    }

    case 'subheader': {
      const subheaderNode = node;
      return <FormNodeSubheader {...subheaderNode} />;
    }

    case 'oauthLogin': {
      const oauthLogin = node;
      return <FormOAuthLogin {...oauthLogin} />;
    }

    case 'termsAgreement': {
      const termsNode = node;
      return (
        <FormNodeTermsAgreement
          {...termsNode}
          checked={policyChecked}
          setChecked={setPolicyChecked}
        />
      );
    }

    case 'linkTermsAgreement': {
      const linkTermsNode = node;
      return (
        <FormNodeLinkTermsAgreement
          {...linkTermsNode}
          checked={policyChecked}
          setChecked={setPolicyChecked}
        />
      );
    }

    case 'pii': {
      const piiNode = node;
      return <FormNodePII {...piiNode} />;
    }

    case 'submit': {
      return <FormNodeSubmit lastPage={lastPage} />;
    }

    case 'divider': {
      return <hr />;
    }

    default: {
      return <i>UnhandledNode: {JSON.stringify(node)}</i>;
    }
  }
};

export const FormSection: FC<FormSectionProps> = (props) => {
  const { name, nodes } = props;

  return (
    <section className="section" id={`section-${name}`}>
      <h2>{name}</h2>
      <div className="section-nodes">
        {nodes.map((node) => (
          <div
            className={['section-node', node.className].join(' ')}
            id={`node-${node.id}`}
            key={node.id}
          >
            <RenderFormSection {...props} node={node} />
          </div>
        ))}
      </div>
    </section>
  );
};
