import React, { FC } from 'react';
import { Col, Container, Row } from 'reactstrap';
import { PageHeader } from '../../components';
import { useTranslation } from '../../translations';

export const NotFoundError: FC = () => {
  const { ts } = useTranslation();
  return (
    <Container className="justify-content-center">
      <Row>
        <Col xs={12}>
          <PageHeader>
            Page Not Found <small>(HTTP 404)</small>
          </PageHeader>
          <p>{ts('the_page_you_were_looking')}</p>
        </Col>
      </Row>
    </Container>
  );
};
