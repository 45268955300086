import { JSONForm } from '@conventioncatcorp/common-fe/dist/components/json-form/JSONForm';
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  FormGroup,
  FormText,
  Input,
  Label,
  Row,
} from 'reactstrap';
import { CurrentUser } from '../../../shared/user/base';
import {
  PasswordChangeInput,
  RegisterUserInfo,
  UniqueAttributeInput,
  WizardStatus,
} from '../../components';
import { captureError } from '../../utils/errorHandling';
import { randomString } from '../../utils/random';
import { IdentityHiddenInput } from '../account/register/form';
import { StripeIdentityResult } from '../account/register/stripeIdentity/model';

interface KioskUserInfoProps {
  readonly stripeIdentityResult?: StripeIdentityResult;
  updateStatus(status: WizardStatus): void;
  onRegister(user: CurrentUser): void;
}

interface KioskUserInfoState {
  [key: string]: unknown;
  password: string;
  registerAccount?: boolean;
  username: string;
}

export class KioskUserInfo extends Component<KioskUserInfoProps, KioskUserInfoState> {
  public override state: KioskUserInfoState = {
    password: randomString(),
    username: `DONOTRETAIN-${randomString()}`,
  };

  public override render(): JSX.Element {
    return (
      <>
        {this.renderLogin()}
        <hr />
        {this.renderCreate()}
      </>
    );
  }

  private renderLogin(): JSX.Element {
    return (
      <JSONForm
        method="post"
        onFailure={() => {
          this.props.updateStatus(WizardStatus.Error);
          return false;
        }}
        onSuccess={async () => await this.onRegisterSuccess(true)}
        path="/api/login"
        preSubmit={() => {
          this.props.updateStatus(WizardStatus.Pending);
        }}
      >
        <Card className="margin-bottom-10">
          <CardBody className="text-center">
            <Row>
              <Col lg={4} xs={12}>
                <p>
                  If you already have an existing account, enter your username and password to
                  login.
                </p>
                <p>If you have forgot your password, ask a member of staff for assistance.</p>
                <p>
                  <Link
                    className="btn btn-link btn-block"
                    id="resetPasswordWithCode"
                    to="/login/reset?redirect=/kiosk"
                  >
                    Reset Password with Reset Code
                  </Link>
                </p>
              </Col>
              <Col lg={8} xs={12}>
                <FormGroup row>
                  <Label for="usernameOrMail" sm={4}>
                    Username
                  </Label>
                  <Col sm={8}>
                    <Input id="usernameOrMail" name="usernameOrMail" />
                  </Col>
                </FormGroup>
                <FormGroup row>
                  <Label for="password" sm={4}>
                    Password
                  </Label>
                  <Col sm={8}>
                    <Input id="password" name="password" type="password" />
                  </Col>
                </FormGroup>
                <Button block color="primary" id="login" type="submit">
                  Login
                </Button>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </JSONForm>
    );
  }

  private renderCreate(): JSX.Element {
    const { registerAccount } = this.state;
    return (
      <JSONForm
        method="post"
        onFailure={() => {
          this.props.updateStatus(WizardStatus.Error);
          return false;
        }}
        onSuccess={async () => await this.onRegisterSuccess()}
        path="/api/users"
        preSubmit={() => {
          this.props.updateStatus(WizardStatus.Pending);
        }}
      >
        <Card className="margin-bottom-10">
          <CardBody className="text-center">
            <FormGroup row>
              <Label for="email" sm={4}>
                Email Address
              </Label>
              <Col sm={8}>
                <UniqueAttributeInput type="email" />
                <FormText>
                  If your email address is already in use, please proceed to the Registration Desk.
                </FormText>
              </Col>
            </FormGroup>
            <IdentityHiddenInput identityId={this.props.stripeIdentityResult?.identityId} />
            <RegisterUserInfo forceValues={this.props.stripeIdentityResult?.user} />
          </CardBody>
        </Card>
        <Card className="margin-bottom-10">
          <CardBody>
            <CardTitle>Account Registration</CardTitle>
            <p>
              When you have a registered account, you can use your stored information to quickly
              register for future events, and use connected third-party services. You can also
              subscribe to the convention newsletter to receive the latest news and info.
            </p>
            <p>
              If you choose not to register an account, your information will be deleted after the
              end of the convention.
            </p>
            <hr />
            <div
              className="custom-control custom-checkbox margin-top-10"
              style={{ paddingLeft: '30px' }}
            >
              <Input
                checked={registerAccount}
                className="custom-control-input"
                id="registerAccount"
                onChange={() => {
                  this.toggleRegisterAccount();
                }}
                type="checkbox"
              />
              <Label className="custom-control-label" for="registerAccount">
                I would like to register an account.
              </Label>
            </div>
            {this.renderAccountInfo()}
          </CardBody>
        </Card>
        <Card>
          <CardBody>
            <Row className="justify-content-center">
              <Col className="margin-top-10" lg={6} xs={12}>
                <Button block color="primary" id="step3Continue" type="submit">
                  Continue
                </Button>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </JSONForm>
    );
  }

  private toggleRegisterAccount(): void {
    const { registerAccount } = this.state;

    this.setState({
      password: registerAccount ? randomString() : '',
      registerAccount: !registerAccount,
      username: registerAccount ? `DONOTRETAIN-${randomString()}` : '',
    });
  }

  private updateField(field: string, value: string): void {
    this.setState({
      [field]: value,
    });
  }

  private renderAccountInfo(): JSX.Element {
    const { registerAccount, username, password } = this.state;

    if (!registerAccount) {
      return (
        <>
          <Input id="username" name="username" type="hidden" value={username} />
          <Input id="password" name="password" type="hidden" value={password} />
        </>
      );
    }

    return (
      <>
        <br />
        <FormGroup row>
          <Label for="username" sm={4}>
            Username
          </Label>
          <Col sm={8}>
            <UniqueAttributeInput
              onChange={(v) => {
                this.updateField('username', v);
              }}
              type="username"
            />
            <FormText color="muted">
              Your username is an identifier that uniquely identifies your account.
            </FormText>
          </Col>
        </FormGroup>
        <PasswordChangeInput
          onChange={(v) => {
            this.updateField('password', v);
          }}
          passwordScore={2}
          sm={8}
        />
      </>
    );
  }

  private async onRegisterSuccess(hasLoggedIn?: boolean): Promise<void> {
    const { password, username } = this.state;

    if (!hasLoggedIn) {
      try {
        await api.login(username, password);
      } catch (error) {
        captureError(error as Error);
      }
    }

    // REVIEW: Why repeat?
    this.props.onRegister(await api.getActiveUser());
  }
}
