import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import { Card, CardBody, CardTitle, Col } from 'reactstrap';
import { MaterialIcon } from '../../../components';
import { useTranslation } from '../../../translations';
import { getConventionIdParam } from '../getConventionIdParam';

export const RegisterComplete: FC = () => {
  const { ts } = useTranslation();
  return (
    <Col lg={6} xs={12}>
      <Card>
        <CardBody className="text-center">
          <div id="regComplete">
            <MaterialIcon className="margin-bottom-10" large name="email" type="muted" />
            <CardTitle>{ts('confirm_your_email_address')}</CardTitle>
            <hr />
            <p>{ts('in_order_to_finalize_your')}</p>
            <p>{ts('please_check_your_email_in')}</p>
            <p>{ts('if_you_do_not_confirm')}</p>
            <p>
              <strong>IMPORTANT</strong> You should ensure that you check your Spam or Junk mail
              folders before contacting the registration team for help, as our e-mails may be
              wrongly marked as junk.
            </p>
            <hr />
            <Link
              className="btn btn-link btn-block"
              id="backToLogin"
              to={`/login${getConventionIdParam()}`}
            >
              {ts('go_back_to_login')}
            </Link>
          </div>
        </CardBody>
      </Card>
    </Col>
  );
};
