import { APIError } from '@conventioncatcorp/common-fe';
import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
  Badge,
  Button,
  Card,
  CardBody,
  CardText,
  CardTitle,
  Col,
  FormGroup,
  Input,
  ListGroup,
  ListGroupItem,
  Row,
} from 'reactstrap';
import { EmailTemplateInfo, EmailTemplateSchema } from '../../../../../shared/email';
import { ActionButtonModal, MaterialIcon } from '../../../../components';
import { useTranslation } from '../../../../translations';
import { Fetcher, useFetcher, useForm } from '../../../../utils';
import { debounce } from '../../../../utils/debounce';
import { DefaultTemplateCard, RenderContainer, TemplateOverrideCard } from './TemplateBodyEntry';

export const TemplateEditor: FC<RouteComponentProps<{ templateName: string }>> = ({ match }) => {
  const templateName = match.params.templateName;

  const [isModifying, setIsModifying] = useState(false);
  const [isCreating, setIsCreating] = useState(false);
  const [creationIsOrgWide, setCreationIsOrgWide] = useState<boolean | undefined>(undefined);

  const providedTemplate = useFetcher(async () => {
    return await api.getTemplate(templateName);
  }, [templateName]);

  if (!providedTemplate.complete) {
    return <Fetcher result={providedTemplate} />;
  }

  return (
    <Row>
      <Col lg={12}>
        <h1>{templateName}</h1>
        {providedTemplate.data && <p>{providedTemplate.data.description}</p>}
        <hr />
      </Col>
      {providedTemplate.data && (
        <>
          {isModifying && creationIsOrgWide !== undefined && (
            <ModifyTemplate
              close={() => setIsModifying(false)}
              data={providedTemplate.data}
              isCreating={isCreating}
              isOrgWide={creationIsOrgWide}
              refresh={providedTemplate.refresh}
            />
          )}
          {!isModifying && (
            <Col lg={12} xs={12}>
              <DefaultTemplateCard
                active={providedTemplate.data.active === 'default'}
                body={providedTemplate.data.defaultBody}
              />
              <TemplateOverrideCard
                active={providedTemplate.data.active === 'convention'}
                enableModify={() => {
                  setCreationIsOrgWide(false);
                  setIsModifying(true);
                }}
                level="convention"
                override={providedTemplate.data.conventionOverride}
                refresh={providedTemplate.refresh}
                setIsCreating={setIsCreating}
                templateName={providedTemplate.data.name}
              />
              <TemplateOverrideCard
                active={providedTemplate.data.active === 'organization'}
                enableModify={() => {
                  setCreationIsOrgWide(true);
                  setIsModifying(true);
                }}
                level="organization"
                override={providedTemplate.data.organizationOverride}
                refresh={providedTemplate.refresh}
                setIsCreating={setIsCreating}
                templateName={providedTemplate.data.name}
              />
            </Col>
          )}
        </>
      )}
    </Row>
  );
};

const ModifyTemplate: FC<{
  readonly isOrgWide: boolean;
  readonly data: EmailTemplateInfo;
  readonly isCreating: boolean;
  readonly close: () => void;
  readonly refresh: () => void;
}> = ({ data, isOrgWide, isCreating, close, refresh }) => {
  const { ts } = useTranslation();
  const [previewBody, setPreviewBody] = useState('');
  const [changePending, setChangePending] = useState(true);
  const inputBoxRef = useRef<HTMLInputElement>(null);
  const defaultValue = isCreating
    ? data.defaultBody.raw
    : isOrgWide
    ? data.organizationOverride!.body.raw
    : data.conventionOverride!.body.raw;

  const debounceBody = useMemo(
    () =>
      debounce((newBody: string) => {
        setPreviewBody(newBody);
      }, 1000),
    [],
  );

  const bodyFetch = useFetcher(async () => {
    if (previewBody.length === 0) {
      return;
    }

    const result = await api.previewTemplate(data.name, previewBody);
    setChangePending(false);
    return result;
  }, [previewBody]);

  const attemptRender = useCallback(() => {
    setChangePending(true);
    debounceBody(inputBoxRef.current?.value ?? '');
  }, [inputBoxRef.current?.value, debounceBody]);

  const form = useForm(async () => {
    await api.modifyOverride(data.name, previewBody, isOrgWide ? 'organization' : 'convention');

    toast.success(isCreating ? ts('new_override_has_been_created') : 'Override has been modified.');

    close();
    refresh();
  }, [isCreating, data, previewBody, isOrgWide]);

  useEffect(() => setPreviewBody(defaultValue), []);

  return (
    <>
      <Col lg={8} xs={12}>
        <Card className="margin-bottom-10">
          <CardBody>
            <CardTitle className="align-middle">
              {isCreating ? 'New' : 'Modifying'} {isOrgWide ? 'Organization' : 'Convention'} Level
              Override
              {bodyFetch.data && (
                <span className="align-middle float-right badge-list compact">
                  {bodyFetch.error instanceof APIError && (
                    <Badge color="danger">{ts('parse_error')}</Badge>
                  )}
                  {bodyFetch.data.variableErrors.map((variable) => {
                    return (
                      <Badge color="danger" key={variable}>
                        {ts('variable_is_invalid')}
                      </Badge>
                    );
                  })}
                </span>
              )}
              <div className="float-right">
                {isCreating && !data.required && (
                  <ActionButtonModal
                    actionContent="Disable"
                    buttonContent="Disable Template"
                    color="danger"
                    id="disable"
                    onComplete={async () => {
                      await api.modifyOverride(
                        data.name,
                        previewBody,
                        isOrgWide ? 'organization' : 'convention',
                        true,
                      );

                      toast.success(ts('new_override_has_been_created'));

                      close();
                      refresh();
                    }}
                    title="Disable email template?"
                  >
                    This action will prevent the email template {data.name} from being sent at all.
                  </ActionButtonModal>
                )}
              </div>
            </CardTitle>
            <form onSubmit={form.onSubmit}>
              <FormGroup>
                <Input
                  defaultValue={defaultValue}
                  id="body"
                  innerRef={inputBoxRef}
                  name="body"
                  onChange={attemptRender}
                  style={{ height: '250px' }}
                  type="textarea"
                />
              </FormGroup>
              <FormGroup>
                <div className="float-right">
                  <Button color="secondary" onClick={() => close()}>
                    {ts('cancel')}
                  </Button>{' '}
                  <Button
                    color="primary"
                    disabled={
                      form.saving ||
                      changePending ||
                      (bodyFetch.data &&
                        (bodyFetch.error instanceof APIError ||
                          bodyFetch.data.variableErrors.length !== 0))
                        ? true
                        : undefined
                    }
                    type="submit"
                  >
                    Submit
                  </Button>
                </div>
              </FormGroup>
            </form>
          </CardBody>
        </Card>
        <Card>
          <CardBody>
            <CardTitle>{ts('preview')}</CardTitle>
            <hr />
            {bodyFetch.data === undefined && <Fetcher result={bodyFetch} />}
            {bodyFetch.data && bodyFetch.error instanceof APIError && (
              <Card className="danger">
                <CardBody className="text-center">
                  <MaterialIcon large name="sync_problem" type="danger" />
                  <CardTitle>{ts('template_was_unable_to_be')}</CardTitle>
                  <CardText tag="div">
                    <p>{ts('a_parsing_error_occurred_view')}</p>
                    <hr />
                    <pre className="text-left">
                      {bodyFetch.error.apiResponse.errors.logic?.map((e) => e.args.internal)}
                    </pre>
                  </CardText>
                </CardBody>
              </Card>
            )}
            {bodyFetch.data && !bodyFetch.error && (
              <RenderContainer data={bodyFetch.data.preview} />
            )}
          </CardBody>
        </Card>
      </Col>
      <Col lg={4} xs={12}>
        <Card>
          <CardBody>
            <CardTitle>{ts('available_variables')}</CardTitle>
            <ListGroup flush>
              {data.schema.keys &&
                Object.keys(data.schema.keys).map((key) => {
                  const value = data.schema.keys![key];
                  switch (value.type) {
                    case 'object': {
                      return <ObjectVariable schema={value} variable={key} />;
                    }

                    case 'array': {
                      return <ArrayVariable sample={value.sample} schema={value} variable={key} />;
                    }

                    default: {
                      return <StaticVariable sample={value.sample!} variable={key} />;
                    }
                  }
                })}
            </ListGroup>
          </CardBody>
        </Card>
      </Col>
    </>
  );
};

const StaticVariable: FC<{
  readonly variable: string;
  readonly sample: boolean | number | string;
}> = ({ variable, sample }) => {
  let parsedSample: number | string;

  // if you directly insert a boolean value, react renders nothing
  if (typeof sample === 'boolean') {
    parsedSample = sample ? 'True' : 'False';
  } else {
    parsedSample = sample;
  }

  return (
    <ListGroupItem style={{ textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap' }}>
      {variable} <small className="text-muted">ex: {parsedSample}</small>
    </ListGroupItem>
  );
};

const ArrayVariable: FC<{
  readonly variable: string;
  readonly schema: EmailTemplateSchema;
  readonly sample?: boolean | number | string;
}> = ({ variable, schema, sample }) => {
  if (schema.itemType === 'object') {
    return (
      <ListGroupItem style={{ textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap' }}>
        <h5>
          {variable}: <small className="text-muted">Complex List</small>
          <MaterialIcon name="keyboard_arrow_down" />
        </h5>
        {Object.keys(schema.keys!).map((key) => {
          const value = schema.keys![key];
          switch (value.type) {
            case 'object': {
              return <ObjectVariable schema={value} variable={key} />;
            }

            case 'array': {
              return <ArrayVariable schema={value} variable={key} />;
            }

            default: {
              return <StaticVariable sample={value.sample!} variable={key} />;
            }
          }
        })}
      </ListGroupItem>
    );
  }

  return (
    <ListGroupItem>
      <span style={{ textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
        {variable}: <small className="text-muted">list, ex: {sample}</small>
      </span>
    </ListGroupItem>
  );
};

const ObjectVariable: FC<{ readonly variable: string; readonly schema: EmailTemplateSchema }> = ({
  variable,
  schema,
}) => {
  return (
    <ListGroupItem>
      <h5>
        {variable} <MaterialIcon name="keyboard_arrow_down" />
      </h5>
      {Object.keys(schema.keys!).map((key) => {
        const value = schema.keys![key];
        switch (value.type) {
          case 'object': {
            return <ObjectVariable schema={value} variable={key} />;
          }

          case 'array': {
            return <ArrayVariable sample={value.sample} schema={value} variable={key} />;
          }

          default: {
            return <StaticVariable sample={value.sample!} variable={key} />;
          }
        }
      })}
    </ListGroupItem>
  );
};
