import { JSONForm } from '@conventioncatcorp/common-fe/dist/components/json-form/JSONForm';
import React, { FC, useCallback, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Button, Card, CardBody, Col, FormGroup, Input, Label } from 'reactstrap';
import { ActionType } from '../../services';
import { useTranslation } from '../../translations';
import { classNames, isAuthError, useBoolState } from '../../utils';
import { AuthErrorCodes } from '../../utils/errorHandling';
import { getConventionIdParam } from './getConventionIdParam';
import { SecondFactorPrompt } from './secondFactor/SecondFactorPrompt';

interface Props {
  readonly showRegister: boolean;
  onForgotPassword(): void;
}

export const LoginForm: FC<Props> = ({ onForgotPassword, showRegister }) => {
  const { ts } = useTranslation();
  const [showSecondFactor, enabledShowSecondFactor, disableShowSecondFactor] = useBoolState(false);
  const submitRef = useRef<HTMLButtonElement>(null);

  const dispatch = useDispatch();
  const history = useHistory();

  const onSuccess = useCallback(async () => {
    const user = await api.getActiveUser();

    dispatch({ type: ActionType.LoginSuccess, user });
  }, []);

  const onFailure = useCallback((err: Error) => {
    if (!isAuthError(err, AuthErrorCodes.SecondFactorMissing)) {
      return false;
    }

    enabledShowSecondFactor();
    return true;
  }, []);

  const submitSecondFactor = useCallback(() => {
    submitRef.current!.click();
  }, []);

  const onClickRegister = useCallback(() => {
    history.push(`/register${getConventionIdParam()}`);
  }, []);

  const onClickResetCode = useCallback(() => {
    history.push('/login/reset');
  }, []);

  return (
    <Col id="loginForm" lg={3} md={6} xs={12}>
      <Card>
        <CardBody>
          <h4>{ts('login')}</h4>
          <hr />
          <JSONForm method="post" onFailure={onFailure} onSuccess={onSuccess} path="/api/login">
            <FormGroup>
              <Label for="loginusernameEmail">{ts('email_username')}</Label>
              <Input
                autoComplete="email"
                id="usernameOrMail"
                name="usernameOrMail"
                placeholder="example@concat.app"
                required
              />
            </FormGroup>
            <FormGroup>
              <Label for="password">{ts('password')}</Label>
              <Input
                autoComplete="current-password"
                id="password"
                name="password"
                placeholder={ts('password')}
                required
                type="password"
              />
            </FormGroup>
            {showSecondFactor && (
              <SecondFactorPrompt
                onCancel={disableShowSecondFactor}
                onSubmit={submitSecondFactor}
              />
            )}
            <div className={classNames({ 'd-none': showSecondFactor })}>
              <FormGroup>
                <Button
                  className="btn-block"
                  color="primary"
                  id="login"
                  innerRef={submitRef}
                  type="submit"
                >
                  {ts('login')}
                </Button>
              </FormGroup>
              {showRegister && (
                <FormGroup>
                  <Button block color="secondary" id="register" onClick={onClickRegister} outline>
                    {ts('register')}
                  </Button>
                </FormGroup>
              )}
              <FormGroup>
                <Button block color="link" id="forgotPassword" onClick={onForgotPassword}>
                  {ts('forgot_password')}
                </Button>
                <Button block color="link" id="resetPasswordWithCode" onClick={onClickResetCode}>
                  {ts('already_got_a_reset_code')}
                </Button>
              </FormGroup>
            </div>
          </JSONForm>
        </CardBody>
      </Card>
    </Col>
  );
};
