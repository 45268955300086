import React, { FC, useCallback, useContext, useEffect, useState } from 'react';
import { Link, useHistory, useLocation, useRouteMatch } from 'react-router-dom';
import { Badge, Card, CardBody, CardText, CardTitle, Col, Row } from 'reactstrap';
import { CashierBadgeData } from '../../../shared/user/cashier';
import {
  ChildRegistrations,
  DateT,
  ElementHeader,
  LinkButton,
  Loading,
  MaterialIcon,
  UserStateComponent,
} from '../../components';
import { BadgePreviewRenderer, transformNodeMap } from '../../modules/badgerenderer/BadgeRender';
import { useTranslation } from '../../translations';
import {
  checkPermission,
  shouldDisableHotkeys,
  useConvention,
  useFetcher,
  useUser,
} from '../../utils';
import { LoadingState } from '../../utils/LoadingState';
import { AttendeeInformation, AttendeeInformationModalState } from './attendeeInformation';
import { AttendeeStatus, UndeliveredProductsList } from './attendeeStatus';
import { CashierContext } from './cashiercontext';

import './profile.scss';

export const CashierProfile: React.FC = () => {
  const { ts } = useTranslation();
  const match = useRouteMatch<{ id: string }>();
  const cashierSettings = useContext(CashierContext);
  const location = useLocation();
  const history = useHistory();
  const convention = useConvention();
  const id = Number.parseInt(match.params.id, 10);
  const params = new URLSearchParams(location.search);
  const barcode = params.get('barcode');
  const [editModalState, setEditModalState] = useState<AttendeeInformationModalState>(null);
  const cashier = useUser();

  useEffect(() => {
    // If a modal is open, disable the ESC hotkey
    cashierSettings.updateCashierSettings!({
      enableESCHotkey: editModalState === null,
    });
  }, [editModalState]);

  useEffect(() => {
    const listener = (event: KeyboardEvent) => {
      if (
        event.key === 'Escape' &&
        cashierSettings.config.enableESCHotkey &&
        !shouldDisableHotkeys()
      ) {
        history.push('/housekeeping/cashier');
      }
    };

    window.addEventListener('keydown', listener);

    return () => {
      window.removeEventListener('keydown', listener);
    };
  }, [history, cashierSettings.config.enableESCHotkey]);

  const fetch = useFetcher(async () => {
    const profile = await api.getUserCashierProfile(id, barcode ?? undefined);
    const children = profile.registration
      ? await api.getChildRegistration(profile.registration.id)
      : [];

    const hasBarcodeNotBeenScanned = profile.info.includes('No id barcode has been scanned.');
    if (cashierSettings.config.requireIDScan && hasBarcodeNotBeenScanned) {
      profile.info = profile.info.filter((i) => i !== 'No id barcode has been scanned.');
      profile.errors.push('No id barcode has been scanned, but is required by event policy.');
      profile.status = 'error';
    }

    return { profile, children };
  }, [id, barcode, cashierSettings.config.requireIDScan]);

  const openUpdateFromScannerModal = useCallback(() => {
    const scannedInformation = fetch.data?.profile.scannedInformation;
    if (!scannedInformation) {
      return;
    }

    setEditModalState({ type: 'user', scannedInformation });
  }, [fetch.data?.profile.scannedInformation]);

  if (!fetch.data && fetch.state === LoadingState.Loading) {
    return (
      <UserStateComponent>
        <Loading inline />
      </UserStateComponent>
    );
  }

  if (fetch.state === LoadingState.Error) {
    return <ProfileError />;
  }

  const { refresh, data } = fetch;
  const { children, profile } = data!;
  const { bannedWords, user, isMinor, registration, badge } = profile;

  return (
    <UserStateComponent>
      <ElementHeader
        actions={[
          {
            id: 'view-profile',
            text: 'View user profile',
            href: `/housekeeping/attendees/user/${user.id}`,
          },
          { id: 'divider-1', divider: true },
          {
            id: 'manage-registration',
            text: `${registration ? 'Update' : 'Create'} registration`,
            onClick: () => setEditModalState({ type: 'reg' }),
          },
          { id: 'divider-2', divider: true },
          {
            id: 'update-personal-info',
            text: 'Update personal details',
            onClick: () => setEditModalState({ type: 'user' }),
            disabled: !checkPermission(cashier!, 'user:update'),
          },
        ]}
        icon={{ url: user.profilePictureUrl }}
        rightContent={
          <div className="cashier-profile-back">
            <Link className="btn btn-outline-secondary" to="/housekeeping/cashier">
              {ts('back_to_user_search')}
            </Link>
            <small>
              or press <strong>ESC</strong> to go back.
            </small>
          </div>
        }
        title={
          <div className="cashier-profile-header-userinfo">
            <div className="cashier-profile-header-userinfo-section">
              <div>#{user.id}</div>
              <div>{ts('id')}</div>
            </div>
            {user.preferredName && (
              <div className="cashier-profile-header-userinfo-section">
                <div>({user.preferredName})</div>
                <div>Preferred</div>
              </div>
            )}
            <div className="cashier-profile-header-userinfo-section">
              <div>{user.firstName}</div>
              <div>First name</div>
            </div>
            <div className="cashier-profile-header-userinfo-section">
              <div>{user.lastName}</div>
              <div>Last name</div>
            </div>
            <div className="cashier-profile-header-userinfo-section">
              <div>
                {user.bornAt ? (
                  <DateT options={{ timeZone: 'UTC' }} value={user.bornAt} />
                ) : (
                  'Unknown'
                )}
              </div>
              <div>{ts('date_of_birth')}</div>
            </div>
          </div>
        }
      >
        <span className="badge-list compact">
          {profile.info.some((s) => s.startsWith('Returning attendee')) && (
            <Badge className="returning-attendee" color="info">
              {ts('returning_attendee')}
            </Badge>
          )}
        </span>
      </ElementHeader>
      <AttendeeInformation
        bannedWords={bannedWords}
        isMinor={isMinor}
        modalState={editModalState}
        onModalClose={() => setEditModalState(null)}
        registration={registration}
        reload={refresh}
        user={user}
      />
      <Row className="justify-content-center" id="dashboard">
        <Col className="margin-bottom-10" md={6} xs={12}>
          <AttendeeStatus
            openUpdateFromScannerModal={openUpdateFromScannerModal}
            profile={profile}
            reload={refresh}
          />
          <UndeliveredProductsList
            products={profile.undeliveredProducts}
            refresh={refresh}
            userId={profile.user.id}
          />
        </Col>
        <Col className="cashier-badge-preview margin-bottom-10" md={6} xs={12}>
          <h4>Badge Print Preview</h4>
          <Card>
            <CardBody>
              <BadgePreview badge={badge} badgeArtId={registration?.badgeArtId ?? undefined} />
            </CardBody>
          </Card>
          <div className="margin-top-10">
            <div className="cashier-badge-helptext">
              <MaterialIcon name="info" small />
              <div>{ts('actual_badge_print_and_label')}</div>
            </div>
            <div className="cashier-badge-helptext">
              <MaterialIcon name="info" small /> Badge labels are printed with black text.&nbsp;
              Emojis will be black and white.
            </div>
          </div>

          {registration && convention.maxChildren > 0 && (
            <div className="margin-top-10">
              <CardTitle>Child Badges</CardTitle>
              <ChildRegistrations
                compact
                hideWarning
                items={children}
                onUpdate={refresh}
                registrationId={registration.id}
                useModal
              />
            </div>
          )}
        </Col>
        <Col md={6} xs={12} />
      </Row>
    </UserStateComponent>
  );
};

const ProfileError: FC = () => {
  const { ts } = useTranslation();
  return (
    <UserStateComponent>
      <Row className="justify-content-center" id="profileNotFound">
        <Col lg={6} xs={12}>
          <Card className="danger">
            <CardBody className="text-center">
              <MaterialIcon large name="error" type="danger" />
              <CardText>{ts('the_specified_user_does_not')}</CardText>
            </CardBody>
          </Card>
        </Col>
        <Col className="margin-bottom-10" xs={12} />
        <Col lg={6} xs={12}>
          <LinkButton block color="primary" to="/housekeeping/cashier">
            {ts('back_to_search')}
          </LinkButton>
        </Col>
      </Row>
    </UserStateComponent>
  );
};

interface BadgePreviewProps {
  readonly badgeArtId?: number;
  readonly badge?: CashierBadgeData;
}

const BadgePreview: FC<BadgePreviewProps> = ({ badge, badgeArtId }) => {
  const { ts } = useTranslation();
  if (!badge?.labels[0]) {
    return (
      <div className="cashier-badge-empty-state" id="noBadgePreview">
        <h4>{ts('no_badge_preview_available')}</h4>
        <p>{ts('ensure_the_user_is_registered')}</p>
      </div>
    );
  }

  return (
    <BadgePreviewRenderer
      image={badgeArtId ? `/api/badgeart/${badgeArtId}/image` : ''}
      label={badge.labels[0]}
      nodes={transformNodeMap(badge.design)}
    />
  );
};
