import {
  JSONForm,
  PreSubmitData,
} from '@conventioncatcorp/common-fe/dist/components/json-form/JSONForm';
import React, { FC, useCallback, useState } from 'react';
import { Button, FormGroup, Input, Label } from 'reactstrap';
import { UserSelector } from '../../../components/UserSelector';
import { User } from '../../../models';
import { useTranslation } from '../../../translations';

interface CreateFeePost {
  amount: number;
  reason: string;
  userId: number;
}

const OuterUserSelector: FC<{
  readonly user?: User;
  readonly selectionChanged: (userIds: number[]) => void;
}> = ({ user, selectionChanged }) => {
  if (user) {
    const formattedName = user.preferredName
      ? `${user.preferredName} (${user.firstName} ${user.lastName})`
      : `${user.firstName} ${user.lastName}`;

    const key = `namelike-${user.firstName}-${user.lastName}-${user.preferredName ?? ''}`;

    return <p key={key}>{formattedName}</p>;
  }

  return <UserSelector id="feeRecipients" maxItems={1} selectionIdsChanged={selectionChanged} />;
};

/**
 * A component that will let you add a ban.
 * Include the userId if you want it to act more like a quick button (Say, on a user's profile page)
 */
// TODO: Rewrite to use <form> and useForm
export const CreateFee: FC<{ readonly user?: User; onSuccess(): void }> = ({ user, onSuccess }) => {
  const { ts } = useTranslation();
  const [userId, setUserId] = useState(user?.id);

  const updatePreSubmit = useCallback(({ inputs }: PreSubmitData<CreateFeePost>) => {
    if (inputs) {
      inputs.amount *= 100;
    }
  }, []);

  return (
    <JSONForm<{}, CreateFeePost>
      id="addFee"
      method="post"
      onSuccess={onSuccess}
      path="/api/fees"
      preSubmit={(opts) => {
        updatePreSubmit(opts);
      }}
    >
      <FormGroup>
        <Label for="recipients">User</Label>
        <Input name="userId" type="hidden" value={userId} />
        <OuterUserSelector selectionChanged={(ids) => setUserId(ids[0])} user={user} />
      </FormGroup>
      <FormGroup>
        <Label for="type">{ts('amount')}</Label>
        <div className="input-group">
          <div className="input-group-prepend">
            <div className="input-group-text">$</div>
          </div>
          <Input id="amount" min="0" name="amount" step="0.01" type="number" />
        </div>
      </FormGroup>
      <FormGroup>
        <Label for="reason">{ts('reason_for_fee')}</Label>
        <Input id="reason" name="reason" style={{ minHeight: '10px' }} type="textarea" />
      </FormGroup>
      <FormGroup>
        <Button block color="primary" id="addFeeBtn" type="submit">
          {ts('create_fee')}
        </Button>
      </FormGroup>
    </JSONForm>
  );
};
