import React, { FC, useCallback, useState } from 'react';
import { Col, Row } from 'reactstrap';
import { ProductModel } from '../../../shared/orders';
import { ConRegistrationForm } from '../../../shared/registration/model';
import { CurrentUser } from '../../../shared/user/base';
import { RegTypeSelectList } from '../../components';
import { RegistrationInfo } from '../../models';
import { RegistrationForm } from '../../modules/registration/RegistrationForm';
import { Fetcher, isResourceError, useFetcher } from '../../utils';
import { captureError } from '../../utils/errorHandling';

interface KioskRegFormProps {
  readonly userId: number;
  onRegister(regId: number, currentAttendanceType: number, hasChildren: boolean): void;
}

export const KioskRegForm: FC<KioskRegFormProps> = ({ userId, onRegister }) => {
  const regStatus = useFetcher(async () => {
    let activeReg: RegistrationInfo | undefined;

    try {
      activeReg = await api.getUserActiveRegistration(userId);
    } catch (error) {
      if (!isResourceError(error, 'Registration')) {
        captureError(error as Error);
      }
    }

    const [form, user] = await Promise.all([api.getRegistrationForm(), api.getActiveUser()]);

    return { activeReg, registrationTypes: form.products, form, user };
  }, [userId]);

  if (!regStatus.complete) {
    return <Fetcher result={regStatus} />;
  }

  const { registrationTypes, activeReg, form, user } = regStatus.data!;

  let defaultAttedance = registrationTypes[0];

  if (activeReg) {
    const activeRegProduct = registrationTypes.find((t) => t.id === activeReg.attendanceTypeId);
    if (activeRegProduct) {
      defaultAttedance = activeRegProduct;
    }
  }

  return (
    <KioskRegFormInner
      attendanceType={defaultAttedance}
      form={form}
      onRegister={onRegister}
      registration={activeReg}
      registrationTypes={registrationTypes}
      user={user}
    />
  );
};

interface KioskRegFormInnerProps {
  readonly attendanceType: ProductModel;
  readonly registrationTypes: ProductModel[];
  readonly registration?: RegistrationInfo;
  readonly user: CurrentUser;
  readonly form: ConRegistrationForm;
  onRegister(regId: number, currentAttendanceType: number, hasChildren: boolean): void;
}

const KioskRegFormInner: FC<KioskRegFormInnerProps> = ({
  registration: conreg,
  registrationTypes,
  attendanceType,
  user,
  form,
  onRegister,
}) => {
  const [currentAttendanceType, setCurrentAttendanceType] = useState<ProductModel>(attendanceType);

  const onSuccess = useCallback(
    async (hasChildren?: boolean) => {
      if (conreg) {
        onRegister(conreg.id, currentAttendanceType.id, !!hasChildren);
        return;
      }

      try {
        const { id } = await api.getUserActiveRegistration(user.id);
        onRegister(id, currentAttendanceType.id, !!hasChildren);
      } catch (error) {
        captureError(error as Error);
      }
    },
    [conreg, user.id, currentAttendanceType],
  );

  return (
    <Row id="registrationForm">
      <RegTypeSelectList
        attendanceType={currentAttendanceType}
        attendanceTypes={registrationTypes}
        onSelect={(pid) => {
          setCurrentAttendanceType(registrationTypes.find((at) => at.id === pid)!);
        }}
      />
      <Col xs={12} />
      <InnerKioskRegistration
        attendanceType={currentAttendanceType}
        form={form}
        onSuccess={onSuccess}
        registration={conreg}
        user={user}
      />
    </Row>
  );
};

interface InnerKioskRegistrationProps {
  readonly form: ConRegistrationForm;
  readonly registration?: RegistrationInfo;
  readonly user: CurrentUser;
  readonly attendanceType: ProductModel;
  onSuccess(hasChildren?: boolean): void;
}

const InnerKioskRegistration: FC<InnerKioskRegistrationProps> = ({
  form,
  registration,
  user,
  attendanceType,
  onSuccess,
}) => {
  return (
    <RegistrationForm
      askForChildren
      attendanceType={attendanceType}
      form={form}
      hasChildren={false}
      onSuccess={onSuccess}
      registration={registration}
      user={user}
    />
  );
};
