import React, { Dispatch, FC, SetStateAction, useCallback } from 'react';
import {
  Button,
  Col,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Table,
} from 'reactstrap';
import { OAuthApplicationModel } from '../../../../../shared/oauth';
import { PermissionName } from '../../../../../shared/permissions';
import { useTranslation } from '../../../../translations';
import { toggleElementInArray, useInputModel, useInputState } from '../../../../utils';

interface WebhookModalProps {
  readonly open: boolean;
  readonly permissions: PermissionName[];
  readonly app: OAuthApplicationModel;
  readonly onClose: (cancelled: boolean) => void;
  readonly setApp: Dispatch<SetStateAction<OAuthApplicationModel>>;
}

export const OAuthManageModal: FC<WebhookModalProps> = ({
  open,
  permissions,
  onClose,
  app,
  setApp,
}) => {
  const { ts } = useTranslation();
  const setName = useInputModel(setApp, 'name');
  const setDescription = useInputModel(setApp, 'description');

  const onChangePermissions = useCallback<React.ChangeEventHandler<HTMLInputElement>>(
    (e) => {
      const children = e.target.querySelectorAll('option');
      const values: PermissionName[] = [];

      // querySelectorAll returns an NodeListOf, which is not an array
      // eslint-disable-next-line unicorn/no-array-for-each
      children.forEach((child) => {
        if (child.selected) {
          values.push(child.value as PermissionName);
        }
      });

      setApp((old) => ({ ...old, applicationPermissions: values }));
    },
    [setApp],
  );

  return (
    <Modal className="modal-large" id="oauthManageModal" isOpen={open}>
      <ModalHeader>Create OAuth Application</ModalHeader>
      <ModalBody>
        <FormGroup row>
          <Label for="name" md={4} sm={12}>
            Name
          </Label>
          <Col md={8} sm={12}>
            <Input id="name" onChange={setName} value={app.name} />
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label for="description" md={4} sm={12}>
            Description
          </Label>
          <Col md={8} sm={12}>
            <Input id="description" onChange={setDescription} value={app.description} />
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label for="url" md={4} sm={12}>
            {ts('callback_uris')}
          </Label>
          <Col md={8} sm={12}>
            <RedirectUris
              list={app.redirectUris}
              setList={(redirectUris) => setApp({ ...app, redirectUris })}
            />
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label for="url" md={4} sm={12}>
            {ts('application_permissions')}
            <br />
            <small>{ts('allowed_by_client_credentials_oauth')}</small>
          </Label>
          <Col md={8} sm={12}>
            <Input id="permissionSelect" multiple onChange={onChangePermissions} type="select">
              {permissions.map((t) => (
                <option key={t} selected={app.applicationPermissions.includes(t)} value={t}>
                  {t}
                </option>
              ))}
            </Input>
          </Col>
        </FormGroup>
      </ModalBody>
      <ModalFooter>
        <Button color="primary" id="completeModal" onClick={() => onClose(false)} type="submit">
          {ts('save')}
        </Button>
        <Button color="secondary" onClick={() => onClose(true)}>
          {ts('cancel')}
        </Button>
      </ModalFooter>
    </Modal>
  );
};

interface RedirectUrlsProps {
  readonly list: string[];
  setList(list: string[]): void;
}

const RedirectUris: FC<RedirectUrlsProps> = ({ list, setList }) => {
  const [newEntry, setNewEntry, resetInput] = useInputState('');

  return (
    <Table>
      <tbody>
        {list.map((item) => {
          return (
            <tr key={item}>
              <td>{item}</td>
              <td>
                <Button
                  color="danger"
                  id="deleteUri"
                  onClick={() => setList(toggleElementInArray(list, item))}
                >
                  -
                </Button>
              </td>
            </tr>
          );
        })}
        <tr>
          <td>
            <Input name="newUri" onChange={setNewEntry} value={newEntry} />
          </td>
          <td>
            <Button
              color="success"
              disabled={!newEntry}
              id="addNewUri"
              onClick={() => {
                setList([...list, newEntry]);
                resetInput('');
              }}
            >
              +
            </Button>
          </td>
        </tr>
      </tbody>
    </Table>
  );
};
