import React, { FC } from 'react';
import { FormGroup, Label } from 'reactstrap';
import { FormOAuthModel } from '../../../../shared/kiosk';
import { NeosVRLoginButton } from '../../../containers/account/sociallink/neosvr';
import { LazyMarkdown } from '../../LazyMarkdown';

export const FormOAuthLogin: FC<FormOAuthModel> = ({ id, target, markdown }) => {
  return (
    <div className="form-node form-node-oauth-social-link" id={`node-${id}`}>
      <FormGroup>
        <Label>
          <LazyMarkdown source={markdown} />
        </Label>
        <NeosVRLoginButton target={target} />
      </FormGroup>
    </div>
  );
};
