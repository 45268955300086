import {
  JSONForm,
  PreSubmitData,
} from '@conventioncatcorp/common-fe/dist/components/json-form/JSONForm';
import React, { FC, useCallback, useState } from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
  Button,
  ButtonGroup,
  Card,
  CardBody,
  Col,
  FormGroup,
  FormText,
  Input,
  Label,
  Row,
} from 'reactstrap';
import {
  DealerApplication,
  DealerArea,
  DealerAssistance,
  DealerForm,
  DealerFullModel,
  SeatingPreference,
} from '../../../../shared/dealer';
import { OptionDataPayload } from '../../../../shared/options';
import { MaterialIcon, PageHeader, PermissionBoundary } from '../../../components';
import { useTranslation } from '../../../translations';
import { renderName, useBoolState } from '../../../utils';
import { LoadingWrapper } from '../../../utils/LoadingWrapper';
import { VendorSeatingRequests } from '../../apply/dealer/VendorSeating';
import { DealerApplicationForm } from './applicationDetails';
import { DealerEditForm } from './dealerEdit';

interface LoadingData {
  dealer: DealerFullModel;
  assistants: DealerAssistance[];
  form: DealerForm;
  areas: DealerArea[];
  seating: SeatingPreference[];
}

async function fetchData(dealerId: number): Promise<LoadingData> {
  const [dealer, assistants, form, areas, seating] = await Promise.all([
    api.getDealer(dealerId),
    api.getDealerAssistants(dealerId),
    api.getDealerForm(),
    api.getDealerAreas(),
    api.getDealerSeatingPreferences(dealerId),
  ]);

  return {
    assistants,
    dealer,
    form,
    areas,
    seating,
  };
}

export const DealerDetails: FC<RouteComponentProps<{ id: string }>> = ({ match }) => {
  const { ts } = useTranslation();
  const id = Number.parseInt(match.params.id, 10);

  return (
    <LoadingWrapper dataFetcher={fetchData} passback={id}>
      {({ assistants, dealer, form, areas, seating }, refresh) => (
        <>
          <PageHeader>{dealer.name}</PageHeader>
          <Row>
            <Col md={6} xs={12}>
              <Card className="margin-bottom-10">
                <CardBody>
                  <DealerDetailsBody dealer={dealer} form={form} refresh={refresh} />
                </CardBody>
              </Card>
              <Card className="margin-bottom-10">
                <CardBody>
                  <h3>{ts('assistants')}</h3>
                  <div>
                    <DealerAssistantsDetails
                      assistants={assistants}
                      dealer={dealer}
                      refresh={refresh}
                    />
                  </div>
                </CardBody>
              </Card>
              <VendorSeatingRequests
                dealer={dealer}
                reload={refresh}
                seatingPreferences={seating}
              />
            </Col>
            <Col className="margin-bottom-10" md={6} xs={12}>
              {dealer.applications.map((application) => {
                return (
                  <Card
                    className="margin-bottom-10"
                    id={`application-${application.id}`}
                    key={application.id}
                  >
                    <CardBody>
                      <DealerApplicationDetails
                        application={application}
                        area={application.dealerArea}
                        dealer={dealer}
                        refresh={refresh}
                      />
                    </CardBody>
                  </Card>
                );
              })}
              {areas
                .filter((t) => !dealer.applications.some((u) => u.areaId === t.id))
                .map((area) => {
                  return (
                    <DealerAreaCreate area={area} dealer={dealer} key={area.id} refresh={refresh} />
                  );
                })}
            </Col>
          </Row>
        </>
      )}
    </LoadingWrapper>
  );
};

interface DealerDetailsBodyProps {
  readonly dealer: DealerFullModel;
  readonly form: DealerForm;
  readonly refresh: () => void;
}

const DealerDetailsBody: FC<DealerDetailsBodyProps> = ({ dealer, form, refresh }) => {
  const [edit, enableEdit, disableEdit] = useBoolState(false);
  const [options, setOptions] = useState<OptionDataPayload>(dealer.options);

  const { ts } = useTranslation();
  const onSuccess = useCallback(() => {
    toast.success(ts('the_dealer_has_been_updated'));
    refresh();
    disableEdit();
  }, [refresh, disableEdit]);

  const startEdit = useCallback(() => {
    setOptions(dealer.options);
    enableEdit();
  }, [dealer]);

  const preSubmit = useCallback(
    (reqOpts: PreSubmitData<{ options: OptionDataPayload }>) => {
      reqOpts.inputs!.options = options;
    },
    [options],
  );

  return (
    <Row>
      <Col xs={12}>
        <JSONForm<undefined, { options: OptionDataPayload }>
          method="post"
          onSuccess={onSuccess}
          path={`/api/users/${dealer.user.id}/dealer`}
          preSubmit={preSubmit}
        >
          <span style={{ float: 'right' }}>
            {!edit && (
              <ButtonGroup className="margin-bottom-10">
                <Button onClick={startEdit}>
                  <MaterialIcon id="editDealer" name="edit" />
                </Button>
              </ButtonGroup>
            )}
            {edit && (
              <ButtonGroup className="margin-bottom-10">
                <Button color="primary" id="submitDealer" type="submit">
                  Save
                </Button>
                <Button onClick={disableEdit}>{ts('cancel')}</Button>
              </ButtonGroup>
            )}
          </span>
          <h3>{ts('dealer_information')}</h3>
          <DealerEditForm
            dealer={dealer}
            edit={edit}
            form={form}
            options={options}
            setOptions={setOptions}
          />
        </JSONForm>
      </Col>
    </Row>
  );
};

interface DealerAssistantsDetailsProps {
  readonly dealer: DealerFullModel;
  readonly assistants: DealerAssistance[];
  readonly refresh: () => void;
}

const DealerAssistantsDetails: FC<DealerAssistantsDetailsProps> = ({
  dealer,
  assistants,
  refresh,
}) => {
  const { ts } = useTranslation();
  const assistantRemoved = useCallback(() => {
    refresh();
    toast.success(ts('assistant_removed'));
  }, [refresh]);

  const assistantAdded = useCallback(() => {
    refresh();
    toast.success('Assistant added.');
  }, [refresh]);

  return (
    <>
      {assistants.length === 0 ? <p className="text-muted">{ts('no_assistants')}</p> : null}
      {assistants.map((assistant, index) => (
        <Col
          className="clearfix-after"
          id={`assistant${assistant.userId}`}
          key={assistant.userId}
          xs={12}
        >
          <div className="float-right">
            <PermissionBoundary inline requiredPermissions={['vendor:update']}>
              <JSONForm
                method="delete"
                onSuccess={assistantRemoved}
                path={`/api/dealers/${dealer.id}/assistants/${assistant.userId}`}
              >
                <Button className="action-delete" color="danger" type="submit">
                  <MaterialIcon name="delete" />
                </Button>
              </JSONForm>
            </PermissionBoundary>
          </div>
          <h4>
            <Link to={`/housekeeping/attendees/user/${assistant.userId}`}>
              {renderName(assistant.user)}
            </Link>
          </h4>
          <div>{assistant.accepted ? 'Accepted' : 'Pending Email Confirmation.'}</div>
          {assistants.length - 1 !== index && <hr />}
        </Col>
      ))}
      <PermissionBoundary inline requiredPermissions={['vendor:update']}>
        <hr />
        <JSONForm
          method="post"
          onSuccess={assistantAdded}
          path={`/api/dealers/${dealer.id}/assistants`}
        >
          <FormGroup>
            <Label for="username">{ts('add_assistant')}</Label>
            <Input id="username" name="username" placeholder="Assistant Username" />
            <FormText color="muted">
              Your assistant can find their username at the top of the left side bar once they've
              logged in.
            </FormText>
          </FormGroup>
          <FormGroup>
            <Button block color="primary" id="submitForm" type="submit">
              {ts('add_assistant')}
            </Button>
          </FormGroup>
        </JSONForm>
      </PermissionBoundary>
    </>
  );
};

interface DealerApplicationDetailsProps {
  readonly dealer: DealerFullModel;
  readonly application?: DealerApplication;
  readonly area: { id: number; categoryId: number; name: string };
  readonly refresh: () => void;
}

const DealerApplicationDetails: FC<DealerApplicationDetailsProps> = ({
  area,
  dealer,
  application,
  refresh,
}) => {
  const [edit, enableEdit, disableEdit] = useBoolState(false);

  const onSubmit = useCallback(() => {
    toast.success('The application has been updated.');
    refresh();
    disableEdit();
  }, [refresh, disableEdit]);

  return (
    <LoadingWrapper dataFetcher={async () => await api.getFullProductsByCategory(area.categoryId)}>
      {(tableTypes) => (
        <Row>
          <Col xs={12}>
            {!edit && (
              <span style={{ float: 'right' }}>
                <ButtonGroup className="margin-bottom-10">
                  <Button onClick={enableEdit}>
                    <MaterialIcon id={`editArea${area.id}`} name="edit" />
                  </Button>
                </ButtonGroup>
              </span>
            )}
            <DealerApplicationForm
              application={application}
              area={area}
              cancel={disableEdit}
              dealer={dealer}
              edit={edit}
              onSuccess={onSubmit}
              tableTypes={tableTypes}
            />
          </Col>
        </Row>
      )}
    </LoadingWrapper>
  );
};

interface DealerAreaCreateProps {
  readonly dealer: DealerFullModel;
  readonly area: DealerArea;
  refresh(): void;
}

const DealerAreaCreate: FC<DealerAreaCreateProps> = ({ area, dealer, refresh }) => {
  const [edit, enableEdit, disableEdit] = useBoolState(false);

  const onSubmit = useCallback(() => {
    toast.success('The application has been created.');
    refresh();
    disableEdit();
  }, [refresh, disableEdit]);

  return (
    <Card className="margin-bottom-10" id={`area-${area.id}`}>
      <CardBody>
        <Row>
          <Col xs={12}>
            <span style={{ float: 'right' }}>
              {!edit && (
                <ButtonGroup className="margin-bottom-10">
                  <Button onClick={enableEdit}>
                    <MaterialIcon id={`createArea${area.id}`} name="add" />
                  </Button>
                </ButtonGroup>
              )}
            </span>
            {!edit && (
              <>
                <h3>{area.name} application</h3>
                No application.
              </>
            )}
            {edit && (
              <LoadingWrapper
                dataFetcher={async () => await api.getFullProductsByCategory(area.categoryId)}
              >
                {(tableTypes) => (
                  <DealerApplicationForm
                    area={area}
                    cancel={disableEdit}
                    dealer={dealer}
                    edit
                    onSuccess={onSubmit}
                    tableTypes={tableTypes}
                  />
                )}
              </LoadingWrapper>
            )}
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
};
