import React, { Dispatch, FC, SetStateAction, useEffect, useState } from 'react';
import { Col, FormGroup, FormText, Input, Label, Row } from 'reactstrap';
import {
  BanType,
  banTypes,
  noteCategories,
  NoteCategory,
  UserNoteBan,
  UserNoteCreate,
} from '../../../shared/user/notes';
import { IconColor } from '../../components';
import { UserSelector } from '../../components/UserSelector';
import { useTranslation } from '../../translations';
import { capitalize, useFetcher, useInputModel, useObject } from '../../utils';

export const noteIcons: Record<
  NoteCategory,
  { color: IconColor; icon: string; description?: string }
> = {
  commendation: { color: 'success', icon: 'thumb_up' },
  feedback: { color: 'info', icon: 'comment' },
  misc: { color: 'info', icon: 'info' },
  reprimand: { color: 'danger', icon: 'thumb_down' },
  request: { color: 'info', icon: 'assignment' },
  restriction: {
    color: 'danger',
    icon: 'block',
    description:
      'User is shadow-banned. The user will not known, but a prominant message will be visible on housekeeping.',
  },
  ban: {
    color: 'danger',
    icon: 'block',
    description:
      'User will be restricted from performing actions. A generic message will be shown to the user that they are banned.',
  },
  cashier: {
    color: 'info',
    icon: 'shopping_cart',
    description:
      'Note will be displayed on the cashier interface when the user checks in. This is useful for requests for special handling.',
  },
};

interface AddNoteFormProps {
  readonly note: UserNoteCreate;
  readonly setNote: Dispatch<SetStateAction<UserNoteCreate>>;
  readonly hideUserInput?: boolean;
}

export const AddNoteForm: FC<AddNoteFormProps> = ({ note, setNote, hideUserInput }) => {
  const setCategory = useInputModel(setNote, 'category');
  const [, setUserId] = useObject(note, setNote, 'userId');
  const setText = useInputModel(setNote, 'text');

  const [ban, setBan] = useState<UserNoteBan>({
    category: 'registration',
    bornAt: null,
    firstName: '',
    lastName: '',
  });

  useEffect(() => {
    setNote((old) => ({
      ...old,
      ban: note.category === 'ban' ? ban : undefined,
    }));
  }, [note.category, ban, setNote]);

  const { description } = noteIcons[note.category];

  return (
    <>
      <FormGroup>
        <Label for="category">Note Category</Label>
        <Input
          id="category"
          name="category"
          onChange={setCategory}
          type="select"
          value={note.category}
        >
          {noteCategories.map((cat) => (
            <option key={cat} value={cat}>
              {capitalize(cat)}
            </option>
          ))}
        </Input>
        <FormText color="muted">{description}</FormText>
      </FormGroup>
      {!hideUserInput && (
        <FormGroup>
          <Label for="userSelection">User {note.category === 'ban' ? '(optional)' : ''}</Label>
          <UserSelector
            id="userSelection"
            maxItems={1}
            selectionIdsChanged={(ids) => {
              setUserId(ids[0]);
            }}
          />
        </FormGroup>
      )}
      <FormGroup>
        <Label for="text">Text</Label>
        <Input
          id="description"
          name="description"
          onChange={setText}
          type="textarea"
          value={note.text}
        />
      </FormGroup>
      {note.category === 'ban' && <BanOptions ban={ban} setBan={setBan} userId={note.userId} />}
    </>
  );
};

interface BanOptionsProps {
  readonly userId?: number;
  readonly ban: UserNoteBan;
  readonly setBan: Dispatch<SetStateAction<UserNoteBan>>;
}

const BanOptions: React.FC<BanOptionsProps> = ({ userId, ban, setBan }) => {
  const { ts } = useTranslation();
  const setBanCategory = useInputModel(setBan, 'category');
  const setFirstName = useInputModel(setBan, 'firstName');
  const setLastName = useInputModel(setBan, 'lastName');

  const useUserFetcher = useFetcher(async () => {
    if (userId) {
      return await api.getExtendedUser(userId);
    }

    return undefined;
  }, [userId]);

  useEffect(() => {
    if (userId && useUserFetcher.complete && useUserFetcher.data) {
      setBan((old) => ({
        ...old,
        bornAt: useUserFetcher.data!.bornAt!,
        firstName: useUserFetcher.data!.firstName,
        lastName: useUserFetcher.data!.lastName,
      }));
    }
  }, [userId, useUserFetcher.complete, useUserFetcher.data]);

  // TODO: Add Birthday input
  return (
    <div>
      <hr />
      <FormGroup>
        <Label for="banCategory">Ban Category</Label>
        <Input
          id="banCategory"
          name="banCategory"
          onChange={setBanCategory}
          type="select"
          value={ban.category}
        >
          {banTypes.map((type) => (
            <option key={type} value={type}>
              {capitalize(type)}
            </option>
          ))}
        </Input>
        <FormText color="muted">
          <BanDescription type={ban.category} />
        </FormText>
      </FormGroup>

      <Row>
        <Col md={6} xs={12}>
          <FormGroup>
            <Label for="firstName">First Name</Label>
            <Input
              disabled={!!userId}
              id="firstName"
              onChange={setFirstName}
              placeholder={`${ts('example')}: John`}
              value={ban.firstName}
            />
          </FormGroup>
        </Col>
        <Col md={6} xs={12}>
          <FormGroup>
            <Label for="lastName">Last Name</Label>
            <Input
              disabled={!!userId}
              id="lastName"
              onChange={setLastName}
              placeholder={`${ts('example')}: Doe`}
              value={ban.lastName}
            />
          </FormGroup>
        </Col>
      </Row>
    </div>
  );
};

const BanDescription: React.FC<{ readonly type: BanType }> = ({ type }) => {
  const { ts } = useTranslation();
  switch (type) {
    case 'registration': {
      return <>{ts('registration_bans_prevent_the_user')}</>;
    }

    case 'dealer': {
      return <>{ts('dealer_bans_prevent_the_user')}</>;
    }

    case 'volunteer': {
      return <>{ts('volunteer_bans_prevent_the_user')}</>;
    }

    case 'other': {
      return <>{ts('user_is_not_prevented_from')}</>;
    }

    default: {
      return <>{ts('unknown_ban_type')}</>;
    }
  }
};
