import { JSONForm } from '@conventioncatcorp/common-fe/dist/components/json-form/JSONForm';
import React, { FC, useMemo, useState } from 'react';
import { Button, FormGroup, Label, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { ProductModel } from '../../../../shared/orders';
import { ElementSelector } from '../../../components';
import { useTranslation } from '../../../translations';
import { LocalSearchProvider } from '../products/utils';

interface Props {
  readonly open?: boolean;
  readonly surchargeId: number;
  onUpdate(): void;
  onCancel(): void;
}

export const AssignModal: FC<Props> = ({ open, surchargeId, onCancel, onUpdate }) => {
  const { ts } = useTranslation();
  const [selectedIds, setSelectedIds] = useState<number[]>([]);
  const memoizedSearchProvider = useMemo(
    () => new LocalSearchProvider(async () => await api.getProducts()),
    [],
  );

  return (
    <Modal className="modal-large" id="assignSurchargeModal" isOpen={open}>
      <JSONForm<undefined, { productIds: number[] }>
        id="assignSurchargeForm"
        method="post"
        onSuccess={onUpdate}
        path={`/api/surcharges/${surchargeId}/assign`}
        preSubmit={(r) => (r.inputs!.productIds = selectedIds)}
      >
        <ModalHeader>Assign Surcharge #{surchargeId}</ModalHeader>
        <ModalBody>
          <FormGroup>
            <Label for="product">{ts('product_search')}</Label>
            <ElementSelector<ProductModel>
              id="surchargeProductSelector"
              searchProvider={memoizedSearchProvider}
              selectionIdsChanged={setSelectedIds}
            />
          </FormGroup>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" disabled={selectedIds.length === 0} id="assignSurcharges">
            {ts('assign_surcharge')}
          </Button>
          <Button color="secondary" onClick={onCancel}>
            {ts('cancel')}
          </Button>
        </ModalFooter>
      </JSONForm>
    </Modal>
  );
};
