import React, { useCallback, useContext, useState } from 'react';
import { CustomInput, Modal, ModalBody, ModalHeader } from 'reactstrap';
import {
  InputMultiSelect,
  MaterialIcon,
  MultiSelectDirection,
  MultiSelectItem,
} from '../../components';
import { useTranslation } from '../../translations';
import { CashierContext, CashierContextProps, PrinterPreferenceMode } from './cashiercontext';

import './settingsModal.scss';

export const CashierSettings: React.FC = () => {
  const cashierSettings = useContext(CashierContext);
  const [modalOpen, setModalOpen] = useState(false);

  const open = useCallback(() => {
    setModalOpen(true);
    cashierSettings.updateCashierSettings!({
      enableESCHotkey: false,
    });
  }, [cashierSettings]);

  const close = useCallback(() => {
    setModalOpen(false);
    cashierSettings.updateCashierSettings!({
      enableESCHotkey: true,
    });
  }, [cashierSettings]);

  return (
    <div className="cashier-settings">
      <MaterialIcon name="settings" onClick={open} type="muted" />
      {modalOpen && <CashierSettingsModal close={close} />}
    </div>
  );
};

interface ModalProps {
  readonly close: () => void;
}

const CashierSettingsModal: React.FC<ModalProps> = ({ close }) => {
  return (
    <Modal className="modal-large cashier-settings-modal hide-print" isOpen toggle={close}>
      <ModalHeader toggle={close}>Configure registration station</ModalHeader>
      <ModalBody>
        <UpdateCashierSettings />
      </ModalBody>
    </Modal>
  );
};

const UpdateCashierSettings: React.FC = () => {
  const cashierSettings = useContext(CashierContext);

  const { ts } = useTranslation();
  return (
    <div className="cashier-settings-form">
      <div className="cashier-settings-form-item">
        <div className="cashier-form-item-input">
          <CashierSettingsToggle
            defaultChecked={cashierSettings.config.enablePayments}
            id="accept-payments"
            label="Enable payment acceptance"
            name="accept-payments"
            onChange={(checked, cs) => {
              cs.updateCashierSettings!({ enablePayments: checked });
            }}
          />
        </div>
        <div className="cashier-form-item-description">
          <p>{ts('if_enabled_this_registration_station')}</p>
          <p>{ts('otherwise_cashiers_will_be_informed')}</p>
        </div>
      </div>
      <div className="cashier-settings-form-item">
        <div className="cashier-form-item-input">
          <CashierSettingsToggle
            defaultChecked={cashierSettings.config.requireIDScan}
            id="require-id-check"
            label="Require ID barcode scan"
            name="require-id-check"
            onChange={(checked, cs) => {
              cs.updateCashierSettings!({ requireIDScan: checked });
            }}
          />
        </div>
        <div className="cashier-form-item-description">
          <p>{ts('if_enabled_this_registration_station')}</p>
          <p>{ts('otherwise_cashiers_will_be_informed')}</p>
        </div>
      </div>
      <div className="cashier-settings-form-item">
        <div className="cashier-form-item-input">
          <CashierSettingsToggle
            defaultChecked={cashierSettings.config.enablePrintingStaffBadges}
            id="enable-staff-checkin"
            label="Enable staff check-in"
            name="enable-staff-checkin"
            onChange={(checked, cs) => {
              cs.updateCashierSettings!({ enablePrintingStaffBadges: checked });
            }}
          />
        </div>
        <div className="cashier-form-item-description">
          <p>{ts('if_enabled_this_registration_station')}</p>
          <p>{ts('otherwise_cashiers_will_be_informed')}</p>
        </div>
      </div>
      <div className="cashier-settings-form-item">
        <div className="cashier-form-item-input">
          <CashierSettingsToggle
            defaultChecked={cashierSettings.config.printSeparateStaffBadge}
            id="print-separate-staff-badge"
            label="Print separate staff badge"
            name="print-separate-staff-badge"
            onChange={(checked, cs) => {
              cs.updateCashierSettings!({ printSeparateStaffBadge: checked });
            }}
          />
        </div>
        <div className="cashier-form-item-description">
          <p>{ts('if_enabled_this_registration_station')}</p>
          <p>{ts('otherwise_a_combination_badge_will')}</p>
        </div>
      </div>
      <div className="cashier-settings-form-item">
        <div className="cashier-form-item-input">
          <CashierSettingsToggle
            defaultChecked={cashierSettings.config.printSeparateVendorBadge}
            id="print-separate-vendor-badge"
            label="Print separate vendor badge"
            name="print-separate-vendor-badge"
            onChange={(checked, cs) => {
              cs.updateCashierSettings!({ printSeparateVendorBadge: checked });
            }}
          />
        </div>
        <div className="cashier-form-item-description">
          <p>{ts('if_enabled_this_registration_station')}</p>
          <p>{ts('otherwise_a_combination_badge_will')}</p>
        </div>
      </div>
      <div>
        <CashierBadgeTypePreference />
      </div>
    </div>
  );
};

interface ToggleProps {
  readonly defaultChecked: boolean;
  readonly id: string;
  readonly label: string;
  readonly name: string;
  readonly onChange: (checked: boolean, cs: CashierContextProps) => void;
}

const CashierSettingsToggle: React.FC<ToggleProps> = ({
  defaultChecked,
  id,
  label,
  name,
  onChange,
}) => {
  const cashierSettings = useContext(CashierContext);
  const [showSaved, setShowSaved] = useState(false);

  const internalOnChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      onChange(e.target.checked, cashierSettings);
      setShowSaved(true);

      setTimeout(() => {
        setShowSaved(false);
      }, 2500);
    },
    [cashierSettings, onChange],
  );

  return (
    <>
      <CustomInput
        defaultChecked={defaultChecked}
        id={id}
        label={label}
        name={name}
        onChange={internalOnChange}
        type="switch"
      />
      {showSaved && (
        <div className="cashier-form-item-saved" id="enablePayments-saved">
          Saved!
        </div>
      )}
    </>
  );
};

const CashierBadgeTypePreference: React.FC = () => {
  const { ts } = useTranslation();
  const [showSaved, setShowSaved] = useState(false);
  const cashierSettings = useContext(CashierContext);

  const primary: MultiSelectItem[] = cashierSettings.config.badgePrintPreferences
    .filter((b) => b.preference === PrinterPreferenceMode.Primary)
    .map((b) => ({ value: b.text, id: b.id }));

  const secondary: MultiSelectItem[] = cashierSettings.config.badgePrintPreferences
    .filter((b) => b.preference === PrinterPreferenceMode.Secondary)
    .map((b) => ({ value: b.text, id: b.id }));

  const onMove = useCallback(
    (items: MultiSelectItem[], type: MultiSelectDirection) => {
      cashierSettings.updateCashierSettings!({
        badgePrintPreferences: cashierSettings.config.badgePrintPreferences.map((b) => {
          if (items.some((i) => i.id === b.id)) {
            return {
              ...b,
              preference:
                type === MultiSelectDirection.Left
                  ? PrinterPreferenceMode.Primary
                  : PrinterPreferenceMode.Secondary,
            };
          }

          return b;
        }),
      });

      setShowSaved(true);

      setTimeout(() => {
        setShowSaved(false);
      }, 2500);
    },
    [cashierSettings],
  );

  return (
    <div className="cashier-badgetype-preferences">
      <div className="cashier-badgetype-header">
        <div>Badge type printer preferences</div>
        {showSaved && (
          <div className="cashier-form-item-saved" id="badgeTypePreferences-saved">
            Saved!
          </div>
        )}
      </div>
      <p className="cashier-explanation-text">{ts('select_the_printers_that_should')}</p>
      <InputMultiSelect
        leftItems={primary}
        leftLabel="Primary Printer"
        onMove={onMove}
        rightItems={secondary}
        rightLabel="Secondary Printer"
      />
    </div>
  );
};
