import React, { FC } from 'react';
import { BaseUser } from '../../../../../../shared/user/base';
import { useTranslation } from '../../../../../translations';

interface OrderUserInfoProps {
  readonly user: BaseUser;
}

export const OrderUserInfo: FC<OrderUserInfoProps> = ({ user }) => {
  const { ts } = useTranslation();
  if (user.address === undefined) {
    return (
      <>
        {user.firstName} {user.lastName} (#{user.id})<br />
        <br />
        <i>{ts('address_and_contact_information_requires')}</i>
      </>
    );
  }

  return (
    <>
      {user.firstName} {user.lastName} (#{user.id})<br />
      <br />
      {user.address.line1},<br />
      {user.address.line2 && (
        <>
          {user.address.line2},<br />
        </>
      )}
      {user.address.city}, {user.address.state}, {user.address.zipcode}
      <br />
      {user.address.country}
      <br />
      <br />
      {user.email}
    </>
  );
};
