import React, { FC, useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import {
  Button,
  Card,
  CardBody,
  Col,
  Form,
  FormGroup,
  FormText,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from 'reactstrap';
import { DealerArea } from '../../../shared/dealer';
import {
  ActionButtonModal,
  PageHeader,
  PermissionBoundary,
  UserStateComponent,
} from '../../components';
import { useTranslation } from '../../translations';
import {
  Fetcher,
  useBoolState,
  useCheckboxState,
  useFetcher,
  useForm,
  useInputState,
} from '../../utils';

export const DealerAreas: FC = () => {
  return (
    <UserStateComponent>
      <PageHeader>Dealer Areas</PageHeader>
      <DealerAreaContainer />
    </UserStateComponent>
  );
};

const DealerAreaContainer: FC = () => {
  const [openModal, enableModal, disableModal] = useBoolState(false);
  const [areaToEdit, setAreaToEdit] = useState<DealerArea | undefined>(undefined);

  const { ts } = useTranslation();
  const fetcher = useFetcher(async () => {
    return await api.getDealerAreas();
  }, []);

  if (!fetcher.complete) {
    return <Fetcher result={fetcher} />;
  }

  return (
    <>
      {openModal && (
        <NewDealerAreaEditModal
          area={areaToEdit}
          disableModal={disableModal}
          refresh={fetcher.refresh}
        />
      )}
      <div>
        <Button
          color="primary"
          id="createArea"
          onClick={() => {
            setAreaToEdit(undefined);
            enableModal();
          }}
        >
          {ts('create_new_dealer_area')}
        </Button>
      </div>
      <hr />
      <Row className="justify-content-center" id="dealerAreaList">
        {fetcher.data!.map((area) => (
          <Col className="margin-bottom-10" id={`${area.name}`} key={area.name} lg={9} xs={9}>
            <Card>
              <CardBody>
                <h4 className="card-title">{area.name} </h4>
                {area.description}
                <div className="float-right">
                  <PermissionBoundary inline requiredPermissions={['vendor:update']}>
                    <Button
                      color="primary"
                      id={`editArea${area.id}`}
                      onClick={() => {
                        setAreaToEdit(area);
                        enableModal();
                      }}
                    >
                      Edit
                    </Button>{' '}
                  </PermissionBoundary>
                  <PermissionBoundary inline requiredPermissions={['staff:admin:update']}>
                    <ActionButtonModal
                      actionContent="Delete"
                      buttonContent="Delete"
                      color="danger"
                      id={`deleteArea${area.id}`}
                      onComplete={async () => {
                        await api.deleteDealerArea(area.id);
                        toast.success(ts('deleted_area'));
                        fetcher.refresh();
                      }}
                      title={ts('delete_dealer_area')}
                    >
                      Are you sure you want to delete {area.name}?
                      <strong>{ts('this_will_delete_all_applications')}</strong>
                    </ActionButtonModal>
                  </PermissionBoundary>
                </div>
              </CardBody>
            </Card>
          </Col>
        ))}
      </Row>
    </>
  );
};

const NewDealerAreaEditModal: FC<{
  readonly area?: DealerArea;
  disableModal(): void;
  refresh(): void;
}> = ({ disableModal, refresh, area }) => {
  const fetcher = useFetcher(async () => {
    return await api.getProductCategories();
  }, []);

  const [areaName, setAreaName] = useInputState(area?.name ?? '');
  const [areaDescription, setAreaDescription] = useInputState(area?.description ?? '');
  const [areaOpen, setAreaOpen] = useCheckboxState(area?.open ?? false);
  const [areaEnabled, setAreaEnabled] = useCheckboxState(area?.enabled ?? false);
  const [areaFull, setAreaFull] = useCheckboxState(area?.full ?? false);
  const [areaCatId, setAreaCatId] = useState(area?.categoryId);

  const categoryIdRef = useRef<HTMLInputElement>(null);

  const isCreate = area === undefined;

  useEffect(() => {
    if (categoryIdRef.current) {
      setAreaCatId(Number.parseInt(categoryIdRef.current.value, 10));
    }
  }, [categoryIdRef.current]);

  const form = useForm(async () => {
    if (isCreate) {
      await api.createDealerArea(
        areaName,
        areaDescription,
        areaEnabled,
        areaFull,
        areaOpen,
        areaCatId!,
      );

      toast.success(ts('created_area'));
    } else {
      await api.updateDealerArea(area.id, {
        name: areaName,
        description: areaDescription,
        enabled: areaEnabled,
        full: areaFull,
        open: areaOpen,
        categoryId: areaCatId!,
      });

      toast.success(ts('updated_area'));
    }

    disableModal();
    refresh();
  }, [
    areaName,
    areaDescription,
    areaEnabled,
    areaFull,
    areaOpen,
    areaCatId,
    isCreate,
    categoryIdRef,
  ]);

  const { ts } = useTranslation();
  return (
    <Modal id="createDealerModal" isOpen>
      <Form onSubmit={form.onSubmit}>
        <ModalHeader>{isCreate ? 'Create New' : 'Edit'} Dealer Area</ModalHeader>
        <ModalBody>
          <FormGroup row>
            <Label for="name" lg={5} sm={12}>
              Name
            </Label>
            <Col lg={7} sm={12}>
              <Input defaultValue={area?.name} id="name" name="name" onChange={setAreaName} />
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label for="description" lg={5} sm={12}>
              Description
            </Label>
            <Col lg={7} sm={12}>
              <Input
                defaultValue={area?.description}
                id="description"
                name="description"
                onChange={setAreaDescription}
                type="textarea"
              />
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label for="categoryId" lg={5} sm={12}>
              {ts('product_category')}
            </Label>

            {fetcher.data && (
              <Col lg={7} sm={12}>
                <Input
                  defaultValue={area?.categoryId.toString()}
                  id="categoryId"
                  innerRef={categoryIdRef}
                  name="categoryId"
                  onChange={(e) => {
                    setAreaCatId(Number.parseInt(e.currentTarget.value, 10));
                  }}
                  type="select"
                >
                  {fetcher.data
                    .filter((t) => t.isVendor)
                    .map((c) => (
                      <option key={c.id} value={c.id}>
                        {c.name} ({c.id})
                      </option>
                    ))}
                </Input>
              </Col>
            )}
          </FormGroup>
          <FormGroup row>
            <div className="custom-control custom-checkbox left-align margin-top-10">
              <Input
                className={`custom-control-input ${area?.enabled ? 'yes' : 'no'}`}
                defaultChecked={area?.enabled}
                id="enabledArea"
                name="enabled"
                onChange={setAreaEnabled}
                type="checkbox"
              />
              <Label className="custom-control-label" for="enabledArea">
                Enabled
                <FormText color="muted">Display the option to users</FormText>
              </Label>
            </div>
          </FormGroup>
          <FormGroup row>
            <div className="custom-control custom-checkbox left-align margin-top-10">
              <Input
                className={`custom-control-input ${area?.full ? 'yes' : 'no'}`}
                defaultChecked={area?.full}
                id="fullArea"
                name="full"
                onChange={setAreaFull}
                type="checkbox"
              />
              <Label className="custom-control-label" for="fullArea">
                Full
                <FormText color="muted">
                  Show that registrations is full, but more slots may open in the future.
                </FormText>
              </Label>
            </div>
          </FormGroup>
          <FormGroup row>
            <div className="custom-control custom-checkbox left-align margin-top-10">
              <Input
                className={`custom-control-input ${area?.open ? 'yes' : 'no'}`}
                defaultChecked={area?.open}
                id="openArea"
                name="open"
                onChange={setAreaOpen}
                type="checkbox"
              />
              <Label className="custom-control-label" for="openArea">
                Open
                <FormText color="muted">Allow for additional registrations</FormText>
              </Label>
            </div>
          </FormGroup>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={disableModal}>
            Cancel
          </Button>{' '}
          <Button color="primary" id="createDealerArea" type="submit">
            {isCreate ? 'Create' : 'Edit'}
          </Button>
        </ModalFooter>
      </Form>
    </Modal>
  );
};
