import React, { FC } from 'react';
import { Button, Card, CardBody, CardHeader } from 'reactstrap';
import { AssetUpload, MaterialIcon } from '../../../../../components';
import { useTranslation } from '../../../../../translations';
import { ImageInfoProps } from './ImageInfoHOC';

interface CreateImageProps extends ImageInfoProps {
  onChange(): void;
}

export const CreateImageComponent: FC<CreateImageProps> = ({ product, onChange, onUpdate }) => {
  const { ts } = useTranslation();
  return (
    <Card className="margin-top-10">
      <CardHeader>
        <div className="card-sub-header">{ts('product_images')}</div>
        <div className="float-right">
          <Button
            color="danger"
            id="close"
            onClick={() => {
              onChange();
            }}
            title="Close"
          >
            <MaterialIcon name="cancel" />
          </Button>
        </div>
      </CardHeader>
      <CardBody>
        <AssetUpload
          category="product"
          onComplete={() => {
            onUpdate();
            onChange();
          }}
          onFileUploaded={async (assetId) => await api.createProductImage(product.id, assetId)}
        />
      </CardBody>
    </Card>
  );
};
