import React, { FC } from 'react';
import { toast } from 'react-toastify';
import { Button, Card, CardBody, CardFooter, CardHeader } from 'reactstrap';
import { InlineHelp, PermissionBoundary } from '../../../../components';
import { Surcharge } from '../../../../models';
import { useTranslation } from '../../../../translations';
import { useBoolState } from '../../../../utils';
import { LoadingWrapper } from '../../../../utils/LoadingWrapper';
import { AssignCategorySurchargeModal } from './assignCategorySurchargeModal';
import { CategorySurchargeItem } from './categorySurchargeItem';

interface Props {
  readonly categoryId: number;
}

export const CategorySurcharges: FC<Props> = ({ categoryId }) => {
  const { ts } = useTranslation();
  const [openModal, enableModal, disableModal] = useBoolState(false);
  return (
    <LoadingWrapper<Surcharge[], void>
      dataFetcher={async () => await api.getCategorySurcharges(categoryId)}
      inline
    >
      {(data, refresh) => (
        <Card className="margin-bottom-10">
          <CardHeader>
            Category Surcharges
            <InlineHelp>
              <p>{ts('surcharges_added_at_the_category')}</p>
              <p>{ts('if_you_remove_a_surcharge')}</p>
            </InlineHelp>
          </CardHeader>
          <CardBody>
            {data.length === 0 && <i>{ts('there_are_no_category_level')}</i>}
            {data.map((surcharge) => (
              <CategorySurchargeItem
                categoryId={categoryId}
                key={surcharge.id}
                refresh={refresh}
                surcharge={surcharge}
              />
            ))}
          </CardBody>
          <CardFooter>
            <PermissionBoundary
              inline
              requiredPermissions={['product:category:manage', 'product:manage']}
            >
              <Button color="primary" onClick={enableModal} outline>
                {ts('assign_category_level_surcharge')}
              </Button>
              <AssignCategorySurchargeModal
                categoryId={categoryId}
                onCancel={disableModal}
                onUpdate={() => {
                  toast.success(`${ts('successfully_assigned_new_category_level')}`);
                  disableModal();
                  refresh();
                }}
                open={openModal}
              />
            </PermissionBoundary>
          </CardFooter>
        </Card>
      )}
    </LoadingWrapper>
  );
};
