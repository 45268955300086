import React, { FC, Fragment } from 'react';
import { Order } from '../../../../../shared/orders/model';
import { OrderItemProduct } from '../../../../containers';
import { cToUsdStrPref, sumBy } from '../../../../utils';

interface Props {
  readonly order: Order;
}

export const FormOrderInfo: FC<Props> = ({ order }) => {
  return (
    <>
      {order.orderItems.map((item) => (
        <OrderItemProduct item={item} key={item.id} order={order} readOnly />
      ))}
      {(order.fees?.length ?? 0) > 0 && (
        <Fragment key="orderFees">
          <div className="order-line">
            <div className="order-item">
              <div className="order-item-description">Fees, Charges, and Fines</div>
              <div className="order-item-price">
                {cToUsdStrPref(sumBy(order.fees, (fee) => fee.amount))}
              </div>
            </div>
          </div>
          <hr />
        </Fragment>
      )}
    </>
  );
};
