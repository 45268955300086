import React, { FC, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { Badge, Button, Card, CardBody, Col, Row } from 'reactstrap';
import { MaterialIcon } from '../../../../components';
import { useTranslation } from '../../../../translations';
import { Fetcher, useFetcher } from '../../../../utils';

export const TemplateList: FC = () => {
  const result = useFetcher(async () => {
    return await api.getTemplateList();
  }, []);

  const sortedTemplates = useMemo(() => {
    if (!result.data) {
      return [];
    }

    return result.data.templates.sort((a, b) =>
      (a.state !== 'default') === (b.state !== 'default') ? 0 : a.state === 'default' ? 1 : -1,
    );
  }, [result]);

  if (!result.complete) {
    return <Fetcher result={result} />;
  }

  return (
    <Row className="justify-content-center" id="templateList">
      {sortedTemplates.map((template) => (
        <Col
          className="margin-bottom-10"
          id={`${template.name}`}
          key={template.name}
          lg={12}
          xs={12}
        >
          <Card>
            <CardBody>
              <h4 className="card-title" style={{ marginRight: '30px' }}>
                {template.name}{' '}
                <span className="align-middle badge-list compact">
                  <StatusBadge templateState={template.state} />
                </span>
              </h4>{' '}
              {(template.name !== 'register' ||
                (template.name === 'register' && result.data!.allowEditRegisterEmailTemplate)) && (
                <>
                  {template.description}
                  <div className="float-right">
                    <Link to={`templates/${template.name}`}>
                      <Button color="primary">Modify</Button>
                    </Link>
                  </div>
                </>
              )}
              {template.name === 'register' && !result.data!.allowEditRegisterEmailTemplate && (
                <>
                  <span className="align-middle">
                    <MaterialIcon name="warning" />
                  </span>{' '}
                  To modify this template, contact support.
                </>
              )}
            </CardBody>
          </Card>
        </Col>
      ))}
    </Row>
  );
};

const StatusBadge: FC<{ readonly templateState: 'default' | 'disabled' | 'override' }> = ({
  templateState,
}) => {
  const { ts } = useTranslation();
  switch (templateState) {
    case 'default': {
      return <Badge color="secondary">{ts('default')}</Badge>;
    }

    case 'disabled': {
      return <Badge color="danger">{ts('disabled')}</Badge>;
    }

    case 'override': {
      return <Badge color="success">{ts('overridden')}</Badge>;
    }
  }
};
