import { SelectBlocks } from '@conventioncatcorp/common-fe';
import React, { FC, useCallback, useState } from 'react';
import { Button, Col, FormGroup, Input, Label, ModalBody, ModalFooter, Row } from 'reactstrap';
import { useTranslation } from '../../../../translations';
import { componentList } from '../utils';

interface Props {
  readonly defaultComponentId?: number;
  readonly name?: string;
  onCancel(): void;
  onNameChange(name: string): void;
  onNextClicked(id: number): void;
}

export const SelectList: FC<Props> = ({
  defaultComponentId,
  name,
  onCancel,
  onNameChange,
  onNextClicked,
}) => {
  const { ts } = useTranslation();
  const [selectedComponentId, setSelectedComponentId] = useState<number | undefined>(
    defaultComponentId,
  );

  const selectedComponent = selectedComponentId !== undefined && componentList[selectedComponentId];
  const onChange = useCallback((ids: number[]) => {
    setSelectedComponentId(ids[0]);
  }, []);

  const onNext = useCallback(() => {
    return selectedComponentId !== undefined && onNextClicked(selectedComponentId);
  }, [selectedComponentId]);

  return (
    <>
      <ModalBody>
        <Row>
          <Col xs={12}>
            <FormGroup>
              <Label for="displayName">
                {ts('display_name')}
                <small>{ts('optional')}</small>
              </Label>
              <Input
                id="displayName"
                name="displayName"
                onChange={(e) => {
                  onNameChange(e.currentTarget.value);
                }}
                required
                value={name}
              />
            </FormGroup>
          </Col>
          <Col xs={12}>
            <FormGroup>
              <Label>Type</Label>
              <SelectBlocks
                defaultSelectedId={selectedComponentId}
                items={componentList}
                onChange={onChange}
              />
            </FormGroup>
          </Col>
          {selectedComponent && (
            <>
              <Col xs={12}>
                <hr />
              </Col>
              <Col xs={12}>
                <Label for="name">What is {selectedComponent.name}?</Label>
                <p>{selectedComponent.description}</p>
              </Col>
            </>
          )}
        </Row>
      </ModalBody>
      <ModalFooter>
        <Button
          className="editor-next-btn"
          color="primary"
          disabled={!selectedComponent}
          onClick={onNext}
        >
          Next
        </Button>
        <Button className="editor-cancel-btn" color="secondary" onClick={onCancel} outline>
          {ts('cancel')}
        </Button>
      </ModalFooter>
    </>
  );
};
