import { DateTime } from '@conventioncatcorp/common-fe';
import { PreSubmitData } from '@conventioncatcorp/common-fe/dist/components/json-form/JSONForm';
import React, { FC } from 'react';
import { Input, Table } from 'reactstrap';
import { Payment } from '../../shared/orders/model';
import { useTranslation } from '../translations';
import { cToUsdStrPref, sumBy } from '../utils';

interface RefundProps {
  readonly payments: Payment[];
  readonly totalToRefund?: number;
}

interface PaymentData {
  paymentData: Record<string, number>;
}

export const RefundPaymentTable: FC<RefundProps> = ({ payments, totalToRefund }: RefundProps) => {
  const { ts } = useTranslation();
  const renderPayments = payments
    .filter(({ status }) => status === 'success')
    .map((payment) => {
      // Payments with the same reference can be a refund
      const relatedPayments = payments.filter(
        ({ reference, status }) =>
          reference === payment.reference && (status === 'success' || status === 'refunded'),
      );

      // If the payment already has been partially refunded, subtract it
      const remainingBalance = sumBy(relatedPayments, ({ amount, status }) =>
        status === 'success' ? amount : -amount,
      );

      return (
        <RefundPaymentTableItem
          key={payment.id}
          payment={payment}
          remainingBalance={remainingBalance}
          totalToRefund={totalToRefund}
        />
      );
    });

  return (
    <Table striped>
      <thead>
        <tr>
          <th>{ts('payment_date')}</th>
          <th>{ts('available_amount')}</th>
          <th>{ts('gateway')}</th>
          <th>{ts('amount_to_refund')}</th>
        </tr>
      </thead>
      <tbody>{renderPayments}</tbody>
    </Table>
  );
};

export const refundUpdatePreSubmit = ({ inputs }: PreSubmitData<PaymentData>): void => {
  if (!inputs) {
    return;
  }

  inputs.paymentData ??= {};
  for (const key of Object.keys(inputs.paymentData)) {
    inputs.paymentData[key] *= 100;
  }
};

interface RefundPaymentTableItemProps {
  readonly payment: Payment;
  readonly remainingBalance: number;
  readonly totalToRefund?: number;
}

const RefundPaymentTableItem: FC<RefundPaymentTableItemProps> = ({
  payment,
  remainingBalance,
  totalToRefund,
}) => {
  let amountToRefund = remainingBalance;

  if (totalToRefund) {
    if (remainingBalance >= totalToRefund) {
      amountToRefund = totalToRefund;
    }

    totalToRefund -= amountToRefund;
  }

  return (
    <tr>
      <td>
        <DateTime value={payment.createdAt} />
      </td>
      <td>{cToUsdStrPref(remainingBalance)}</td>
      <td>{payment.gateway}</td>
      <td>
        <div className="input-group">
          <div className="input-group-prepend">
            <div className="input-group-text">$</div>
          </div>
          <Input
            defaultValue={totalToRefund === undefined ? 0 : amountToRefund / 100}
            disabled={remainingBalance <= 0}
            id={`payment${payment.id}Amount`}
            min="0"
            name={`paymentData[${payment.id}]`}
            step="0.01"
            type="number"
          />
        </div>
      </td>
    </tr>
  );
};
