import React, { FC } from 'react';
import { Button } from 'reactstrap';

interface FormNodeSubmitProps {
  readonly lastPage: boolean;
}

export const FormNodeSubmit: FC<FormNodeSubmitProps> = ({ lastPage }) => {
  return (
    <Button color="primary" id="page-continue" outline={!lastPage} type="submit">
      {lastPage ? 'Submit Registration' : 'Continue'}
    </Button>
  );
};
