import React, { FC, useCallback, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useDispatch, useSelector } from 'react-redux';
import { v4 } from 'uuid';
import { Loading } from '..';
import { FinalPageModel, FormLayout, FormPageModel } from '../../../shared/kiosk';
import { Order } from '../../../shared/orders/model';
import { ProductModel, ProductOptionInput } from '../../../shared/orders/product';
import { getConventionId } from '../../containers/account/getConventionIdParam';
import { User } from '../../models';
import { ActionType, GlobalState } from '../../services';
import { isResourceError, omit, useConvention } from '../../utils';
import { captureError } from '../../utils/errorHandling';
import { LazyMarkdown } from '../LazyMarkdown';
import { MessageCard } from '../MessageCard';
import { DefaultKioskCSS } from './DefaultCSS';
import { FormExceptionHandler, KioskException } from './models/FormException';
import { FormPage } from './models/FormPage';

interface LayoutProps {
  readonly layout: FormLayout;
}

export type FormInputs = Record<string, unknown>;

class KioskOrderSkippableError extends Error {
  public constructor() {
    super();
    this.name = 'KioskOrderSkippableError';
  }
}

export const LayoutRenderer: FC<LayoutProps> = ({ layout }) => {
  const [formInputs, setFormInputs] = useState<FormInputs>({
    conventionId: getConventionId() || undefined,
  });

  const [attendanceTypes, setAttendanceTypes] = useState<ProductModel[]>([]);
  const [productOptions, setProductOptions] = useState<ProductOptionInput[]>([]);
  const [hasRetrieved, setHasRetrieved] = useState(false);
  const [page, setPage] = useState(0);

  const [exception, setException] = useState<KioskException>();
  const [order, setOrder] = useState<Order>();
  const [orderPaid, setOrderPaid] = useState(false);

  const dispatch = useDispatch();
  const user = useSelector((state: GlobalState) => state.user);

  const hasCSS = layout.stylesheet.length > 0;

  const fetchOrder = useCallback(async () => {
    if (!user) {
      return;
    }

    // Check for orders
    try {
      const activeOrder = await api.getActiveOrder(user.id, false);

      if (activeOrder.orderItems.length === 0) {
        throw new KioskOrderSkippableError();
      }

      // Order isn't paid, send to payment page
      setOrder(activeOrder);
    } catch (error) {
      if (!isResourceError(error, 'Order') && !(error instanceof KioskOrderSkippableError)) {
        captureError(error as Error);
        return;
      }

      // Unpaid order wasn't found, check for existing one?
      try {
        const reg = await api.getUserActiveRegistration(user.id);
        if (!reg.paidOrderItem) {
          return;
        }
      } catch (error_) {
        if (!isResourceError(error_, 'Registration')) {
          captureError(error_ as Error);
        }

        return;
      }

      // Send user to thanks page!
      setOrderPaid(true);
    }

    setPage((old) => old + 1);
  }, [user]);

  const tryLogin = useCallback(
    async (username?: string, password?: string) => {
      if (!username || !password) {
        username = formInputs.username as string;
        password = formInputs.password as string;
      }

      try {
        await api.login(username, password);
        const activeUser = await api.getActiveUser();
        dispatch({ type: ActionType.LoginSuccess, user: activeUser });
        setException(undefined);
      } catch (error) {
        captureError(error as Error);
      }
    },
    [formInputs],
  );

  const updateInputs = useCallback(
    async (data: FormInputs) => {
      const newInputs = { ...formInputs, ...data };
      setFormInputs(newInputs);

      if (page + 1 !== layout.pages.length) {
        // If not last page
        setPage((old) => old + 1);
        return;
      }

      try {
        if (user) {
          const amendedPayload = {
            ...omit(newInputs, 'registration'),
            ...((newInputs.registration || {}) as object),
          };

          await api.request({
            method: 'post',
            payload: amendedPayload,
            url: `/api/users/${user.id}/registration`,
          });

          await fetchOrder();
          return;
        }

        const { verified } = await api.request<Partial<User>>({
          method: 'post',
          payload: {
            ...omit(newInputs, 'options'),
            registration: {
              ...(newInputs.registration as object),
              options: newInputs.options,
            },
          },
          url: '/api/users',
        });

        if (verified) {
          await tryLogin(newInputs.username as string, newInputs.password as string);
        } else if (!verified) {
          setException('unverified');
        }
      } catch (error) {
        captureError(error as Error);
      }
    },
    [formInputs, page, user],
  );

  useEffect(() => {
    fetchOrder().catch(captureError);
  }, [user]);

  useEffect(() => {
    api
      .getProductCategories()
      .then(async (categories) => {
        const regCategory = categories.find(({ isRegistration }) => isRegistration);
        if (!regCategory) {
          setHasRetrieved(true);
          return;
        }

        const products = await api.getProductsByCategory(regCategory.id);
        setAttendanceTypes(products);
        setProductOptions(
          products.reduce<ProductOptionInput[]>((arr, { options }) => arr.concat(options), []),
        );

        setHasRetrieved(true);
      })
      .catch((error) => {
        captureError(error as Error);
        setHasRetrieved(true);
      });
  }, []);

  useEffect(() => {
    if (hasRetrieved && layout.disableBootstrap) {
      // If custom CSS is provided, disable the default ConCat CSS
      (document.getElementById('bootstrapv4') as HTMLLinkElement).disabled = true;
    }
  }, [layout.disableBootstrap, hasRetrieved]);

  if (!hasRetrieved) {
    return <Loading inline>Loading Kiosk...</Loading>;
  }

  if (attendanceTypes.length === 0) {
    return (
      <div className="margin-top-10">
        <MessageCard icon="offline_bolt" level="warning" title="Registration is Unavailable">
          <p>
            There are currently no attendance types available to select, so registration is
            temporarily closed.
          </p>
        </MessageCard>
      </div>
    );
  }

  return (
    <div className="form-layout">
      {!hasCSS && <DefaultKioskCSS />}
      <Helmet>
        <title>{layout.title}</title>
        {hasCSS && <style type="text/css">{unescape(layout.stylesheet)}</style>}
      </Helmet>
      <header>
        <LazyMarkdown source={layout.header} />
      </header>
      {exception && <FormExceptionHandler exception={exception} tryLogin={tryLogin} />}
      {!exception && (
        <GetPage
          attendanceTypes={attendanceTypes}
          finalPage={layout.finalPage}
          order={order}
          orderPaid={orderPaid}
          pageNumber={page}
          pages={layout.pages}
          paymentOptions={layout.paymentOptions}
          productOptions={productOptions}
          updateInputs={updateInputs}
          updateOrder={fetchOrder}
        />
      )}
      <footer>
        <LazyMarkdown source={layout.footer} />
      </footer>
    </div>
  );
};

interface GetPageProps {
  readonly attendanceTypes: ProductModel[];
  readonly pageNumber: number;
  readonly pages: FormPageModel[];
  readonly paymentOptions: { markdown: string };
  readonly productOptions: ProductOptionInput[];
  readonly finalPage: FinalPageModel;
  readonly order?: Order;
  readonly orderPaid?: boolean;
  readonly updateInputs: (data: FormInputs) => void;
  readonly updateOrder: () => Promise<void>;
}

const GetPage: FC<GetPageProps> = ({
  attendanceTypes,
  finalPage,
  pageNumber,
  pages,
  paymentOptions,
  order,
  orderPaid,
  productOptions,
  updateInputs,
  updateOrder,
}) => {
  const convention = useConvention();
  const page = pages[pageNumber];

  if (page) {
    const lastPage = pageNumber + 1 === pages.length;
    return (
      <FormPage
        {...page}
        attendanceTypes={attendanceTypes}
        lastPage={lastPage}
        productOptions={productOptions}
        updateInputs={updateInputs}
        updateOrder={updateOrder}
      />
    );
  }

  if (!orderPaid && convention.kioskPaymentsEnabled) {
    // Payment Page is required
    if (!order) {
      return <Loading inline />;
    }

    return (
      <FormPage
        attendanceTypes={attendanceTypes}
        id=""
        lastPage={false}
        name="payment"
        order={order}
        productOptions={productOptions}
        sections={[
          {
            id: v4(),
            name: 'Checkout',
            nodes: [
              { id: '1', type: 'cart' },
              { id: '2', type: 'divider' },
              { id: '3', type: 'markdown', markdown: paymentOptions.markdown },
            ],
          },
        ]}
        updateInputs={updateInputs}
        updateOrder={updateOrder}
      />
    );
  }

  return (
    <FormPage
      attendanceTypes={attendanceTypes}
      id=""
      lastPage={false}
      name="confirmation"
      productOptions={productOptions}
      sections={[
        ...finalPage.sections,
        {
          id: v4(),
          name: 'Updating Registration',
          nodes: [
            {
              id: '1',
              type: 'markdown',
              markdown:
                'If you need to update your registration, you can click **Update Registration** below to be redirected.',
            },
            {
              id: '2',
              type: 'markdown',
              markdown: '[Update Registration](/event/register/edit) {.btn .btn-primary}',
            },
          ],
        },
      ]}
      updateInputs={updateInputs}
      updateOrder={updateOrder}
    />
  );
};
