import React, { FC, ReactNode, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { Button, Col, Row } from 'reactstrap';
import { MaterialIcon, MessageCard, PageHeader, UserStateComponent } from '../../components';
import { RegistrationInfo } from '../../models/RegistrationInfo';
import { useTranslation } from '../../translations';
import { isResourceError, useConvention, useUser } from '../../utils';
import { LoadingWrapper } from '../../utils/LoadingWrapper';
import { registrationClosedHandler } from './RegistrationClosed';
import { RegistrationListItem } from '.';

const errorDisplay = (err: Error): ReactNode => {
  return registrationClosedHandler(err);
};

interface RegistrationRequest {
  registration?: RegistrationInfo;
  limitReached: boolean;
}

async function getUserActiveRegistration(userId: number): Promise<RegistrationInfo | undefined> {
  try {
    return await api.getUserActiveRegistration(userId);
  } catch (error) {
    // When the error is just a 404 to the Registration resource
    if (!isResourceError(error)) {
      throw error;
    }
  }

  return undefined;
}

async function loadRegistration(
  userId: number,
  limitAttendees: number | null,
): Promise<RegistrationRequest> {
  const registration = await getUserActiveRegistration(userId);
  let limitReached = false;

  if (limitAttendees) {
    const countReq = await api.getRegistrationLimitReached();
    ({ limitReached } = countReq);
  }

  return {
    limitReached,
    registration,
  };
}

export const RegistrationList: FC = () => {
  const user = useUser()!;
  const { limitAttendees } = useConvention();

  return (
    <UserStateComponent>
      <LoadingWrapper<RegistrationRequest, number>
        dataFetcher={async (uid) => await loadRegistration(uid, limitAttendees)}
        errorDisplay={errorDisplay}
        inline
        passback={user.id}
      >
        {(req, refresh) => (
          <EventRegistration limitAttendees={limitAttendees} refresh={refresh} req={req} />
        )}
      </LoadingWrapper>
    </UserStateComponent>
  );
};

interface EventRegistrationProps {
  readonly req: RegistrationRequest;
  readonly refresh: () => void;
  readonly limitAttendees: number | null;
}

const EventRegistration: FC<EventRegistrationProps> = ({ req, refresh, limitAttendees }) => {
  const { ts } = useTranslation();
  if (!req.registration && limitAttendees && req.limitReached) {
    return (
      <>
        <PageHeader>{ts('event_registration')}</PageHeader>
        <MessageCard icon="remove_circle" id="registrationFull" level="danger">
          {ts('registration_is_full')}
        </MessageCard>
      </>
    );
  }

  return (
    <>
      <PageHeader>{ts('event_registration')}</PageHeader>
      <CreateRegistrationButton conRegistration={req.registration} />
      <hr />
      <Row className="justify-content-center" id="conventionInfoForm">
        {req.registration ? (
          <RenderRegistrationItem conRegistration={req.registration} refresh={refresh} />
        ) : (
          <NoRegistration />
        )}
      </Row>
    </>
  );
};

const CreateRegistrationButton: FC<{ readonly conRegistration?: RegistrationInfo }> = ({
  conRegistration,
}) => {
  const history = useHistory();
  return (
    <Button
      className="margin-bottom-10"
      color={conRegistration ? 'secondary' : 'primary'}
      disabled={!!conRegistration}
      id="createNewRegistration"
      onClick={() => {
        if (!conRegistration) {
          history.push('/event/register/new');
        }
      }}
    >
      Create New Registration
    </Button>
  );
};

const RenderRegistrationItem: FC<{
  readonly conRegistration: RegistrationInfo;
  readonly refresh: () => void;
}> = ({ conRegistration, refresh }) => {
  const history = useHistory();
  const redirect = useCallback(
    (url: string) => {
      history.push(url);
    },
    [history],
  );

  return (
    <Col lg={8} xs={12}>
      <RegistrationListItem
        conRegistration={conRegistration}
        redirectFn={redirect}
        refresh={refresh}
      />
    </Col>
  );
};

const NoRegistration: FC = () => {
  const { ts } = useTranslation();
  return (
    <Col className="text-center" lg={8} xs={12}>
      <MaterialIcon large name="sentiment_dissatisfied" />
      <h4>{ts('you_dont_have_any_registrations')}</h4>
      <p>{ts('lets_get_you_registered_just')}</p>
    </Col>
  );
};
