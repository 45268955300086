import { JSONForm } from '@conventioncatcorp/common-fe/dist/components/json-form/JSONForm';
import React, { FC, useState } from 'react';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
  Button,
  Card,
  CardBody,
  CardText,
  CardTitle,
  Col,
  FormGroup,
  FormText,
  Input,
  Label,
  Row,
} from 'reactstrap';
import { DealerAssistance, DealerFullModel } from '../../../../shared/dealer';
import { MaterialIcon, UserStateComponent } from '../../../components';
import { useTranslation } from '../../../translations';
import { Fetcher, renderName, useFetcher } from '../../../utils';

export const VendorAssistantComponent: FC<{
  readonly dealer: DealerFullModel | undefined;
}> = ({ dealer }) => {
  const { ts } = useTranslation();
  return (
    <UserStateComponent>
      <InnerVendorAssistant dealer={dealer} />
      <Row className="justify-content-center">
        <Col lg={6} xs={12}>
          <Link to="/vendor">
            <Button block color="primary" style={{ marginTop: '20px' }} type="submit">
              {ts('go_back')}
            </Button>
          </Link>
        </Col>
      </Row>
    </UserStateComponent>
  );
};

const InnerVendorAssistant: FC<{
  readonly dealer: DealerFullModel | undefined;
}> = ({ dealer }) => {
  const { ts } = useTranslation();
  const [assistants, setAssistants] = useState<DealerAssistance[] | null>(null);

  const noValidDealer = !dealer || !dealer.applications.some((t) => t.status === 'approved');

  const fetcher = useFetcher(async () => {
    if (noValidDealer) {
      return [];
    }

    const fetchedAssistants = await api.getDealerAssistants(dealer.id);
    setAssistants(fetchedAssistants);
    return fetchedAssistants;
  });

  if (noValidDealer) {
    return (
      <Row className="justify-content-center" id="vendorUnapproved">
        <Col lg={6} xs={12}>
          <Card className="warning">
            <CardBody className="text-center">
              <MaterialIcon large name="assignment_late" type="warning" />
              <CardText tag="div">
                <p>{ts('as_your_vendor_application_has')}</p>
                <p>{ts('please_check_back_later')}</p>
              </CardText>
            </CardBody>
          </Card>
        </Col>
      </Row>
    );
  }

  return (
    <Row className="justify-content-center" id="dealerForm">
      <Col lg={3} xs={12}>
        <Card>
          <CardBody>
            <CardTitle>{ts('add_assistant')}</CardTitle>
            <p>{ts('you_can_nominate_up_to')}</p>
            <p>{ts('when_you_add_a_new')}</p>
            <hr />
            <JSONForm
              method="post"
              onSuccess={async () => {
                fetcher.refresh();
                toast.success(
                  'We have sent your assistant request. They receive an email asking them to confirm your request.',
                );
              }}
              path={`/api/dealers/${dealer.id}/assistants`}
            >
              <FormGroup>
                <Label for="username">Assistant Username</Label>
                <Input id="username" name="username" />
                <FormText color="muted">
                  Your assistant can find their username at the top of the left side bar once
                  they've logged in.
                </FormText>
              </FormGroup>
              <FormGroup>
                <Button block color="primary" id="submitForm" type="submit">
                  {ts('add_assistant')}
                </Button>
              </FormGroup>
            </JSONForm>
          </CardBody>
        </Card>
      </Col>
      <Col lg={7} xs={12}>
        <Card className="margin-bottom-10">
          <CardBody>
            <CardTitle>{ts('current_assistants')}</CardTitle>
            {!fetcher.complete && <Fetcher result={fetcher} />}
            {fetcher.data && fetcher.data.length === 0 && (
              <p className="text-muted">{ts('you_dont_have_any_assistants')}</p>
            )}
            {fetcher.data && fetcher.data.length > 0 && (
              <Col xs={12}>
                {assistants!.map((assistant) => (
                  <Col
                    className="clearfix-after"
                    id={`assistant${assistant.userId}`}
                    key={assistant.userId}
                    xs={12}
                  >
                    <div className="float-right">
                      <JSONForm
                        method="delete"
                        onSuccess={async () => {
                          fetcher.refresh();
                          toast.success(ts('assistant_removed'));
                        }}
                        path={`/api/dealers/${dealer.id}/assistants/${assistant.userId}`}
                      >
                        <Button className="action-delete" color="danger" type="submit">
                          <MaterialIcon name="delete" />
                        </Button>
                      </JSONForm>
                    </div>
                    <h4>{renderName(assistant.user)}</h4>
                    <div>
                      {assistant.accepted
                        ? 'Accepted'
                        : 'Pending Email Confirmation. Please ask your assistant to check their email.'}
                    </div>
                    <hr />
                  </Col>
                ))}
              </Col>
            )}
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
};
