import React, { FC } from 'react';
import { PageHeader, TermsLoader, UserStateComponent } from '../../components';
import { useTranslation } from '../../translations';

export const Privacy: FC = () => {
  const { ts } = useTranslation();
  return (
    <UserStateComponent>
      <div id="conPrivacy">
        <PageHeader>{ts('privacy_policy')}</PageHeader>
        <TermsLoader name="privacy" />
      </div>
    </UserStateComponent>
  );
};
