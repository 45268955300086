import React, { FC } from 'react';
import { toast } from 'react-toastify';
import { Webhook } from '../../../../APIClient';
import { ActionButton } from '../../../../components';
import { useTranslation } from '../../../../translations';
import { captureError } from '../../../../utils/errorHandling';

export const WebhookTable: FC<{
  readonly webhooks: Webhook[];
  readonly onChange: () => void;
}> = ({ webhooks, onChange }) => {
  const { ts } = useTranslation();
  if (webhooks.length === 0) {
    return <p>No webhooks created</p>;
  }

  return (
    <table className="table">
      <thead>
        <tr>
          <th>#</th>
          <th>{ts('name')}</th>
          <th>Url</th>
          <th>Events</th>
          <th>{ts('actions')}</th>
        </tr>
      </thead>
      <tbody>
        {webhooks.map((wh) => (
          <tr key={wh.id}>
            <td>{wh.id}</td>
            <td>{wh.name}</td>
            <td>{wh.url}</td>
            <td>{wh.events.join(', ')}</td>
            <td>
              <ActionButton
                action={`/api/webhooks/${wh.id}`}
                color="danger"
                id={`webhook-${wh.name}-delete`}
                method="delete"
                onFailure={captureError}
                onSuccess={() => {
                  toast.success('Webhook deleted');
                  onChange();
                }}
              >
                Delete
              </ActionButton>{' '}
              <ActionButton
                action={`/api/webhooks/${wh.id}/test`}
                color="warning"
                method="post"
                onFailure={captureError}
                onSuccess={() => {
                  toast.success('Success, see console for data');
                }}
              >
                Test
              </ActionButton>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};
