import React, { FC, useState } from 'react';
import { toast } from 'react-toastify';
import { Button, Card, CardBody, Col, Row } from 'reactstrap';
import { ExportListItem } from '../../../../shared/export';
import { ActionButtonModal, DateTime, MessageCard } from '../../../components';
import { ExportRequest } from '../../../models';
import { useTranslation } from '../../../translations';
import { Fetcher, useConfig, useFetcher, useQuery, useUser } from '../../../utils';
import { LoadingWrapper } from '../../../utils/LoadingWrapper';

export const ExportSettings: FC = () => {
  const { ts } = useTranslation();
  const req = useFetcher(async () => {
    const pendingExportRequests = await api.fetchPendingExportRequests();
    return { pendingExportRequests };
  }, []);

  if (!req.complete) {
    return <Fetcher result={req} />;
  }

  const { pendingExportRequests } = req.data!;

  return (
    <>
      <h3>Export data</h3>
      <br />
      <Row>
        <Col className="margin-bottom-10" lg={12} xs={12}>
          <Card>
            <CardBody>
              <p>{ts('export_the_convention_data_from')}</p>
              <p>{ts('data_exports_may_contain_personal')}</p>
            </CardBody>
          </Card>
        </Col>
        <Col xs={12} />
        <ExportGate />
      </Row>
      <br />
      <PendingRequestsTable onChange={req.refresh} pendingRequestsArray={pendingExportRequests} />
    </>
  );
};

interface PendingRequestsTableProps {
  readonly pendingRequestsArray: ExportListItem[];
  onChange(): void;
}

export const PendingRequestsTable: FC<PendingRequestsTableProps> = ({
  pendingRequestsArray,
  onChange,
}) => {
  const { ts } = useTranslation();
  if (pendingRequestsArray.length === 0) {
    return null;
  }

  return (
    <>
      <h3>{ts('pending_requests')}</h3>
      <table className="table">
        <thead>
          <tr>
            <th>{ts('datetime_created')}</th>
            <th>{ts('name_of_requesting_user')}</th>
            <th>{ts('actions')}</th>
          </tr>
        </thead>
        <tbody>
          {pendingRequestsArray.map((request) => (
            <tr key={request.id}>
              <td>
                <DateTime value={request.createdAt} />
              </td>
              <td>
                {request.requester.firstName} "{request.requester.preferredName}"{' '}
                {request.requester.lastName}
              </td>
              <td>
                <RequestButtons onChange={onChange} request={request} />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </>
  );
};

const RequestButtons: FC<{ readonly request: ExportListItem; onChange(): void }> = ({
  request,
  onChange,
}) => {
  const { ts } = useTranslation();
  const user = useUser()!;
  const {
    organization: { approvalDirect },
  } = useConfig();

  if (user.id === request.requester.id) {
    return (
      <ActionButtonModal
        actionContent="Delete"
        buttonContent="Delete"
        className="indent"
        color="primary"
        id={`delete-${request.id}`}
        onComplete={async () => {
          await api.deleteExportRequest(`${request.id}`);
          toast.success(ts('request_deleted'));
          onChange();
        }}
        title="Delete Request"
      >
        Are you sure you want to delete your request?
      </ActionButtonModal>
    );
  }

  if (approvalDirect) {
    return (
      <>
        <ActionButtonModal
          actionContent="Approve"
          buttonContent="Approve"
          className="indent"
          color="primary"
          id={`approve-${request.id}`}
          onComplete={async () => {
            await api.approveExportRequest(`${request.id}`);
            toast.success('Request approved!');
            onChange();
          }}
          title="Approve Request"
        >
          Are you sure you want to approve {request.requester.firstName}{' '}
          {request.requester.lastName}
          's request?
        </ActionButtonModal>
        <ActionButtonModal
          actionContent="Reject"
          buttonContent="Reject"
          className="indent"
          color="danger"
          id={`reject-${request.id}`}
          onComplete={async () => {
            await api.rejectExportRequest(`${request.id}`);
            toast.success('Request rejected!');
            onChange();
          }}
          title="Reject Request"
        >
          Are you sure you want to reject {request.requester.firstName} {request.requester.lastName}
          's request?
        </ActionButtonModal>
      </>
    );
  }

  return (
    <ActionButtonModal
      actionContent="Reject"
      buttonContent="Reject"
      className="indent"
      color="danger"
      id={`reject-${request.id}`}
      onComplete={async () => {
        await api.rejectExportRequest(`${request.id}`);
        toast.success('Request rejected!');
        onChange();
      }}
      title="Reject Request"
    >
      Are you sure you want to reject {request.requester.firstName} {request.requester.lastName}'s
      request?
    </ActionButtonModal>
  );
};

export const ExportBlock: FC = () => {
  const { ts } = useTranslation();
  const id = useQuery().get('code');

  if (!id) {
    return (
      <MessageCard icon="offline_bolt" level="danger" title="`code` not found on the url query" />
    );
  }

  return (
    <LoadingWrapper dataFetcher={async () => await api.rejectExportRequest(id)}>
      {() => {
        return <div id="rejectedSuccess">{ts('the_data_export_request_id', id)}</div>;
      }}
    </LoadingWrapper>
  );
};

const ExportGate: FC = () => {
  return (
    <LoadingWrapper dataFetcher={async () => await api.fetchExportRequestStatus(false)}>
      {(data, refresh) => {
        if (!data) {
          return <RequestExport refresh={refresh} />;
        }

        if (data.status === 'approved') {
          return <ExportList />;
        }

        return <RequestExportStatus request={data} />;
      }}
    </LoadingWrapper>
  );
};

const RequestExport: FC<{ refresh(): void }> = ({ refresh }) => {
  const { ts } = useTranslation();
  const {
    organization: { approvalDirect },
  } = useConfig();

  const [loading, setLoading] = useState(false);
  async function requestAccess(): Promise<void> {
    setLoading(true);
    await api.fetchExportRequestStatus(true);
    refresh();
    setLoading(false);
  }

  return (
    <Col className="margin-bottom-10" lg={12} xs={12}>
      <Card>
        <CardBody>
          <p>
            {approvalDirect ? (
              <>
                For security and user privacy, data export requires authorized administrator
                approval or a 24-hour delay in which any other admin can cancel the export request.
              </>
            ) : (
              <>
                For security and user privacy, data export requires a 24-hour delay in which any
                other admin can cancel the export request.
              </>
            )}
          </p>
          <Button color="primary" disabled={loading} id="requestExport" onClick={requestAccess}>
            {ts('request_access')}
          </Button>
        </CardBody>
      </Card>
    </Col>
  );
};

const RequestExportStatus: FC<{ readonly request: ExportRequest }> = ({ request }) => {
  const approvalDate = new Date(request.createdAt);
  approvalDate.setDate(approvalDate.getDate() + 1);

  const { ts } = useTranslation();
  return (
    <Col className="margin-bottom-10" lg={12} xs={12}>
      <Card id="approvalWaiting">
        <CardBody>
          <h4>{ts('your_export_approval_request_is')}</h4>
          <p>
            {ts('you_can_ask_an_authorized')}
            <DateTime value={approvalDate} />.
          </p>
        </CardBody>
      </Card>
    </Col>
  );
};

const ExportList: FC = () => {
  const { ts } = useTranslation();
  return (
    <>
      <Col className="margin-bottom-10" lg={12} xs={12}>
        <Card>
          <CardBody>
            <p>{ts('export_all_user_data_including')}</p>
            <ExportButtons type="user" />
          </CardBody>
        </Card>
      </Col>
      <Col className="margin-bottom-10" lg={12} xs={12}>
        <Card>
          <CardBody>
            <p>{ts('export_registration_data_including_user')}</p>
            <ExportButtons type="registration" />
          </CardBody>
        </Card>
      </Col>
      <Col className="margin-bottom-10" lg={12} xs={12}>
        <Card>
          <CardBody>
            <p>{ts('export_volunteer_data_their_names')}</p>
            <ExportButtons type="volunteer" />
          </CardBody>
        </Card>
      </Col>
      <Col className="margin-bottom-10" lg={12} xs={12}>
        <Card>
          <CardBody>
            <p>{ts('export_dealer_data_their_names')}</p>
            <ExportButtons type="dealer" />
          </CardBody>
        </Card>
      </Col>
      <Col className="margin-bottom-10" lg={12} xs={12}>
        <Card>
          <CardBody>
            <p>{ts('export_order_items_data_include')}</p>
            <ExportButtons type="orderItems" />
          </CardBody>
        </Card>
      </Col>
      <Col className="margin-bottom-10" lg={12} xs={12}>
        <Card>
          <CardBody>
            <p>{ts('export_order_data_include_each')}</p>
            <ExportButtons type="orders" />
          </CardBody>
        </Card>
      </Col>
    </>
  );
};

const ExportButtons: FC<{ readonly type: string }> = ({ type }) => {
  return (
    <div id={`export-${type}`}>
      <Button color="primary" href={`/api/export/download/${type}?format=csv`} tag="a">
        CSV
      </Button>{' '}
      <Button color="primary" href={`/api/export/download/${type}?format=json`} tag="a">
        JSON
      </Button>
    </div>
  );
};
