import React, { FC, useCallback, useState } from 'react';
import { Button, Col, FormGroup, Input, Label } from 'reactstrap';
import { OptionDataPayload } from '../../../shared/options';
import {
  ProductAddonModel,
  ProductEligibility,
  ProductModel,
  ProductOptionInput,
} from '../../../shared/orders';
import { AddToCartButton, ProductOptionViewer, RaffleContainer } from '../../components';
import { useTranslation } from '../../translations';
import { handleUnknownOptionError, isLogicError, uniqueBy } from '../../utils';
import { captureError, LogicError } from '../../utils/errorHandling';

interface ProductConfiguratorProps {
  readonly product: ProductModel;
  readonly eligibility: ProductEligibility;
  readonly quantity: number;
  onQuantityChange(quantity: number): void;
  onAddedToCart(success: true): void;
}

export const mapProductAddons = (
  productOptions: ProductOptionInput[],
  addons: ProductAddonModel[],
): ProductOptionInput[] => {
  const options = productOptions
    // Create a combined array of all base options and any options from free addons
    .concat(...addons.map((addon) => (addon.isFree && addon.options) || []))
    // Sort so all required options are at the top
    .sort((a, b) => {
      if (b.required === a.required) {
        return 0;
      }

      return a.required ? -1 : 1;
    });

  return uniqueBy(options, (opt) => opt.id);
};

export const ProductConfigurator: FC<ProductConfiguratorProps> = ({
  onAddedToCart,
  onQuantityChange,
  eligibility,
  product,
  quantity,
}) => {
  const { ts } = useTranslation();
  const { addons, options: baseOptions, raffle, isTicketed, maxGrants } = product;
  const [stockStatus, setStockStatus] = useState(product.stockStatus);

  const onFailure = useCallback(
    (error: Error) => {
      if (isLogicError(error, LogicError.InsufficientStock) && error.apiResponse.errors.logic) {
        const outofStock = error.apiResponse.errors.logic[0].args?.outofStock === 'true';

        if (outofStock) {
          setStockStatus('soldOut');
          return;
        }

        captureError(error);
      } else if (isLogicError(error, LogicError.UnknownProductOption)) {
        handleUnknownOptionError(error, product.options);
      } else {
        captureError(error);
      }
    },
    [product],
  );

  if (stockStatus === 'soldOut') {
    return (
      <Col lg={10} xs={12}>
        <FormGroup row>
          <Label sm={4}>{ts('quantity')}</Label>
          <Col lg={5} sm={4} xl={4}>
            <Input defaultValue="1" disabled min="1" type="number" />
          </Col>
        </FormGroup>
        <Button block color="secondary" disabled id="soldOut">
          {ts('sold_out')}
        </Button>
      </Col>
    );
  }

  const productOptions: OptionDataPayload = {};
  const options = mapProductAddons(baseOptions, addons);

  return (
    <>
      {options.map((o) => (
        <>
          <Col key={o.id} lg={10} xs={12}>
            <ProductOptionViewer
              onChange={(optId, opts) => (productOptions[optId] = opts)}
              option={o}
            />
          </Col>
          <Col xs={12} />
        </>
      ))}
      {options.length > 0 && <hr />}
      <Col lg={10} xs={12}>
        <RaffleContainer raffle={raffle}>
          {!isTicketed && (!maxGrants || maxGrants > 1) && (
            <FormGroup row>
              <Label sm={4}>{ts('quantity')}</Label>
              <Col lg={5} sm={4} xl={4}>
                <Input
                  defaultValue="1"
                  disabled={eligibility.grantLeft === 0}
                  max={eligibility.grantLeft ?? undefined}
                  min="1"
                  onChange={(e) => {
                    onQuantityChange(Number.parseInt(e.currentTarget.value, 10));
                  }}
                  type="number"
                />
              </Col>
            </FormGroup>
          )}
          <AddToCartButton
            eligibility={eligibility}
            onAddedToCart={onAddedToCart}
            onFailure={onFailure}
            product={product}
            productOptions={productOptions}
            quantity={quantity}
          />
        </RaffleContainer>
      </Col>
    </>
  );
};
