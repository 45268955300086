import React, { FC } from 'react';
import { Alert, Button, Card, CardBody, CardTitle, Col, Input, Label, Row } from 'reactstrap';
import { useTranslation } from '../translations';
import { useCheckboxState } from '../utils';
import { TermsLoader } from '.';

interface PolicyComponentProps {
  readonly isChild?: boolean;
  onAccept(): void;
}

export const PolicyComponent: FC<PolicyComponentProps> = ({ isChild, onAccept }) => {
  const { ts } = useTranslation();
  const [agreePrivacyTerms, setAgreePrivacyTerms] = useCheckboxState(false);
  const [agreeConTerms, setAgreeConTerms] = useCheckboxState(false);

  const continueDisabled = !agreePrivacyTerms || !agreeConTerms;
  return (
    <>
      <CardTitle>Convention Policies</CardTitle>
      <hr />
      <Card className="card-terms" color="secondary" outline>
        <CardBody>
          <TermsLoader name="terms" />
        </CardBody>
      </Card>
      <hr />
      {isChild && (
        <>
          <Alert color="primary">
            <TermsLoader name="underageWarning" />
          </Alert>
          <hr />
        </>
      )}
      <div className="custom-control custom-checkbox margin-top-10">
        <Input
          className="custom-control-input"
          id="agreeConTerms"
          name="agreeConTerms"
          onChange={setAgreeConTerms}
          type="checkbox"
        />
        <Label className="custom-control-label" for="agreeConTerms">
          {ts('i_have_read_and_agree')}
        </Label>
      </div>
      <div className="custom-control custom-checkbox margin-top-10">
        <Input
          className="custom-control-input"
          id="agreePrivacyTerms"
          name="agreePrivacyTerms"
          onChange={setAgreePrivacyTerms}
          type="checkbox"
        />
        <Label className="custom-control-label" for="agreePrivacyTerms">
          {ts('i_have_read_and_agree_to_the')}&nbsp;
          <a href="/policy/privacy" target="_blank">
            {ts('privacy_policy')}
          </a>
          .
        </Label>
      </div>
      <Row className="justify-content-center">
        <Col className="margin-top-10" lg={6} xs={12}>
          <Button
            block
            color="primary"
            disabled={continueDisabled}
            id="step2Continue"
            onClick={onAccept}
          >
            Continue
          </Button>
        </Col>
      </Row>
    </>
  );
};
