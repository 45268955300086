import React, { FC, useEffect, useState } from 'react';
import { Redirect } from 'react-router-dom';
import { FormLayout } from '../../../shared/kiosk';
import { Loading } from '../../components';
import { ErrorBoundary } from '../../components/ErrorBoundary';
import { LayoutRenderer } from '../../components/formElements/LayoutRenderer';
import { useConvention } from '../../utils';
import { captureError } from '../../utils/errorHandling';
import { KioskComponent } from './KioskComponent';

interface KioskModel {
  id: number;
  layout: FormLayout;
}

export const KioskBoundaryComponent: FC = () => {
  const [hasRetrieved, setHasRetrieved] = useState(false);
  const [kioskData, setKioskData] = useState<KioskModel>();
  const convention = useConvention();

  useEffect(() => {
    if (!convention.defaultKioskLayoutId) {
      setHasRetrieved(true);
      return;
    }

    api
      .request<KioskModel>(`/api/kiosk/${convention.defaultKioskLayoutId}`)
      .then((data) => {
        setKioskData(data);
        setHasRetrieved(true);
      })
      .catch((error) => {
        captureError(error as Error);
        setHasRetrieved(true);
      });
  }, [convention.defaultKioskLayoutId]);

  if (!convention.defaultKioskLayoutId) {
    return <OldKioskBoundaryComponent />;
  }

  if (!hasRetrieved) {
    return <Loading inline />;
  }

  if (!kioskData) {
    return <Redirect to="/" />;
  }

  return (
    <ErrorBoundary redirectText="Reset Kiosk" redirectTo="/kiosk">
      <LayoutRenderer layout={kioskData.layout} />
    </ErrorBoundary>
  );
};

export const OldKioskBoundaryComponent: FC = () => (
  <ErrorBoundary redirectText="Reset Kiosk" redirectTo="/kiosk">
    <KioskComponent />
  </ErrorBoundary>
);
