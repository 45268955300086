import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { useTranslation } from '../translations';
import { Fetcher, renderName, useFetcher } from '../utils';

interface UserListModalProps {
  readonly title: string;
  readonly userList: number[];
  close(): void;
}

// eslint-disable-next-line import/no-unused-modules
export const UserListModal: FC<UserListModalProps> = ({ title, userList, close }) => {
  const { ts } = useTranslation();
  const userRequest = useFetcher(async () => {
    return await api.getUsersByIds(userList);
  }, [userList]);

  return (
    <Modal isOpen toggle={close}>
      <ModalHeader toggle={close}>{title}</ModalHeader>
      <ModalBody>
        {!userRequest.complete && <Fetcher result={userRequest} />}
        {userRequest.complete && (
          <>
            {ts('total_results')}: {userRequest.data!.length}
            <ol>
              {userRequest.data!.map((reg) => {
                return (
                  <li id={`user${reg.id}`} key={reg.id}>
                    <Link to={`/housekeeping/attendees/user/${reg.id}`}>{renderName(reg)}</Link>
                  </li>
                );
              })}
            </ol>
          </>
        )}
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={close}>
          Close
        </Button>
      </ModalFooter>
    </Modal>
  );
};
