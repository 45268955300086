import React, { FC, useEffect, useState } from 'react';
import { PaymentGatewayConfig } from '../APIClient';
import { useTranslation } from '../translations';
import { useConvention, useGlobalState, useUser } from '../utils';
import { LoadingWrapper } from '../utils/LoadingWrapper';
import { makeLazyComponent } from '../utils/lazy';
import { Loading } from './Loading';
import { FormAlert } from './formElements/models/FormAlert';
import { PaymentProps, PaymentState } from '.';

interface PaymentGateway {
  name: string;
  type: 'stripe' | 'stripeConnect';
  lazyComponent: FC<PaymentProps>;
}

export const paymentGateways: PaymentGateway[] = [
  {
    lazyComponent: makeLazyComponent(
      async () =>
        (await import(/* webpackChunkName: "stripepayment" */ './payment/StripePayment'))
          .StripePayment,
    ),
    name: 'Stripe',
    type: 'stripeConnect',
  },
  {
    lazyComponent: makeLazyComponent(
      async () =>
        (await import(/* webpackChunkName: "stripepayment" */ './payment/StripePayment'))
          .StripePayment,
    ),
    name: 'Stripe',
    type: 'stripe',
  },
];

interface PaymentResult {
  state: PaymentState;
  message?: string;
}

interface WrapperProps {
  readonly total: number;
  readonly onSuccessTimeout?: number;
  onSuccess(): void;
}

interface Props extends WrapperProps {
  readonly gatewayConfig: PaymentGatewayConfig;
}

interface ErrorProps {
  readonly error?: string;
}

const PaymentGatewayError: FC<ErrorProps> = ({ error }) => {
  const { ts } = useTranslation();

  return (
    <div className="payment-gateway payment-gateway-error">
      <p>{ts('sorry_we_cant_accept_payments')}</p>
      <p>{ts('please_contact_registrationconventiondomain_for_assistance')}</p>
      {error && (
        <p className="text-muted">
          <small>
            <strong>Error</strong>: {error}
          </small>
        </p>
      )}
    </div>
  );
};

export const PaymentGatewayWrapper: FC<WrapperProps> = (props) => {
  /*
   * @ts-expect-error: TS6198
   * const { t, ts } = useTranslation();
   */
  return (
    <LoadingWrapper
      dataFetcher={async () => await api.getPaymentGateways()}
      errorDisplay={(err) => <PaymentGatewayError error={err.message} />}
    >
      {(data) => <PaymentGatewayComponent {...props} gatewayConfig={data} />}
    </LoadingWrapper>
  );
};

const PaymentGatewayComponent: FC<Props> = ({
  gatewayConfig,
  total,
  onSuccess,
  onSuccessTimeout = 0,
}) => {
  const { ts } = useTranslation();
  const [paymentState, setPaymentState] = useState<PaymentResult>({ state: PaymentState.Pending });
  const user = useUser();
  const convention = useConvention();
  const contacts = useGlobalState((s) => s.config?.contact);

  useEffect(() => {
    if (paymentState.state === PaymentState.Success) {
      setTimeout(onSuccess, onSuccessTimeout);
    }
  }, [paymentState, onSuccess, onSuccessTimeout]);

  // The kiosk mode timeout may log out the user.
  if (!user) {
    return <PaymentGatewayError error="User is not logged in." />;
  }

  if (paymentState.state === PaymentState.Success) {
    return (
      <div className="payment-gateway">
        <FormAlert markdown={paymentState.message!} type="success" />
      </div>
    );
  }

  for (const gateway of paymentGateways) {
    if (gateway.type === gatewayConfig.type) {
      return (
        <div className="payment-gateway">
          <h4>{ts('pay_by_credit_debit')}</h4>
          <p>
            <small>{ts('processing_by_gatewayname')}</small>
          </p>
          {paymentState.state === PaymentState.Processing && (
            <Loading>
              <p>{ts('processing_payment')}</p>
            </Loading>
          )}
          <hr />
          <div className="payment-gateway-form">
            {paymentState.state === PaymentState.Failed && (
              <FormAlert
                markdown={
                  paymentState.message ??
                  `An unexpected error occurred. Please contact ${
                    contacts?.email.registration ?? 'the registration team'
                  } for assistance.`
                }
                type="error"
              />
            )}
            <gateway.lazyComponent
              conventionName={convention.longName}
              onUpdate={(state, message) => {
                setPaymentState({ state, message });
              }}
              paymentConfig={gatewayConfig}
              total={total}
              user={user}
            />
          </div>
        </div>
      );
    }
  }

  return <PaymentGatewayError error="No payment gateways have been configured." />;
};
