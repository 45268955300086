import React, { FC } from 'react';
import { NavLink, Route, Switch, useParams, useRouteMatch } from 'react-router-dom';
import { Col, Nav, Row } from 'reactstrap';
import { ProductModel } from '../../../../../shared/orders';
import { ElementHeader, PermissionBoundary, UserStateComponent } from '../../../../components';
import { useTranslation } from '../../../../translations';
import { displayName, Fetcher, useFetcher } from '../../../../utils';
import { AuditObjectPage } from '../../audit/log';
import { ProductInfoBadges } from '../utils';
import { ProductExtraActions } from './actions';
import { DiscountInfo } from './discountinfo';
import { ImageInfoHOC } from './imageEditor/ImageInfoHOC';
import { ProductOrders } from './orders';
import { ProductOption } from './productoptions';
import { AddonInfo, PriceInfo, ProductInfo, SurchargeInfo, UpgradeInfo } from '.';

export const ProductViewer: FC = () => {
  const { id: paramId } = useParams<{ id: string }>();

  const fetcher = useFetcher(async () => {
    return await api.getProductById(Number.parseInt(paramId, 10));
  }, [paramId]);

  if (!fetcher.complete) {
    return <Fetcher result={fetcher} />;
  }

  return (
    <UserStateComponent>
      <ProductDisplay product={fetcher.data!} refresh={fetcher.refresh} />
    </UserStateComponent>
  );
};

const ProductDisplay: FC<{ readonly product: ProductModel; readonly refresh: () => void }> = ({
  product,
  refresh,
}) => {
  const { ts } = useTranslation();
  const { url } = useRouteMatch();

  return (
    <>
      <ElementHeader
        icon={{ iconName: 'list' }}
        title={`Product: ${displayName(product)} (ID: ${product.id})`}
      >
        <ProductInfoBadges product={product} />
      </ElementHeader>
      <Nav tabs>
        <NavLink activeClassName="active" className="nav-link" exact to={url}>
          Product
        </NavLink>
        <NavLink activeClassName="active" className="nav-link" to={`${url}/orders`}>
          Orders
        </NavLink>
        <PermissionBoundary inline requiredPermissions={['audit:read']}>
          <NavLink activeClassName="active" className="nav-link" to={`${url}/auditlogs`}>
            {ts('audit_logs')}
          </NavLink>
        </PermissionBoundary>
      </Nav>
      <Switch>
        <Route
          exact
          path={url}
          render={() => <ProductDetails product={product} refresh={refresh} />}
        />
        <Route exact path={`${url}/orders`} render={() => <ProductOrders product={product} />} />
        <Route
          exact
          path={`${url}/auditlogs`}
          render={() => <ProductAuditLogs product={product} />}
        />
      </Switch>
    </>
  );
};

const ProductDetails: FC<{ readonly product: ProductModel; readonly refresh: () => void }> = ({
  product,
  refresh,
}) => {
  return (
    <Row id={`product${product.id}`}>
      <Col lg={8} xs={12}>
        <ProductInfo onUpdate={refresh} product={product} />
        <ImageInfoHOC onUpdate={refresh} product={product} />
        <AddonInfo onUpdate={refresh} product={product} />
        <DiscountInfo onUpdate={refresh} product={product} />
        {product.category.isRegistration && <UpgradeInfo onUpdate={refresh} product={product} />}
        <ProductOption onUpdate={refresh} product={product} />
        <SurchargeInfo onUpdate={refresh} product={product} />
      </Col>
      <Col lg={4} xs={12}>
        <PriceInfo onUpdate={refresh} product={product} />
        <PermissionBoundary requiredPermissions={['product:manage']}>
          <ProductExtraActions onUpdate={refresh} product={product} />
        </PermissionBoundary>
      </Col>
    </Row>
  );
};

const ProductAuditLogs: FC<{ readonly product: ProductModel }> = ({ product }) => {
  return <AuditObjectPage entityId="Product" objectId={product.id.toString()} />;
};
