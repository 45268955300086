import React, { FC, useState } from 'react';
import { FormGroup, Label } from 'reactstrap';
import { CountrySelector, RegionSelector } from '../../..';
import { CurrentUser } from '../../../../../shared/user/base';
import { useTranslation } from '../../../../translations';

interface Props {
  readonly user: CurrentUser | null;
}

export const FormNodePIIRegionAddress: FC<Props> = ({ user }) => {
  const { ts } = useTranslation();
  const [country, setCountry] = useState('US');
  const [region, setRegion] = useState('');

  return (
    <>
      <div className="form-node form-country-selector">
        <FormGroup>
          <Label>{ts('country')}</Label>
          {user ? (
            <p>
              <strong>{user.addressCountry}</strong>
            </p>
          ) : (
            <>
              <input name="addressCountry" type="hidden" value={country} />
              <CountrySelector
                autoComplete="country-name"
                classes="form-control"
                id="addressCountry"
                name="addressCountry"
                onChange={setCountry}
                value={country}
                valueType="short"
              />
            </>
          )}
        </FormGroup>
      </div>
      <div className="form-node form-state-province-selector">
        <FormGroup>
          <Label>{ts('state_province')}</Label>
          {user ? (
            <p>
              <strong>{user.addressState}</strong>
            </p>
          ) : (
            <>
              <input name="addressState" type="hidden" value={region} />
              <RegionSelector
                autoComplete="address-level1"
                classes="form-control"
                country={country}
                countryValueType="short"
                data-coerce={false}
                disableWhenEmpty
                id="addressState"
                name="addressState"
                onChange={setRegion}
                value={region}
                valueType="short"
              />
            </>
          )}
        </FormGroup>
      </div>
    </>
  );
};
