import React, { FC, useCallback, useState } from 'react';
import { Col, FormGroup, FormText, Input, Label } from 'reactstrap';
import { StripeIdentityUser } from '../../shared/user/identity';
import { useTranslation } from '../translations';
import { CountrySelector } from './CountrySelector';
import { DatePicker, PhoneInput, RegionSelector } from '.';

interface RegisterUserInfoProps {
  readonly forceValues?: StripeIdentityUser;
}

function getDob(input: StripeIdentityUser): string {
  const utcDate = input.bornAt.getUTCDate();
  const utcMonth = input.bornAt.getUTCMonth() + 1;

  const month = utcMonth < 10 ? `0${utcMonth}` : `${utcMonth}`;
  const day = utcDate < 10 ? `0${utcDate}` : `${utcDate}`;

  return `${input.bornAt.getUTCFullYear()}-${month}-${day}`;
}

export const RegisterUserInfo: FC<RegisterUserInfoProps> = ({ forceValues }) => {
  const { ts } = useTranslation();
  const [country, setCountry] = useState('');
  const [phone, setPhone] = useState<string | null>(null);
  const [region, setRegion] = useState('');
  const [dateOfBirth, setDateOfBirth] = useState<string | null>(null);

  const defaultDoB = useCallback(() => {
    if (!forceValues?.bornAt) {
      return null;
    }

    return getDob(forceValues);
  }, [forceValues]);

  return (
    <>
      <FormGroup row>
        <Label for="firstName" sm={4}>
          {ts('first_name')}
        </Label>
        <Col sm={8}>
          <Input
            autoComplete="given-name"
            defaultValue={forceValues?.firstName}
            disabled={!!forceValues}
            id="firstName"
            name="firstName"
            placeholder={`${ts('example')}: John`}
          />
          {forceValues?.lastName ? (
            <FormText color="info">{ts('this_field_has_been_prepopulated_firstname')}</FormText>
          ) : (
            <FormText color="muted">{ts('this_must_be_your_legal_first')}</FormText>
          )}
        </Col>
      </FormGroup>
      <FormGroup row>
        <Label for="lastName" sm={4}>
          {ts('last_name')}
        </Label>
        <Col sm={8}>
          <Input
            autoComplete="family-name"
            defaultValue={forceValues?.lastName}
            disabled={!!forceValues}
            id="lastName"
            name="lastName"
            placeholder={`${ts('example')}: Doe`}
          />
          {forceValues?.lastName ? (
            <FormText color="info">{ts('this_field_has_been_prepopulated_lastname')}</FormText>
          ) : (
            <FormText color="muted">{ts('this_must_be_your_legal')}</FormText>
          )}
        </Col>
      </FormGroup>
      <FormGroup row>
        <Label for="preferredName" sm={4}>
          {ts('preferred_name')}
          <small>(Optional)</small>
        </Label>
        <Col sm={8}>
          <Input
            autoComplete="username"
            id="preferredName"
            name="preferredName"
            placeholder={`${ts('example')}: Johnny`}
          />
          <FormText color="muted">{ts('if_you_have_a_name')}</FormText>
        </Col>
      </FormGroup>
      <FormGroup row>
        <Label for="bornAt" sm={4}>
          {ts('date_of_birth')}
        </Label>
        <Col sm={8}>
          <DatePicker
            defaultDate={defaultDoB()}
            disabled={!!forceValues}
            onChange={setDateOfBirth}
          />
          <Input
            hidden
            id="bornAt"
            name="bornAt"
            value={dateOfBirth ?? defaultDoB() ?? undefined}
          />
          {forceValues?.lastName ? (
            <FormText className="mt-0" color="info">
              {ts('this_field_has_been_prepopulated_birthday')}
            </FormText>
          ) : (
            <FormText className="mt-0">{ts('you_must_be_at_least')}</FormText>
          )}
        </Col>
      </FormGroup>
      <hr />
      <FormGroup row>
        <Label for="addressLine1" sm={4}>
          {ts('address_line_1')}
        </Label>
        <Col sm={8}>
          <Input
            autoComplete="address-line1"
            id="addressLine1"
            name="addressLine1"
            placeholder={`${ts('example')}: 123 Main St`}
          />
        </Col>
      </FormGroup>
      <FormGroup row>
        <Label for="addressLine2" sm={4}>
          {ts('address_line_2_optional')} <small>{ts('optional')}</small>
        </Label>
        <Col sm={8}>
          <Input
            autoComplete="address-line2"
            id="addressLine2"
            name="addressLine2"
            placeholder={`${ts('example')}: Apt 2`}
          />
        </Col>
      </FormGroup>
      <FormGroup row>
        <Label for="addressCity" sm={4}>
          City
        </Label>
        <Col sm={8}>
          <Input
            autoComplete="address-level2"
            id="addressCity"
            name="addressCity"
            placeholder={`${ts('example')}: Reno`}
          />
        </Col>
      </FormGroup>
      <FormGroup row>
        <Label for="addressZipcode" sm={4}>
          {ts('zip_postal_code')}
        </Label>
        <Col sm={8}>
          <Input
            autoComplete="postal-code"
            id="addressZipcode"
            name="addressZipcode"
            placeholder={`${ts('example')}: 89501`}
          />
        </Col>
      </FormGroup>
      <FormGroup row>
        <Label for="addressCountry" sm={4}>
          Country
        </Label>
        <Col sm={8}>
          <CountrySelector
            autoComplete="country-name"
            classes="form-control"
            id="addressCountry"
            name="addressCountry"
            onChange={setCountry}
            value={country}
            valueType="short"
          />
        </Col>
      </FormGroup>
      <FormGroup row>
        <Label for="addressState" sm={4}>
          {ts('state_province')}
        </Label>
        <Col sm={8}>
          <RegionSelector
            classes="form-control"
            country={country}
            countryValueType="short"
            data-coerce={false}
            disableWhenEmpty
            id="addressState"
            name="addressState"
            onChange={setRegion}
            value={region}
            valueType="short"
          />
        </Col>
      </FormGroup>
      <FormGroup row>
        <Label for="phone" sm={4}>
          {ts('phone_number')}
        </Label>
        <Col sm={8}>
          <PhoneInput name="phone" onChange={setPhone} value={phone ?? ''} />
        </Col>
      </FormGroup>
    </>
  );
};
