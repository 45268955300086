import React, { FC } from 'react';
import { Badge, FormGroup, Label } from 'reactstrap';
import { ProductOptionRenderer } from '../../';
import { FormOptionModel } from '../../../../shared/kiosk';
import { ProductOptionInput } from '../../../../shared/orders/product';
import { LazyMarkdown } from '../../LazyMarkdown';

interface FormNodeProductOptionProps extends FormOptionModel {
  readonly productOptions: ProductOptionInput[];
}

export const FormNodeProductOption: FC<FormNodeProductOptionProps> = ({
  defaultValue,
  id,
  markdown,
  optionId,
  productOptions = [],
}) => {
  const productOption = productOptions.find((option) => option.id === optionId);
  if (!productOption) {
    throw new Error(
      `Critical Render Failure: Missing productOption where id = ${optionId}. Contact Support.`,
    );
  }

  return (
    <div className="form-node form-node-product-option" id={`node-${id}`}>
      <FormGroup>
        <Label>
          <LazyMarkdown source={markdown} />{' '}
          {!productOption.required && <Badge color="secondary">Optional</Badge>}
        </Label>
        <ProductOptionRenderer
          defaultValue={defaultValue}
          option={productOption}
          required={productOption.required}
        />
      </FormGroup>
    </div>
  );
};
