import { APIError } from '@conventioncatcorp/common-fe/dist/APIClient';
import React, { FC, FormEvent, useCallback, useState } from 'react';
import { FormFeedback, Input } from 'reactstrap';
import { UniqueAttribute } from '../APIClient';
import { useTranslation } from '../translations';
import { delayPromise, randInt } from '../utils';

export enum UniqueInputState {
  INVALID = -2,
  INUSE = -1,
  UNCHECKED = 0,
  AVAILABLE = 1,
}

export interface UniqueAttributeProps {
  readonly type: UniqueAttribute;
  readonly onChange?: (val: string) => void;
}
export interface UniqueAttributeState {
  state: UniqueInputState;
}

const StateMessage: FC<{
  readonly type: UniqueAttribute;
  readonly state: UniqueInputState;
}> = ({ type, state }) => {
  const { ts } = useTranslation();
  switch (state) {
    case UniqueInputState.INVALID: {
      if (type === 'username') {
        return <>{ts('that_username_is_invalid_usernames')}</>;
      }

      return <>{`This ${type} is invalid`}</>;
    }

    case UniqueInputState.INUSE: {
      return <>{`This ${type} is already in use.`}</>;
    }

    case UniqueInputState.AVAILABLE: {
      return <>{`Great! This ${type} is available`}</>;
    }

    default: {
      return null;
    }
  }
};

export const UniqueAttributeInput: FC<{
  readonly type: UniqueAttribute;
  readonly onChange?: (val: string) => void;
}> = ({ type, onChange }) => {
  const [state, setState] = useState<UniqueInputState>(UniqueInputState.UNCHECKED);

  const checkUnique = useCallback(
    async (event: FormEvent<HTMLInputElement>, field: UniqueAttribute) => {
      let localState = UniqueInputState.INVALID;
      const { value } = event.currentTarget;
      try {
        // If exists, 200. If not found, 404.
        localState = (await api.checkUserAttributeUnique(value, field))
          ? UniqueInputState.AVAILABLE
          : UniqueInputState.INUSE;
      } catch (error) {
        if (!(error instanceof APIError) || error.apiResponse.errors.rateLimit) {
          throw error;
        }

        await delayPromise(randInt(100, 250));
      }

      setState(localState);

      if (onChange) {
        onChange(value);
      }
    },
    [setState, onChange],
  );

  return (
    <>
      <Input
        autoComplete={type === 'username' ? 'username' : 'email'}
        id={type}
        // eslint-disable-next-line @typescript-eslint/no-unsafe-enum-comparison
        invalid={state < 0}
        name={type}
        onBlur={async (el) => await checkUnique(el, type)}
        placeholder={`Example: ${type === 'email' ? 'bobcat@concat.app' : 'bobcat18'}`}
        type={type === 'email' ? 'email' : 'text'}
      />
      {state !== UniqueInputState.UNCHECKED && (
        <FormFeedback
          invalid={state === UniqueInputState.INVALID ? 'true' : undefined}
          valid={state === UniqueInputState.AVAILABLE}
        >
          <StateMessage state={state} type={type} />
        </FormFeedback>
      )}
    </>
  );
};
