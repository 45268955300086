import React, { FC } from 'react';
import { Card, CardBody, CardText, Col, Row } from 'reactstrap';
import { useTranslation } from '../translations';
import { MaterialIcon } from '.';

interface AccessDeniedProps {
  readonly message?: string;
}

export const AccessDenied: FC<AccessDeniedProps> = ({ message }) => {
  const { t } = useTranslation();

  return (
    <Row className="justify-content-center" id="accessDenied">
      <Col lg={6} xs={12}>
        <Card className="danger">
          <CardBody className="text-center">
            <MaterialIcon large name="block" type="danger" />
            <CardText>{message ?? t('access_denied.text')}</CardText>
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
};
