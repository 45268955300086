import { JSONForm } from '@conventioncatcorp/common-fe/dist/components/json-form/JSONForm';
import React, { FC } from 'react';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { Surcharge } from '../../../models';
import { useTranslation } from '../../../translations';
import { SurchargeForm } from './SurchargeForm';

interface Props {
  readonly open?: boolean;
  onUpdate(id: number): void;
  onCancel(): void;
}

export const CreateModal: FC<Props> = ({ open, onCancel, onUpdate }) => {
  const { ts } = useTranslation();
  return (
    <Modal id="createSurchargeModal" isOpen={open}>
      <JSONForm<{ surcharge: Surcharge }, { amount: number }>
        id="createSurchargeForm"
        method="post"
        onSuccess={({ surcharge }) => {
          onUpdate(surcharge.id);
        }}
        path="/api/surcharges"
        preSubmit={(r) => (r.inputs!.amount *= 100)}
      >
        <ModalHeader>Create Surcharge</ModalHeader>
        <ModalBody>
          <SurchargeForm />
        </ModalBody>
        <ModalFooter>
          <Button color="primary" id="createSurcharge">
            {ts('create_surcharge')}
          </Button>
          <Button color="secondary" onClick={onCancel}>
            {ts('cancel')}
          </Button>
        </ModalFooter>
      </JSONForm>
    </Modal>
  );
};
