import React, { Component } from 'react';
import { Button, Col, Row } from 'reactstrap';
import { ChildRegistrations, MessageCard } from '../../components';
import { ChildRegistration } from '../../models';
import { useTranslation } from '../../translations';
import { isLogicError } from '../../utils';
import { LogicError } from '../../utils/errorHandling';

const ParentCannotVolunteer: React.FC = () => {
  const { ts } = useTranslation();
  return (
    <MessageCard icon="face" id="parentCanNotVolunteer" level="warning">
      {ts('no_human_being_can_possibly')}
    </MessageCard>
  );
};

interface KioskChildBadgesProps {
  readonly registrationId: number;
  onRegister(): void;
}

interface KioskChildBadgesState {
  children: ChildRegistration[];
  isDisallowed?: boolean;
}

export class KioskChildBadges extends Component<KioskChildBadgesProps> {
  public override state: KioskChildBadgesState = {
    children: [],
  };

  public override async componentDidMount(): Promise<void> {
    await this.updateChildren();
  }

  public override render(): JSX.Element {
    return (
      <>
        {this.showChildOptions()}
        <Row className="justify-content-center">
          <Col className="margin-top-10" lg={6} xs={12}>
            <Button
              block
              color="primary"
              id="step3Continue"
              onClick={this.props.onRegister}
              outline
            >
              Continue
            </Button>
          </Col>
        </Row>
      </>
    );
  }

  private showChildOptions(): JSX.Element {
    const { registrationId } = this.props;
    const { children, isDisallowed } = this.state;

    if (isDisallowed) {
      return <ParentCannotVolunteer />;
    }

    return (
      <ChildRegistrations
        items={children}
        onUpdate={async () => await this.updateChildren()}
        registrationId={registrationId}
      />
    );
  }

  private async updateChildren(): Promise<void> {
    try {
      const children = await api.getCurrentUserChildren();
      this.setState({
        children,
      });
    } catch (error) {
      if (
        isLogicError(error, LogicError.ChildrenNotAllowed) ||
        isLogicError(error, LogicError.ParentCannotVolunteer)
      ) {
        this.setState({
          isDisallowed: true,
        });
      }

      throw error;
    }
  }
}
